var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c("notifications", { attrs: { group: "top-right" } }),
      _c("form-login", {
        attrs: {
          form: _vm.title.form,
          userName: _vm.title.userName,
          password: _vm.title.password,
          isLoading: _vm.isLoading
        },
        on: { "emit-login": _vm.login }
      }),
      _vm.switchModal
        ? _c("modal-check-secret", {
            attrs: {
              title: "2段階認証を行います",
              text: "Authenticatorに表示された6桁の数字を入力してください",
              acceptBtn: "2段階認証",
              cancelBtn: "戻る",
              disabledOverlay: ""
            },
            on: { emit: _vm.loginComplete }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }