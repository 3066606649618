<template>
  <li class='list-slide'>
    <button class='list-slide__top' @click='active'>
      <p class='list-slide__top__left'>{{ title }}</p>
      <div class='list-slide__top__right'>
        <i v-if='isActive' class="list-slide__top__right__btn fas fa-caret-up"></i>
        <i v-if='!isActive' class="list-slide__top__right__btn fas fa-caret-down"></i>
      </div>
    </button>
    <div class='list-slide__bottom' v-if='isActive'>
      <p>{{ text }}</p>
    </div>
  </li>
</template>

<script>

export default {
  props: {
    title: String,
    text: String,
  },
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    active() {
      this.isActive = !this.isActive;
    },
  },
};
</script>

<style scoped>
.list-slide {
  background-color: white;
  list-style: none;
  border-top: 1px solid #F2EEEE;
}

.list-slide:last-child {
  border-bottom: 1px solid #F2EEEE;
}

.list-slide__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  width: 100%;
}

.list-slide__top__left {
  font-weight: 500;
  text-align: left;
}

.list-slide__top__right__btn {
  color: #CBCBCB;
  font-size: 24px;
  margin-left: 20px;
}

.list-slide__bottom {
  font-size: 14px;
  margin: 0 30px;
  padding: 30px 0;
  border-top: 1px solid #F2EEEE;
  white-space:pre-wrap;
  word-wrap:break-word;
}
</style>
