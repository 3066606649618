<template>
  <div class='lesson-output'>
    <div class='lesson-output__top'>
      <lesson-output-tab :tabInfo='tabInfo' @emit='changeFocus' />
    </div>
    <div class='lesson-output__bottom'>
      <p v-show='selectId === 0' v-html='runResult.user' />
      <p v-show='selectId === 1' v-html='runResult.sample' />
    </div>
  </div>
</template>

<script>
import { LessonOutputTab } from '@/components/atoms/lesson';

export default {
  components: {
    LessonOutputTab,
  },
  props: {
    editSource: String,
    sampleSource: String,
    isActiveCodeRun: Boolean,
  },
  data() {
    return {
      selectId: 0,
      tabInfo: ['出力結果', '見本'],
      runResult: {
        user: '',
        sample: '',
      },
    };
  },
  mounted() {
    // View全体レンダリング後「出力結果」「見本」のソースを実行
    this.$nextTick(function () {
      this.codeRun(this.editSource, 'user');
      this.codeRun(this.sampleSource, 'sample');
    });
  },
  methods: {
    // タブ、コンソールを連結させているselectIdを変更する
    changeFocus(index) {
      this.selectId = index;
    },

    // コードを実行する
    async codeRun(source, target) {
      const vm = this; // Vueインスタンスのthisをvmに避難

      // JS既存関数をmanabiba仕様に再定義
      const originalConsole = {
        // eslint-disable-next-line no-console
        log: console.log,
        // eslint-disable-next-line no-console
        error: console.error,
        // eslint-disable-next-line no-console
        warn: console.warn,
      };

      // eslint-disable-next-line no-console
      console.log = function (val) {
        vm.runResult[target] += `${val}<br>`;
      };
      // eslint-disable-next-line no-console
      console.error = function (val) {
        // Vue Warnを弾く
        if (typeof val === 'string') {
          if (val.match(/Vue warn/)) return;
        }
        vm.runResult[target] += `${val}<br>`;
      };
      // eslint-disable-next-line no-console
      console.warn = function (val) {
        vm.runResult[target] += `${val}<br>`;
      };

      // コード実行
      this.runResult[target] = '';
      // eslint-disable-next-line no-new-func
      await new Function(source)();

      // manabiba仕様に定義した関数を元に戻す
      // eslint-disable-next-line no-console
      console.log = originalConsole.log;
      // eslint-disable-next-line no-console
      console.error = originalConsole.error;
      // eslint-disable-next-line no-console
      console.warn = originalConsole.warn;
    },
  },
  watch: {
    // コード実行イベント取得後codeRunメソッド実行 実行イベントを親に返却
    isActiveCodeRun(to) {
      if (to) {
        this.codeRun(this.editSource, 'user');
        this.$emit('emit-code-run');
      }
    },
  },
};
</script>

<style scoped>
.lesson-output {
  width: 100%;
}

.lesson-output__top {
  width: 100%;
}

.lesson-output__bottom {
  width: 100%;
  height: calc(100vh - 110px);
  border: none;
  color: #fff;
  background-color: #191b24;
  padding: 10px;
  overflow: scroll;
}

.lesson-output__bottom::-webkit-scrollbar {
  display: none;
}
</style>
