<template>
  <div class='detail'>
    <LayoutHeader />
    <div class='detail__contents'>
      <list-menu
        class='detail__contents__left'
        :targetList='createTitleList'
        :defaultSelectId=3
        @emit='movePageMenu'
      />
      <div class='detail__contents__right'>
        <title-normal
          class='detail__contents__right__title'
          :title='titleBar.title'
          :button='titleBar.button'
          :disabled='!isVaild || adminInfo.profile.status != 99'
          :loading='isLoading.putAdminUserDetail'
          @emit='execUpdateAdminUser'
        />
        <icon-loading v-if='isLoading.loadAdminUserDetail' blue/>
        <div class='detail__contents__right__form' v-if="!isLoading.loadAdminUserDetail">
          <div class='detail__contents__right__form__name'>
            <heading-normal small>{{ form.userName }}</heading-normal>
            <input-text :text='defaultInfo.userName' @emit='saveUserame' />
          </div>
          <div class='detail__contents__right__form__email'>
            <heading-normal small>{{ form.email }}</heading-normal>
            <input-text :text='defaultInfo.email' @emit='saveEmail' />
          </div>
          <div class='detail__contents__right__form__auth'>
            <heading-normal small>{{ form.auth }}</heading-normal>
            <select v-model='submitInfo.auth'>
              <option
                v-for='(type, index) in types'
                :value='type.id'
                :key='index'
              >{{ type.name }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import helper from '@/mixins/admin/methods/helper';
import pageList from '@/mixins/admin/data/pageList';
import LayoutHeader from '@/components/layouts/admin/LayoutHeader';
import { TitleNormal } from '@/components/molecules/other';
import { HeadingNormal } from '@/components/atoms/heading';
import { IconLoading } from '@/components/atoms/icon/';
import { InputText } from '@/components/atoms/input';
import { ListMenu } from '@/components/atoms/list';

export default {
  components: {
    LayoutHeader,
    TitleNormal,
    HeadingNormal,
    IconLoading,
    InputText,
    ListMenu,
  },
  data() {
    return {
      isLoading: {
        putAdminUserDetail: false,
        loadAdminUserDetail: false,
      },
      types: [
        {
          id: 1,
          name: '閲覧者',
        },
        {
          id: 99,
          name: '管理者',
        },
      ],
      titleBar: {
        title: '管理ユーザー詳細',
        button: '更新',
      },
      form: {
        userName: 'アカウント名',
        email: 'メールアドレス',
        auth: '権限',
      },
      submitInfo: {
        userName: '',
        email: '',
        auth: 1,
      },
      defaultInfo: {},
    };
  },
  mixins: [helper, pageList],
  computed: {
    ...mapState('admin', ['adminInfo']),
    createTitleList() {
      const titleList = this.pageList.map((page) => page.title);
      return titleList;
    },
    isVaild() {
      // 入力内容が変わっていないパターン
      if (this.submitInfo.userName === this.defaultInfo.userName
        && this.submitInfo.email === this.defaultInfo.email
        && this.submitInfo.auth === this.defaultInfo.auth) {
        return false;
      }
      // フォーム空パターン
      if (!this.submitInfo.userName || !this.submitInfo.email) {
        return false;
      }
      return true;
    },
  },
  created() {
    this.loadAdminUserDetail();
  },
  methods: {
    ...mapActions('admin', ['updateAdminUser']),
    movePageMenu(index) {
      const { name } = this.pageList[index];
      this.$router.push({ name }, () => {});
    },
    saveUserame(userName) {
      this.submitInfo.userName = userName;
    },
    saveEmail(email) {
      this.submitInfo.email = email;
    },
    async loadAdminUserDetail() {
      try {
        this.isLoading.loadAdminUserDetail = !this.isLoading.loadAdminUserDetail;
        this.$http.defaults.headers.common.Authorization = `Bearer ${this.adminInfo.token}`;
        const { data } = await this.$http.get(`/api/admin/adminusers/${this.$route.params.id}`);
        this.isLoading.loadAdminUserDetail = !this.isLoading.loadAdminUserDetail;

        this.defaultInfo.userName = data.username;
        this.defaultInfo.email = data.email;
        this.defaultInfo.auth = data.status;
        this.submitInfo.userName = this.defaultInfo.userName;
        this.submitInfo.email = this.defaultInfo.email;
        this.submitInfo.auth = this.defaultInfo.auth;
      } catch (e) {
        if (e.response !== undefined && e.response.status === 401) {
          this.unAdminAuthenticate();
        } else {
          this.movePage('AdminError');
        }
      }
    },
    async execUpdateAdminUser() {
      try {
        this.isLoading.putAdminUserDetail = !this.isLoading.putAdminUserDetail;
        const res = await this.updateAdminUser(
          {
            submitInfo: this.submitInfo,
            adminId: this.$route.params.id,
          },
        );
        if (res === 401) {
          this.$store.commit('admin/logout');
          this.movePage('AdminLogin', '', { url: this.$route.path });
        }
        if (res === false) throw new Error();
        this.defaultInfo.userName = res.data.username;
        this.defaultInfo.email = res.data.email;
        this.defaultInfo.auth = res.data.status;
        // isVaildを作動させるために一度空に
        this.submitInfo = {
          userName: '',
          email: '',
          auth: 0,
        };
        this.submitInfo.userName = res.data.username;
        this.submitInfo.email = res.data.email;
        this.submitInfo.auth = res.data.status;
        this.isLoading.putAdminUserDetail = !this.isLoading.putAdminUserDetail;
        this.showNoticeBar('success', '管理ユーザー情報の更新を完了しました');
      } catch (e) {
        this.isLoading.putAdminUserDetail = !this.isLoading.putAdminUserDetail;
        this.showNoticeBar('error', '管理ユーザー情報の更新に失敗しました');
      }
    },
  },
};
</script>

<style scoped>
.detail {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f9fa;
}

.detail__contents {
  display: flex;
  margin: 20px;
}

.detail__contents__left {
  width: 15%;
  background-color: #fff;
  padding: 20px;
}

.detail__contents__right {
  width: 85%;
  margin-left: 20px;
}

.detail__contents__right__title {
  margin-bottom: 10px;
}

.detail__contents__right__form{
  padding: 20px;
  padding-right: 50%;
  width: 100%;
  text-align: left;
  background-color: #fff;
}

.detail__contents__right__form__name {
  margin-bottom: 20px;
}

.detail__contents__right__form__email {
  margin-bottom: 20px;
}

.detail__contents__right__form__auth select {
  -webkit-appearance: menulist;
  border: 2px solid #d1d3d9;
  font-size: 14px;
  outline: none;
}
</style>
