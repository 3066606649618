import axios from 'axios';

const axiosBase = axios.create({
  baseURL: process.env.VUE_APP_HOST,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  credentials: true,
  responseType: 'json',
});

export default {
  namespaced: true,
  state: {
    adminInfo: {
      login: false,
      token: '',
      secret: '',
      profile: {
        id: '',
        name: '',
        userName: '',
        email: '',
        status: '',
      },
    },
  },
  mutations: {
    login(state, adminInfo) {
      const admin = state;
      admin.adminInfo.login = true;
      admin.adminInfo.token = adminInfo.token;
      admin.adminInfo.secret = adminInfo.secret;
      admin.adminInfo.profile.id = adminInfo.admin.id;
      admin.adminInfo.profile.name = adminInfo.admin.name;
      admin.adminInfo.profile.userName = adminInfo.admin.username;
      admin.adminInfo.profile.email = adminInfo.admin.email;
      admin.adminInfo.profile.status = adminInfo.admin.status;
    },
    set2FAToken(state, token) {
      const admin = state;
      admin.adminInfo.token = token;
    },
    saveProfile(state, profile) {
      const admin = state;
      admin.adminInfo.profile.user = profile.status;
      admin.adminInfo.profile.userName = profile.userName;
      admin.adminInfo.profile.email = profile.email;
      admin.adminInfo.profile.status = profile.status;
    },
    logout(state) {
      const admin = state;
      admin.adminInfo = {
        login: false,
        token: '',
        secret: '',
        profile: {
          id: '',
          name: '',
          userName: '',
          email: '',
          status: '',
        },
      };
    },
  },
  actions: {
    // emailから新規Admin作成処理
    async createAdmin(context, formData) {
      try {
        // adminsignupフォームデータ整理
        const data = new FormData();
        if (formData.userName !== null) {
          data.append('email', formData.email);
        }
        if (formData.password !== null) {
          data.append('status', formData.status);
        }
        // adminsignupフォームデータ送信
        axiosBase.defaults.headers.common.Authorization = `Bearer ${formData.token}`;
        await axiosBase.post('/api/admin/create/', data);
        return true;
      } catch (e) {
        if (e.response.status === 401) return e.response.status;
        return false;
      }
    },

    // サインアップ
    async signup(context, formData) {
      try {
        const admin = context;
        // adminsignupフォームデータ整理
        const data = new FormData();
        if (formData.userName !== null) {
          data.append('username', formData.userName);
        }
        if (formData.password !== null) {
          data.append('password', formData.password);
        }
        if (formData.password !== null) {
          data.append('hash', formData.hash);
        }
        // adminsignupフォームデータ送信
        const res = await axiosBase.post('/api/admin/signup/', data);
        await admin.commit('set2FAToken', res.data.token);
        return true;
      } catch (e) {
        if (e.response.status === 401) return e.response.status;
        return false;
      }
    },
    // シークレットキーの保存
    async saveSecret(context, formData) {
      try {
        // フォームデータ整理
        const data = new FormData();
        if (formData.secretKey !== null) {
          data.append('secret', formData.secretKey);
        }
        // adminsecretフォームデータ送信
        axiosBase.defaults.headers.common.Authorization = `Bearer ${formData.token}`;
        await axiosBase.post('/api/admin/secret/', data);
        return true;
      } catch (e) {
        return false;
      }
    },
    // ログイン処理
    async checkAdmin(context, formData) {
      try {
        // adminloginフォームデータ整理
        const data = new FormData();
        if (formData.userName !== null) {
          data.append('username', formData.userName);
        }
        if (formData.password !== null) {
          data.append('password', formData.password);
        }
        // adminloginフォームデータ送信
        const res = await axiosBase.post('/api/admin/login/', data, {
          'Content-Type': 'multipart/form-data',
        });
        return res;
      } catch (e) {
        return false;
      }
    },
    // ログイン処理で返却された値をstateに保存
    setAdmin(context, adminInfo) {
      const admin = context;
      admin.commit('login', adminInfo);
    },
    // 更新処理
    async updateAdminUser(context, payload) {
      try {
        const admin = context;

        // 更新データ整理
        const data = new URLSearchParams();
        if (payload.submitInfo.userName !== null) {
          data.append('username', payload.submitInfo.userName);
        }
        if (payload.submitInfo.email !== null) {
          data.append('email', payload.submitInfo.email);
        }
        if (payload.submitInfo.auth !== null) {
          data.append('status', payload.submitInfo.auth);
        }

        axiosBase.defaults.headers.common.Authorization = `Bearer ${admin.state.adminInfo.token}`;
        const res = await axiosBase.put(`/api/admin/adminusers/${payload.adminId}`, data);

        // 自身の情報の更新であればStateを更新
        if (payload.adminId === admin.state.adminInfo.profile.id) {
          // Stateのプロフィールを更新
          const profile = {
            name: res.data.name,
            userName: res.data.username,
            email: res.data.email,
            status: res.data.status,
          };
          admin.commit('saveProfile', profile);
        }
        return res;
      } catch (e) {
        if (e.response.status === 401) return e.response.status;
        return false;
      }
    },
    // ログアウト処理
    async deleteAdminInfo(context, token) {
      try {
        const admin = context;
        // adminloginフォームデータ送信
        axiosBase.defaults.headers.common.Authorization = `Bearer ${token}`;
        await axiosBase.put('/api/admin/logout/');

        // Stateの値を削除
        admin.commit('logout');
        return true;
      } catch (e) {
        if (e.response.status === 401) return e.response.status;
        return false;
      }
    },
  },
};
