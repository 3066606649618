<template>
  <div class='billing'>
    <layout-header />
    <div class='billing__main center'>
      <section-menu
        :userInfo='userInfo'
        :pageList='createTitleList'
        @emit-menu-index='movePageMenu'
        :defaultSelectId='2'
      />
      <div class='billing__main__right'>
        <heading-normal  class='billing__main__right__top' white large>クレジットカード管理_</heading-normal>
        <div class='billing__main__right__bottom' v-show='!isLoading.loadCard'>
          <div class='billing__main__right__bottom__form'>
            <div class='billing__main__right__bottom__form__input' v-show='!hasCardInfo'>
              <div id='card-element' />
              <div id='card-errors' role='alert' />
            </div>
            <ul class='billing__main__right__bottom__form__card' v-show='hasCardInfo'>
              <li>ブランド: {{ cardInfo.brand }}</li>
              <li>期限: {{ cardInfo.exp }}</li>
              <li>下4桁: {{ cardInfo.last4 }}</li>
            </ul>
          </div>
          <div class='billing__main__right__bottom__button'>
            <button-normal
              class='billing__main__right__bottom__button__regist'
              large
              :disabled='!isVaild'
              :loading='isLoading.registCard'
              v-if='!hasCardInfo'
              @emit='getTokenCardInfo'
            >登録する</button-normal>
            <button-normal
              class='billing__main__right__bottom__button__delete'
              large
              v-if='hasCardInfo'
              :loading='isLoading.deleteCard'
              @emit='deleteCardFromCustomer'
            >削除する</button-normal>
          </div>
        </div>
        <div class='billing__main__right__loading' v-show='isLoading.loadCard'>
          <icon-loading />
        </div>
      </div>
    </div>
    <layout-footer />
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import { mapState } from 'vuex';
import helper from '@/mixins/general/methods/helper';
import pageList from '@/mixins/general/data/userInfoPageList';
import { IconLoading } from '@/components/atoms/icon/';
import { LayoutHeader, LayoutFooter } from '@/components/layouts/general/';
import { HeadingNormal } from '@/components/atoms/heading';
import { ButtonNormal } from '@/components/atoms/button';
import SectionMenu from '@/components/molecules/general/userInfo/SectionMenu';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    SectionMenu,
    ButtonNormal,
    HeadingNormal,
    IconLoading,
  },
  data() {
    return {
      isVaild: false,
      isLoading: {
        loadCard: false,
        registCard: false,
        deleteCard: false,
      },
      stripe: null,
      cardElement: null,
      cardInfo: {
        brand: null,
        exp: null,
        last4: null,
      },
    };
  },
  mixins: [helper, pageList],
  computed: {
    ...mapState('user', ['userInfo']),
    createTitleList() {
      const titleList = this.pageList.map((page) => page.title);
      return titleList;
    },
    hasCardInfo() {
      if (this.cardInfo.brand !== null
          && this.cardInfo.exp !== null
          && this.cardInfo.last4 !== null) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.loadCardtoCustomer();
  },
  async mounted() {
    // カード入力エレメント生成・testKey利用
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
    const elements = this.stripe.elements();
    this.cardElement = elements.create('card', { hidePostalCode: true });
    this.cardElement.mount('#card-element');
    this.cardElement.addEventListener('change', (event) => {
      this.isVaild = event.complete;
      const displayError = document.getElementById('card-errors');
      displayError.textContent = event.error ? event.error.message : '';
    });
  },
  methods: {
    movePageMenu(index) {
      const { name } = this.pageList[index];
      this.$router.push({ name }, () => {});
    },
    loading(target) {
      this.isLoading[target] = !this.isLoading[target];
    },
    async getTokenCardInfo() {
      this.loading('registCard');
      this.stripe.createToken(this.cardElement).then((result) => {
        if (result.error) {
          // エラー表示.
          const errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
          this.loading('registCard');
        } else {
          // トークンをサーバに送信
          this.setCardtoCustomer(result.token);
        }
      });
    },
    async loadCardtoCustomer() {
      try {
        this.loading('loadCard');
        this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
        const { data } = await this.$http.get('/api/auth/user/customer/card');
        this.cardInfo.brand = data.brand;
        this.cardInfo.exp = data.exp;
        this.cardInfo.last4 = data.last4;
        this.loading('loadCard');
      } catch (e) {
        this.loading('loadCard');
        if (e.response.status === 401) {
          this.$store.commit('user/logout');
          this.movePage('Login', '', { url: this.$route.path });
        }
      }
    },
    async setCardtoCustomer(token) {
      try {
        this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
        const { data } = await this.$http.post('/api/auth/user/customer/card', {
          token: JSON.stringify(token.id),
        });
        this.cardInfo.brand = data.brand;
        this.cardInfo.exp = data.exp;
        this.cardInfo.last4 = data.last4;
        this.showNoticeBar('success', 'カード情報の登録を完了しました');
        this.loading('registCard');
      } catch (e) {
        this.loading('registCard');
        if (e.response.status === 401) {
          this.$store.commit('user/logout');
          this.movePage('Login', '', { url: this.$route.path });
        }
        this.showNoticeBar('error', 'カード情報の登録に失敗しました');
      }
    },
    async deleteCardFromCustomer() {
      try {
        this.loading('deleteCard');
        this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
        await this.$http.delete('/api/auth/user/customer/card');
        this.cardElement.clear();
        this.cardInfo.brand = null;
        this.cardInfo.exp = null;
        this.cardInfo.last4 = null;
        this.showNoticeBar('success', 'カード情報の削除を完了しました');
        this.loading('deleteCard');
      } catch (e) {
        this.loading('deleteCard');
        if (e.response.status === 401) {
          this.$store.commit('user/logout');
          this.movePage('Login', '', { url: this.$route.path });
        }
        this.showNoticeBar('error', 'カード情報の削除に失敗しました');
      }
    },
  },
};
</script>

<style scoped>
.billing {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #4CAF50;
}

.billing__main {
  width: auto;
  display: flex;
  align-items: flex-start;
  margin-top: 40px;
  margin-bottom: 80px;
}

.billing__main__right {
  width: 700px;
  margin-left: 20px;
  margin-top: 50px;
}

.billing__main__right__top {
  padding-bottom: 10px;
}

.billing__main__right__bottom {
  background-color: #fff;
}

.billing__main__right__bottom__form {
  padding: 40px;
}

.billing__main__right__bottom__form__card {
  list-style: none;
  display: flex;
  justify-content: center;
  background-color: #F9F9FA;
  padding: 20px;
}

.billing__main__right__bottom__form__card li {
  margin-left: 20px;
}

.billing__main__right__bottom__form__card li:first-child {
  margin-left: 0;
}

.billing__main__right__bottom__button {
  padding: 0 40px 40px;
  display: flex;
  justify-content: center;
}

.billing__main__right__loading {
  padding: 20px;
  background-color: #fff;
}
</style>
