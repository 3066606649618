<template>
  <div class='lessons'>
    <layout-header />
    <div class='lessons__main center'>
      <section-menu
        :userInfo='userInfo'
        :pageList='createTitleList'
        @emit-menu-index='movePageMenu'
        :defaultSelectId='1'
      />
      <div class='lessons__main__right' id='lesson-management'>
        <heading-normal  class='lessons__main__right__top' white large>レッスン管理_</heading-normal>
        <div class='lessons__main__right__bottom'>
          <div class='lessons__main__right__bottom__header'>
            <ul>
              <li
                :class='{"focus--purchase": showLessonListNum === 0}'
                @click='changeShowLessonList(0)'
                >
                <i class="fas fa-shopping-cart"></i>
                購入済み
              </li>
              <li
                :class='{"focus--post": showLessonListNum === 1}'
                @click='changeShowLessonList(1)'
                >
                <i class="fas fa-pencil-alt"></i>
                投稿管理
              </li>
            </ul>
          </div>
          <div v-if='showLessonListNum === 0'>
            <ul class='lessons__main__right__bottom__purchase' v-if='!isLoading'>
              <li v-for='purchase in purchaseList[pagerInfo.purchase.count - 1]' :key='purchase.id'>
                <lesson-card-purchase
                  :lessonInfo='purchase'
                />
              </li>
            </ul>
            <p
              class='lessons__main__right__bottom__notfound'
              v-if='setTotalLessonCount === 0 && !isLoading'
            >
              購入済みレッスンはありません
            </p>
            <icon-loading class='lessons__main__right__bottom__load' v-if='isLoading'/>
          </div>
          <div v-if='showLessonListNum === 1'>
            <ul class='lessons__main__right__bottom__post' v-if='!isLoading'>
              <li v-for='post in postList[pagerInfo.post.count - 1]' :key='post.id'>
                <lesson-card-post
                  :lessonInfo='post'
                  :buttonText='setButtonText(post)'
                />
              </li>
            </ul>
            <icon-loading class='lessons__main__right__bottom__load' v-if='isLoading'/>
            <p
              class='lessons__main__right__bottom__notfound'
              v-if='setTotalLessonCount === 0 && !isLoading'
            >
              投稿済みレッスンはありません
            </p>
          </div>
          <pager-normal
            class='lesson__main__right__bottom__pager'
            v-show='pagerInfo.purchase.maxPage !== 1 && showLessonListNum === 0'
            :totalCount='userInfo.lesson.purchase.length'
            :display='pagerInfo.purchase.limit'
            @emit='setPagerCount($event, "purchase")'/>
          <pager-normal
            class='lesson__main__right__bottom__pager'
            v-if='!isLoading'
            v-show='pagerInfo.post.maxPage !== 1 && showLessonListNum === 1'
            :totalCount='userInfo.lesson.post.length'
            :display='pagerInfo.post.limit'
            @emit='setPagerCount($event, "post")'/>
        </div>
      </div>
    </div>
    <layout-footer />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import helper from '@/mixins/general/methods/helper';
import pageList from '@/mixins/general/data/userInfoPageList';
import { LayoutHeader, LayoutFooter } from '@/components/layouts/general/';
import { HeadingNormal } from '@/components/atoms/heading';
import { LessonCardPurchase, LessonCardPost } from '@/components/molecules/other';
import { IconLoading } from '@/components/atoms/icon/';
import { PagerNormal } from '@/components/atoms/other/';
import SectionMenu from '@/components/molecules/general/userInfo/SectionMenu';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    SectionMenu,
    HeadingNormal,
    LessonCardPurchase,
    LessonCardPost,
    IconLoading,
    PagerNormal,
  },
  data() {
    return {
      isLoading: false,
      showLessonListNum: 0,
      purchaseList: [],
      postList: [],
      pagerInfo: {
        purchase: {
          count: 1,
          limit: 5,
          maxPage: 1,
        },
        post: {
          count: 1,
          limit: 5,
          maxPage: 1,
        },
      },
    };
  },
  mixins: [helper, pageList],
  computed: {
    ...mapState('user', ['userInfo']),
    createTitleList() {
      const titleList = this.pageList.map((page) => page.title);
      return titleList;
    },
    setTotalLessonCount() {
      if (this.showLessonListNum === 0) return this.userInfo.lesson.purchase.length;
      return this.userInfo.lesson.post.length;
    },
  },
  created() {
    // 学習日数取得
    this.loadCount().then((res) => {
      if (res === 401) {
        this.$store.commit('user/logout');
        this.movePage('Login', '', { url: this.$route.path });
      }
      if (!res) this.movePage('Error');
    });
  },
  async mounted() {
    this.loading();

    // 購入済みレッスン取得
    const resultPurchase = await this.loadPurchaseLesson();
    if (await !resultPurchase) {
      this.movePage('Error');
    } else if (resultPurchase === 401) {
      this.movePage('Login', '', { url: this.$route.path });
    }

    // 投稿済みレッスン取得
    const resultPost = await this.loadPostLesson();
    if (await !resultPost) {
      this.movePage('Error');
    } else if (resultPost === 401) {
      this.movePage('Login', '', { url: this.$route.path });
    }

    await this.calculateMaxPage('purchase');
    await this.pushLessonList('purchase');

    await this.calculateMaxPage('post');
    await this.pushLessonList('post');

    await this.loading();
  },
  methods: {
    ...mapActions('user', ['loadPostLesson', 'loadPurchaseLesson']),
    ...mapActions('user', ['loadCount']),
    loading() {
      this.isLoading = !this.isLoading;
    },
    movePageMenu(index) {
      const { name } = this.pageList[index];
      this.$router.push({ name }, () => {});
    },
    scrollTo(id) {
      const elem = document.getElementById(id);
      this.$scrollTo(elem, 1);
    },
    changeShowLessonList(num) {
      this.showLessonListNum = num;
    },
    calculateMaxPage(target) {
      const caluculateResult = Math.ceil(
        this.userInfo.lesson[target].length / this.pagerInfo[target].limit,
      );
      if (caluculateResult !== 0) {
        this.pagerInfo[target].maxPage = caluculateResult;
      }
    },
    pushLessonList(target) {
      const listName = `${target}List`;
      this[listName] = [];
      for (let i = 0; i < this.pagerInfo[target].maxPage; i += 1) {
        this[listName].push(
          this.userInfo.lesson[target].slice(
            i * this.pagerInfo[target].limit, (i + 1) * this.pagerInfo[target].limit,
          ),
        );
      }
    },
    setButtonText(lessonId) {
      let buttonText = [];
      if (lessonId.status === 1) {
        buttonText = '公開中';
      } else if (lessonId.status === 0) {
        buttonText = '審査中';
      } else {
        buttonText = '非公開';
      }
      return buttonText;
    },
    setPagerCount(count, target) {
      this.pagerInfo[target].count = count;
      this.scrollTo('lesson-management');
    },
  },
};
</script>

<style scoped>
.lessons {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #4CAF50;
}

.lessons__main {
  width: auto;
  display: flex;
  align-items: flex-start;
  margin-top: 40px;
  margin-bottom: 80px;
}

.lessons__main__right {
  width: 700px;
  margin-left: 20px;
  margin-top: 50px;
}

.lessons__main__right__top {
  padding-bottom: 25px;
}

.lessons__main__right__bottom {
  background-color: #fff;
}

.lessons__main__right__bottom__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.lessons__main__right__bottom__header ul {
  list-style: none;
  width: 100%;
  height: 70px;
  display: flex;
  text-align: center;
  vertical-align: center;
  position: relative;
}

.lessons__main__right__bottom__header ul li {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-bottom: 1px solid #F2EEEE;
}

.lessons__main__right__bottom__header ul li i {
  margin-right: 5px;
}

.focus--purchase {
  background-color: #90CCE6;
  height: 85px;
  position: relative;
  color: white;
  top: -15px;
  font-weight: 500;
}

.focus--post {
  background-color: #E74860;
  height: 85px;
  position: relative;
  top: -15px;
  color: white;
  font-weight: 500;
}

.lessons__main__right__bottom__purchase {
  list-style: none;
}

.lessons__main__right__bottom__purchase > li {
  border-bottom: 1px solid #F2EEEE;
  padding: 15px 0px;
}

.lessons__main__right__bottom__post {
  list-style: none;
}

.lessons__main__right__bottom__post > li {
  border-bottom: 1px solid #F2EEEE;
  padding: 15px 0px;
}

.lessons__main__right__bottom__notfound {
  background-color: #F9F9FA;
  text-align: center;
  font-size: 14px;
  padding: 30px ;
}

.lessons__main__right__bottom__load {
  padding: 40px 0;
}

.lesson__main__right__bottom__pager {
  padding: 40px 0 30px;
  display: flex;
  justify-content: center;
}
</style>
