<template>
  <div class='create'>
    <LayoutHeader />
    <div class='create__contents'>
      <list-menu
        class='create__contents__left'
        :targetList='createTitleList'
        :defaultSelectId=2
        @emit='movePageMenu'
      />
      <div class='create__contents__right'>
        <title-normal
          class='list__contents__right__top'
          :title='titleBar.title'
          :button='titleBar.button'
          :disabled='!isVaild'
          :loading='isLoading'
          @emit='postContent()'
        />
        <div class='create__contents__right__bottom'>
          <div class='create__contents__right__bottom__title'>
            <heading-normal>タイトル</heading-normal>
            <input-text @emit='saveTitle'/>
          </div>
          <div class='create__contents__right__bottom__type'>
            <heading-normal>種別</heading-normal>
            <select v-model='submitInfo.selectType'>
              <option
                v-for='(type, index) in types'
                :value='index + 1'
                :key='type.id'
              >{{ type }}</option>
            </select>
          </div>
          <div class='create__contents__right__bottom__markdown'>
            <heading-normal>本文</heading-normal>
            <vue-simplemde
              v-model='submitInfo.markdown'
              ref='markdownEditor'
              :configs='configs'
            />
          </div>
        </div>
      </div>
      <modal-normal
        v-if='isActive.modalCreateComplete'
        :title='modalInfo.createComplete.title'
        :text='modalInfo.createComplete.text'
        :acceptBtn='modalInfo.createComplete.acceptBtn'
        :cancelBtn='modalInfo.createComplete.cancelBtn'
        @emit='movePage("AdminCmsList")'
        disabledOverlay
      />
    </div>
  </div>
</template>

<script>
import VueSimplemde from 'vue-simplemde';
import { mapState } from 'vuex';
import helper from '@/mixins/admin/methods/helper';
import pageList from '@/mixins/admin/data/pageList';
import LayoutHeader from '@/components/layouts/admin/LayoutHeader';
import { TitleNormal, ModalNormal } from '@/components/molecules/other';
import { ListMenu } from '@/components/atoms/list';
import { InputText } from '@/components/atoms/input';
import { HeadingNormal } from '@/components/atoms/heading';

export default {
  components: {
    VueSimplemde,
    LayoutHeader,
    TitleNormal,
    ModalNormal,
    ListMenu,
    InputText,
    HeadingNormal,
  },
  data() {
    return {
      isActive: {
        modalCreateComplete: false,
      },
      isLoading: false,
      modalInfo: {
        createComplete: {
          title: '投稿完了',
          text: 'コンテンツの投稿を完了しました',
          acceptBtn: 'コンテンツ一覧画面に戻る',
        },
      },
      titleBar: {
        title: 'コンテンツ新規作成',
        button: '新規作成',
      },
      types: ['お知らせ'],
      configs: {
        status: ['autosave', 'lines', 'words', 'cursor'],
        spellChecker: false,
      },
      submitInfo: {
        title: '',
        markdown: '',
        selectType: 1,
      },
    };
  },
  mixins: [helper, pageList],
  computed: {
    ...mapState('admin', ['adminInfo']),
    createTitleList() {
      const titleList = this.pageList.map((page) => page.title);
      return titleList;
    },
    isVaild() {
      if (this.submitInfo.title && this.submitInfo.markdown) return true;
      return false;
    },
  },
  methods: {
    movePageMenu(index) {
      const { name } = this.pageList[index];
      this.$router.push({ name }, () => {});
    },
    active(target) {
      this.isActive[target] = !this.isActive[target];
    },
    saveTitle(title) {
      this.submitInfo.title = title;
    },
    async postContent() {
      try {
        this.isLoading = !this.isLoading;
        this.$http.defaults.headers.common.Authorization = `Bearer ${this.adminInfo.token}`;
        const params = {
          title: this.submitInfo.title,
          category: this.submitInfo.selectType,
          text: this.submitInfo.markdown,
        };
        await this.$http.post('api/admin/notices', params);
        this.isLoading = await !this.isLoading;
        await this.active('modalCreateComplete');
      } catch (e) {
        if (e.response !== undefined && e.response.status === 401) {
          this.unAdminAuthenticate();
        } else {
          this.showNoticeBar('error', 'コンテンツの登録に失敗しました');
        }
      }
    },
  },
};
</script>

<style scoped>
.create {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f9fa;
}

.create__contents {
  display: flex;
  margin: 20px;
}

.create__contents__left {
  width: 15%;
  background-color: #fff;
  padding: 20px;
}

.create__contents__right {
  width: 85%;
  margin-left: 20px;
}

.create__contents__right__bottom {
  top: 0;
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
}

.create__contents__right__bottom__type {
  margin-top: 20px;
}

.create__contents__right__bottom__type select {
  -webkit-appearance: menulist;
  border: 2px solid #d1d3d9;
  font-size: 14px;
  outline: none;
}

.create__contents__right__bottom__markdown {
  margin-top: 20px;
}
</style>
