export default {
  data() {
    return {
      pageList: [
        {
          title: ' ユーザー情報設定',
          name: 'UserInfoSetting',
        },
        {
          title: 'レッスン管理',
          name: 'UserInfoLessons',
        },
        {
          title: 'クレジットカード管理',
          name: 'UserInfoBilling',
        },
        {
          title: '販売管理',
          name: 'UserInfoSales',
        },
      ],
    };
  },
};
