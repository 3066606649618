<template>
  <div class='cart'>
    <layout-header />
    <heading-title>
      <template v-slot:main>
        <p>Cart</p>
      </template>
      <template v-slot:sub>
        <p>カート_</p>
      </template>
    </heading-title>
    <section class='cart__main center'>
      <div class='cart__main__top' v-if='this.userInfo.cart.length !== 0'>
        <p class='cart__main__top__left'>
          カートには{{ setTotalCount }}件のレッスンが入っています
        </p>
        <div class='cart__main__top__right'>
          <p class='cart__main__top__right__total'>合計</p>
          <p class='cart__main__top__right__price'>¥{{ setTotalPrice }}</p>
          <router-link :to='{ name: "CartCheckout"}'>
            <button-normal large>レジに進む</button-normal>
          </router-link>
        </div>
      </div>
      <div class='cart__main__top' v-if='this.userInfo.cart.length === 0'>
        <p class='cart__main__top__notfound'>
          カートにレッスンが存在しません
        </p>
      </div>
      <ul class='cart__main__bottom'>
        <li
          class='cart__main__bottom__card'
          v-for='lessonInfo in userInfo.cart'
          :key='lessonInfo.id'
        >
          <lesson-card-cart
            :lessonInfo='lessonInfo'
            @emit-delete-cart='deleteCart($event)'
          />
        </li>
      </ul>
    </section>
    <layout-footer />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { LayoutHeader, LayoutFooter } from '@/components/layouts/general/';
import helper from '@/mixins/general/methods/helper';
import { HeadingTitle } from '@/components/atoms/heading';
import { ButtonNormal } from '@/components/atoms/button';
import { LessonCardCart } from '@/components/molecules/other';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    HeadingTitle,
    ButtonNormal,
    LessonCardCart,
  },
  mixins: [helper],
  computed: {
    ...mapState('user', ['userInfo']),
    setTotalCount() {
      return this.userInfo.cart.length;
    },
    setTotalPrice() {
      const priceList = this.userInfo.cart.map((lesson) => lesson.price);
      const totalPrice = priceList.reduce((a, x) => a + x);
      return totalPrice;
    },
  },
  async created() {
    this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
    const res = await this.$http.get('api/auth/cart');
    this.$store.commit('user/saveResetCart');
    res.data.lessons.forEach((lesson) => {
      this.$store.commit('user/saveCart', lesson);
      if (lesson.status === 2) {
        this.deleteCart(lesson.id);
      }
    });
  },
  methods: {
    ...mapActions('user', ['deleteCart']),
    execDeleteCart() {
      try {
        this.deleteCart();
      } catch (e) {
        this.showNoticeBar('error', 'カートの削除に失敗しました');
      }
    },
  },
};
</script>

<style scoped>
.cart {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.cart__main {
  margin: 0 auto 80px;
}

.cart__main__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  font-size: 14px;
}

.cart__main__top__right {
  display: flex;
  align-items: center;
}

.cart__main__top__right__total {
  margin-top: 2px;
}

.cart__main__top__right__price {
  margin: 0 20px;
  font-size: 24px;
  font-weight: 600;
}

.cart__main__top__notfound {
  font-size: 16px;
  font-weight: 500;
  margin: 10px auto;
}

.cart__main__bottom {
  list-style: none;
}

.cart__main__bottom__card {
  padding: 15px 0;
  border-bottom: 1px solid #F2EEEE;
}

.cart__main__bottom__card:first-child {
  padding-top: 0;
}
</style>
