/**
 * lessonAttend.js
 * レッスン受講時のレッスン情報、チャプターコンテンツを管理する
 */
import axios from 'axios';

const axiosBase = axios.create({
  baseURL: process.env.VUE_APP_HOST,
  headers: {
    'Content-Type': 'application/json',
  },
  credentials: true,
  responseType: 'json',
});

export default {
  namespaced: true,
  state: {
    currentLesson: {
      id: '',
      content: '',
      freeContent: '',
      title: '',
      update: '',
      chapterTitle: [],
      usercode: {},
    },
    setting: {
      areaWidth: {
        markdown: 30,
        editor: 40,
      },
    },
  },
  mutations: {
    logout(state) {
      const { setting } = state;
      const { currentLesson } = state;
      setting.areaWidth.markdown = 30;
      setting.areaWidth.editor = 40;

      currentLesson.id = '';
      currentLesson.content = '';
      currentLesson.freeContent = '';
      currentLesson.title = '';
      currentLesson.update = '';
      currentLesson.chapterTitle = [];
      currentLesson.usercode = {};
    },
    // レッスン情報、各Chapterコンテンツを保存する
    saveLessonInfo(state, loadLesson) {
      const lesson = state;
      lesson.currentLesson.id = loadLesson.id;
      lesson.currentLesson.title = loadLesson.title;
      lesson.currentLesson.update = loadLesson.update;
      lesson.currentLesson.content = loadLesson.content;
      lesson.currentLesson.freeContent = '';
      lesson.currentLesson.chapterTitle = loadLesson.chapterTitle;
    },
    saveLessonInfoFreeRange(state, loadLesson) {
      const lesson = state;
      lesson.currentLesson.id = loadLesson.id;
      lesson.currentLesson.title = loadLesson.title;
      lesson.currentLesson.update = loadLesson.update;
      lesson.currentLesson.content = '';
      lesson.currentLesson.freeContent = loadLesson.freeContent;
      lesson.currentLesson.chapterTitle = loadLesson.chapterTitle;
    },
    saveAreaWidth(state, loadAreaWidth) {
      const width = state;
      width.setting.areaWidth[loadAreaWidth.changeAreaName] = loadAreaWidth.parseSize;
    },
    saveUsercode(state, usercode) {
      const lesson = state;
      if (usercode.length === 0) {
        lesson.currentLesson.usercode = {};
      } else {
        lesson.currentLesson.usercode = usercode;
      }
    },
    saveUpdateUsercode(state, updateInfo) {
      const lesson = state;
      if (lesson.currentLesson.usercode.length === 0) {
        // 一件もusercodeがないパターン
        lesson.currentLesson.usercode = { [updateInfo.num]: updateInfo.codeUrl };
      } else if (lesson.currentLesson.usercode[updateInfo.num] === undefined) {
        // 同一Numが存在しないパターン
        lesson.currentLesson.usercode[updateInfo.num] = updateInfo.codeUrl;
      }
    },
    saveDeleteUsercode(state) {
      const lesson = state;
      lesson.currentLesson.usercode = {};
    },
  },
  actions: {
    async loadLesson(context, loadInfo) {
      try {
        const lesson = context;
        // レッスン受講画面遷移前にレッスン情報、各Chapterコンテンツを読み込む
        axiosBase.defaults.headers.common.Authorization = `Bearer ${loadInfo.token}`;
        const loadLesson = await axiosBase.get(`/api/auth/lessons/attend/${loadInfo.id}`);
        const lessonInfo = {
          id: loadLesson.data.id,
          title: loadLesson.data.title,
          update: loadLesson.data.updated_at,
          content: loadLesson.data.content,
          chapterTitle: loadLesson.data.chapter,
        };
        await lesson.commit('saveLessonInfo', lessonInfo);

        // ユーザー入力コードを取得
        const usercodeUrl = await axiosBase.get(`/api/auth/lessons/usercode/${loadInfo.id}`);
        const lessonUpdateDate = new Date(loadLesson.data.updated_at);
        const usercodeUpdateDate = usercodeUrl.data.updated_at !== '' ? new Date(usercodeUrl.data.updated_at) : null;

        // ユーザーコード保存時のレッスンと現在のレッスンが異なるバージョンの場合はユーザーコード削除
        if (usercodeUpdateDate !== null) {
          if (usercodeUpdateDate >= lessonUpdateDate) {
            await lesson.commit('saveUsercode', usercodeUrl.data.usercode);
          } else {
            await axiosBase.delete(`/api/auth/lessons/usercode/${loadInfo.id}`);
            await lesson.commit('saveDeleteUsercode');
          }
        } else {
          lesson.commit('saveDeleteUsercode');
        }
        return lessonInfo;
      } catch (e) {
        return false;
      }
    },
    loadPreviewLesson(context, previewSetting) {
      const lesson = context;
      const lessonInfo = {
        id: 0,
        title: previewSetting.title,
        chapterTitle: previewSetting.chapter,
        content: '',
        update: '',
      };
      lesson.commit('saveLessonInfo', lessonInfo);
    },
    loadAdminPreviewLesson(context, previewSetting) {
      const lesson = context;
      const lessonInfo = {
        id: previewSetting.lessonInfo.id,
        title: previewSetting.lessonInfo.title,
        chapterTitle: previewSetting.chapterInfo.chapterList,
        content: previewSetting.chapterInfo.content,
        update: previewSetting.lessonInfo.update,
      };
      lesson.commit('saveLessonInfo', lessonInfo);
    },
    loadFreeRangeLesson(context, lessonInfo) {
      const lesson = context;
      lesson.commit('saveLessonInfoFreeRange', lessonInfo);
    },
    // usercode更新
    async updateUsercode(context, updateInfo) {
      try {
        const lesson = context;
        axiosBase.defaults.headers.common.Authorization = `Bearer ${updateInfo.token}`;
        const codeUrl = await axiosBase.put(`/api/auth/lessons/usercode/${updateInfo.lessonId}/${updateInfo.num}`, {
          usercode: JSON.stringify(updateInfo.editSource),
        });
        await lesson.commit('saveUpdateUsercode', {
          codeUrl: codeUrl.data.usercode,
          num: updateInfo.num,
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    // usercode削除
    async deleteUsercode(context, deleteInfo) {
      try {
        const lesson = context;
        axiosBase.defaults.headers.common.Authorization = `Bearer ${deleteInfo.token}`;
        await axiosBase.delete(`/api/auth/lessons/usercode/${deleteInfo.lessonId}`);
        await lesson.commit('saveDeleteUsercode');
        return true;
      } catch (e) {
        return false;
      }
    },
  },
};
