<template>
  <button @click='$emit("emit", auth)' :class="`${auth}`">
    <i :class="`fab fa-${auth}`" />
    <p>シェアする</p>
  </button>
</template>

<script>
export default {
  props: {
    auth: String,
  },
};
</script>

<style scoped>
.twitter {
  padding: 8px 10px;
  background-color: #2CAFF5;
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 8px;
}

.twitter:hover {
  background-color: #43bdff;
}

.twitter i {
  margin-right: 5px;
  font-size: 16px;
}

.twitter p {
  font-size: 12px;
}
</style>
