<template>
  <div class='section-top-info'>
    <div class='section-top-info__main center'>
      <div class='section-top-info__main__left' v-if='!isLoading.loadLesson'>
        <img class='section-top-info__main__left__thumbnail' :src='setThumbnail' alt='レッスンのサムネイル'>
      </div>
      <div class='section-top-info__main__right' v-if='!isLoading.loadLesson'>
        <h3 class='section-top-info__main__right__title'>{{ lessonInfo.title }}</h3>
        <div class='section-top-info__main__right__bottom'>
          <review-star-multi :score='lessonInfo.evaluation' />
          <div class='section-top-info__main__right__tag'>
            <p class='section-top-info__main__right__tag__text'
              v-if='lessonInfo.category.length'>
            カテゴリー
            </p>
            <ul class='section-top-info__main__right__tag__list'>
              <li :key='lang.id' v-for='lang in lessonInfo.category'>
                <icon-lang>{{ lang }}</icon-lang>
              </li>
            </ul>
          </div>
        </div>
        <p
          class='section-top-info__main__right__warn'
          v-if='pageType === "creator" && lessonInfo.judge === 2'
        >
          レッスン更新審査中のため、新規更新できません
        </p>
      </div>
      <icon-loading class='section-top-info__main__load' v-if='isLoading.loadLesson' />
    </div>
  </div>
</template>

<script>
import { IconLang, IconLoading } from '@/components/atoms/icon';
import { ReviewStarMulti } from '@/components/atoms/reviewStar';

export default {
  components: {
    IconLang,
    IconLoading,
    ReviewStarMulti,
  },
  props: {
    lessonInfo: Object,
    levelLabel: Array,
    isLoading: Object,
    pageType: String,
  },
  computed: {
    setThumbnail() {
      if (this.lessonInfo.image !== null) {
        const separateFilename = this.lessonInfo.thumbnail.split('.');
        separateFilename[separateFilename.length - 2] = `${separateFilename[separateFilename.length - 2]}_original`;
        const combineFilename = separateFilename.join('.');
        return combineFilename;
      }
      return null;
    },
  },
};
</script>

<style scoped>
img {
  display: block;
}
.section-top-info__main {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.section-top-info__main__left {
  position: relative;
  width: 50%;
}

.section-top-info__main__left__thumbnail {
  width: 100%;
  height: 247.67px;
}

.section-top-info__main__right {
  width: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.section-top-info__main__right__title {
  font-size: 24px;
  width: 100%;
  font-weight: 500;
  line-height: 32px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-all;
}

.section-top-info__main__right__bottom {
  margin-top: 10px;
}

.section-top-info__main__right__tag {
  display: flex;
  align-items: center;
}

.section-top-info__main__right__tag__text {
  margin-right: 5px;
}

.section-top-info__main__right__tag__list {
  display: flex;
  list-style: none;
}

.section-top-info__main__right__tag__list li {
  margin-left: 5px
}

.section-top-info__main__right__warn {
  font-size: 14px;
  color: #E74860;
}

.section-top-info__main__load {
  margin: 0px auto;
  padding-top: 0;
  background-color: transparent;
}
</style>
