<template>
  <router-link class='lesson-card-wide'
    :to='{ name: "LessonDetail", params: { id: lessonInfo.id }}'
  >
    <div class='lesson-card-wide__left'>
      <img
        class='lesson-card-wide__left__thumbnail'
        :src='lessonInfo.image'
        alt='レッスンのサムネイル'
        @error='setErrorThumbnail'>
      <div class='lesson-card-wide__left__author' v-if='!hideAuthor'>
        <img
          class='lesson-card-wide__left__author__avatar'
          :src='setAvatar'
          alt='avatar ユーザーアイコン'
          @error='setErrorAvatar'>
        <p class='lesson-card-wide__left__author__name'>{{ setAuthor }}</p>
      </div>
    </div>
    <div class='lesson-card-wide__center'>
      <div class='lesson-card-wide__center__top'>
        <icon-level class='lesson-card-wide__center__top__level' :level='lessonInfo.level' />
        <h3>{{ lessonInfo.title }}</h3>
      </div>
      <p  class='lesson-card-wide__center__bottom'>
        {{ lessonInfo.description }}
      </p>
    </div>
    <div class='lesson-card-wide__right'>
      <icon-post-date
        class='lesson-card-wide__right__date'
        :date='lessonInfo.updatedDate'
      >
        UPDATE
      </icon-post-date>
      <review-star :score='lessonInfo.evaluation' />
      <p class='lesson-card-wide__right__price'>{{ setPrice }}</p>
      <div class="lesson-card-wide__right__bottom">
        <button-stripe
          @emit='$emit("lesson-detail", {id: lessonInfo.id})'
          arrow
        >レッスンを見る</button-stripe>
      </div>
    </div>
  </router-link>
</template>

<script>
import { ReviewStar } from '@/components/atoms/reviewStar';
import { IconLevel, IconPostDate } from '@/components/atoms/icon';
import { ButtonStripe } from '@/components/atoms/button';

export default {
  components: {
    ReviewStar,
    IconLevel,
    IconPostDate,
    ButtonStripe,
  },
  props: {
    lessonInfo: Object,
    levelLabel: Array,
    hideAuthor: Boolean,
  },
  computed: {
    setPrice() {
      if (this.lessonInfo.price === 0) {
        return '無料';
      }
      return `¥${this.lessonInfo.price}`;
    },
    setAuthor() {
      if (this.lessonInfo.author === null) return '削除されたユーザー';
      return this.lessonInfo.author;
    },
    setAvatar() {
      if (this.lessonInfo.avatar === null) return `${process.env.VUE_APP_S3_URL}/image/avatar/unknown.png`;
      return this.lessonInfo.avatar;
    },
  },
  methods: {
    setErrorAvatar(e) {
      e.target.src = `${process.env.VUE_APP_S3_URL}/image/avatar/unknown.png`;
    },
    setErrorThumbnail(e) {
      e.target.src = `${process.env.VUE_APP_S3_URL}/image/thumbnail/manabiba.png`;
    },
  },
};
</script>

<style scoped>
.lesson-card-wide {
  display: flex;
  width: 100%;
  height: 220px;
  background-color: #fff;
  cursor: pointer;
  border: 10px solid #fff;
  transition-duration: 0.2s;
  padding: 10px;
}

.lesson-card-wide:hover {
  border: 10px solid #F1EFFC;
}

.lesson-card-wide:hover
>>> .button-stripe__front {
  transform: translate(-53%, -60%);
  transition: all 0.3s ease;
  background-color: #fddb33d3;
}

.lesson-card-wide__left__thumbnail {
  width: 220px;
  height: 123.75px;
}

.lesson-card-wide__left__author {
  display: flex;
  align-items: center;
  position: relative;
  top: -15px;
}

.lesson-card-wide__left__author__avatar {
  background-color: #F1EFFC;
  width: 55px;
  height: 55px;
  border-radius: 27.5px;
  border: 3px solid #fff;
  margin-left: 10px;
}

.lesson-card-wide__left__author__name {
  margin-left: 10px;
  font-size: 12px;
  width: 130px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-all;
}

.lesson-card-wide__center {
  margin: 0 20px;
  flex-grow: 1;
}

.lesson-card-wide__center__top {
  display: flex;
  align-items: center;
}

.lesson-card-wide__center__top h3 {
  font-size: 20px;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  margin-left: 10px;
  width: 80%;

}

.lesson-card-wide__center__top__level {
  margin-top: 2px;
  width: 10%;
}

.lesson-card-wide__center__bottom {
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  word-break: break-all;
  margin-top: 10px;
}

.lesson-card-wide__right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 20%;
}

.lesson-card-wide__right__date {
  margin-bottom: 10px;
}

.lesson-card-wide__right__price {
  font-size: 20px;
}

.lesson-card-wide__right__bottom {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-top: 15px;
}
</style>
