<template>
  <div class='lesson-detail'>
    <layout-header class='lesson-detail__header' />
    <div class='lesson-detail__back' />
    <div class='lesson-detail__front center'>
      <section-top-info
        :isLoading='isLoading'
        :lessonInfo='lessonInfo'
        :levelLabel='levelLabel'
        :pageType='pageType'
      />
      <section-tool-bar
        v-if='!isLoading.loadLesson'
        :lessonInfo='lessonInfo'
        :purchaseList='userInfo.lesson.purchase'
        :postList='userInfo.lesson.post'
        :isLoading='isLoading'
        :pageType='pageType'
        :status='status'
        :checkCart='checkCart'
        @emit-move-page='clickContinue'
        @emit-move-page-update='movePage("LessonUpdate", {id: lessonInfo.id})'
        @emit-reset-progress='onResetBtn(lessonInfo.id)'
        @emit-change-status-public='active("setLessonPublic")'
        @emit-change-status-private='active("setLessonPrivate")'
        @emit-add-cart='addCart()'
      />
      <section class='lesson-detail__info' v-if='!isLoading.loadLesson'>
        <div
          class='lesson-detail__info__accordion'
          v-if='pageType === "afterBuy"'
          @click='showAccordion'>
          <i v-if='!isActive.accordion'
            class="lesson-detail__info__accordion__btn fas fa-caret-up"></i>
          <i v-if='isActive.accordion'
            class="lesson-detail__info__accordion__btn fas fa-caret-down"></i>
          <p class='lesson-detail__info__accordion__text'>レッスン内容と対象ユーザを見る</p>
        </div>
        <div v-if='isActive.accordion' class='lesson-detail__info__description'>
          <heading-normal large>レッスン内容</heading-normal>
          <text-normal>{{ lessonInfo.description }}</text-normal>
        </div>
        <div v-if='isActive.accordion' class='lesson-detail__info__target'>
          <heading-normal large>対象ユーザー</heading-normal>
          <text-normal>{{ lessonInfo.target }}</text-normal>
        </div>
        <div class='lesson-detail__info__syllabus'>
          <heading-normal large>シラバス</heading-normal>
          <ul class='lesson-detail__info__syllabus__list'>
            <li :key='chapter.id' v-for='(chapter, index) in chapterList'>
              <list-chapter
                v-if='completeChapter.length !== 0
                && pageType !== "creator"'
                :num='index'
                :id='lessonInfo.id'
                :title='chapter'
                :complete='completeChapter[index].complete'
                :login='userInfo.login'
                @emit-move-page='movePage("LessonAttend", { id: lessonInfo.id, num: index + 1 })'
              />
              <list-chapter
                v-if='
                  completeChapter.length === 0
                  &&  pageType !== "creator"
                  && lessonInfo.price !== 0'
                :num='index'
                :id='lessonInfo.id'
                :title='chapter'
                :login='userInfo.login'
                :lock='lessonInfo.freeChapterNum <= index  ? true : false'
                hideCheck
                @emit-move-page='moveFreeRangeAttend(index + 1)'
              />
              <list-chapter
                v-if='
                  completeChapter.length === 0
                  &&  pageType !== "creator"
                  && lessonInfo.price === 0'
                :num='index'
                :id='lessonInfo.id'
                :title='chapter'
                :login='userInfo.login'
                free
                @emit-move-page='onChapter(lessonInfo.id)'
              />
              <list-chapter v-if='pageType === "creator"'
                :num='index'
                :id='lessonInfo.id'
                :title='chapter'
                disabled
                hideCheck
                @emit-move-page='onChapter(lessonInfo.id)'
              />
            </li>
          </ul>
        </div>
      </section>
    </div>
    <section-author
      v-if='Object.keys(this.authorInfo).length !== 0 && !isLoading.loadLesson'
      :authorInfo='authorInfo'
      pointer
    />
    <section-author
      v-if='Object.keys(this.authorInfo).length === 0 && !isLoading.loadLesson'
      :authorInfo='unknownAuthorInfo'
    />
    <div class='lesson-detail__modal'>
      <modal-normal
        v-if='isActive.setLessonPublic'
        @emit='changeStatus($event, 1)'
        title='レッスンを公開する'
        text='レッスンを公開すると全てのユーザーがレッスンを閲覧可能になりますがよろしいですか？'
        acceptBtn='公開する'
        cancelBtn='キャンセル'
      />
      <modal-normal
        v-if='isActive.setLessonPrivate'
        @emit='changeStatus($event, 2)'
        title='レッスンを非公開にする'
        text='レッスンを非公開にすると既に購入済みのユーザーを除くユーザーがレッスンを閲覧できなくなりますがよろしいですか？'
        acceptBtn='非公開にする'
        cancelBtn='キャンセルする'
      />
    </div>
    <layout-footer />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import helper from '@/mixins/general/methods/helper';
import { LayoutHeader, LayoutFooter } from '@/components/layouts/general';
import { SectionTopInfo, SectionToolBar } from '@/components/molecules/general/lessonDetail';
import { ModalNormal, SectionAuthor } from '@/components/molecules/other';
import { ListChapter } from '@/components/atoms/list';
import { HeadingNormal } from '@/components/atoms/heading';
import TextNormal from '@/components/atoms/text/TextNormal';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    ModalNormal,
    SectionAuthor,
    SectionToolBar,
    SectionTopInfo,
    ListChapter,
    HeadingNormal,
    TextNormal,
  },
  data() {
    return {
      levelLabel: ['初心者', '中級者', '上級者'],
      completeChapter: [],
      authorInfo: {},
      unknownAuthorInfo: {
        id: '',
        name: '削除されたユーザー',
        img: `${process.env.VUE_APP_S3_URL}/image/avatar/unknown.png`,
        description: '',
      },
      lessonInfo: {},
      lessonInfoPrivate: {},
      chapterList: {},
      isLoading: {
        loadLesson: false,
        loadContinue: false,
        resetProgress: false,
        addCart: false,
      },
      isActive: {
        postLesson: false,
        accordion: true,
        setLessonPublic: false,
        setLessonPrivate: false,
      },
      currentUrl: '',
      pageType: '',
      status: null,
    };
  },
  async created() {
    await this.loadLessonDetail();
    const targetPost = this.userInfo.lesson.post
      .filter((lesson) => lesson.id === Number(this.$route.params.id));
    const targetPurchase = this.userInfo.lesson.purchase
      .filter((lesson) => lesson.id === Number(this.$route.params.id));
    if (targetPost.length !== 0) {
      this.active('postLesson');
      this.pageType = 'creator';
      this.status = targetPost[0].status;
    } else if (targetPurchase.length === 0) {
      this.pageType = 'beforeBuy';
    } else {
      this.pageType = 'afterBuy';
      this.active('accordion');
    }
    this.currentUrl = this.$route.path;
  },
  mixins: [helper],
  computed: {
    ...mapState('user', ['userInfo']),
    checkCart() {
      const target = this.userInfo.cart
        .findIndex((lessonInfo) => lessonInfo.id === this.lessonInfo.id);
      if (target !== -1) return true;
      return false;
    },
  },
  methods: {
    ...mapActions('user', ['loadPurchaseLesson', 'loadPostLesson']),
    ...mapActions('lessonAttend', ['deleteUsercode', 'loadFreeRangeLesson']),
    active(target) {
      this.isActive[target] = !this.isActive[target];
    },
    loading(target) {
      this.isLoading[target] = !this.isLoading[target];
    },
    showAccordion() {
      this.active('accordion');
    },
    async changeStatus(event, status) {
      try {
        if (status === 1) {
          this.active('setLessonPublic');
        } else {
          this.active('setLessonPrivate');
        }
        if (!event) return;
        this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
        await this.$http.put(`/api/creator/lessons/status/${Number(this.$route.params.id)}`, { status });
        this.userInfo.lesson.post.forEach((lesson, num) => {
          if (lesson.id === Number(this.$route.params.id)) {
            this.userInfo.lesson.post[num].status = status;
          }
        });
        this.$store.commit('user/savePostLesson', this.userInfo.lesson.post);
        this.status = status;
        await this.showNoticeBar('success', 'レッスンの公開設定を変更しました');
      } catch (e) {
        if (e.response !== undefined && e.response.status === 401) {
          this.unAuthenticate();
        } else {
          await this.showNoticeBar('error', 'レッスンの公開設定の変更に失敗しました');
        }
      }
    },
    async buyLesson(id) {
      this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
      try {
        await this.$http.post('/api/auth/lessons/buy', { lesson_ids: id });
      } catch (e) {
        if (e.response !== undefined && e.response.status === 401) {
          this.unAuthenticate();
        } else {
          this.movePage('Error');
        }
      }
    },
    submitLessonPageview() {
      if (this.$route.params.id === undefined) return;
      this.$http.put(`/api/lessons/pageview/${this.$route.params.id}`);
    },
    async resetProgress(id) {
      this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
      try {
        await this.$http.delete(`/api/auth/lessons/progress/${id}`);
      } catch (e) {
        if (e.response !== undefined && e.response.status === 401) {
          this.unAuthenticate();
        } else {
          this.movePage('Error');
        }
      }
    },
    clickContinue(name, params, query) {
      this.loading('loadContinue');
      this.movePage(name, params, query);
    },
    loadComplete(id) {
      // 購入済みレッスンリストから対象レッスン情報抽出
      const target = this.userInfo.lesson.purchase
        .filter((lessonPurchase) => lessonPurchase.id === id)[0];
      // chapterId,complete情報を取得
      if (target !== undefined) {
        this.lessonInfoPrivate = target;
        for (let index = 1; index <= this.chapterList.length; index += 1) {
          this.completeChapter.push({
            chapterId: index,
            complete: Boolean(target.inProgress.completeChapter.indexOf(index) + 1),
          });
        }
      }
    },
    // 最初から始めるボタン押下イベント
    async onResetBtn(id) {
      this.loading('resetProgress');
      const result = await this.userInfo.lesson.purchase
        .filter((lesson) => lesson.id === id);
      if (this.userInfo.login) {
        // 入力コードリセットロジック
        if (await result.length !== 0 && result[0].inProgress.progressRate !== 0) {
          await this.deleteUsercode({
            token: this.userInfo.token,
            lessonId: this.lessonInfo.id,
          });
        }
        if (await result.length === 0) {
          // レッスン購入ロジック
          await this.buyLesson([this.lessonInfo.id]);
          await this.loadPurchaseLesson();
          await this.movePage('LessonAttend', { id: this.lessonInfo.id, num: 1 });
        } else if (await this.lessonInfoPrivate.inProgress.completeChapter.length !== 0) {
          // レッスン進捗リセットロジック
          await this.resetProgress(this.lessonInfo.id);
          await this.loadPurchaseLesson();
          await this.movePage('LessonAttend', { id: this.lessonInfo.id, num: 1 });
        } else {
          await this.movePage('LessonAttend', { id: this.lessonInfo.id, num: 1 });
        }
      } else {
        this.movePage('Login', '', { url: this.currentUrl });
      }
    },
    async addCart() {
      if (this.userInfo.login) {
        const maxCartErrorMessage = 'カートの最大件数は20件です';
        try {
          this.loading('addCart');
          if (this.userInfo.cart.length >= 20) throw new Error(maxCartErrorMessage);
          const changeformatAuthor = {
            author: this.authorInfo.name,
            author_avatar: this.authorInfo.img,
            author_user_id: this.authorInfo.id,
          };
          const cartInfo = Object.assign(this.lessonInfo, changeformatAuthor);
          this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
          await this.$http.post('/api/auth/cart', { lesson_id: this.lessonInfo.id });
          this.$store.commit('user/saveCart', cartInfo);
          this.loading('addCart');
          this.showNoticeBar('success', 'カートに追加しました');
        } catch (e) {
          this.loading('addCart');
          if (e.message === maxCartErrorMessage) {
            this.showNoticeBar('error', 'カートの追加に失敗しました', e.message);
          } else {
            this.showNoticeBar('error', 'カートの追加に失敗しました');
          }
        }
      } else {
        this.movePage('Login');
      }
    },
    // chapterList押下イベント
    async onChapter(id) {
      const result = await this.userInfo.lesson.purchase
        .filter((lesson) => lesson.id === id);
      if (!this.userInfo.login) {
        this.movePage('Login', '', { url: this.currentUrl });
      } else if (await result.length === 0) {
        // レッスン購入ロジック
        if (this.lessonInfo.freeChapterNum === null) {
          await this.buyLesson([this.lessonInfo.id]);
          await this.loadPurchaseLesson();
          await this.movePage('LessonAttend', {
            id: this.lessonInfo.id,
            num: 1,
          });
        }
      }
    },
    async loadLessonDetail() {
      try {
        this.loading('loadLesson');
        const res = await this.$http.get(`/api/lessons/${this.$route.params.id}`);

        // validation
        const result = await this.validation(res);
        if (!result) {
          this.$router.push({ path: '/notfound' });
          return;
        }

        // url直指定の場合statusが0の時postLessonを呼び出す
        const postStatusCheck = this.userInfo.lesson.post
          .filter((lesson) => lesson.id === Number(this.$route.params.id));
        if (postStatusCheck.length !== 0 && postStatusCheck[0].status === 0) {
          await this.loadPostLesson();
        }

        const { lesson } = res.data;
        // ログイン後購入済みレッスンの更新がありchapterが増えていた場合
        const purchaseCheck = this.userInfo.lesson.purchase
          .filter((lessons) => lessons.id === Number(this.$route.params.id));
        if (purchaseCheck.length !== 0
          && purchaseCheck[0].chapter.length !== lesson.chapter.length) {
          await this.loadPurchaseLesson();
        }
        const { author } = res.data;
        this.lessonInfo = {
          id: lesson.id,
          title: lesson.title,
          level: lesson.level,
          description: lesson.description,
          target: lesson.target,
          judge: lesson.judge,
          version: lesson.version,
          price: lesson.price,
          evaluation: lesson.evaluation,
          thumbnail: lesson.thumbnail,
          category: lesson.category,
          updatedDate: lesson.updated_at,
          freeContent: lesson.free_content,
          freeChapterNum: lesson.free_chapter_num,
        };

        if (author !== null) {
          this.authorInfo = {
            id: author.id,
            name: author.name,
            img: author.avatar,
            description: author.introduction,
          };
        }

        this.submitLessonPageview();
        this.chapterList = lesson.chapter;
        this.loading('loadLesson');
      } catch (e) {
        if (e.response.status === 404) {
          this.$router.push({ path: '/notfound' });
        } else {
          this.movePage('Error');
        }
      }
    },
    validation(detail) {
      // errorが返却されたパターン
      if (detail.data.errors) return false;
      // status=0パターン
      if (detail.data === null) return false;
      // status=2パターン
      if (detail.data.lesson.status === 2) {
        const postResult = this.userInfo.lesson.post
          .filter((postLesson) => postLesson.id === Number(this.$route.params.id));
        const purchaseResult = this.userInfo.lesson.purchase
          .filter((buyLesson) => buyLesson.id === Number(this.$route.params.id));

        if (postResult.length === 0
          && purchaseResult.length === 0
        ) return false;
      }
      return true;
    },
    moveFreeRangeAttend(chapterNum) {
      try {
        if (chapterNum > this.lessonInfo.freeChapterNum) throw new Error();
        const passLessonInfo = {
          id: this.lessonInfo.id,
          title: this.lessonInfo.title,
          chapterTitle: this.chapterList.slice(0, this.lessonInfo.freeChapterNum),
          freeContent: this.lessonInfo.freeContent,
          update: this.lessonInfo.updatedDate,
        };
        this.loadFreeRangeLesson(passLessonInfo);
        this.movePage('LessonAttendFreeRange', { id: this.lessonInfo.id, num: chapterNum });
      } catch (e) {
        this.$router.push({ path: '/notfound' });
      }
    },
  },
  watch: {
    lessonInfo(to) {
      this.loadComplete(to.id);
    },
    $route(to) {
      this.$router.go({ name: to.name, params: { id: to.params.id } });
    },
  },
};
</script>

<style scoped>
.lesson-detail {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.lesson-detail__back {
  z-index: 1;
  width: 100%;
  height: 300px;
  position: absolute;
  top: 0;
  background-color: #4CAF50;
}

.lesson-detail__front {
  z-index: 2;
  padding: 0 60px;
  margin-top: 80px;
}

.lesson-detail__caution {
  margin-top: 40px;
}

.lesson-detail__info {
  margin-bottom: 40px;
}

.lesson-detail__info__accordion {
  display: flex;
  justify-content: center;
  background-color: #F7F7F7;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
}

.lesson-detail__info__accordion__btn {
  font-size: 24px;
}
.lesson-detail__info__accordion__text {
  margin-left: 10px;
  font-size: 16px;
}

.lesson-detail__info__description {
  padding: 40px 20px;
  border-bottom: 1px solid #F2EEEE;
}
.lesson-detail__info__target {
  padding: 40px 20px;
  border-bottom: 1px solid #F2EEEE;
}

.lesson-detail__info__syllabus {
  margin-top: 20px;
  padding: 40px 20px 0;
}

.lesson-detail__info__syllabus__list {
  list-style: none;
}

.lesson-detail__info__syllabus__list li {
  margin-top: 10px;
}
</style>
