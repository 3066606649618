import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import {
  admin,
  lessonAttend,
  lessonCreate,
  material,
  notice,
  user,
} from './modules';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    admin,
    lessonAttend,
    lessonCreate,
    material,
    notice,
    user,
  },
  plugins: [createPersistedState({
    key: 'manabiba',
    paths: [
      'admin.adminInfo',
      'lessonAttend.currentLesson',
      'lessonAttend.setting',
      'notice.noticeList',
      'user.userInfo',
    ],
  })],
});
