<template>
  <div class='section-user-info'>
    <div class='section-user-info__contents center' v-if='!isLoading'>
      <icon-user :src='setAvatar' xlarge />
      <p class='section-user-info__username'>{{ userName }}</p>
      <icon-role class='section-user-info__ambassador' v-if='ambassador' ambassador/>
    </div>
    <icon-loading class='section-user-info__load' v-if='isLoading' />
  </div>
</template>

<script>
import { IconUser, IconLoading, IconRole } from '@/components/atoms/icon';

export default {
  components: {
    IconUser,
    IconLoading,
    IconRole,
  },
  props: {
    userName: String,
    ambassador: Number,
    src: String,
    isLoading: Boolean,
  },
  computed: {
    setAvatar() {
      if (this.src !== null) {
        const separateFilename = this.src.split('.');
        separateFilename[separateFilename.length - 2] = `${separateFilename[separateFilename.length - 2]}_original`;
        const combineFilename = separateFilename.join('.');
        return combineFilename;
      }
      return null;
    },
  },
};
</script>

<style scoped>
.section-user-info {
  background-color: #4CAF50;
  display: flex;
  align-items: center;
  position: relative;
  min-height: 180px
}

.section-user-info__contents {
  position: relative;
  left: 50px;
  top: 25px;
  display: flex;
  align-items: center;
}

.section-user-info__username {
  font-size: 32px;
  font-weight: bold;
  color: white;
  margin-left: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 540px;
}

.section-user-info__ambassador {
  margin: 4px 0 0 10px;
}

.section-user-info__load {
  margin: 0 auto;
}
</style>
