<template>
  <div @click='$emit("emit")'>
    <i v-if='!isActive' :class="`fas fa-square checkbox-normal`"></i>
    <i v-if='isActive' :class="`fas fa-check-square checkbox-normal`"></i>
  </div>
</template>

<script>
export default {
  props: {
    isActive: Boolean,
  },
};
</script>

<style scope>
  .checkbox-normal {
    color: #4CAF50;
    font-size: 20px;
  }
</style>
