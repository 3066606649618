var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "heading-title" }, [
    _c("div", { staticClass: "heading-title__text center" }, [
      _c(
        "div",
        { staticClass: "heading-title__text__main" },
        [_vm._t("main")],
        2
      ),
      _c("div", { staticClass: "heading-title__text__sub" }, [_vm._t("sub")], 2)
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }