<template>
  <div class='lesson-output'>
    <div class='lesson-output__top'>
      <lesson-output-tab :tabInfo='tabInfo' @emit='changeFocus' />
    </div>
    <div class='lesson-output__bottom'>
      <iframe
        class='lesson-output__bottom__iframe'
        id='assignment'
        sandbox
        v-show='selectId === 0 !== isLoading.createCodeRunEnv'
        :src='this.ideUrl.assignment'
      />
      <iframe
        class='lesson-output__bottom__iframe'
        sandbox
        v-show='selectId === 1 && !isLoading.createCodeRunEnv'
        :src='this.ideUrl.sample'
      />
      <div class='lesson-output__bottom__loading' v-if='isLoading.createCodeRunEnv'>
        <icon-loading
          class='lesson-output__bottom__loading__icon'
          white
        >
        コード実行環境を構築しています...
        </icon-loading>
        <div class='lesson-output__bottom__loading__overlay' />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import helper from '@/mixins/admin/methods/helper';
import { mapState } from 'vuex';
import { IconLoading } from '@/components/atoms/icon';
import { LessonOutputTab } from '@/components/atoms/lesson';

const ideAxiosBase = axios.create({
  baseURL: process.env.VUE_APP_IDE_HOST,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.VUE_APP_IDE_KEY,
  },
  credentials: true,
  responseType: 'json',
});

export default {
  mixins: [helper],
  components: {
    IconLoading,
    LessonOutputTab,
  },
  props: {
    editSource: String,
    sampleSource: String,
    isActiveCodeRun: Boolean,
    isActiveDatabaseReset: Boolean,
  },
  data() {
    return {
      selectId: 0,
      tabInfo: ['出力結果', '見本'],
      codeRunServerInfo: { // 単一ファイル実行限定data
        service: 'php',
        filename: 'index.php',
      },
      runResult: {
        assignment: '',
        sample: '',
      },
      isLoading: {
        createCodeRunEnv: false,
      },
      ideBaseUrl: {
        stg: 'stg.ide.donbler.com',
        prd: 'prd.ide.donbler.com',
      },
      ideUrl: {
        assignment: '',
        sample: '',
      },
      ideUserId: {
        assignment: '',
        sample: '',
      },
    };
  },
  computed: {
    ...mapState('user', ['userInfo']),
    setLessonId() {
      return this.$route.params.id === undefined ? '0' : this.$route.params.id;
    },
  },
  async mounted() {
    const sleep = (msec) => new Promise((resolve) => setTimeout(resolve, msec));

    this.isLoading.createCodeRunEnv = true;
    await this.setUserId('assignment');
    await this.setUserId('sample');

    await this.databaseReset('assignment');
    await sleep(1000);
    await this.databaseReset('sample');
    await sleep(1000);

    if (await this.validationCodeRun(this.setSource('assignment'))) {
      await this.buildFile('assignment');
      await sleep(5000);
      await this.setIframeUrl('assignment');
    }
    if (await this.validationCodeRun(this.setSource('sample'))) {
      await this.buildFile('sample');
      await sleep(5000);
      await this.setIframeUrl('sample');
    }

    await this.$emit('emit-generated-server-env');
    this.isLoading.createCodeRunEnv = await false;
  },
  methods: {
    // タブ、コンソールを連結させているselectIdを変更する
    changeFocus(index) {
      this.selectId = index;
    },
    // EFS内に対象ファイルの存在チェック、なければ作成
    async buildFile(target) {
      try {
        // 存在チェック
        const resFileExist = await this.checkFileExist(target);
        if (resFileExist.data.errorType === 'FileNotFoundError') {
          // なければ作成
          this.createFile(target);
        } else if (resFileExist.data.errorType !== undefined) {
          this.showNoticeBar('error', 'コードの実行に失敗しました', '画面をリロードしもう一度お試しください');
        } else {
          // 存在するなら最新コードセーブ
          this.codeSave(target);
        }
      } catch {
        this.showNoticeBar('error', 'コードの実行に失敗しました', '画面をリロードしもう一度お試しください');
      }
    },
    // EFSにファイルが存在するかチェック
    async checkFileExist(target) {
      try {
        return await ideAxiosBase.get(`/${this.setEnv()}/contents`, {
          params: {
            userId: this.ideUserId[target],
            service: this.codeRunServerInfo.service,
            lessonId: this.setLessonId,
            path: this.$route.params.num,
            fileName: this.codeRunServerInfo.filename,
          },
        });
      } catch {
        return false;
      }
    },
    // EFSにファイル作成
    async createFile(target) {
      try {
        await ideAxiosBase.post(`/${this.setEnv()}/add`, {
          userId: this.ideUserId[target],
          service: this.codeRunServerInfo.service,
          path: `${this.setLessonId}/${this.$route.params.num}`,
          fileName: this.codeRunServerInfo.filename,
          content: this.setSource(target),
        });
      } catch {
        this.showNoticeBar('error', 'コードの実行に失敗しました', '画面をリロードしもう一度お試しください');
      }
    },
    // EFSのファイル内のコードを更新
    async codeSave(target) {
      try {
        await ideAxiosBase.post(`/${this.setEnv()}/edit`, {
          userId: this.ideUserId[target],
          service: this.codeRunServerInfo.service,
          path: `${this.setLessonId}/${this.$route.params.num}`,
          fileName: this.codeRunServerInfo.filename,
          content: this.setSource(target),
        });
      } catch {
        this.showNoticeBar('error', 'コードの実行に失敗しました', 'もう一度お試しください');
      }
    },
    // database.sqliteを初期化
    async databaseReset(target) {
      await ideAxiosBase.post(`/${this.setEnv()}/edit`, {
        userId: this.ideUserId[target],
        service: this.codeRunServerInfo.service,
        path: `${this.setLessonId}/${this.$route.params.num}`,
        fileName: 'database.sqlite',
        content: '',
      });
    },
    async sha256(text) {
      const uint8 = new TextEncoder().encode(text);
      const digest = await crypto.subtle.digest('SHA-256', uint8);
      return Array.from(new Uint8Array(digest)).map((v) => v.toString(16).padStart(2, '0')).join('').substr(0, 16);
    },
    async setUserId(target) {
      let joinedUrl = await this.sha256(String(this.userInfo.profile.id));
      // assignment, sample判定
      joinedUrl = target === 'assignment' ? `a_${joinedUrl}` : `s_${joinedUrl}`;
      // プレビュー環境判定
      if (this.$route.name === 'LessonPreview') joinedUrl = `preview_${joinedUrl}`;
      // Adminプレビュー環境判定
      if (this.$route.name === 'AdminLessonPreview') joinedUrl = `admin_preview_${joinedUrl}`;
      // 開発環境かどうか
      if (process.env.VUE_APP_HOST === 'https://dev.donbler.com') joinedUrl = `dev_${joinedUrl}`;
      this.ideUserId[target] = joinedUrl;
    },
    setSource(target) {
      return target === 'assignment'
        ? this.editSource
        : this.sampleSource;
    },
    async setIframeUrl(target) {
      const uniqueSubDomain = `${this.ideUserId[target]}-${this.setLessonId}-${this.$route.params.num}-php-pure`;
      const date = new Date();
      const formatedDate = `${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getSeconds()}`;
      this.ideUrl[target] = `https://${uniqueSubDomain}.${this.ideBaseUrl[this.setEnv()]}?date=${formatedDate}`;
    },
    setEnv() {
      return process.env.VUE_APP_HOST === 'https://api.donbler.com' ? 'prd' : 'stg';
    },
    validationCodeRun(content) {
      // sqlite命名判定
      if (content.includes('sqlite:') && !content.includes('sqlite:database.sqlite')) {
        this.showNoticeBar('error', 'コードの実行に失敗しました', 'sqliteのファイル名はdatabase.sqliteのみ設定可能です');
        return false;
      }
      // sqlite複数定義
      if (content.match(/sqlite:/g) !== null && content.match(/sqlite:/g).length > 1) {
        this.showNoticeBar('error', 'コードの実行に失敗しました', 'sqliteはファイル内で一度しか定義できません');
        return false;
      }
      return true;
    },
  },
  watch: {
    // コード実行イベント取得後codeRunメソッド実行 実行イベントを親に返却
    async isActiveCodeRun(to) {
      if (to) {
        if (this.validationCodeRun(this.setSource('assignment'))) {
          await this.codeSave('assignment');
          await this.setIframeUrl('assignment');
        }
        this.$emit('emit-code-run');
      }
    },
    isActiveDatabaseReset(to) {
      if (to) {
        this.databaseReset('assignment');
        this.$emit('emit-database-reset');
      }
    },
  },
};
</script>

<style scoped>
.lesson-output {
  width: 100%;
}

.lesson-output__top {
  width: 100%;
}

.lesson-output__bottom {
  position: relative;
  height: calc(100vh - 110px);
}

.lesson-output__bottom__iframe {
  width: 100%;
  height: calc(100vh - 110px);
  border: none;
  color: #000;
  background-color: #fff;
  padding: 0;
  overflow: scroll;
}

.lesson-output__bottom__loading__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.lesson-output__bottom__loading__overlay {
  position: absolute;
  top: 0;
  background-color: rgb(65, 65, 65);
  width: 100%;
  height: calc(100vh - 110px);
  z-index: 1;
}
</style>
