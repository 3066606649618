<template>
  <ul class='input-radio'>
      <li v-for='(inputData, index) in radioInfo.dataList' :key='index'>
        <input
          type='radio'
          v-model='checkValue'
          :id='`${radioInfo.name}-${inputData.value}`'
          :name='radioInfo.name'
          :value='inputData.value'
        >
        <label :for='`${radioInfo.name}-${inputData.value}`'>{{ inputData.label}}</label>
      </li>
  </ul>
</template>

<script>
export default {
  props: {
    checked: [Number, String],
    radioInfo: Object,
  },
  data() {
    return {
      checkValue: null,
    };
  },
  mounted() {
    // radio初期選択
    this.checkValue = this.checked;
  },
  watch: {
    checkValue(to) {
      this.$emit('emit-radio-value', to);
    },
  },
};
</script>

<style scoped>
.input-radio {
  display: flex;
  list-style: none;
}

.input-radio input{
  display: none;
}

.input-radio input + label{
  padding-left: 25px;
  position:relative;
  margin-right: 20px;
  font-size: 14px;
}

.input-radio input + label::before{
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: #4CAF50;
  border-radius: 50%;
}

.input-radio input:checked + label::after{
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
}
</style>
