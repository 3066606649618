<template>
  <div class="title-normal">
    <heading-list
      class='title-normal__left'
      :listInfo='label'
      :disabled='disabled'
      title
      large
      @emit='$emit("emit-heading", $event)'
    >{{ title }}</heading-list>
    <div class="title-normal__right">
      <p class='title-normal__right__count' v-if='count'>{{ count }}件</p>
      <button-normal
        v-if='button'
        @emit='$emit("emit")'
        small
        :disabled='disabled'
        :loading='loading'
      >{{ button }}</button-normal>
    </div>
  </div>
</template>

<script>
import { HeadingList } from '@/components/atoms/heading';
import { ButtonNormal } from '@/components/atoms/button';

export default {
  components: {
    HeadingList,
    ButtonNormal,
  },
  props: {
    title: String,
    count: Number,
    label: Array,
    button: String,
    disabled: Boolean,
    loading: Boolean,
  },
};
</script>

<style scoped>
.title-normal {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #fff;
}

.title-normal__right {
  display: flex;
  align-items: center;
}

.title-normal__right__count {
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
}
</style>
