<template>
  <ul class='dropdown-header-category'>
    <li
      class='dropdown-header-category__list'
      v-for='(categoryName, key) in categoryNameList'
      :key='categoryName.id'
    >
      <router-link
        class='dropdown-header-category__list__btn'
        :to='{ name: "Categories",  params: { category: key}}'
      >
        <i :class='`fab fa-${ setCategoryClass(key) }`' />
        <p>{{ categoryName }}</p>
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    categoryNameList: Object,
  },
  methods: {
    setCategoryClass(key) {
      // paramsの値とclass名が異なる場合は修正してreturnする
      if (key === 'htmlcss') {
        return 'html5';
      } if (key === 'javascript') {
        return 'js';
      }
      return key;
    },
  },
};
</script>

<style scoped>
.dropdown-header-category {
  background-color: #fff;
  position: relative;
  width: 150px;
  padding: 5px;
  box-shadow:  0 8px 10px 4px rgba(0, 0, 0, 0.02);
}

.dropdown-header-category::after {
  content: "";
  position: absolute;
  right: 0;
  top: -12px;
  left: -115px;
  width: 0px;
  height: 0px;
  margin: auto;
  border-right: 10px solid transparent;
  border-bottom: 16px solid #fff;
  border-left: 10px solid transparent;
}

.dropdown-header-category__list {
  list-style: none;
}

.dropdown-header-category__list:hover {
  background-color: #f9f9fa;
}

.dropdown-header-category__list__btn {
  display: flex;
  align-items: center;
  padding: 5px;
}

.dropdown-header-category__list__btn p {
  margin-left: 5px;
  font-size: 12px;
}

/* iconカラー */
.fab {
  font-size: 20px;
  width: 30px;
}

.fa-html5 {
  color: #FF6200;
}

.fa-js {
  color: #FFD800;
}

.fa-php {
  color: #3B00FF;
}
</style>
