var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dropdown-user" },
    [
      _c(
        "router-link",
        {
          staticClass: "dropdown-user__userinfo",
          attrs: { to: { name: "UserInfoSetting" } }
        },
        [
          _c("button-user", {
            staticClass: "dropdown-user__userinfo__icon",
            attrs: { src: _vm.userIcon }
          }),
          _c("p", { staticClass: "dropdown-user__userinfo__text" }, [
            _vm._v(_vm._s(_vm.userName))
          ])
        ],
        1
      ),
      _c("div", { staticClass: "dropdown-user__lesson" }, [
        _c("p", { staticClass: "dropdown-user__lesson__text" }, [
          _vm._v("受講中のレッスン")
        ]),
        _c(
          "ul",
          { staticClass: "dropdown-user__lesson__list" },
          [
            _vm._l(_vm.purchaseList.slice(0, 5), function(lesson) {
              return _c(
                "router-link",
                {
                  key: lesson.id,
                  staticClass: "dropdown-user__lesson__list__content",
                  attrs: {
                    to: {
                      name: "LessonAttend",
                      params: { id: lesson.id, num: _vm.setContinue(lesson.id) }
                    }
                  }
                },
                [
                  _c("img", {
                    staticClass: "dropdown-user__lesson__list__content__left",
                    attrs: { src: lesson.image, alt: "レッスンのサムネイル" }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "dropdown-user__lesson__list__content__right"
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "dropdown-user__lesson__list__content__right__title"
                        },
                        [_vm._v(_vm._s(lesson.title))]
                      ),
                      _c("bar-progress", {
                        attrs: {
                          progress: lesson.inProgress.progressRate,
                          small: ""
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            }),
            _vm.purchaseList.length > 2
              ? _c(
                  "li",
                  { staticClass: "dropdown-user__lesson__all" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { name: "UserInfoLessons" } } },
                      [_vm._v(" すべてのレッスン ")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          2
        ),
        _vm.purchaseList.length === 0
          ? _c("p", { staticClass: "dropdown-user__lesson__notfound" }, [
              _vm._v("受講中のレッスンはありません")
            ])
          : _vm._e()
      ]),
      _c(
        "router-link",
        {
          staticClass: "dropdown-user__create",
          attrs: { to: { name: "LessonCreate" } }
        },
        [
          _c("i", { staticClass: "fas fa-file-upload" }),
          _c("p", [_vm._v("レッスンを投稿")])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "dropdown-user__logout",
          on: {
            click: function($event) {
              return _vm.$emit("emit-logout")
            }
          }
        },
        [
          _c("i", { staticClass: "fas fa-sign-out-alt" }),
          _c("p", { staticClass: "dropdown-user__logout__text" }, [
            _vm._v("ログアウト")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }