var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "button-user",
      on: {
        click: function($event) {
          return _vm.$emit("emit")
        }
      }
    },
    [
      _c("img", {
        attrs: { src: _vm.src, alt: "ユーザーアイコン" },
        on: { error: _vm.setErrorAvatar }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }