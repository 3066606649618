var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "button-username",
      on: {
        click: function($event) {
          return _vm.$emit("emit")
        }
      }
    },
    [
      _vm.isActive
        ? _c("i", { staticClass: "button-username__icon fas fa-caret-up" })
        : _vm._e(),
      !_vm.isActive
        ? _c("i", { staticClass: "button-username__icon fas fa-caret-down" })
        : _vm._e(),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }