import InputUrl from './InputUrl';
import InputFile from './InputFile';
import InputText from './InputText';
import InputNumber from './InputNumber';
import InputSearch from './InputSearch';
import InputTextarea from './InputTextarea';
import InputFileLesson from './InputFileLesson';
import InputPassword from './InputPassword';
import InputRadio from './InputRadio';
import InputBank from './InputBank';

export {
  InputUrl,
  InputFile,
  InputFileLesson,
  InputText,
  InputNumber,
  InputTextarea,
  InputSearch,
  InputPassword,
  InputRadio,
  InputBank,
};
