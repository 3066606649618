var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { staticClass: "input-file-lesson center" }, [
    _c("div", { class: _vm.addClass }, [
      _c("i", {
        staticClass: "fas fa-folder fa-5x input-file-lesson__form__icon"
      }),
      _c(
        "div",
        { staticClass: "input-file-lesson__form__text" },
        [_vm._t("default")],
        2
      )
    ]),
    _c("input", {
      staticClass: "input-file-lesson__hide",
      attrs: { id: "lessonForm", type: "file", webkitdirectory: "" },
      on: { change: _vm.loadPostLesson }
    }),
    _c("div", { staticClass: "input-file-lesson__error" }, [
      _vm._v(" " + _vm._s(_vm.errorMsg) + " ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }