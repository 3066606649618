<template>
    <button :class='`
      list-chapter-mini
      ${current ? "current" : ""}
      ${lock ? "list-chapter-mini--lock": ""}
    `'
    @click='emitPush("emit-move-page")'>
      <div :class='`list-chapter-mini__left ${current ? "current" : ""}`'>
        <p class='list-chapter-mini__left__num'>{{ num + 1}}</p>
        <p class='list-chapter-mini__left__text'>{{ title }}</p>
      </div>
      <div :class='`list-chapter-mini__right ${current ? "current" : ""}`'>
        <div
          class='list-chapter-mini__right__check'
          v-if='!hideCheck && !lock'
        >
          <i v-if='complete === true' class='fas fa-check-circle' />
          <i v-if='complete === false' class='far fa-circle' />
        </div>
      <div
        class='list-chapter-mini__right__lock'
        v-if='lock'
      >
        <i class="fas fa-lock" />
      </div>
      </div>
    </button>
</template>

<script>

export default {
  props: {
    num: Number,
    id: Number,
    title: String,
    complete: Boolean,
    current: Boolean,
    lock: Boolean,
    hideCheck: Boolean,
  },
  methods: {
    emitPush(event) {
      if (!this.current) {
        this.$emit(event);
      }
    },
  },
};
</script>

<style scoped>
.list-chapter-mini {
  display: flex;
  align-items: center;
  background-color: #f9f9fa;
  justify-content: space-between;
  padding: 0 20px;
  height: 50px;
  width: 100%;
  transition: 0.15s;
}

.list-chapter-mini:hover {
  background-color: #f3f3f5;
}

.list-chapter-mini--lock {
  background-color: #CBCBCB;
  pointer-events: none;
}

.list-chapter-mini__left {
  display: flex;
  font-weight: 500;
  font-size: 14px;
}

.list-chapter-mini__left__text {
  margin: 0 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-all;
}

.list-chapter-mini__right {
  font-size: 20px;
  color: #4CAF50;
}

.list-chapter-mini__right__lock {
  color: #E74860;
  font-size: 12px;
}

.current.list-chapter-mini {
  background-color: #4CAF50;
  color: #fff;
  cursor: default;
}

.current.list-chapter-mini__right {
  color: #fff;
}
</style>
