var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "about" },
    [
      _c("layout-header"),
      _vm._m(0),
      _vm._m(1),
      _vm._m(2),
      _vm._m(3),
      _vm._m(4),
      _c("section", { staticClass: "about__start" }, [
        !_vm.userInfo.login
          ? _c(
              "div",
              { staticClass: "about__start__wrapper" },
              [
                _c("h2", { staticClass: "about__start__title" }, [
                  _vm._v("さっそく始めましょう")
                ]),
                _vm._m(5),
                _c(
                  "router-link",
                  { attrs: { to: { name: "Login" } } },
                  [
                    _c(
                      "button-normal",
                      {
                        staticClass: "about__start__button",
                        attrs: { large: "" }
                      },
                      [_vm._v(" ログイン ")]
                    )
                  ],
                  1
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "about__start__movetop",
                    attrs: { to: { name: "Top" } }
                  },
                  [_vm._v("レッスンを見る")]
                )
              ],
              1
            )
          : _vm._e(),
        _vm.userInfo.login
          ? _c(
              "div",
              { staticClass: "about__start__wrapper" },
              [
                _c("h2", { staticClass: "about__start__title" }, [
                  _vm._v("さっそく始めましょう")
                ]),
                _c("p", { staticClass: "about__start__lead" }, [
                  _vm._v(
                    " 自分に合ったレッスンを見つけ勉強をスタートしましょう！ "
                  )
                ]),
                _c(
                  "router-link",
                  { attrs: { to: { name: "Top" } } },
                  [
                    _c(
                      "button-normal",
                      {
                        staticClass: "about__start__button",
                        attrs: { large: "" }
                      },
                      [_vm._v(" レッスンを見る ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _c("section", { staticClass: "about__sns about__sns__wrapper" }),
      _c("section", { staticClass: "about__official" }, [
        _c("div", { staticClass: "about__official__warpper" }, [
          _vm._m(6),
          _vm._m(7),
          _c(
            "div",
            { staticClass: "about__official__sns" },
            [
              _c(
                "share-network",
                {
                  staticClass: "about__official__sns__btn",
                  attrs: {
                    network: _vm.shareInfo.twitter.network,
                    url: _vm.shareInfo.twitter.url,
                    title: _vm.shareInfo.twitter.title,
                    hashtags: _vm.shareInfo.twitter.hashtags
                  }
                },
                [
                  _c("i", {
                    staticClass: "fab fa-twitter about__official__sns__twitter"
                  })
                ]
              ),
              _c(
                "share-network",
                {
                  staticClass: "about__official__sns__btn",
                  attrs: {
                    network: _vm.shareInfo.facebook.network,
                    url: _vm.shareInfo.facebook.url,
                    title: _vm.shareInfo.facebook.title,
                    hashtags: _vm.shareInfo.facebook.hashtags
                  }
                },
                [
                  _c("i", {
                    staticClass:
                      "fab fa-facebook-square about__official__sns__facebook"
                  })
                ]
              ),
              _c(
                "share-network",
                {
                  staticClass: "about__official__sns__btn",
                  attrs: {
                    network: _vm.shareInfo.line.network,
                    url: _vm.shareInfo.line.url,
                    title: _vm.shareInfo.line.title,
                    description: _vm.shareInfo.line.description
                  }
                },
                [
                  _c("i", {
                    staticClass: "fab fa-line about__official__sns__line"
                  })
                ]
              ),
              _c(
                "share-network",
                {
                  staticClass: "about__official__sns__btn",
                  attrs: {
                    network: _vm.shareInfo.pocket.network,
                    url: _vm.shareInfo.pocket.url,
                    title: _vm.shareInfo.pocket.title
                  }
                },
                [
                  _c("i", {
                    staticClass:
                      "fab fa-get-pocket about__official__sns__pocket"
                  })
                ]
              )
            ],
            1
          )
        ])
      ]),
      _c("layout-footer")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "about__top" }, [
      _c("div", { staticClass: "about__top__wrapper" }, [
        _c("div", { staticClass: "about__top__content" }, [
          _c("h1", { staticClass: "about__top__content__title" }, [
            _vm._v("脱プログラミング初心者！"),
            _c("br"),
            _vm._v("次の一歩に悩むあなたへ")
          ]),
          _c("p", { staticClass: "about__top__content__text" }, [
            _vm._v(" manabibaは環境構築不要で、すぐ学べる"),
            _c("br"),
            _vm._v("プログラミング学習レッスンプラットフォームです。 ")
          ])
        ]),
        _c("div", { staticClass: "about__top__image" }, [
          _c("img", {
            staticClass: "about__top__image__attend",
            attrs: {
              src: require("@/assets/img/sphone/sphone_attendpage.png"),
              alt: ""
            }
          })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "about__feature" }, [
      _c("div", { staticClass: "about__feature__wrapper" }, [
        _c("h2", { staticClass: "about__feature__title" }, [
          _vm._v("manabibaの特徴")
        ]),
        _c("ul", { staticClass: "about__feature__list__wrapper" }, [
          _c("li", { staticClass: "about__feature__list" }, [
            _c("img", {
              staticClass: "about__feature__list__img",
              attrs: {
                src: require("@/assets/img/sphone/sphone_attendpage_zoom.png"),
                alt: "manabibaの受講ページ"
              }
            }),
            _c("div", { staticClass: "about__feature__list__contents" }, [
              _c(
                "h3",
                { staticClass: "about__feature__list__contents__title" },
                [
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticClass:
                          "about__feature__list__contents__title__num"
                      },
                      [_vm._v("1")]
                    ),
                    _c(
                      "span",
                      {
                        staticClass:
                          "about__feature__list__contents__title__period"
                      },
                      [_vm._v(".")]
                    )
                  ]),
                  _c(
                    "p",
                    {
                      staticClass: "about__feature__list__contents__title__text"
                    },
                    [
                      _vm._v(" 開発環境の準備不要！"),
                      _c("br"),
                      _vm._v("ブラウザ上ですぐ学べる ")
                    ]
                  )
                ]
              ),
              _c("p", { staticClass: "about__feature__list__contents__text" }, [
                _vm._v(" ブラウザ上でコードを実行できるため"),
                _c("br"),
                _vm._v("難しい環境構築の必要はありません！ ")
              ])
            ])
          ]),
          _c("li", { staticClass: "about__feature__list" }, [
            _c("img", {
              staticClass: "about__feature__list__img",
              attrs: {
                src: require("@/assets/img/sphone/sphone_feature_variety.png"),
                alt: "プログラミング言語"
              }
            }),
            _c("div", { staticClass: "about__feature__list__contents" }, [
              _c(
                "h3",
                { staticClass: "about__feature__list__contents__title" },
                [
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticClass:
                          "about__feature__list__contents__title__num"
                      },
                      [_vm._v("2")]
                    ),
                    _c(
                      "span",
                      {
                        staticClass:
                          "about__feature__list__contents__title__period"
                      },
                      [_vm._v(".")]
                    )
                  ]),
                  _c(
                    "p",
                    {
                      staticClass: "about__feature__list__contents__title__text"
                    },
                    [
                      _vm._v(" 基礎から応用まで幅広い"),
                      _c("br"),
                      _vm._v("レベルのレッスンを提供 ")
                    ]
                  )
                ]
              ),
              _c("p", { staticClass: "about__feature__list__contents__text" }, [
                _vm._v(
                  " 投稿型サービスなので多種多様なレッスンの中からあなたに合ったレッスンを受講できます。 "
                )
              ])
            ])
          ]),
          _c("li", { staticClass: "about__feature__list" }, [
            _c("img", {
              staticClass: "about__feature__list__img",
              attrs: {
                src: require("@/assets/img/sphone/sphone_feature_knowledge.png"),
                alt: "プログラミングの学習についての悩み"
              }
            }),
            _c("div", { staticClass: "about__feature__list__contents" }, [
              _c(
                "h3",
                { staticClass: "about__feature__list__contents__title" },
                [
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticClass:
                          "about__feature__list__contents__title__num"
                      },
                      [_vm._v("3")]
                    ),
                    _c(
                      "span",
                      {
                        staticClass:
                          "about__feature__list__contents__title__period"
                      },
                      [_vm._v(".")]
                    )
                  ]),
                  _c(
                    "p",
                    {
                      staticClass: "about__feature__list__contents__title__text"
                    },
                    [_vm._v(" 独学で必要な予備知識を徹底解説(準備中) ")]
                  )
                ]
              ),
              _c("p", { staticClass: "about__feature__list__contents__text" }, [
                _vm._v(
                  " 学習ロードマップやどこまで学べば 次へ進んでよいかなど、独学する上で悩みがちな箇所の解説を発信します。 "
                )
              ])
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "about__procedure" }, [
      _c("div", { staticClass: "about__procedure__wrapper" }, [
        _c("h2", { staticClass: "about__procedure__title" }, [
          _vm._v("レッスンの受講手順")
        ]),
        _c("ul", { staticClass: "about__procedure__list__wrapper" }, [
          _c("li", { staticClass: "about__procedure__list" }, [
            _c("div", { staticClass: "about__procedure__list__contents" }, [
              _c(
                "p",
                { staticClass: "about__procedure__list__contents__num" },
                [_vm._v("1")]
              ),
              _c("img", {
                staticClass: "about__procedure__list__contents__img",
                attrs: {
                  src: require("@/assets/img/toppage.png"),
                  alt: "manabibaのトップページ"
                }
              }),
              _c(
                "h3",
                { staticClass: "about__procedure__list__contents__title" },
                [_vm._v(" 1. manabibaにログイン ")]
              ),
              _c(
                "p",
                { staticClass: "about__procedure__list__contents__text" },
                [
                  _vm._v(" Github/Twitter/Google/Facebook"),
                  _c("br"),
                  _vm._v("アカウントでログインできます。 ")
                ]
              )
            ]),
            _c("div", { staticClass: "about__procedure__list__triangle" })
          ]),
          _c("li", { staticClass: "about__procedure__list" }, [
            _c("div", { staticClass: "about__procedure__list__contents" }, [
              _c(
                "p",
                { staticClass: "about__procedure__list__contents__num" },
                [_vm._v("2")]
              ),
              _c("img", {
                staticClass: "about__procedure__list__contents__img",
                attrs: {
                  src: require("@/assets/img/detailpage.png"),
                  alt: "manabibaの詳細ページ"
                }
              }),
              _c(
                "h3",
                { staticClass: "about__procedure__list__contents__title" },
                [_vm._v(" 2. レッスンを選択 ")]
              ),
              _c(
                "p",
                { staticClass: "about__procedure__list__contents__text" },
                [_vm._v(" 自分に合ったレッスンを選択してください。 ")]
              )
            ]),
            _c("div", { staticClass: "about__procedure__list__triangle" })
          ]),
          _c("li", { staticClass: "about__procedure__list" }, [
            _c("div", { staticClass: "about__procedure__list__contents" }, [
              _c(
                "p",
                { staticClass: "about__procedure__list__contents__num" },
                [_vm._v("3")]
              ),
              _c("img", {
                staticClass: "about__procedure__list__contents__img",
                attrs: {
                  src: require("@/assets/img/sphone/sphone_attendpage.png"),
                  alt: "manabibaの受講ページ"
                }
              }),
              _c(
                "h3",
                { staticClass: "about__procedure__list__contents__title" },
                [_vm._v(" 3. 受講スタート ")]
              ),
              _c(
                "p",
                { staticClass: "about__procedure__list__contents__text" },
                [_vm._v(" ブラウザ内で学習を開始できます。 ")]
              )
            ])
          ])
        ])
      ]),
      _c("img", {
        staticClass: "about__procedure__first-bar",
        attrs: {
          src: require("@/assets/img/section-welcome-back.svg"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "about__lang" }, [
      _c("div", { staticClass: "about__lang__wrapper" }, [
        _c("h2", { staticClass: "about__lang__title" }, [_vm._v("対応言語")]),
        _c("ul", { staticClass: "about__lang__contents" }, [
          _c("li", { staticClass: "about__lang__contents__list" }, [
            _c("div", { staticClass: "about__lang__contents__htmlcss" }, [
              _c("i", {
                staticClass: "fab fa-html5 about__lang__contents__html"
              }),
              _c("i", {
                staticClass: "fab fa-css3-alt about__lang__contents__css"
              })
            ]),
            _c("p", { staticClass: "about__lang__contents__list__text" }, [
              _vm._v("HTML/CSS")
            ])
          ]),
          _c("li", { staticClass: "about__lang__contents__list" }, [
            _c("i", { staticClass: "fab fa-js about__lang__contents__js" }),
            _c("p", { staticClass: "about__lang__contents__list__text" }, [
              _vm._v("Javascript")
            ])
          ]),
          _c("li", { staticClass: "about__lang__contents__list" }, [
            _c("i", { staticClass: "fab fa-php about__lang__contents__php" }),
            _c("p", { staticClass: "about__lang__contents__list__text" }, [
              _vm._v("PHP")
            ])
          ]),
          _c("li", { staticClass: "about__lang__contents__list" }, [
            _c("img", {
              staticClass: "about__lang__contents__other",
              attrs: {
                src: require("@/assets/img/sphone/sphone_language.svg"),
                alt: "桃 peach"
              }
            }),
            _c("p", { staticClass: "about__lang__contents__list__text" }, [
              _vm._v("その他"),
              _c("br"),
              _vm._v("順次対応中")
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "about__creator" }, [
      _c("div", { staticClass: "about__creator__wrapper" }, [
        _c("h2", { staticClass: "about__creator__title" }, [
          _vm._v("レッスンを作ってみたい方も")
        ]),
        _c("p", { staticClass: "about__creator__text" }, [
          _vm._v(" manabibaではレッスンを作成してくれる方を募集しています。"),
          _c("br"),
          _vm._v(
            " テキストとソースコードがあれば投稿できて、副業としても最適です。"
          ),
          _c("br"),
          _vm._v(" manabibaを通じて次世代のエンジニア育成に貢献しませんか？ ")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "about__start__lead" }, [
      _vm._v(" Github/Twitter/Google/Facebookアカウントがあれば"),
      _c("br"),
      _vm._v("すぐに学習を開始できます。 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "about__official__img__wrap",
        attrs: { href: "https://twitter.com/manabiba_jp", target: "_blank" }
      },
      [_c("i", { staticClass: "fab fa-twitter about__official__img" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "about__official__text" }, [
      _vm._v(" manabibaの最新情報は公式Twitter"),
      _c("br"),
      _vm._v("@manabiba_jp でチェックできます。 ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }