<template>
  <ul class='pager-normal'>
    <li>
      <button
        class='pager-normal__prev'
        :class='activePage === 1 ? "disabled": ""'
        @click='activePage !== 1 ? selectPage(activePage - 1):""'
      >
        <i class='fas fa-chevron-left pager-normal__prev__btn' />
      </button>
    </li>
    <li class='pager-normal__list' v-for='(page) in pages' :key='page.id'>
      <button
        :class='activePage === page ? "select": "" ' @click='selectPage(page)'>
        {{ page }}
      </button>
    </li>
    <li>
      <button
        class='pager-normal__next'
        :class='activePage === pageCount ? "disabled": ""'
        @click='activePage !== pageCount ? selectPage(activePage + 1):""'
      >
        <i class="fas fa-chevron-right pager-normal__next__btn" />
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    totalCount: {
      type: Number,
      default: 100,
    },
    display: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      pageCount: 0,
      pages: [],
      activePage: 1,
    };
  },
  created() {
    this.pageCount = Math.ceil(this.totalCount / this.display);
    this.pageNumberList(1);
  },
  methods: {
    pageNumberList(pageNumber) {
      this.pages.splice(-this.pages.length);
      if (pageNumber <= 6 || this.pageCount < 10) {
        if (this.pageCount < 10) {
          for (let i = 1; i <= this.pageCount; i += 1) { this.pages.push(i); }
        } else {
          for (let i = 1; i <= 10; i += 1) { this.pages.push(i); }
        }
      } else if (pageNumber > 6 && pageNumber < this.pageCount - 4) {
        for (let i = pageNumber - 5; i <= pageNumber + 4; i += 1) {
          this.pages.push(i);
        }
      } else {
        for (let i = this.pageCount - 9; i <= this.pageCount; i += 1) {
          this.pages.push(i);
        }
      }
    },
    selectPage(page) {
      if (this.activePage !== page) {
        this.activePage = page;
        this.pageNumberList(page);
        this.$emit('emit', page);
      }
    },
  },
  watch: {
    totalCount(to) {
      this.pageCount = Math.ceil(to / this.display);
      this.pageNumberList(1);
    },
  },
};
</script>
<style>
.pager-normal {
  display: flex;
  list-style: none;
  align-items: center;
}

.pager-normal__list {
  font-size: 16px;
  width: 30px;
  text-align: center;
  color: #4CAF50;
}

.pager-normal__prev {
  display: block;
  content: '';
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: #4CAF50;
  position: relative;
  margin-right: 10px;
}

.pager-normal__prev__btn {
  color: #fff;
  font-size: 1.3em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-60%, -50%);
}

.pager-normal__next {
  display: block;
  content: '';
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: #4CAF50;
  position: relative;
  margin-left: 10px;
}

.pager-normal__next__btn {
  color: #fff;
  font-size: 1.3em;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(60%, -50%);
}

.select {
  color: #000;
  font-weight: 500;
  cursor: default;
}

.disabled{
  background-color: #E1E1E1;
}
</style>
