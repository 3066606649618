var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "signup" },
    [
      _c("notifications", { attrs: { group: "top-right" } }),
      _c("form-signup", {
        attrs: {
          form: _vm.title.form,
          userName: _vm.title.userName,
          password: _vm.title.password,
          repassword: _vm.title.repassword,
          hash: _vm.title.hash,
          isLoading: _vm.isLoading
        },
        on: { "emit-admin-signup": _vm.adminSignup }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }