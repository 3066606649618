var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.addClass }, [
    !_vm.simpleFooter
      ? _c("div", { staticClass: "layout-footer__main center" }, [
          _c("div", { staticClass: "layout-footer__main__left" }, [
            _c(
              "div",
              { staticClass: "layout-footer__main__left__top" },
              [
                _c("layout-logo", { attrs: { small: "" } }),
                _c(
                  "p",
                  { staticClass: "layout-footer__main__left__top__subtitle" },
                  [_vm._v(_vm._s(_vm.subtitle))]
                )
              ],
              1
            ),
            _c("div", { staticClass: "layout-footer__main__left__bottom" }, [
              _c(
                "p",
                { staticClass: "layout-footer__main__left__bottom__copyright" },
                [_vm._v(_vm._s(_vm.copyright))]
              )
            ])
          ]),
          _c("div", { staticClass: "layout-footer__main__right" }, [
            _c("nav", { staticClass: "layout-footer__main__right__nav" }, [
              _c(
                "h3",
                { staticClass: "layout-footer__main__right__nav__title" },
                [_vm._v(" " + _vm._s(_vm.menuInfo.service.title) + " ")]
              ),
              _c(
                "ul",
                { staticClass: "layout-footer__main__right__nav__menu" },
                _vm._l(_vm.menuInfo.service.menuList, function(menu) {
                  return _c(
                    "li",
                    {
                      key: menu.id,
                      staticClass: "layout-footer__main__right__nav__menu__list"
                    },
                    [
                      menu.link === "Youtube"
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://www.youtube.com/channel/UCnbZUetlXRKUZuRcXAK0dBg?view_as=subscriber",
                                target: "_blank",
                                rel: "noopener noreferrer"
                              }
                            },
                            [_vm._v(" " + _vm._s(menu.title) + " ")]
                          )
                        : menu.link === "CreatorWiki"
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://www.notion.so/manabiba-Creator-Wiki-d530dbc3058841e3a3078aacb41bbb8c",
                                target: "_blank",
                                rel: "noopener noreferrer"
                              }
                            },
                            [_vm._v(" " + _vm._s(menu.title) + " ")]
                          )
                        : _c(
                            "router-link",
                            { attrs: { to: { name: menu.link } } },
                            [_vm._v(" " + _vm._s(menu.title) + " ")]
                          )
                    ],
                    1
                  )
                }),
                0
              )
            ]),
            _c("nav", { staticClass: "layout-footer__main__right__nav" }, [
              _c(
                "h3",
                { staticClass: "layout-footer__main__right__nav__title" },
                [_vm._v(" " + _vm._s(_vm.menuInfo.support.title) + " ")]
              ),
              _c(
                "ul",
                { staticClass: "layout-footer__main__right__nav__menu" },
                _vm._l(_vm.menuInfo.support.menuList, function(menu) {
                  return _c(
                    "li",
                    {
                      key: menu.id,
                      staticClass: "layout-footer__main__right__nav__menu__list"
                    },
                    [
                      menu.link === "Contact"
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.getGoogleFormUrl,
                                target: "_blank",
                                rel: "noopener noreferrer"
                              }
                            },
                            [_vm._v(" " + _vm._s(menu.title) + " ")]
                          )
                        : _c(
                            "router-link",
                            { attrs: { to: { name: menu.link } } },
                            [_vm._v(" " + _vm._s(menu.title) + " ")]
                          )
                    ],
                    1
                  )
                }),
                0
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm.simpleFooter
      ? _c("div", { staticClass: "layout-footer--simple__main center" }, [
          _c("p", [_vm._v(_vm._s(_vm.copyright))]),
          _c(
            "a",
            {
              attrs: {
                href: _vm.getGoogleFormUrl,
                target: "_blank",
                rel: "noopener noreferrer"
              }
            },
            [_vm._v(" お問い合わせ ")]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }