<template>
  <div class='news'>
    <layout-header />
    <heading-title>
      <template v-slot:main>
        <p>NEWS</p>
      </template>
      <template v-slot:sub>
        <p>お知らせ_</p>
      </template>
    </heading-title>
    <section class='news__main center'>
      <article-normal
        class='news__main__article'
        :title='title'
        :text='body'
        :date='setDate'
      >
      </article-normal>
      <button @click='movePage("NewsList")' class="news__main__back">
        <p class='news__main__back__text'>
          <i class="fa fa-chevron-left"></i>
          お知らせ一覧
        </p>
      </button>
    </section>
    <layout-footer />
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import helper from '@/mixins/general/methods/helper';
import { LayoutHeader, LayoutFooter } from '@/components/layouts/general/';
import { HeadingTitle } from '@/components/atoms/heading/';
import { ArticleNormal } from '@/components/atoms/other/';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    HeadingTitle,
    ArticleNormal,
  },
  data() {
    return {
      title: null,
      date: null,
      body: null,
    };
  },
  created() {
    const targetNews = this.noticeList
      .filter((notice) => notice.id === Number(this.$route.params.id))[0];

    if (targetNews === undefined) {
      this.$router.push({ path: '/notfound' });
    } else {
      this.title = targetNews.title;
      this.date = targetNews.updated_at;
      this.body = targetNews.body;
    }
  },
  mixins: [helper],
  computed: {
    ...mapState('notice', ['noticeList']),
    setDate() {
      return moment(this.date.updated_at).format('YYYY/MM/DD');
    },
  },
};
</script>

<style scoped>
.news {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.news__main {
  margin: 80px auto;
}

.news__main__article {
  margin: 0 40px;
}

.news__main__back {
  margin: 40px 40px 0;
  text-align: left;
}

.news__main__back__text {
  color: #4CAF50;
}
</style>
