var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      on: {
        click: function($event) {
          return _vm.$emit("emit")
        }
      }
    },
    [
      !_vm.isActive
        ? _c("i", { class: "fas fa-square checkbox-normal" })
        : _vm._e(),
      _vm.isActive
        ? _c("i", { class: "fas fa-check-square checkbox-normal" })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }