<template>
  <div class='maintenance'>
    <LayoutHeader />
    <div class='maintenance__contents'>
      <list-menu
        class='maintenance__contents__left'
        :targetList='createTitleList'
        :defaultSelectId=4
        @emit='movePageMenu'
      />
      <div class='maintenance__contents__right'>
        <title-normal
          class='maintenance__contents__right__title'
          :title='title'
          :button='setModeButtonName'
          :loading='isLoading'
          :disabled='isLoading'
          @emit='activeModal'
        />
        <p class='maintenance__contents__right__mode'>
          現在のモード: {{setModeName}}
        </p>
        <modal-normal
          v-if = 'isActive.modalSwichMaintenanceMode'
          :title = 'modalText.modalSwichMaintenanceMode.title'
          :text = 'modalText.modalSwichMaintenanceMode.text'
          :cancelBtn = 'modalText.modalSwichMaintenanceMode.cancelBtn'
          :acceptBtn = 'modalText.modalSwichMaintenanceMode.acceptBtn'
          @emit='modalEvent'>
        </modal-normal>
        <modal-normal
          v-if = 'isActive.modalSwichNormalMode'
          :title = 'modalText.modalSwichNormalMode.title'
          :text = 'modalText.modalSwichNormalMode.text'
          :cancelBtn = 'modalText.modalSwichNormalMode.cancelBtn'
          :acceptBtn = 'modalText.modalSwichNormalMode.acceptBtn'
          @emit='modalEvent'>
        </modal-normal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import helper from '@/mixins/admin/methods/helper';
import pageList from '@/mixins/admin/data/pageList';
import LayoutHeader from '@/components/layouts/admin/LayoutHeader';
import { TitleNormal, ModalNormal } from '@/components/molecules/other';
import { ListMenu } from '@/components/atoms/list';

export default {
  components: {
    LayoutHeader,
    TitleNormal,
    ListMenu,
    ModalNormal,
  },
  data() {
    return {
      isActive: {
        modalSwichMaintenanceMode: false,
        modalSwichNormalMode: false,
      },
      isLoading: false,
      modalText: {
        modalSwichMaintenanceMode: {
          title: 'メンテナンスモードに変更',
          text: 'メンテナンスモードに変更するとユーザーはサービスを利用できなくなりますがよろしいですか？',
          acceptBtn: '変更する',
          cancelBtn: 'キャンセル',
        },
        modalSwichNormalMode: {
          title: '通常モードに変更',
          text: '通常モードに変更するとユーザーはサービスを利用可能になりますがよろしいですか？',
          acceptBtn: '変更する',
          cancelBtn: 'キャンセル',
        },
      },
      title: 'メンテナンス',
    };
  },
  created() {
  },
  mixins: [helper, pageList],
  computed: {
    ...mapState('admin', ['adminInfo']),
    ...mapState('material', ['maintenance']),
    createTitleList() {
      return this.pageList.map((page) => page.title);
    },
    setModeName() {
      if (this.maintenance.status === 200) {
        return '通常';
      } if (this.maintenance.status === 503) {
        return 'メンテナンス';
      }
      return 'モードの取得に失敗しました';
    },
    setModeButtonName() {
      if (this.maintenance.status === 200) {
        return 'メンテナンスモードに変更';
      } if (this.maintenance.status === 503) {
        return '通常モードに変更';
      }
      return 'モードの取得に失敗しました';
    },
    setEnvironmentName() {
      return process.env.VUE_APP_HOST === 'https://api.donbler.com' ? 'PRD' : 'STG';
    },
  },
  methods: {
    ...mapActions('material', ['getMaintenanceStatus']),
    active(target) {
      this.isActive[target] = !this.isActive[target];
    },
    loading() {
      this.isLoading = !this.isLoading;
    },
    activeModal() {
      if (this.maintenance.status === 200) {
        this.active('modalSwichMaintenanceMode');
      } else if (this.maintenance.status === 503) {
        this.active('modalSwichNormalMode');
      }
    },
    movePageMenu(index) {
      const { name } = this.pageList[index];
      this.$router.push({ name }, () => {});
    },
    modalEvent(data) {
      this.isActive.modalSwichMaintenanceMode = false;
      this.isActive.modalSwichNormalMode = false;
      if (data === true) this.changeStatus();
    },
    sendSlackNotification() {
      axios.post(process.env.VUE_APP_SLACK_NOTIFICATION_URL, {
        text: `【 ${this.setEnvironmentName} 】manabibaを${this.setModeName}モードに移行しました`,
        channel: '#prj-donbler-notification',
        username: 'メンテナンスBot',
        icon_emoji: ':ghost:',
      },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
    },
    async changeStatus() {
      try {
        this.loading();
        this.$http.defaults.headers.common.Authorization = `Bearer ${this.adminInfo.token}`;
        await this.$http.post('/api/admin/maintenance', {
          status: this.maintenance.status === 200 ? 503 : 200,
        });
        await this.getMaintenanceStatus();
        await this.sendSlackNotification();
        this.loading();
        await this.showNoticeBar('success', 'メンテナンスステータスの変更を完了しました');
      } catch (e) {
        if (e.response !== undefined && e.response.status === 401) {
          this.unAdminAuthenticate();
        } else {
          this.showNoticeBar('error', 'メンテナンスステータスの変更を失敗しました');
        }
      }
    },
  },
};
</script>

<style scoped>
.maintenance {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f9fa;
}

.maintenance__contents {
  display: flex;
  margin: 20px;
}

.maintenance__contents__left {
  width: 15%;
  padding: 20px;
  background-color: #fff;
}

.maintenance__contents__right {
  width: 85%;
  margin-left: 20px;
}

.maintenance__contents__right__mode {
  width: 100%;
  background-color: white;
  font-size: 18px;
  padding: 20px;
}
.maintenance__contents__right__title {
  margin-bottom: 10px;
}
</style>
