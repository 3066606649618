<template>
  <div class='lesson-header'>
    <notifications group="top-right" />
    <div class='lesson-header__main'>
      <div class='lesson-header__main__left'>
        <router-link
          :class='addClassLinkDisabled'
          :to='{ name: "Top"}'
          >
          <layout-logo iconOnly yellow />
        </router-link>
        <div class='lesson-header__main__left__attend'>
          <p class='lesson-header__main__left__attend__lesson' v-if='!previewMode'>
            {{ lessonInfo.title }}
          </p>
          <button
            class='lesson-header__main__left__attend__chapter'
            v-if='!previewMode && !adminPreviewMode'
            @click='$emit("emit-chapter-list")'
          >
            <p>{{ lessonInfo.chapterTitle[chapterNum - 1]}}</p>
            <i class='fas fa-caret-square-down' />
          </button>
          <div class='lesson-header__main__left__attend__creator' v-if='previewMode'>
            <button @click='$emit("emit-move-confirm")'>
              確認ページに戻る
            </button>
            <p>
              Creator Mode / Preview : {{ lessonInfo.chapterTitle[chapterNum - 1] }}
            </p>
          </div>
          <p  class='lesson-header__main__left__attend__admin' v-if='adminPreviewMode'>
            Admin Mode / Preview : {{ lessonInfo.chapterTitle[chapterNum - 1] }}
          </p>
        </div>
      </div>
      <div class='lesson-header__main__right'>
        <div class='lesson-header__main__right__func'>
          <!-- <button @click='$emit("emit-code-save")' v-if='!previewMode && !adminPreviewMode'>
            <i class='fas fa-save' />
            <p>セーブ</p>
          </button> -->
          <button @click='$emit("emit-reset")'>
            <i class='fas fa-redo' />
            <p>リセット</p>
          </button>
          <button @click='$emit("emit-compare-source")'>
            <i class='fas fa-eye' />
            <p>答えを見る</p>
          </button>
        </div>
        <div class='lesson-header__main__right__move' v-if='!previewMode'>
          <button
            v-if='chapterNum !== 1'
            :disabled='isLoading.movePrevPage'
            class='lesson-header__main__right__move__prev'
            @click='$emit("emit-prev-page")'
          >
            前に戻る
          </button>
          <button
            :disabled='isLoading.moveNextPage'
            :class='addClassNextBtn'
            @click='$emit("emit-next-page")'
          >
            完了して次へ
          </button>
        </div>
        <button
          v-if='adminPreviewMode'
          :class='isActive.adminCodeLoad ?
            "lesson-header__main__right__admin--disabled" : "lesson-header__main__right__admin"'
          :disabled='isActive.adminCodeLoad'
          @click='$emit("emit-admin-code-load"), active("adminCodeLoad")'
        >
          CodeLoad
        </button>
        <button-user
          v-if='!adminPreviewMode'
          :src='userInfo.profile.avatar'
          @emit='active("dropdownUser")'
        />
      </div>
    </div>
    <div class='lesson-header__dropdown'>
      <dropdown-header-user
        class='lesson-header__main__dropdown__user'
        v-if='isActive.dropdownUser'
        :userIcon='userInfo.profile.avatar'
        :userName='userInfo.profile.userName'
        :purchaseList='filterPurchaseList'
        @emit-logout='logout'
      />
    </div>
    <div
      class='lesson-header__overlay'
      v-if='isActive.dropdownUser'
      @click='active("dropdownUser")'
    />
  </div>
</template>

<script>
import helper from '@/mixins/general/methods/helper';
import LayoutLogo from '@/components/layouts/general/LayoutLogo';
import { DropdownHeaderUser } from '@/components/molecules/other';
import { ButtonUser } from '@/components/atoms/button';

export default {
  props: {
    lessonInfo: Object,
    userInfo: Object,
    isLoading: Object,
    chapterNum: Number,
    previewMode: Boolean,
    adminPreviewMode: Boolean,
  },
  components: {
    LayoutLogo,
    ButtonUser,
    DropdownHeaderUser,
  },
  data() {
    return {
      isActive: {
        dropdownUser: false,
        adminCodeLoad: false,
      },
    };
  },
  mixins: [helper],
  computed: {
    // ドロップダウンメニューに表示する受講中レッスンの表示制御
    filterPurchaseList() {
      const filterList = this.userInfo.lesson.purchase
        .filter((lesson) => lesson.inProgress.continue !== null
        && lesson.inProgress.progressRate !== 100);
      return filterList;
    },
    addClassNextBtn() {
      if (this.chapterNum === 1) return 'lesson-header__main__right__move__next--only';
      return 'lesson-header__main__right__move__next';
    },
    addClassLinkDisabled() {
      return {
        'lesson-header__main__left--disabled': this.adminPreviewMode,
      };
    },
  },
  created() {
    // sphone判定
    const ua = window.navigator.userAgent.toLowerCase();
    if ((ua.indexOf('iphone') !== -1 || ua.indexOf('ipad') !== -1 || (ua.indexOf('macintosh') && 'ontouchend' in document))
      || ua.indexOf('android') !== -1) {
      this.movePage('Sphone');
    }
  },
  methods: {
    active(target) {
      this.isActive[target] = !this.isActive[target];
    },
    async logout() {
      this.active('dropdownUser');
      this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
      try {
        await this.$http.put('api/auth/user/logout');
        this.$store.commit('user/logout');
        this.$store.commit('lessonAttend/logout');
        this.movePage('Top');
      } catch (e) {
        if (e.response !== undefined && e.response.status === 401) {
          this.unAuthenticate();
        } else {
          this.movePage('Error');
        }
      }
    },
  },
};
</script>

<style scoped>
.lesson-header__main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: #fff;
  padding: 0 20px;
  height: 60px;
}

.lesson-header__main__left{
  display: flex;
  align-items: center;
  width: 50%;
}

.lesson-header__main__left--disabled {
  pointer-events: none;
}
.lesson-header__main__left__attend {
  display: flex;
  align-items: center;
  width: 100%;
}

.lesson-header__main__left__attend__lesson {
  margin: 0 20px;
  font-size: 16px;
  font-weight: 600;
  color: #4CAF50;
  max-width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lesson-header__main__left__attend__chapter {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  background-color: #e1e1e1ad;
  max-width: 40%;
}

.lesson-header__main__left__attend__chapter p {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lesson-header__main__left__attend__chapter:hover {
  background-color: #E1E1E1;
}

.lesson-header__main__left__attend__chapter i {
  margin: 1px 0 0 10px;
}

.lesson-header__main__left__attend__creator {
  display: flex;
  align-items: center;
  max-width: 100%;
  margin-left: 20px;
}

.lesson-header__main__left__attend__creator button {
  background-color: #e1e1e1ad;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 8px 0 0 8px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lesson-header__main__left__attend__creator button:hover {
  background-color: #E1E1E1;
}

.lesson-header__main__left__attend__creator p {
  background-color: #E74860;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 0 8px 8px 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lesson-header__main__left__attend__admin {
  background-color: #E74860;
  color: #fff;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 8px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lesson-header__main__right{
  display: flex;
  align-items: center;
  justify-content:flex-end;
  width: 50%;
}

.lesson-header__main__right__func {
  display: flex;
  margin-right: 20px;
}

.lesson-header__main__right__func button {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  background-color: #fff;
  border: 2px solid #4CAF50;
  border-radius: 10px;
  padding: 5px 10px;
  transition: 0.1s;
  margin-right: 10px;
}

.lesson-header__main__right__func button:hover {
  background-color: #4CAF50;
  color: #fff
}

.lesson-header__main__right__func button i {
  margin: 2px 5px 0 0;
}

.lesson-header__main__right__func button:last-child {
  margin-right: 0px;
}

.lesson-header__main__right__move {
  font-size: 12px;
  font-weight: 500;
  margin-right: 20px;
  border: 2px solid #4CAF50;
  border-radius: 10px;
}

.lesson-header__main__right__move__prev {
  width: 85px;
  padding: 5px;
  border-right: 2px solid #4CAF50;
  transition: 0.1s;
  background-color: #fff;
  border-radius: 8px 0 0 8px;
}

.lesson-header__main__right__move__prev:hover {
  background-color: #4CAF50;
  color: #fff
}

.lesson-header__main__right__move__next {
  width: 85px;
  padding: 5px;
  transition: 0.1s;
  background-color: #fff;
  border-radius: 0 8px 8px 0;
}

.lesson-header__main__right__move__next:hover {
  background-color: #4CAF50;
  color: #fff
}

.lesson-header__main__right__admin {
  font-size: 12px;
  font-weight: 600;
  background-color: #fff;
  border: 2px solid #E74860;
  border-radius: 10px;
  padding: 5px 10px;
  transition: 0.1s;
}

.lesson-header__main__right__admin:hover {
  background-color: #E74860;
  color: #fff;
}

.lesson-header__main__right__admin--disabled {
  font-size: 12px;
  font-weight: 600;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #CBCBCB;
  border: 2px solid #CBCBCB;
  color: #fff;
}

.lesson-header__main__right__move__next--only {
  width: 85px;
  padding: 5px;
  transition: 0.1s;
  background-color: #fff;
  border-radius: 8px;
}

.lesson-header__main__right__move__next--only:hover {
  background-color: #4CAF50;
  color: #fff
}

.lesson-header__main__dropdown__user {
  position: absolute;
  top: 70px;
  right: 8px;
  z-index: 999;
}

.lesson-header__overlay {
  position: fixed;
  z-index: 998;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
</style>
