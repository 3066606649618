<template>
  <div class='list'>
    <LayoutHeader />
    <div class='list__contents'>
      <list-menu
        class='list__contents__left'
        :targetList='createTitleList'
        :defaultSelectId=1
        @emit='movePageMenu'
      />
      <div class='list__contents__right'>
        <title-normal
          class='list__contents__right__title'
          :title='titleBar.title'
          :button='titleBar.button'
          :count='titleBar.count'
          :label='titleBar.label'
        />
        <icon-loading v-if='isLoading' blue/>
        <table-normal
          v-if='!isLoading'
          type='status'
          :headingList='table.headingList'
          :rows='table.rows'
          :rowButton='table.rowButton'
          :status='table.rowsStatus'
          @emit = 'active'
        />
        <modal-normal
          v-if = 'isActive.start'
          :title = 'modalText.start.title'
          :text = 'modalText.start.text'
          :cancelBtn = 'modalText.start.cancelBtn'
          :acceptBtn = 'modalText.start.acceptBtn'
          @emit='modalEvent'>
        </modal-normal>
        <modal-normal
          v-if = 'isActive.stop'
          :title = 'modalText.stop.title'
          :text = 'modalText.stop.text'
          :cancelBtn = 'modalText.stop.cancelBtn'
          :acceptBtn = 'modalText.stop.acceptBtn'
          @emit='modalEvent'>
        </modal-normal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import helper from '@/mixins/admin/methods/helper';
import pageList from '@/mixins/admin/data/pageList';
import LayoutHeader from '@/components/layouts/admin/LayoutHeader';
import { TitleNormal, TableNormal, ModalNormal } from '@/components/molecules/other';
import { ListMenu } from '@/components/atoms/list';
import { IconLoading } from '@/components/atoms/icon/';

export default {
  components: {
    LayoutHeader,
    TitleNormal,
    ListMenu,
    TableNormal,
    ModalNormal,
    IconLoading,
  },
  data() {
    return {
      isActive: {
        start: false,
        stop: false,
      },
      isLoading: false,
      clickData: {
        btnFlg: Number,
        rowIndex: Number,
        rowId: Number,
      },
      modalText: {
        stop: {
          title: 'ユーザーの停止',
          text: '対象ユーザーはmanabibaにログインできなくなりますがよろしいですか？',
          acceptBtn: '停止する',
          cancelBtn: 'キャンセル',
        },
        start: {
          title: 'ユーザーを再開する',
          text: 'ユーザーを再開するとユーザーはサービスを利用できるようになりますがよろしいですか？',
          acceptBtn: '再開する',
          cancelBtn: 'キャンセル',
        },
      },
      titleBar: {
        title: 'ユーザー一覧',
        count: 0,
      },
      table: {
        headingList: [
          'アバター',
          'ID',
          'ユーザー名',
          'メールアドレス',
          '登録日',
          '最終ログイン',
          'ステータス',
        ],
        rows: [],
        rowsStatus: [],
        rowButton: {
          stop: ['停止する'],
          start: ['再開する'],
        },
      },
    };
  },
  created() {
    this.showNoticeBar('error', 'ユーザー一覧の読み込みに失敗しました');
    this.loadUser();
  },
  mixins: [helper, pageList],
  computed: {
    ...mapState('admin', ['adminInfo']),
    createTitleList() {
      const titleList = this.pageList.map((page) => page.title);
      return titleList;
    },
  },
  methods: {
    active(event) {
      const pickRowsId = [];
      this.table.rows.forEach((rows) => {
        pickRowsId.push(rows.id);
      });
      this.clickData.rowIndex = pickRowsId.indexOf(event.id);
      this.clickData.rowId = event.id;
      if (this.table.rowsStatus[this.clickData.rowIndex] === 1) {
        this.isActive.stop = !this.isActive.stop;
      } else {
        this.isActive.start = !this.isActive.start;
      }
    },
    movePageMenu(index) {
      const { name } = this.pageList[index];
      this.$router.push({ name }, () => {});
    },
    async modalEvent(data) {
      if (data === true) {
        this.changeUserStatus();
      } else {
        this.isActive.stop = false;
        this.isActive.start = false;
      }
    },
    async changeUserStatus() {
      try {
        this.isActive.stop = false;
        this.isActive.start = false;

        const params = {
          status: (this.table.rowsStatus[this.clickData.rowIndex] + 1) % 2,
        };
        this.$http.defaults.headers.common.Authorization = `Bearer ${this.adminInfo.token}`;
        await this.$http.put(`api/admin/users/${this.table.rows[this.clickData.rowIndex].id}`, params);
        await this.showNoticeBar('success', 'ユーザーステータスの変更を完了しました');
        await this.loadUser();
      } catch (e) {
        if (e.response !== undefined && e.response.status === 401) {
          this.unAdminAuthenticate();
        } else {
          this.showNoticeBar('error', 'ユーザーステータスの変更を失敗しました');
        }
      }
    },
    async loadUser() {
      try {
        this.isLoading = !this.isLoading;
        this.table.rows.splice(-this.table.rows.length);
        this.table.rowsStatus.splice(-this.table.rowsStatus.length);

        this.$http.defaults.headers.common.Authorization = `Bearer ${this.adminInfo.token}`;
        const res = await this.$http.get('api/admin/users');
        res.data.forEach((data) => {
          this.table.rows.push({
            image: data.avatar,
            id: data.id,
            username: data.name,
            email: data.email,
            create: data.created_at,
            update: data.updated_at,
          });
          this.table.rowsStatus.push(data.status);
        });

        this.titleBar.count = this.table.rows.length;
        this.isLoading = !this.isLoading;
      } catch (e) {
        this.isLoading = !this.isLoading;
        if (e.response !== undefined && e.response.status === 401) {
          this.unAdminAuthenticate();
        } else {
          this.showNoticeBar('error', 'ユーザー一覧の読み込みに失敗しました');
        }
      }
    },
  },
};
</script>

<style scoped>
.list {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f9fa;
}

.list__contents {
  display: flex;
  margin: 20px;
}

.list__contents__left {
  width: 15%;
  padding: 20px;
  background-color: #fff;
}

.list__contents__right {
  width: 85%;
  margin-left: 20px;
}

.list__contents__right__title {
  margin-bottom: 10px;
}
</style>
