<template>
  <div :class='addClass'>
    <input
      type='text'
      v-model='emitText'
      :placeholder='placeholder'
      :maxlength='maxLength'
    >
    <p class='input-bank__error' v-if='errorMsg'>{{ errorMsg }}</p>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    name: String,
    placeholder: String,
    maxLength: Number,
    errorMsg: String,
  },
  data() {
    return {
      emitText: String,
    };
  },
  computed: {
    addClass() {
      return {
        'input-bank': true,
        'input-bank--error': this.errorMsg,
      };
    },
  },
  created() {
    this.emitText = this.text;
  },
  updated() {
    this.$emit('emit', this.emitText, this.name);
  },
};
</script>

<style scoped>
.input-bank input {
  background-color: #F9F9FA;
  padding: 15px;
  font-size: 14px;
  width: 100%;
  border-radius: 8px;
  flex-basis: calc(100% - 190px);
}

.input-bank--error input {
  background-color: rgb(255, 147, 169);
}

.input-bank__error {
  color: rgb(255, 147, 169);
  margin-top: 5px;
  font-size: 12px;
}
</style>
