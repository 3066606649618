<template>
  <button :class='addClass' @click='$emit("emit")'>
    <i v-if="icon" :class="`fas fa-${icon} button-card-icon`"></i>
    <slot />
  </button>
</template>
<script>
export default {
  props: {
    icon: String,
  },
  computed: {
    addClass() {
      return {
        'button-card': true,
      };
    },
  },
};
</script>

<style scoped>
.button-card {
  font-size: 14px;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  background-color: white;
  font-weight: bold;
}

.button-card:hover {
  background-color: #F7F7F7;
}

.button-card-icon {
  padding-right: 20px;
  color: #4CAF50;
}
</style>
