<template>
  <table class='table-normal' cellpadding="0">
    <tr class='table-normal__title'>
      <th v-for='heading in headingList' :key='heading.key'>{{ heading }}</th>
    </tr>
    <tr class='table-normal__data' v-for='(row, rowIndex) in rows' :key='row.id'>
      <td v-if='row.image'>
        <img class='table__normal__data__img' :src='row.image' alt='レッスンのサムネイルやユーザーアイコン'>
      </td>
      <td v-for='(content) in filterRow(row)' :key='content.id'>{{ content }}</td>
      <template v-if="type === 'review'">
        <td v-for='(button, index) in rowButton' :key='button.id'>
          <button-normal
            small
            @emit='$emit("emit", { btnFlg: index, id: row.id})'
          >{{ button }}</button-normal>
        </td>
      </template>
      <template v-if="type === 'status'" >
        <td v-for='(button, index) in (status[rowIndex] === 0
          ? rowButton.start
          : rowButton.stop
        )' :key='button.id'>
          <button-normal
            small
            @emit='$emit("emit", { btnFlg: index, id: row.id})'
          >{{ button }}</button-normal>
        </td>
      </template>
      <template v-if="type === 'detail'">
        <td v-for='(button, index) in rowButton' :key='button.id'>
          <button-normal
            small
            :disabled="disabled && button == '削除'"
            @emit='$emit("emit", { btnFlg: index, id: row.id})'
          >{{ button }}</button-normal>
        </td>
      </template>
    </tr>
  </table>
</template>

<script>
import { ButtonNormal } from '@/components/atoms/button';

export default {
  components: {
    ButtonNormal,
  },
  props: {
    type: String,
    headingList: Array,
    rows: Array,
    rowButton: [Array, Object],
    status: Array,
    disabled: Boolean,
  },
  methods: {
    filterRow(row) {
      // rowにimageを含む場合除外したrowを再構築する
      if (row.image) {
        const filterRow = [];
        Object.keys(row).forEach((key) => {
          if (key !== 'image') {
            filterRow.push(row[key]);
          }
        });
        return filterRow;
      }
      return row;
    },
  },
};
</script>

<style scoped>
.table-normal {
  width: 100%;
  border-spacing: 0;
  text-align: center;
  font-size: 12px;
}

.table-normal__title {
  background-color: #fff;
}

.table-normal__title th {
  padding: 15px 5px;
}

.table-normal__data {
  background-color: #fff;
}

.table-normal__data td {
  padding: 15px 10px;
  border-top: 10px solid #f9f9fa;
  max-width: 200px;
  word-break: break-all;
}

.table__normal__data__img {
  height: 30px;
  vertical-align: middle;
}
</style>
