<template>
  <button class='button-user' @click='$emit("emit")'>
    <img :src='src' alt='ユーザーアイコン' @error='setErrorAvatar'>
  </button>
</template>

<script>
export default {
  props: {
    src: String,
  },
  methods: {
    setErrorAvatar(e) {
      e.target.src = `${process.env.VUE_APP_S3_URL}/image/avatar/unknown.png`;
    },
  },
};
</script>

<style scoped>
.button-user img {
  width: 35px;
  height: 35px;
  border-radius: 999px;
  vertical-align: middle;
}
</style>
