<template>
    <img :class='addClass' :src='src' alt='ユーザーアイコン' @error='setErrorAvatar'>
</template>
<script>

export default {
  props: {
    src: String,
    large: Boolean,
    xlarge: Boolean,
  },
  computed: {
    addClass() {
      return {
        'icon-user': true,
        'icon-user--large': this.large,
        'icon-user--xlarge': this.xlarge,
      };
    },
  },
  methods: {
    setErrorAvatar(e) {
      e.target.src = `${process.env.VUE_APP_S3_URL}/image/avatar/unknown.png`;
    },
  },
};
</script>

<style scoped>
.icon-user {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.icon-user--large {
  width: 140px;
  height: 140px;
}

.icon-user--xlarge {
  width: 180px;
  height: 180px;
}
</style>
