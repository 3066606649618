var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "checkout-service" },
    [
      _c("layout-header"),
      _c("heading-title", {
        scopedSlots: _vm._u([
          {
            key: "main",
            fn: function() {
              return [_c("p", [_vm._v("CheckoutService")])]
            },
            proxy: true
          },
          {
            key: "sub",
            fn: function() {
              return [_c("p", [_vm._v("決済サービス_")])]
            },
            proxy: true
          }
        ])
      }),
      _c("cs-md", { staticClass: "checkout-service__markdown center mdbase" }),
      _c("layout-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }