/**
 * lessonCreate.js
 * レッスン作成・更新時の投稿レッスン
 */
export default {
  namespaced: true,
  state: {
    postLessonInfo: {
      thumbnail: null,
      content: [],
      freeContent: [],
      setting: {
        title: null,
        level: null,
        price: null,
        version: null,
        description: null,
        target: null,
        tag: [],
        chapter: [],
        freeChapterNum: null,
      },
      change: {},
    },
  },
  mutations: {
    // アップロードされたレッスン情報をStateに格納
    savePostLesson(state, postLesson) {
      const lesson = state;
      lesson.postLessonInfo.content = postLesson.content;
      lesson.postLessonInfo.freeContent = postLesson.content
        .filter((content, index) => index < postLesson.setting.freeChapterNum);
      lesson.postLessonInfo.thumbnail = postLesson.thumbnail;
      lesson.postLessonInfo.setting.title = postLesson.setting.title;
      lesson.postLessonInfo.setting.level = postLesson.setting.level;
      lesson.postLessonInfo.setting.price = postLesson.setting.price;
      lesson.postLessonInfo.setting.version = postLesson.setting.version;
      lesson.postLessonInfo.setting.description = postLesson.setting.description;
      lesson.postLessonInfo.setting.target = postLesson.setting.target;
      lesson.postLessonInfo.setting.tag = postLesson.setting.category; // 文言変更の影響、改修必須
      lesson.postLessonInfo.setting.chapter = postLesson.setting.chapter;
      lesson.postLessonInfo.setting.freeChapterNum = postLesson.setting.freeChapterNum;
      lesson.postLessonInfo.change = postLesson.change;
    },
    // Stateに格納されたアップロードされたレッスン情報を破棄
    deletePostLesson(state) {
      const lesson = state;
      lesson.postLessonInfo.content = [];
      lesson.postLessonInfo.thumbnail = null;
      lesson.postLessonInfo.setting = {
        title: null,
        level: null,
        price: null,
        version: null,
        description: null,
        target: null,
        tag: [],
        chapter: [],
        freeChapterNum: null,
      };
    },
  },
  actions: {
    // アップロードされたレッスン情報をmutationsに渡す
    loadPostLesson(context, postLesson) {
      const lesson = context;
      const chapterContentList = [];
      if (postLesson.markdown.length !== 0 && postLesson.source.length !== 0) {
        // Markdown、ソースコード情報をソート
        postLesson.markdown.sort((a, b) => {
          if (a.chapterNum < b.chapterNum) return -1;
          if (a.chapterNum > b.chapterNum) return 1;
          return 0;
        });
        postLesson.source.sort((a, b) => {
          if (a.chapterNum < b.chapterNum) return -1;
          if (a.chapterNum > b.chapterNum) return 1;
          return 0;
        });

        // chapterのコンテンツ整理
        for (let i = 0; i < postLesson.markdown.length; i += 1) {
          const chapterSource = postLesson.source
            .filter((file) => file.chapterNum === i + 1)
            .map((file) => ({
              filename: file.filename,
              assignment: file.source.assignment,
              sample: file.source.sample,
            }));
          chapterContentList.push({
            markdown: postLesson.markdown[i].text,
            source: chapterSource,
          });
        }
      }

      const convertedLesson = {
        setting: postLesson.setting,
        thumbnail: postLesson.thumbnail,
        content: chapterContentList,
        change: postLesson.change,
      };
      if (postLesson.markdown.length !== 0 && postLesson.source.length !== 0) {
        convertedLesson.content.forEach((data) => {
          data.source.sort((a, b) => {
            const aSplitFilename = a.filename.toLowerCase().split('.');
            const bSplitFilename = b.filename.toLowerCase().split('.');
            if (aSplitFilename[1] === bSplitFilename[1]) {
              if (aSplitFilename[0] < bSplitFilename[0]) return -1;
            } else if (aSplitFilename[1] === 'html' || bSplitFilename[1] === 'html') {
              if (aSplitFilename[1] === 'html') return -1;
            } else if (aSplitFilename[1] === 'js' || bSplitFilename[1] === 'js') {
              if (bSplitFilename[1] === 'js') return -1;
            }
            return 1;
          });
        });
      }
      lesson.commit('savePostLesson', convertedLesson);
    },
    // アップロードされたレッスン情報を削除
    execDeletePostLesson(context) {
      const lesson = context;
      lesson.commit('deletePostLesson');
    },
  },
};
