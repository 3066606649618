<template>
  <div :class='addClass'>
    <h3><slot /></h3>
    <ul>
      <li
        v-for='(list, index) in listInfo'
        :key='list.id'>
        <button
          :disabled='selectId === index || disabled'
          :class='{"focus" : selectId === index}'
          @click="$emit('emit', index), changeFocus(index)"
        >{{ list }}</button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    listInfo: Array,
    title: Boolean,
    large: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      selectId: 0,
    };
  },
  computed: {
    addClass() {
      return {
        'heading-list': true,
        'heading-list--title': this.title,
        'heading-list--large': this.large,
      };
    },
  },
  methods: {
    changeFocus(index) {
      this.selectId = index;
    },
  },
};
</script>

<style scoped>
/* default */
.heading-list {
  margin-bottom: 5px;
}

.heading-list ul{
  display: flex;
  list-style: none;
  cursor: pointer;
}

.heading-list ul li {
  margin-left: 5px;
  font-size: 14px;
  font-weight: 500;
}

.heading-list ul li:first-child {
  margin-left: 0;
}

.focus {
  color: #46aad5;
  font-weight: 600;
}

/* title系 */
.heading-list--title {
  margin-bottom: 0;
}

/* large系 */
.heading-list--large h3 {
  font-size: 24px;
  font-weight: 600;
}

.heading-list--large ul li {
  margin-left: 5px;
  font-size: 16px;
  font-weight: 500;
}
</style>
