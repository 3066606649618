<template>
  <div class='login'>
    <layout-header />
    <modal-auth class='login__auth' @emit-login='authProvider' :load='isActive.load'/>
    <layout-footer class='login__footer' hidePageUp simpleFooter/>
    <div class='login__overlay' v-if='isActive.load'/>
  </div>
</template>

<script>
import helper from '@/mixins/general/methods/helper';
import { mapState, mapActions } from 'vuex';
import { LayoutHeader, LayoutFooter } from '@/components/layouts/general/';
import { ModalAuth } from '@/components/molecules/other/';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    ModalAuth,
  },
  data() {
    return {
      redirectUrl: '/',
      twitterUrl: null,
      fromRouteName: null,
      isActive: {
        load: false,
      },
    };
  },
  created() {
    if (this.userInfo.login) this.movePage('Top');
  },
  mounted() {
    if (this.$route.query.oauth_token) this.authTwitter();
  },
  mixins: [helper],
  computed: {
    ...mapState('user', ['userInfo']),
  },
  methods: {
    ...mapActions('user', ['checkAuth', 'loadPurchaseLesson', 'loadPostLesson']),

    active(target) {
      this.isActive[target] = !this.isActive[target];
    },
    async authProvider(provider) {
      try {
        await this.active('load');
        if (provider === 'twitter') {
          const { data } = await this.$http.get('api/login/twitter/');
          this.twitterUrl = data.redirect_url;
        } else {
          const payload = await this.$auth.authenticate(provider);
          const authInfo = await { provider, payload };
          const result = await this.checkAuth(authInfo);
          if (await result === true) {
            await this.loadPurchaseLesson();
            await this.loadPostLesson();
            await this.active('load');
            this.fromRouteName ? await this.$router.go(-1) : await this.$router.go();
          } else if (result === 'このユーザは停止されています') {
            this.active('load');
            this.showNoticeBar('error', 'ログインに失敗しました', 'このユーザは停止されています');
          } else {
            throw new Error();
          }
        }
      } catch (e) {
        await this.active('load');
        if (await !this.userInfo.login) await this.showNoticeBar('error', 'ログインに失敗しました');
      }
    },

    // Twitter用Auth処理
    async authTwitter() {
      try {
        this.active('load');
        const result = await this.checkAuth({ provider: 'twitter', payload: this.$route.query });
        if (await result === true) {
          await this.loadPurchaseLesson();
          await this.loadPostLesson();
          this.$router.push({ path: this.redirectUrl });
        } else if (result === 'twitterアカウントにEmailがありませんでした') {
          this.active('load');
          this.showNoticeBar('error', 'ログインに失敗しました', 'twitterアカウントにメールアドレスを紐づけてください');
        } else if (result === 'このユーザは停止されています') {
          this.active('load');
          this.showNoticeBar('error', 'ログインに失敗しました', 'このユーザは停止されています');
        } else {
          this.active('load');
          this.showNoticeBar('error', 'ログインに失敗しました');
        }
      } catch (e) {
        await this.active('load');
        await this.showNoticeBar('error', 'ログインに失敗しました');
      }
    },
  },
  watch: {
    twitterUrl() {
      window.location.href = this.twitterUrl;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const vueInstance = vm;
      vueInstance.fromRouteName = from.name;
    });
  },
};
</script>

<style scoped>
.login {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: #4CAF50;
  overflow: hidden;
}

.login__background {
  opacity: 0.4;
  position: absolute;
  bottom: 0;
  right: -15px;
  width: 700px;
  z-index: 1;
}

.login__footer {
  margin-top: 0;
  z-index: 2;
}

.login__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
}
</style>
