<template>
  <div class='lesson-markdown'>
    <div class='mdbase' v-html='parsed' />
  </div>
</template>

<script>
import marked from 'marked';
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css';
import dompurify from 'dompurify';

export default {
  props: {
    markdown: String,
  },
  data() {
    return {
      parsed: String,
    };
  },
  methods: {
    parseMarkdown() {
      marked.setOptions({
        langPrefix: '',
        highlight: (code, lang) => hljs.highlightAuto(code, [lang]).value,
      });
      const md = marked(this.markdown);
      this.parsed = dompurify.sanitize(md);
    },
    addBlank() {
      // aタグの遷移先を別タブで開くようtarget属性付与
      const anchorTagList = document.querySelectorAll('.mdbase a');
      Array.prototype.forEach.call(anchorTagList, (anchorTag) => {
        anchorTag.setAttribute('target', '_blank');
      });
    },
  },
  async mounted() {
    await this.parseMarkdown();
    await this.addBlank();
  },
};
</script>

<style scoped>
.lesson-markdown {
  background-color: #fff;
  padding: 20px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.lesson-markdown::-webkit-scrollbar {
  display:none;
}
</style>
