<template>
  <div class='review-star-select'>
    <ul class='review-star-select__star'>
      <li
        v-for='n of 3'
        :key='n'
        :class='selectNum >= n ? "select":""'
        @click='clickStar(n)'
      >★</li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectNum: 0,
    };
  },
  methods: {
    clickStar(num) {
      this.selectNum = num;
      this.$emit('emit-evaluation', num);
    },
  },
};
</script>

<style scoped>
.review-star-select__star {
  display: flex;
  list-style: none;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}

.review-star-select__star li {
  margin-left: 8px;
}

.review-star-select__star li:first-child {
  margin-left: 0;
}

.select {
  color: #E74860;
}
</style>
