export default {
  data() {
    return {
      pageList: [
        {
          title: 'レッスン一覧',
          name: 'AdminLessons',
        },
        {
          title: 'ユーザー一覧',
          name: 'AdminUsers',
        },
        {
          title: 'コンテンツ管理',
          name: 'AdminCmsList',
        },
        {
          title: '管理ユーザー一覧',
          name: 'AdminAdminUserList',
        },
        {
          title: 'メンテナンス',
          name: 'AdminMaintenance',
        },
      ],
    };
  },
};
