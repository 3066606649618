var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c("layout-header"),
      _c("modal-auth", {
        staticClass: "login__auth",
        attrs: { load: _vm.isActive.load },
        on: { "emit-login": _vm.authProvider }
      }),
      _c("layout-footer", {
        staticClass: "login__footer",
        attrs: { hidePageUp: "", simpleFooter: "" }
      }),
      _vm.isActive.load
        ? _c("div", { staticClass: "login__overlay" })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }