<template>
  <div class='lesson-output'>
    <div class='lesson-output__top'>
      <lesson-output-tab :tabInfo='tabInfo' @emit='changeTab' />
      <div class='lesson-output__top__func'>
        <input-url
          class='lesson-output__top__func__url'
          v-if='select.output === 0'
          target='assignment'
          :path='viewHtml.assignment'
          @emit-path='savePath'
        />
        <input-url
          class='lesson-output__top__func__url'
          v-if='select.output === 1'
          target='sample'
          :path='viewHtml.sample'
          @emit-path='savePath'
        />
      </div>
    </div>
    <div class='lesson-output__bottom'>
      <iframe
        id='assignment'
        v-show='select.output === 0'
        :src='outputUrl.assignment'
        :class='addIframeClass("assignment")'
      />
      <iframe
        id='sample'
        v-show='select.output === 1'
        :src='outputUrl.sample'
        :class='addIframeClass("sample")'
      />
    </div>
  </div>
</template>

<script>
import { LessonOutputTab } from '@/components/atoms/lesson';
import { InputUrl } from '@/components/atoms/input';

export default {
  components: {
    LessonOutputTab,
    InputUrl,
  },
  props: {
    isActiveCodeRun: Boolean,
    editSource: Array,
    sampleSource: Array,
  },
  data() {
    return {
      select: {
        output: 0,
        device: {
          assignment: 0,
          sample: 0,
        },
      },
      tabInfo: ['出力結果', '見本'],
      viewHtml: {
        assignment: 'index.html',
        sample: 'index.html',
      },
      source: {
        assignment: null,
        sample: null,
      },
      outputUrl: {
        assignment: null,
        sample: null,
      },
    };
  },
  mounted() {
    this.buildOutout(this.editSource, 'assignment');
    this.buildOutout(this.sampleSource, 'sample');
  },
  methods: {
    // タブ、コンソールを連結させているselect.outputを変更する
    changeTab(index) {
      this.select.output = index;
    },
    addIframeClass(target) {
      return {
        'iframe-output': true,
        'iframe-output--tab': this.select.device[target] === 1,
        'iframe-output--sp': this.select.device[target] === 2,
      };
    },
    savePath(path, target) {
      this.viewHtml[target] = path;
      if (target === 'assignment') {
        this.buildOutout(this.editSource, 'assignment');
      } else {
        this.buildOutout(this.sampleSource, 'sample');
      }
    },
    buildOutout(sourceList, target) {
      // editSourceから拡張子を機軸にhtml,css,jsを分けて取得
      const htmlList = sourceList.filter((source) => source.filename.split('.').slice(-1)[0] === 'html');
      const css = sourceList.filter((source) => source.filename.split('.').slice(-1)[0] === 'css')[0];
      const js = sourceList.filter((source) => source.filename.split('.').slice(-1)[0] === 'js')[0];

      // InputUrlに指定されたファイルを特定(空白の場合index.htmlを指定)
      let filterFilename = '';
      if (this.viewHtml[target] === '') {
        filterFilename = 'index.html';
      } else {
        filterFilename = this.viewHtml[target];
      }
      const selectHtml = htmlList.filter((source) => source.filename === filterFilename)[0];
      if (selectHtml === undefined) {
        this.source[target] = '指定されたファイルが見つかりません';
        const blob = new Blob([this.source[target]], { type: 'text/html' });
        this.outputUrl[target] = URL.createObjectURL(blob);
        return;
      }

      // ソースコード形成(ユーザー課題、見本別対応)
      if (target === 'assignment') {
        this.source[target] = selectHtml.source;
        if (css !== undefined) this.source[target] += `<style>* { margin: 0; } html { background-color: #fff;} ${css.source}</style>`;
      } else {
        this.source[target] = selectHtml.sample;
        if (css !== undefined) this.source[target] += `<style>* { margin: 0; } html { background-color: #fff;} ${css.sample}</style>`;
      }

      // 形成したソースをBlob変換
      const blob = new Blob([this.source[target]], { type: 'text/html' });
      this.outputUrl[target] = URL.createObjectURL(blob);

      // script埋め込み対応
      setTimeout(() => {
        const iframe = document.getElementById(target);
        const doc = iframe.contentWindow.document.querySelector('body');
        const script = document.createElement('script');
        script.type = 'text/javascript';
        if (target === 'assignment') {
          script.innerHTML = js.source;
        } else {
          script.innerHTML = js.sample;
        }
        doc.appendChild(script);
      }, 500);
    },
    saveViewHtml(href, target) {
      this.viewHtml[target] = href;
    },
  },
  watch: {
    isActiveCodeRun(to) {
      if (to) {
        this.buildOutout(this.editSource, 'assignment');
        this.$emit('emit-code-run');
      }
    },
    'outputUrl.assignment': function () {
      setTimeout(() => {
        const iframe = document.getElementById('assignment');
        if (iframe.contentWindow.document.querySelector('a') !== null) {
          iframe.contentWindow.document.querySelectorAll('a').forEach((href) => {
            href.addEventListener('click', (e) => {
              this.saveViewHtml(href.getAttribute('href'), 'assignment');
              this.buildOutout(this.editSource, 'assignment');
              e.preventDefault();
            });
          });
        }
      }, 500);
    },
  },
};
</script>

<style scoped>
.lesson-output__top__func {
  background-color: #E3E3DA;
  padding: 10px;
  display: flex;
}

.lesson-output__top__func__url {
  width: 100%;
}

.focus {
  color: #E74860;
}

.lesson-output__bottom {
  height: calc(100vh - 165px);
  background-color: #E3E3DA;
}

/* iframe系 */
.iframe-output {
  height: calc(167vh - 278px);
  width: 167%;
  transform: scale(0.6);
  transform-origin: left top;
  border: none;
  overflow: scroll;
  background-color: #fff;
}

.iframe-output--tab {
  width: 120%;
  position: relative;
  left: 15%;
}

.iframe-output--sp {
  width: 80%;
  position: relative;
  left: 28%;
}
</style>
