<template>
  <div class='lesson-editor'>
    <lesson-editor-tab :tabInfo='tabInfo' @emit='switchEditor' />
    <div class='lesson-editor__bottom'>
    <lesson-ace
      v-show='editor == index'
      v-for='(sourceInfo, index) in sourceList'
      :key='sourceInfo.id'
      :id='index'
      :filename='sourceInfo.filename'
      :source='sourceInfo.assignment'
      :resetSource='resetSourceList[index].assignment'
      :resetFlg='resetFlg'
      @emit-source='passEmit'
    />
    <button
      class='lesson-editor__bottom__runbtn'
      v-if='runButtonClient'
      @click='$emit("emit-code-run")'>
      <i class='fas fa-play' />
      <p>コードを実行</p>
    </button>
    <button
      class='lesson-editor__bottom__runbtn'
      v-if='runButtonServer'
      @click='emitCodeRun()'
      :disabled='isActive.codeRun || !isActiveGeneratedServerEnv'
    >
      <div v-if='!isActive.codeRun'>
        <i class='fas fa-play' />
        <p>コードを実行</p>
      </div>
      <div v-if='isActive.codeRun'>
        <p>実行中...</p>
      </div>
    </button>
    </div>
  </div>
</template>

<script>
import { LessonAce, LessonEditorTab } from '@/components/atoms/lesson';

export default {
  components: {
    LessonAce,
    LessonEditorTab,
  },
  props: {
    runButtonClient: Boolean,
    runButtonServer: Boolean,
    sourceList: Array,
    resetSourceList: Array,
    isActiveCodeReset: Boolean,
    isActiveGeneratedServerEnv: Boolean,
  },
  data() {
    return {
      editor: 0,
      resetFlg: false,
      tabInfo: [],
      isActive: {
        codeRun: false,
      },
    };
  },
  methods: {
    shortcut(event) {
      // Ctr + Enter押下かつ、コード実行中でなければemitCodeRun
      if (event.key === 'Enter' && event.ctrlKey && !this.isActive.codeRun) this.emitCodeRun();
    },
    // エディターのタブ切り替えを実施する
    switchEditor(index) {
      this.editor = index;
    },
    // atomsからのemitをviewsに渡す
    passEmit(filename, source) {
      this.$emit('emit-source', filename, source);
    },
    // コード実行ボタン押下イベント制御
    async emitCodeRun() {
      const sleep = (msec) => new Promise((resolve) => setTimeout(resolve, msec));
      if (this.runButtonClient) {
        this.$emit('emit-code-run');
      } else {
        await this.active('codeRun');
        await sleep(2000);
        await this.$emit('emit-code-run');
        await sleep(500);
        await this.active('codeRun');
      }
    },
    active(target) {
      this.isActive[target] = !this.isActive[target];
    },
  },
  mounted() {
    // ショートカットイベント追加
    document.addEventListener('keyup', this.shortcut);

    // filenameを抜き出してタブに当て込む
    this.tabInfo = this.sourceList.map((val) => val.filename);
  },
  watch: {
    // 入力コードリセット処理
    isActiveCodeReset(to) {
      if (to) {
        this.resetFlg = !this.resetFlg;
        this.$emit('emit-reset');
      }
    },
  },
  destroyed() {
    document.removeEventListener('keyup', this.shortcut);
  },
};
</script>

<style scoped>
.lesson-editor__bottom {
  position: relative;
}

.lesson-editor__bottom__runbtn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: #E74860;
  border: 3px solid #d63d54;
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  box-shadow:  0 8px 5px 4px rgba(31, 31, 31, 0.2);
  display: flex;
  align-items: center;
}

.lesson-editor__bottom__runbtn div {
  display: flex;
  align-items: center;
}

.lesson-editor__bottom__runbtn:hover {
  background-color: rgb(238, 52, 80);
}

.lesson-editor__bottom__runbtn p {
  margin-left: 5px;
  font-size: 14px;
}
</style>
