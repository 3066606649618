<template>
  <div :class='addClass'>
    <textarea
      v-model='emitText'
      :rows='rows'
      :maxlength='maxLength'
      :placeholder='placeholder'
    />
    <div class='input-textarea__text'>
      <p class='input-textarea__text__error' v-show='errorMsg'>{{ errorMsg }}</p>
      <p class='input-textarea__text__count' v-if='maxLength'>
        {{ countLength }}/{{ maxLength }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    name: String,
    rows: Number,
    maxLength: Number,
    errorMsg: String,
    placeholder: String,
  },
  data() {
    return {
      emitText: '',
    };
  },
  computed: {
    countLength() {
      if (this.emitText) {
        return this.emitText.length;
      }
      return 0;
    },
    addClass() {
      return {
        'input-textarea': true,
        'input-textarea--error': this.errorMsg,
      };
    },
  },
  created() {
    this.emitText = this.text;
  },
  updated() {
    this.$emit('emit', this.emitText, this.name);
  },
};
</script>

<style scoped>
.input-textarea textarea {
  background-color: #F9F9FA;
  padding: 20px;
  font-size: 14px;
  width: 100%;
  outline: none;
  resize: none;
  border-radius: 8px;
}

.input-textarea textarea::placeholder {
  color: #BCBCBC;
}

.input-textarea--error textarea {
  background-color: rgb(255, 147, 169);
}

.input-textarea__text{
  display: flex;
}

.input-textarea__text__count {
  width: 100%;
  text-align: right;
  font-size: 12px;
  color: #CBCBCB;
  margin-top: 5px;
  margin-right: 5px;
}

.input-textarea__text__error {
  width: 100%;
  color: rgb(255, 147, 169);
  font-size: 12px;
}
</style>
