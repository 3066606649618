<template>
  <section class='section-welcome'>
    <div class='section-welcome__main' v-if='!isLoading'>
      <div
        class='section-welcome__main__lesson'
        v-if='welcomeLessonList.length !== 0 && username.length !== 0'
      >
        <div class='section-welcome__main__lesson__left' >
          <div class='section-welcome__main__lesson__left__select'>
            <div class='section-welcome__main__lesson__left__select__content'>
              <div class='section-welcome__main__lesson__left__select__content__top'>
                <h3>{{ welcomeLessonList[welcomeLesson.selected].title }}</h3>
                <img :src='setAvatar' alt='avatar ユーザーのアイコン' @error='setErrorAvatar'>
              </div>
              <div class='section-welcome__main__lesson__left__select__content__left'>
                <p class='section-welcome__main__lesson__left__select__content__left__description'>
                  {{ welcomeLessonList[welcomeLesson.selected].description }}
                </p>
                <p class='section-welcome__main__lesson__left__select__content__left__price'>
                  {{ setPrice }}
                </p>
                <div class='section-welcome__main__lesson__left__select__content__left__button'>
                  <router-link
                    :to='{
                      name: "LessonDetail",
                      params: {
                        id: welcomeLessonList[welcomeLesson.selected].id
                      }
                    }'
                  >
                    <button-stripe arrow>レッスンを見る</button-stripe>
                  </router-link>
                </div>
              </div>
            </div>
            <router-link
              class='section-welcome__main__lesson__left__select__thumbnail'
              :to='{
                name: "LessonDetail",
                params: {
                  id: welcomeLessonList[welcomeLesson.selected].id
                }
              }'
            >
              <img :src='setWelcomeThumbnail' alt='レッスンのサムネイル' @error='setErrorThumbnail'>
            </router-link>
          </div>
          <ul class='section-welcome__main__lesson__left__thumbnaillist'>
            <li v-for='(lesson, index) in welcomeLessonList' :key='index'>
              <img
                :src='lesson.image'
                @mouseover='changeSelectWelcomeLesson(index)'
                alt='レッスンのサムネイル'
                @error='setErrorThumbnail'>
            </li>
          </ul>
        </div>
        <div
          class='section-welcome__main__lesson__right'
          v-if='username !== "" && attendLessonList.length !== 0'
        >
          <icon-user-lesson
            class='section-welcome__main__lesson__right__author'
            :avatar='attendLessonList[attendLesson.selected].avatar'
            :username='attendLessonList[attendLesson.selected].author'
          />
          <router-link
            class='section-welcome__main__lesson__right__top'
            :to='{
              name: "LessonDetail",
              params: {
                id: attendLessonList[attendLesson.selected].id
                }
              }'
          >
            <img
              :src='attendLessonList[attendLesson.selected].image'
              alt='レッスンのサムネイル'
              @error='setErrorThumbnail'>
          </router-link>
          <div class='section-welcome__main__lesson__right__bottom'>
            <div class='section-welcome__main__lesson__right__bottom__welcome'>
              <p class='section-welcome__main__lesson__right__bottom__welcome__username'>
                {{ username }}
              </p>
              <p>さんお帰りなさい</p>
            </div>
            <div class='section-welcome__main__lesson__right__bottom__lessoninfo'>
              <h3 class='section-welcome__main__lesson__right__bottom__lessoninfo__lesson'>
                {{ attendLessonList[attendLesson.selected].title }}
              </h3>
              <p class='section-welcome__main__lesson__right__bottom__lessoninfo__chapter'>
                {{ setChapterTitle }}
              </p>
            </div>
            <div class='section-welcome__main__lesson__right__bottom__progress'>
              <div>
                <bar-progress
                  class='section-welcome__main__lesson__right__bottom__progress__bar'
                  :progress='attendLessonList[attendLesson.selected].inProgress.progressRate'
                />
              </div>
              <p>{{ attendLessonList[attendLesson.selected].inProgress.progressRate }}%</p>
            </div>
            <div class='section-welcome__main__lesson__right__bottom__bottom'>
              <div class='section-welcome__main__lesson__right__bottom__bottom__select'>
                <div class='section-welcome__main__lesson__right__bottom__bottom__select__move'>
                  <button-arrow
                    direction='left'
                    :disabled='setDisabledLeft'
                    @emit='changeSelectAttendLesson(-1)'
                    small
                  />
                  <p>{{ setPageCount }}</p>
                  <button-arrow
                    direction='right'
                    :disabled='setDisabledRight'
                    @emit='changeSelectAttendLesson(1)'
                    small
                  />
                </div>
              </div>
              <div class="section-welcome__main__lesson__right__bottom__bottom__text">
                <p>もう一踏ん張り頑張りましょう!</p>
              </div>
            </div>
            <div class='section-welcome__main__lesson__right__bottom__button'>
              <router-link
                :to='{
                    name: "LessonDetail",
                    params: {
                      id: attendLessonList[attendLesson.selected].id
                      }
                    }'
                >
                <button-stripe
                  arrow
                >レッスンを続ける</button-stripe>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div
        class='section-welcome__main__intro'
        v-if='welcomeLessonList.length === 0 || username.length === 0'
      >
        <div class='section-welcome__main__intro__left'>
          <h1>脱プログラミング初心者！<br>次の一歩に悩むあなたへ</h1>
          <p>manabibaは環境構築不要で、すぐ学べる<br>プログラミング学習レッスンプラットフォームです。</p>
          <router-link :to='{ name: "About"}'>
            <button-normal
              white
              large
            >
              manabibaについて詳しく
            </button-normal>
          </router-link>
        </div>
        <div class='section-welcome__main__intro__right'>
          <img
            class='section-welcome__main__intro__right__attend'
            src='@/assets/img/sphone/sphone_attendpage.png'
            alt=''
          >
        </div>
      </div>
    </div>
    <icon-loading  v-if='isLoading' class='section-welcome__load' />
  </section>
</template>

<script>
import { BarProgress } from '@/components/atoms/other';
import { ButtonArrow, ButtonStripe, ButtonNormal } from '@/components/atoms/button/';
import { IconLoading, IconUserLesson } from '@/components/atoms/icon/';

export default {
  components: {
    IconLoading,
    IconUserLesson,
    ButtonArrow,
    BarProgress,
    ButtonStripe,
    ButtonNormal,
  },
  props: {
    username: String,
    attendLessonList: Array,
    welcomeLessonList: Array,
    isLoading: Boolean,
  },
  data() {
    return {
      welcomeLesson: {
        selected: 0,
      },
      attendLesson: {
        selected: 0,
      },
      progressRate: 20,
    };
  },
  computed: {
    setWelcomeThumbnail() {
      if (this.welcomeLessonList[this.welcomeLesson.selected].image !== null) {
        const separateFilename = this.welcomeLessonList[this.welcomeLesson.selected].image.split('.');
        separateFilename[separateFilename.length - 2] = `${separateFilename[separateFilename.length - 2]}_original`;
        const combineFilename = separateFilename.join('.');
        return combineFilename;
      }
      return null;
    },
    setChapterTitle() {
      const targetLesson = this.attendLessonList[this.attendLesson.selected];
      const targetChapter = targetLesson.chapter
        .filter((chapter, index) => index + 1 === targetLesson.inProgress.continue)[0];
      return targetChapter;
    },
    setPageCount() {
      const totalCount = this.attendLessonList.length;
      const currentCount = this.attendLesson.selected + 1;
      const countText = `${currentCount}/${totalCount}`;
      return countText;
    },
    setDisabledLeft() {
      if (this.attendLesson.selected === 0) return true;
      return false;
    },
    setDisabledRight() {
      if (this.attendLessonList.length === this.attendLesson.selected + 1) return true;
      return false;
    },
    setPrice() {
      if (this.welcomeLessonList[this.welcomeLesson.selected].price === 0) {
        return '無料';
      }
      return `¥${this.welcomeLessonList[this.welcomeLesson.selected].price}`;
    },
    setAvatar() {
      if (this.welcomeLessonList[this.welcomeLesson.selected].avatar === null) return `${process.env.VUE_APP_S3_URL}/image/avatar/unknown.png`;
      return this.welcomeLessonList[this.welcomeLesson.selected].avatar;
    },
  },
  methods: {
    setErrorAvatar(e) {
      e.target.src = `${process.env.VUE_APP_S3_URL}/image/avatar/unknown.png`;
    },
    changeSelectWelcomeLesson(index) {
      this.welcomeLesson.selected = index;
    },
    changeSelectAttendLesson(page) {
      this.attendLesson.selected = page + this.attendLesson.selected;
    },
    setErrorThumbnail(e) {
      e.target.src = `${process.env.VUE_APP_S3_URL}/image/thumbnail/manabiba.png`;
    },
  },
};
</script>

<style scoped>
/* 共通 */
.section-welcome {
  background-color: #4CAF50;
  height: 430px;
}

.section-welcome__main__lesson {
  display: flex;
  justify-content: center;
  justify-items: center;
}

/* 左ウェルカムレッスン部分 */
.section-welcome__main__lesson__left {
  display: flex;
  position: relative;
  margin: 20px 0 0 250px;
  z-index: 2;
}

.section-welcome__main__lesson__left__select {
  height: 320px;
}

.section-welcome__main__lesson__left__select__content {
  background-color: #90cce6b4;
  width: 350px;
  height: 200px;
  color: #fff;
  position: absolute;
  top: 155px;
  left: -250px;
  z-index: 2;
}

.section-welcome__main__lesson__left__select:hover
>>> .button-stripe__front  {
  transform: translate(-53%, -60%);
  transition: all 0.3s ease;
  background-color: #fddb33d3;
}

.section-welcome__main__lesson__left__select__content__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.section-welcome__main__lesson__left__select__content__top h3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  font-size: 16px;
  font-weight: 500;
  margin-right: 10px;
}

.section-welcome__main__lesson__left__select__content__top img {
  width: 50px;
  height: 50px;
  border: 2px solid #fff;
  border-radius: 30px;
}

.section-welcome__main__lesson__left__select__content__left {
  padding: 15px;
  height: 100px;
  display: flex;
  justify-content: space-between;
}

.section-welcome__main__lesson__left__select__content__left__description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-all;
  width: 240px;
  height: 55px;
  font-size: 12px;
  margin-right: 20px;
}

.section-welcome__main__lesson__left__select__content__left__price {
  font-size: 24px;
  font-weight: 300;
  margin-top: 20px;
}

.section-welcome__main__lesson__left__select__content__left__button {
  position: absolute;
  right: 20px;
  bottom: -20px;
}

.section-welcome__main__lesson__left__select__thumbnail {
  transition: all 0.3s ease 0s;
}

.section-welcome__main__lesson__left__select__thumbnail img {
  height: 320px;
  width: 550px;
  border: 10px solid #fff;
  margin-bottom: -5px;
  transition: all 0.3s ease 0s;
}

.section-welcome__main__lesson__left__select:hover
.section-welcome__main__lesson__left__select__thumbnail {
  transform: translate(-4px,-4px);
  transition: all 0.3s ease;
  box-shadow: 8px 8px #E2B43B;
}

.section-welcome__main__lesson__left__thumbnaillist {
  position: absolute;
  top: 330px;
  display: flex;
  list-style: none;
  margin-left: 110px;
  margin-top: 5px;
}

.section-welcome__main__lesson__left__thumbnaillist li {
  margin-left: 10px;
}

.section-welcome__main__lesson__left__thumbnaillist li img {
  width: 60px;
  height: 35.5px;
  padding: 2px;
}

.section-welcome__main__lesson__left__thumbnaillist li img:hover {
  border: 2px solid #fff;
  padding: 0;
}

/* 右続きからレッスン */
.section-welcome__main__lesson__right {
  height: 440px;
  width: 350px;
  background-color: #fff;
  margin-left: 20px;
  padding: 15px;
  box-shadow:  3px 3px 8px 4px rgba(0, 0, 0, 0.07);
  z-index: 2;
  position: relative;
}

.section-welcome__main__lesson__right:hover
>>> .button-stripe__front  {
  transform: translate(-53%, -60%);
  transition: all 0.3s ease;
  background-color: #fddb33d3;
}

.section-welcome__main__lesson__right__author {
  position: absolute;
  top: 20px;
  right: -35px;
}

.section-welcome__main__lesson__right__top img {
  width: 320px;
  height: 180px;
}

.section-welcome__main__lesson__right__bottom__welcome {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  margin: 10px 0;
}

.section-welcome__main__lesson__right__bottom__welcome__username {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-all;
  max-width: 170px;
}

.section-welcome__main__lesson__right__bottom__lessoninfo {
  height: 100px;
}

.section-welcome__main__lesson__right__bottom__lessoninfo__lesson {
  color: #E94861;
  font-weight: 500;
  font-size: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  width: 100%;
}

.section-welcome__main__lesson__right__bottom__lessoninfo__chapter {
  font-weight: 400;
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  width: 100%;
}

.section-welcome__main__lesson__right__bottom__progress {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.section-welcome__main__lesson__right__bottom__progress__bar {
  width: 270px;
}

.section-welcome__main__lesson__right__bottom__progress p {
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
}

.section-welcome__main__lesson__right__bottom__bottom {
  position: relative;
  display: flex;
}

.section-welcome__main__lesson__right__bottom__bottom__select {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.section-welcome__main__lesson__right__bottom__bottom__select p {
  font-size: 16px;
  font-weight: bold;
  margin: 0 10px;
}

.section-welcome__main__lesson__right__bottom__bottom__select__move {
  display: flex;
}

.section-welcome__main__lesson__right__bottom__bottom__text {
  position: relative;
  width: 100%;
  padding: 5px 5px;
  margin: 10px 0 0 15px;
  border: 1px solid #ccc;
  font-size: 12px;
  font-weight: 500;
  overflow: hidden;
}

.section-welcome__main__lesson__right__bottom__bottom__text::before {
  background-color: #F1EFFC;
  content: "";
  position: absolute;
  left: -10px;
  top: 0px;
  height: 100%;
  width: 0;
  transition: all .1s ease-in;
  z-index: -1;
}

.section-welcome__main__lesson__right:hover
.section-welcome__main__lesson__right__bottom__bottom__text::before {
  width: 110%;
  transition: all .3s ease-in;
  z-index: -1;
}

.section-welcome__main__lesson__right__bottom__button {
  position: absolute;
  right: 16px;
  bottom: -35px;
}

.section-welcome__load {
  margin-top: 150px;
  position: relative;
  z-index: 2;
}

/* 表示コンテンツがない場合の挙動 */
.section-welcome__main__intro {
  width: 1200px;
  height: 430px;
  margin:  0 auto;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  /* justify-content: space-between; */
  color: #fff;
}

.section-welcome__main__intro__left {
  margin-right: 150px;
}

.section-welcome__main__intro__left h1 {
  font-size: 40px;
}

.section-welcome__main__intro__left p {
  font-size: 20px;
  font-weight: 500;
  margin: 10px 0 20px;
}

.section-welcome__main__intro__right__attend {
  width: 500px;
}

/* 背景バー */
.section-welcome__back__bar-first {
  position: absolute;
  top: 30px;
  left: -200px;
  height: 250px;
  z-index: 1;
}

.section-welcome__back__bar-second {
  position: absolute;
  top: 350px;
  left: -50px;
  width: 700px;
  height: 200px;
  object-fit: cover;
  z-index: 1;
}

.section-welcome__back__bar-third {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.section-welcome__back__logo {
  position: absolute;
  top: 80px;
  left: 330px;
  height: 200px;
  z-index: 1;
  opacity: 0.7;
}
</style>
