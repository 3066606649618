<template>
  <div class='button-list'>
    <p>{{ title }}</p>
    <ul class='button-list__label'>
      <li class='button-list__label__list' v-for='(label, index) in labelList' :key='index'>
        <button
          class='button-list__label__list__btn'
          :class='{"focus" : selected === index}'
          :disabled='disabled'
          @click='changeSelect(index)'
        >
          {{ label }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    labelList: Array,
    title: String,
    disabled: Boolean,
  },
  data() {
    return {
      selected: 0,
    };
  },
  methods: {
    changeSelect(index) {
      this.selected = index;
    },
  },
  watch: {
    selected(to) {
      this.$emit('emit', to);
    },
  },
};
</script>

<style scoped>
.button-list {
  display: flex;
  align-items: center;
}

.button-list__label {
  display: flex;
  list-style: none;
}

.button-list__label__list {
  margin-left: 10px;
}

.button-list__label__list__btn {
  font-size: 14px;
  padding: 5px 10px;
  background-color: #F7F7F7;
  border: 1px solid #DDD;
  border-radius: 20px;
}

.focus {
  background-color: #4CAF50;
  border: 1px solid #4CAF50;
  color: #fff;
  font-weight: 500;
}
</style>
