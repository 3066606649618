var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "news-list" },
    [
      _c("layout-header"),
      _c("heading-title", {
        scopedSlots: _vm._u([
          {
            key: "main",
            fn: function() {
              return [_c("p", [_vm._v("NEWS")])]
            },
            proxy: true
          },
          {
            key: "sub",
            fn: function() {
              return [_c("p", [_vm._v("お知らせ_")])]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "ul",
        { staticClass: "news-list__contents center" },
        _vm._l(_vm.noticeList, function(notice, index) {
          return _c(
            "router-link",
            {
              key: index,
              staticClass: "news-list__contents__list",
              attrs: { to: { name: "News", params: { id: notice.id } } }
            },
            [
              _c("h3", { staticClass: "news-list__contents__list__title" }, [
                _vm._v(_vm._s(notice.title))
              ]),
              _c("p", { staticClass: "news-list__contents__list__date" }, [
                _vm._v(_vm._s(_vm._f("moment")(notice.updated_at)))
              ])
            ]
          )
        }),
        1
      ),
      _c("layout-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }