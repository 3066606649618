<template>
  <div class='modal-compare-source'>
    <div class='modal-compare-source__main'>
      <div class='modal-compare-source__main__edit'>
        <h3 class='modal-compare-source__main__edit__title'>自分のコード</h3>
        <lesson-editor-tab
          :tabInfo='editInfo.tabInfo'
          @emit='switchEditorEdit'
        />
        <lesson-ace
          v-show='editInfo.editor == index'
          v-for='(sourceInfo, index) in editSourceList'
          :key='sourceInfo.id'
          :id='100 + index'
          :filename='sourceInfo.filename'
          :source='sourceInfo.source'
          readonly
        />
      </div>
      <div class='modal-compare-source__main__default'>
        <h3 class='modal-compare-source__main__default__title'>答え</h3>
        <lesson-editor-tab
          :tabInfo='defaultInfo.tabInfo'
          @emit='switchEditorDefault'
        />
        <lesson-ace
          v-show='defaultInfo.editor == index'
          v-for='(sourceInfo, index) in defaultSourceList'
          :key='sourceInfo.id'
          :id='200 + index'
          :filename='sourceInfo.filename'
          :source='sourceInfo.sample'
          readonly
        />
        <button-hide @emit='$emit("emit-hide")' />
      </div>
    </div>
    <div class='modal-compare-source__overlay' @click='$emit("emit-hide")' />
  </div>
</template>

<script>
import { ButtonHide } from '@/components/atoms/button';
import { LessonAce, LessonEditorTab } from '@/components/atoms/lesson';

export default {
  components: {
    ButtonHide,
    LessonAce,
    LessonEditorTab,
  },
  props: {
    defaultSourceList: Array,
    editSourceList: Array,
  },
  data() {
    return {
      defaultInfo: {
        editor: 0,
        tabInfo: [],
      },
      editInfo: {
        editor: 0,
        tabInfo: [],
      },
    };
  },
  methods: {
    switchEditorEdit(index) {
      this.editInfo.editor = index;
    },
    switchEditorDefault(index) {
      this.defaultInfo.editor = index;
    },
  },
  mounted() {
    // filenameを抜き出してタブに当て込む
    this.defaultInfo.tabInfo = this.defaultSourceList.map((val) => val.filename);
    this.editInfo.tabInfo = this.editSourceList.map((val) => val.filename);
  },
};
</script>

<style scoped>
.modal-compare-source__main {
  text-align: center;
  position: absolute;
  width: 97%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 997;
  display: flex;
}

.modal-compare-source__overlay {
  position: absolute;
  z-index: 996;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-compare-source__main__edit {
  width: 49%;
  margin-right: auto;
  padding: 5px;
  background-color: #f9f9fa;
}

.modal-compare-source__main__edit__title {
  text-align: left;
  background-color: #f9f9fa;
  padding: 5px 10px 10px;
}

.modal-compare-source__main__default {
  width: 49%;
  padding: 5px;
  background-color: #f9f9fa;
  position: relative;
}

.modal-compare-source__main__default__title {
  text-align: left;
  background-color: #f9f9fa;
  padding: 5px 10px 10px;
}
</style>
