<template>
  <div class='sphone'>
    <section class='sphone__header sphone__header__wrapper'>
      <img
        class='sphone__header__logo'
        src='@/assets/img/sphone/sphone_textlogo_white.svg'
        alt='ロゴ'>
      <p class='sphone__header__text'>プログラミング学習レッスン プラットフォーム manabiba</p>
    </section>
    <section class='sphone__main__top'>
      <div class='sphone__main__top__wrapper'>
        <h1 class='sphone__main__top__title'>脱プログラミング初心者！<br>次の一歩に悩むあなたへ</h1>
        <img
          class='sphone__main__top__img'
          src='@/assets/img/sphone/sphone_attendpage.png'
          alt='manabibaの受講ページ'>
        <p class='sphone__main__top__text'>
          manabibaは環境構築不要で、すぐ学べる<br>プログラミング学習レッスンプラットフォームです。
        </p>
        <div class='sphone__main__sns'>
          <share-network
            class='sphone__main__sns__btn'
            :network='shareInfo.twitter.network'
            :url='shareInfo.twitter.url'
            :title='shareInfo.twitter.title'
            :hashtags='shareInfo.twitter.hashtags'
          >
            <i class="fab fa-twitter sphone__main__sns__twitter"></i>
          </share-network>
          <share-network
            class='sphone__main__sns__btn'
            :network='shareInfo.facebook.network'
            :url='shareInfo.facebook.url'
            :title='shareInfo.facebook.title'
            :hashtags='shareInfo.facebook.hashtags'
          >
            <i class="fab fa-facebook-square sphone__main__sns__facebook"></i>
          </share-network>
          <share-network
            class='sphone__main__sns__btn'
            :network='shareInfo.line.network'
            :url='shareInfo.line.url'
            :title='shareInfo.line.title'
            :description='shareInfo.line.description'
          >
            <i class="fab fa-line sphone__main__sns__line"></i>
          </share-network>
          <share-network
            class='sphone__main__sns__btn'
            :network='shareInfo.pocket.network'
            :url='shareInfo.pocket.url'
            :title='shareInfo.pocket.title'
          >
            <i class="fab fa-get-pocket sphone__main__sns__pocket"></i>
          </share-network>
        </div>
      </div>
    </section>

    <section class='sphone__main__feature'>
      <div class='sphone__main__feature__wrapper'>
        <h2 class='sphone__main__feature__title'>
          <img
            class='sphone__main__feature__title__img'
            src='@/assets/img/sphone/sphone_logo_white.svg'
            alt='ロゴ'>
          <p class='sphone__main__feature__title__text'>manabibaの特徴 </p>
        </h2>
        <ul>
          <li class='sphone__main__feature__list'>
            <img
              class='sphone__main__feature__list__img'
              src='@/assets/img/sphone/sphone_attendpage_zoom.png'
              alt='doblerの受講ページ'>
            <div class='sphone__main__feature__list__contents'>
              <h3 class='sphone__main__feature__list__contents__title'>
              <div>
                <span class='sphone__main__feature__list__contents__title__num'>1</span>
                <span class='sphone__main__feature__list__contents__title__period'>.</span>
              </div>
                <p class='sphone__main__feature__list__contents__title__text'>
                  開発環境の準備不要！<br>ブラウザ上ですぐ学べる
                </p>
              </h3>
              <p class='sphone__main__feature__list__contents__text'>
                ブラウザ上でコードを実行できるため<br>難しい環境構築の必要はありません！
              </p>
            </div>
          </li>
          <li class='sphone__main__feature__list'>
            <img
              class='sphone__main__feature__list__img'
              src='@/assets/img/sphone/sphone_feature_variety.png'
              alt='プログラミング言語'>
            <div class='sphone__main__feature__list__contents'>
              <h3 class='sphone__main__feature__list__contents__title'>
              <div>
                <span class='sphone__main__feature__list__contents__title__num'>2</span>
                <span class='sphone__main__feature__list__contents__title__period'>.</span>
              </div>
                <p class='sphone__main__feature__list__contents__title__text'>
                  基礎から応用まで幅広い<br>レベルのレッスンを提供
                </p>
              </h3>
              <p class='sphone__main__feature__list__contents__text'>
                投稿型サービスなので多種多様なレッスンの中からあなたに合ったレッスンを受講できます。
              </p>
            </div>
          </li>
          <li class='sphone__main__feature__list'>
            <img
              class='sphone__main__feature__list__img'
              src='@/assets/img/sphone/sphone_feature_knowledge.png'
              alt='プログラミングの学習の時の悩み'>
            <div class='sphone__main__feature__list__contents'>
              <h3 class='sphone__main__feature__list__contents__title'>
              <div>
                <span class='sphone__main__feature__list__contents__title__num'>3</span>
                <span class='sphone__main__feature__list__contents__title__period'>.</span>
              </div>
                <p class='sphone__main__feature__list__contents__title__text'>
                  独学で必要な予備知識を徹底解説(準備中)
                </p>
              </h3>
              <p class='sphone__main__feature__list__contents__text'>
                学習ロードマップやどこまで学べば 次へ進んでよいかなど、独学する上で悩みがちな箇所の解説を発信します。
              </p>
            </div>
          </li>
        </ul>
      </div>
    </section>

    <section class='sphone__main__procedure'>
      <div class='sphone__main__procedure__wrapper'>
        <div class='sphone__main__procedure__title'>
          <img
            class='sphone__main__procedure__title__img'
            src='@/assets/img/sphone/sphone_logo_white.svg'
            alt='ロゴ'>
          <h2 class='sphone__main__procedure__title__text'>レッスンの受講手順</h2>
        </div>
        <ul>
          <li class='sphone__main__procedure__list'>
            <div class='sphone__main__procedure__list__contents'>
              <h3 class='sphone__main__procedure__list__contents__title'>
                1. パソコンでmanabibaに<br>アクセス
              </h3>
              <p class='sphone__main__procedure__list__contents__text'>
                manabibaはPCで利用するサービスです。<br>Github/Twitter/Google/Facebook<br>アカウントで登録できます。
              </p>
            </div>
            <img
              class='sphone__main__procedure__list__img'
              src='@/assets/img/sphone/sphone_access.png'
              alt='OKのサインをする人'>
            <div class='sphone__main__procedure__list__triangle'></div>
          </li>
          <li class='sphone__main__procedure__list'>
            <div class='sphone__main__procedure__list__contents'>
              <h3 class='sphone__main__procedure__list__contents__title'>
                2. レッスンを選択
                </h3>
              <p class='sphone__main__procedure__list__contents__text'>
                自分に合ったレッスンを選択してください。
              </p>
            </div>
            <img
              class='sphone__main__procedure__list__img'
              src='@/assets/img/sphone/sphone_choose.png'
              alt='プログラミングのコード'>
            <div class='sphone__main__procedure__list__triangle'></div>
          </li>
          <li class='sphone__main__procedure__list'>
            <div class='sphone__main__procedure__list__contents'>
              <h3 class='sphone__main__procedure__list__contents__title'>
                3. 受講スタート
              </h3>
              <p class='sphone__main__procedure__list__contents__text'>
                ブラウザ内で学習を開始できます。
              </p>
            </div>
            <img
              class='sphone__main__procedure__list__img'
              src='@/assets/img/sphone/sphone_start.png'
              alt='猿のぬいぐるみ'>
          </li>
        </ul>
      </div>
    </section>

    <section class='sphone__main__lang'>
      <div class='sphone__main__lang__wrapper'>
        <h2 class='sphone__main__lang__title'>
          <img
            class='sphone__main__lang__title__img'
            src='@/assets/img/sphone/sphone_logo_white.svg'
            alt='ロゴ'>
          <p class='sphone__main__lang__title__text'>対応言語</p>
        </h2>
        <ul class='sphone__main__lang__contents'>
          <li class='sphone__main__lang__contents__list'>
            <div class='sphone__main__lang__contents__htmlcss'>
              <i class="fab fa-html5 sphone__main__lang__contents__html"></i>
              <i class="fab fa-css3-alt sphone__main__lang__contents__css"></i>
            </div>
            <p class='sphone__main__lang__contents__list__text'>HTML/CSS</p>
          </li>
          <li class='sphone__main__lang__contents__list'>
            <i class="fab fa-js sphone__main__lang__contents__js"></i>
            <p class='sphone__main__lang__contents__list__text'>Javascript</p>
          </li>
          <li class='sphone__main__lang__contents__list'>
            <i class="fab fa-php sphone__main__lang__contents__php"></i>
            <p class='sphone__main__lang__contents__list__text'>PHP</p>
          </li>
          <li class='sphone__main__lang__contents__list'>
            <img
              src='@/assets/img/sphone/sphone_language.svg'
              class='sphone__main__lang__contents__other'
              alt='桃 peach'>
            <p class='sphone__main__lang__contents__list__text'>その他<br>順次対応中</p>
          </li>
        </ul>
      </div>
    </section>

    <section class='sphone__main__creater'>
      <div class='sphone__main__creater__wrapper'>
        <h2 class='sphone__main__creater__title'>レッスンを<br>作ってみたい方も</h2>
        <p class='sphone__main__creater__text'>
          manabibaではレッスンを<br>作成してくれる方を募集しています。<br><br>
          テキストとソースコードがあれば<br>投稿できて、副業としても最適です。<br><br>
          manabibaを通じて次世代の<br>エンジニア育成に貢献しませんか？
        </p>
      </div>
    </section>

    <section class='sphone__main__form'>
      <!-- 必要な際表示 -->
      <!-- <div class='sphone__main__form__wrapper'>
        <div class='sphone__main__form__contents'>
          <img
            class='sphone__main__form__contents__logo'
            src='@/assets/img/sphone/sphone_textlogo_orange.svg'>
          <p class='sphone__main__form__contents__text'>メールでURLをパソコンに送る</p>
          <form class='sphone__main__form__contents__email'>
            <input
              class='sphone__main__form__contents__email__input'
              type='email'
              placeholder='パソコンで受け取るメールアドレスを入力'>
            <button class='sphone__main__form__contents__email__btn'>
              メールを送る
              <i class="fas fa-paper-plane sphone__main__form__contents__email__btn__send"></i>
            </button>
          </form>
        </div>
      </div> -->
    </section>

    <section class='sphone__main__sns sphone__main__sns__wrapper'>
      <share-network
        class='sphone__main__sns__btn'
        :network='shareInfo.twitter.network'
        :url='shareInfo.twitter.url'
        :title='shareInfo.twitter.title'
        :hashtags='shareInfo.twitter.hashtags'
      >
        <i class="fab fa-twitter sphone__main__sns__twitter"></i>
      </share-network>
      <share-network
        class='sphone__main__sns__btn'
        :network='shareInfo.facebook.network'
        :url='shareInfo.facebook.url'
        :title='shareInfo.facebook.title'
        :hashtags='shareInfo.facebook.hashtags'
      >
        <i class="fab fa-facebook-square sphone__main__sns__facebook"></i>
      </share-network>
      <share-network
        class='sphone__main__sns__btn'
        :network='shareInfo.line.network'
        :url='shareInfo.line.url'
        :title='shareInfo.line.title'
        :description='shareInfo.line.description'
      >
        <i class="fab fa-line sphone__main__sns__line"></i>
      </share-network>
      <share-network
        class='sphone__main__sns__btn'
        :network='shareInfo.pocket.network'
        :url='shareInfo.pocket.url'
        :title='shareInfo.pocket.title'
      >
        <i class="fab fa-get-pocket sphone__main__sns__pocket"></i>
      </share-network>
    </section>

    <section class='sphone__main__official'>
      <a
        href='https://twitter.com/manabiba_jp'
        class='sphone__main__official__img__wrap'
        target='_blank'
      >
        <i class='fab fa-twitter sphone__main__official__img'></i>
      </a>
      <div class='sphone__main__official__contents'>
        <p class='sphone__main__official__contents__title'>SNS</p>
        <p class='sphone__main__official__contents__text'>
          manabibaの最新情報は公式Twitter<br>@manabiba_jp でチェックできます。
        </p>
      </div>
    </section>

    <div class='sphone__footer'>
      <p>©︎ 2021 manabiba, Inc.</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shareInfo: {
        twitter: {
          network: 'twitter',
          url: 'https://donbler.com',
          title: 'manabibaは環境構築不要で、すぐ学べるプログラミング学習レッスンプラットフォームです。',
          hashtags: 'manabiba',
        },
        facebook: {
          network: 'facebook',
          url: 'https://donbler.com',
          title: 'manabiba | 脱プログラミング初心者！次の一歩に悩むあなたへ',
          description: 'manabibaは環境構築不要で、すぐ学べるプログラミング学習レッスンプラットフォームです。',
          quote: '引用',
          hashtags: 'manabiba',
        },
        line: {
          network: 'line',
          url: 'https://donbler.com',
          title: 'manabiba | 脱プログラミング初心者！次の一歩に悩むあなたへ',
          description: 'manabibaは環境構築不要で、すぐ学べるプログラミング学習レッスンプラットフォームです。',
        },
        pocket: {
          network: 'pocket',
          url: 'https://donbler.com',
          title: 'manabibaは環境構築不要で、すぐ学べるプログラミング学習レッスンプラットフォームです。',
        },
      },
    };
  },
  created() {
    const html = document.getElementsByTagName('html');
    html[0].style.fontSize = '2.67vw';
  },
};
</script>

<style scoped>
img {
  vertical-align:top;
}

.sphone {
  background-color: #FDD519;
  background-image: url('~@/assets/img/sphone/sphone_background.svg');
  background-position: 7rem -9rem;
  background-size: 100% auto;
}

.sphone__header__wrapper {
  padding: 0 1.5rem 0;
}
.sphone__header {
  display: flex;
  justify-content: space-between;
  padding-top: 2.1rem;
}

.sphone__header__logo {
  width: 12.5rem;
  margin-right: 0.5em
}

.sphone__header__text {
  color: white;
  font-size: 1em;
  word-break: keep-all;
}

.sphone__main__top {
  margin-top: 4rem;
}

.sphone__main__top__wrapper {
  padding: 0 1.5rem 0;
}

.sphone__main__top__title {
  font-size: 2.5rem;
  line-height: 4rem;
  color: white;
  text-align: center;
  font-weight: bold;
}

.sphone__main__top__img {
  margin-top: 1rem;
  width: 100%;
  padding: 1rem;
}

.sphone__main__top__text {
  margin-top: .8rem;
  color: white;
  font-size: 1.4rem;
  line-height: 2.4rem;
  text-align: center;
}

.sphone__main__sns {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
}

.sphone__main__sns__wrapper {
  padding: 0 1.5rem 0;
}

.sphone__main__sns__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 7.5rem;
  height: 4.5rem;
  border-radius: .8rem;
}

.sphone__main__sns__twitter {
  color: #74AFFF;
  font-size: 2.3rem;
}

.sphone__main__sns__facebook {
  color: #3E54FF;
  font-size: 2.1rem;
}

.sphone__main__sns__line {
  color: #10CC00;
  font-size: 2.1rem;
}

.sphone__main__sns__pocket {
  color: #FF4B4B;
  font-size: 2.1rem;
}

.sphone__main__feature {
  margin-top: 7rem;
}

.sphone__main__feature__wrapper {
  padding: 0 1.5rem 0;
}

.sphone__main__feature__title {
  display: flex;
  align-items: bottom;
}

.sphone__main__feature__title__img {
  width: 2.5rem;
  height: 3.6rem;
}

.sphone__main__feature__title__text {
  color: white;
  font-size: 2.4rem;
  margin-left: .7rem;
}

.sphone__main__feature__list {
  margin-top: 2rem;
  border-radius: .8rem;
  background-color: white;
  overflow: hidden;
}

.sphone__main__feature__list__img {
  width: 100%;
}

.sphone__main__feature__list__contents {
  padding:2rem 2.3rem 2.5rem;
}
.sphone__main__feature__list__contents__title {
  display: flex;
  align-items: top;
  color: #E95456;
}

.sphone__main__feature__list__contents__title__num {
  font-size: 6rem;
  font-family: 'tahoma','Helvetica Neue', 'Arial';
  font-weight: bold;
  line-height: 6rem;
}

.sphone__main__feature__list__contents__title__period {
  font-size: 3rem;
  font-family: 'tahoma','Helvetica Neue', 'Arial';
}

.sphone__main__feature__list__contents__title__text {
  margin-left: .7rem;
  font-size: 2rem;
  line-height: 3rem;
}

.sphone__main__feature__list__contents__text {
  font-size: 1.6rem;
  line-height: 2.6rem;
  margin-top: 1.5rem;
  color: #707070;
}

.sphone__main__procedure {
  margin-top: 6.5rem;
}

.sphone__main__procedure__wrapper {
  padding: 0 1.5rem 0;
}

.sphone__main__procedure__title {
  display: flex;
  align-items: bottom;
}

.sphone__main__procedure__title__img {
  width: 2.5rem;
  height: 3.6rem;
}

.sphone__main__procedure__title__text {
  color: white;
  font-size: 2.4rem;
  margin-left: .7rem;
}

.sphone__main__procedure__list {
  z-index: 1;
  position: relative;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: .8rem;
  width: 100%;
  background-color: white;
  margin-top: 1.6rem;
  color: #707070;
}

.sphone__main__procedure__list__contents {
  padding: 0 1rem;
  flex-basis: 72%;
}

.sphone__main__procedure__list__contents__title {
  font-size: 1.6rem;
  line-height: 3rem;
  font-weight: bold;
}

.sphone__main__procedure__list__contents__text {
  font-size: 1.2rem;
  line-height: 1.6rem;
}

.sphone__main__procedure__list__img {
  flex-basis: 28%;
  width: 9.6rem;
}

.sphone__main__procedure__list__triangle {
  z-index: 2;
  display: block;
  position: absolute;
  bottom: -2.9rem;
  left: 50%;
  transform: translateX(-50%);
  height: 0;
  width: 0;
  border-top: 1.8rem solid #E74860;
  border-right: 1.05rem solid transparent;
  border-bottom: 1.8rem solid transparent;
  border-left: 1.05rem solid transparent;
}

.sphone__main__lang {
  margin-top: 6rem;
  background-image: url('~@/assets/img/sphone/sphone_background.svg');
  background-size: 100% auto;
  background-position: 9rem 0;
}

.sphone__main__lang__wrapper {
  padding: 0 1.5rem 0;
}

.sphone__main__lang__title {
  display: flex;
  align-items: bottom;
}

.sphone__main__lang__title__img {
  width: 2.5rem;
  height: 3.6rem;
}

.sphone__main__lang__title__text {
  color: white;
  font-size: 2.4rem;
  margin-left: .7rem;
}

.sphone__main__lang__contents {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  width: 28.7rem;
  height: 28.7rem;
  list-style: none;
  margin: 1.7rem auto;
}

.sphone__main__lang__contents__list {
  background-color: white;
  padding: 0 3rem 1.5rem;
  border-radius: 1.1rem;
  display: flex;
  justify-content: flex-end;
  flex-direction:column;
  text-align: center;
  width: 13.6rem;
  height: 13.6rem;
}

.sphone__main__lang__contents__list__text {
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #707070;
}

.sphone__main__lang__contents__htmlcss {
  display: flex;
  justify-content: space-between;
  margin-bottom:1.6rem;
}

.sphone__main__lang__contents__html {
  color: #FF971D;
  font-size: 4.5rem;
}

.sphone__main__lang__contents__css {
  color: #14A4FF;
  font-size: 4.5rem;
}

.sphone__main__lang__contents__js {
  color: #FFD800;
  font-size: 4.5rem;
  margin-bottom: 1.6rem;
}

.sphone__main__lang__contents__php {
  color: #7948FF;
  font-size: 4.5rem;
  margin-bottom: 1.6rem;
}

.sphone__main__lang__contents__other {
  height: 4.5rem;
  margin-bottom: 1rem;
}

.sphone__main__creater {
  margin-top: 6.5rem;
  padding: 0 1rem 0;
}

.sphone__main__creater__wrapper {
  padding: 0 1.5rem 0;
}

.sphone__main__creater__title {
  font-size: 3rem;
  line-height: 3.5rem;
  color: white;
}

.sphone__main__creater__text {
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: white;
  margin-top: 2rem;
}

.sphone__main__form {
  margin-top: 6.5rem;
  text-align: center;
}

.sphone__main__form__wrapper {
  padding: 0 1.5rem 0;
}

.sphone__main__form__contents {
  background-color: white;
  border-radius: .8rem;
  padding: 2rem;
}

.sphone__main__form__contents__logo {
  width: 14.6rem;
}
.sphone__main__form__contents__text {
  margin-top: 2rem;
  font-size: 1.4rem;
  line-height: 1.7rem;
}

.sphone__main__form__contents__email__input {
  margin-top: 1.7rem;
  height: 4.5rem;
  width: 95%;
  background-color: #F5F5F5;
  border-radius: .8rem;
  padding-left: 1.5rem;
}

.sphone__main__form__contents__email__input::placeholder {
  color: #BFBFBF;
}

.sphone__main__form__contents__email__btn {
  background-color: #E95456;
  color: white;
  font-size: 1.4rem;
  text-align: center;
  width: 95%;
  height: 4.5rem;
  margin-top: .8rem;
  border-radius: .8rem;
}

.sphone__main__official {
  display: flex;
  padding: 3.5rem 4.5rem;
  background-color: white;
  margin-top: 3rem;
  width: 100vw;
}

.sphone__main__official__img__wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  background-color: #74AFFF;
  border-radius: 1.1rem;
  width: 7.4rem;
  height: 7.4rem;
}

.sphone__main__official__img {
  color: white;
  font-size: 3rem;
}

.sphone__main__official__contents {
  margin-left: 1.5rem;
  color: #707070;
}

.sphone__main__official__contents__title {
  font-size: 1.6rem;
  line-height: 1.9rem;
}

.sphone__main__official__contents__text {
  font-size: 1.2rem;
  line-height: 2rem;
  margin-top: .8rem;
}

.sphone__footer {
  color: white;
  font-size: 1.2rem;
  padding: 2rem 0;
  text-align: center;
}
</style>
