var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lesson-header" },
    [
      _c("notifications", { attrs: { group: "top-right" } }),
      _c("div", { staticClass: "lesson-header__main" }, [
        _c(
          "div",
          { staticClass: "lesson-header__main__left" },
          [
            _c(
              "router-link",
              {
                class: _vm.addClassLinkDisabled,
                attrs: { to: { name: "Top" } }
              },
              [_c("layout-logo", { attrs: { iconOnly: "", yellow: "" } })],
              1
            ),
            _c("div", { staticClass: "lesson-header__main__left__attend" }, [
              !_vm.previewMode
                ? _c(
                    "p",
                    {
                      staticClass: "lesson-header__main__left__attend__lesson"
                    },
                    [_vm._v(" " + _vm._s(_vm.lessonInfo.title) + " ")]
                  )
                : _vm._e(),
              !_vm.previewMode && !_vm.adminPreviewMode
                ? _c(
                    "button",
                    {
                      staticClass: "lesson-header__main__left__attend__chapter",
                      on: {
                        click: function($event) {
                          return _vm.$emit("emit-chapter-list")
                        }
                      }
                    },
                    [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.lessonInfo.chapterTitle[_vm.chapterNum - 1]
                          )
                        )
                      ]),
                      _c("i", { staticClass: "fas fa-caret-square-down" })
                    ]
                  )
                : _vm._e(),
              _vm.previewMode
                ? _c(
                    "div",
                    {
                      staticClass: "lesson-header__main__left__attend__creator"
                    },
                    [
                      _c(
                        "button",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$emit("emit-move-confirm")
                            }
                          }
                        },
                        [_vm._v(" 確認ページに戻る ")]
                      ),
                      _c("p", [
                        _vm._v(
                          " Creator Mode / Preview : " +
                            _vm._s(
                              _vm.lessonInfo.chapterTitle[_vm.chapterNum - 1]
                            ) +
                            " "
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm.adminPreviewMode
                ? _c(
                    "p",
                    { staticClass: "lesson-header__main__left__attend__admin" },
                    [
                      _vm._v(
                        " Admin Mode / Preview : " +
                          _vm._s(
                            _vm.lessonInfo.chapterTitle[_vm.chapterNum - 1]
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e()
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "lesson-header__main__right" },
          [
            _c("div", { staticClass: "lesson-header__main__right__func" }, [
              _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("emit-reset")
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fas fa-redo" }),
                  _c("p", [_vm._v("リセット")])
                ]
              ),
              _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("emit-compare-source")
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fas fa-eye" }),
                  _c("p", [_vm._v("答えを見る")])
                ]
              )
            ]),
            !_vm.previewMode
              ? _c("div", { staticClass: "lesson-header__main__right__move" }, [
                  _vm.chapterNum !== 1
                    ? _c(
                        "button",
                        {
                          staticClass: "lesson-header__main__right__move__prev",
                          attrs: { disabled: _vm.isLoading.movePrevPage },
                          on: {
                            click: function($event) {
                              return _vm.$emit("emit-prev-page")
                            }
                          }
                        },
                        [_vm._v(" 前に戻る ")]
                      )
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      class: _vm.addClassNextBtn,
                      attrs: { disabled: _vm.isLoading.moveNextPage },
                      on: {
                        click: function($event) {
                          return _vm.$emit("emit-next-page")
                        }
                      }
                    },
                    [_vm._v(" 完了して次へ ")]
                  )
                ])
              : _vm._e(),
            _vm.adminPreviewMode
              ? _c(
                  "button",
                  {
                    class: _vm.isActive.adminCodeLoad
                      ? "lesson-header__main__right__admin--disabled"
                      : "lesson-header__main__right__admin",
                    attrs: { disabled: _vm.isActive.adminCodeLoad },
                    on: {
                      click: function($event) {
                        _vm.$emit("emit-admin-code-load"),
                          _vm.active("adminCodeLoad")
                      }
                    }
                  },
                  [_vm._v(" CodeLoad ")]
                )
              : _vm._e(),
            !_vm.adminPreviewMode
              ? _c("button-user", {
                  attrs: { src: _vm.userInfo.profile.avatar },
                  on: {
                    emit: function($event) {
                      return _vm.active("dropdownUser")
                    }
                  }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "lesson-header__dropdown" },
        [
          _vm.isActive.dropdownUser
            ? _c("dropdown-header-user", {
                staticClass: "lesson-header__main__dropdown__user",
                attrs: {
                  userIcon: _vm.userInfo.profile.avatar,
                  userName: _vm.userInfo.profile.userName,
                  purchaseList: _vm.filterPurchaseList
                },
                on: { "emit-logout": _vm.logout }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.isActive.dropdownUser
        ? _c("div", {
            staticClass: "lesson-header__overlay",
            on: {
              click: function($event) {
                return _vm.active("dropdownUser")
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }