/**
 * notice.js
 * 通知情報の状態管理を行う
 */

import axios from 'axios';

const axiosBase = axios.create({
  baseURL: process.env.VUE_APP_HOST,
  headers: {
    'Content-Type': 'application/json',
  },
  credentials: true,
  responseType: 'json',
});

export default {
  namespaced: true,
  state: {
    noticeList: [],
  },
  mutations: {
    saveNoticeList(state, loadNoticeList) {
      const notice = state;
      notice.noticeList = loadNoticeList;
    },
  },
  actions: {
    // 指定したカテゴリの記事一覧の取得
    async loadNoticeList(context, categoryId) {
      try {
        const notice = context;
        const res = await axiosBase.get(`/api/notices/${categoryId}`);
        const noticeList = res.data.sort((a, b) => {
          if (a.updated_at > b.updated_at) {
            return -1;
          }
          return 1;
        });
        await notice.commit('saveNoticeList', noticeList);
        return true;
      } catch (e) {
        return false;
      }
    },
  },
};
