<template>
  <div class='user'>
    <layout-header />
      <section-user-info
        :isLoading='isLoading'
        :ambassador='userInfo.ambassador'
        :userName='userInfo.userName'
        :src='userInfo.avatar' />
      <div class='user__contents center' v-if='!isLoading'>
        <div class='user__contents__introduction'>
          <heading-normal large yellow>自己紹介_</heading-normal>
          <text-normal class='user__contents__introduction__text'>
            {{ setDescription }}
          </text-normal>
        </div>
        <div class='user__contents__lessons'>
          <div class='user__contents__lessons__title'>
            <heading-normal id='user-lesson' large yellow>投稿レッスン_</heading-normal>
          </div>
          <p
            id='user'
            class='user__contents__lessons__notfound'
            v-if='lessonInfo.totalCount === 0'>
            投稿したレッスンはありません
          </p>
          <ul v-if='lessonInfo.totalCount !== 0'>
            <li
              class='user__contents__lessons__postinfo'
              v-for='info in lessonInfo.list[pagerInfo.count - 1]'
              :key='info.id'>
              <lesson-card-wide
                :lessonInfo='info'
                :levelLabel='levelLabel'
                hideAuthor
              />
            </li>
          </ul>
          <pager-normal
            class='user__contents__lessons__pager'
            v-show='pagerInfo.maxPage !== 1'
            :totalCount='lessonInfo.totalCount'
            :display='pagerInfo.limit'
            @emit='setPagerCount($event)'/>
        </div>
      </div>
    <layout-footer />
  </div>
</template>

<script>
import helper from '@/mixins/general/methods/helper';
import { LayoutHeader, LayoutFooter } from '@/components/layouts/general/';
import { HeadingNormal } from '@/components/atoms/heading/';
import { LessonCardWide } from '@/components/molecules/other/';
import TextNormal from '@/components/atoms/text/TextNormal';
import SectionUserInfo from '@/components/molecules/general/user/SectionUserInfo';
import { PagerNormal } from '@/components/atoms/other/';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    HeadingNormal,
    SectionUserInfo,
    TextNormal,
    LessonCardWide,
    PagerNormal,
  },
  data() {
    return {
      isLoading: false,
      levelLabel: ['初心者', '中級者', '上級者'],
      userInfo: {
        introduction: '',
        userName: '',
        avatar: '',
      },
      lessonInfo: {
        totalCount: 0,
        list: [],
      },
      pagerInfo: {
        maxPage: 1,
        limit: 10,
        count: 1,
      },
    };
  },
  mixins: [helper],
  computed: {
    setDescription() {
      if (!this.userInfo.introduction) return '自己紹介文はありません';
      return this.userInfo.introduction;
    },
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    scrollTo(id) {
      const elem = document.getElementById(id);
      this.$scrollTo(elem, 1);
    },
    async getUserInfo() {
      try {
        this.isLoading = !this.isLoading;
        const res = await this.$http.get(`/api/users/${this.$route.params.id}`);
        if (res.data.errors && res.data.errors.id[0] === '選択されたidは正しくありません。') throw new Error(404);

        // ユーザー情報保存
        this.userInfo.introduction = await res.data.introduction;
        this.userInfo.avatar = await res.data.avatar;
        this.userInfo.userName = await res.data.username;
        this.userInfo.ambassador = await res.data.ambassador;

        // 投稿済みレッスン保存
        this.lessonInfo.totalCount = await res.data.lessons.length;
        const allPostLesson = await res.data.lessons.map((lesson) => ({
          author: lesson.username,
          description: lesson.description,
          evaluation: lesson.evaluation,
          id: lesson.id,
          image: lesson.thumbnail,
          level: lesson.level,
          price: lesson.price,
          title: lesson.title,
          updatedDate: lesson.updated_at,
        }));
        await this.calculateMaxPage(allPostLesson.length);
        await this.pushLessonList(allPostLesson);
        this.isLoading = !this.isLoading;
      } catch (e) {
        if (e.message === '404' || e.response.status === 404) {
          this.$router.push({ path: '/notfound' });
        } else {
          this.movePage('Error');
        }
      }
    },
    calculateMaxPage(length) {
      const caluculateResult = Math.ceil(length / this.pagerInfo.limit);
      if (caluculateResult !== 0) this.pagerInfo.maxPage = caluculateResult;
    },
    pushLessonList(allLesson) {
      this.lessonInfo.list = [];
      for (let i = 0; i < this.pagerInfo.maxPage; i += 1) {
        this.lessonInfo.list.push(
          allLesson.slice(
            i * this.pagerInfo.limit, (i + 1) * this.pagerInfo.limit,
          ),
        );
      }
    },
    setPagerCount(count) {
      this.pagerInfo.count = count;
      this.scrollTo('user-lesson');
    },
  },
};
</script>

<style scoped>
.user {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.user__contents {
  margin-top: 80px;
  margin-bottom: 80px;
}

.user__contents__lessons {
  margin-top: 40px;
}

.user__contents__lessons__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user__contents__lessons__title p {
  font-weight: bold;
}

.user__contents__lessons__postinfo {
  padding: 20px 0;
  border-bottom: 1px solid #F2EEEE;
  list-style: none;
}

.user__contents__lessons__postinfo:first-child {
  padding-top: 0;
}

.user__contents__lessons__notfound {
  background-color: white;
  text-align: center;
  font-size: 18px;
  padding: 30px ;
}

.user__contents__lessons__pager {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
</style>
