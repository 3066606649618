<template>
  <div class='button-stripe'>
    <button class='button-stripe__front' @click='$emit("emit")'>
      <slot/>
      <i class='fas fa-chevron-right button-stripe__front__arrow' v-if='arrow' />
    </button>
    <img class='button-stripe__back' src='@/assets/img/lessoncard-button-background.svg' alt=''>
  </div>
</template>

<script>
export default {
  props: {
    arrow: Boolean,
  },
};
</script>

<style scoped>
.button-stripe__front {
  position: absolute;
  top: 45%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #FDDA33;
  color: #fff;
  font-size: 16px;
  width: 160px;
  height: 43.7px;
  transition: all 0.3s ease;
}

.button-stripe__front__arrow {
  margin-left: 3px;
}

.button-stripe__back {
  width: 160px;
}
</style>
