/**
 *  user.js
 *  ユーザー個人の情報を管理する
 */

import axios from 'axios';

const axiosBase = axios.create({
  baseURL: process.env.VUE_APP_HOST,
  headers: {
    'Content-Type': 'application/json',
  },
  credentials: true,
  responseType: 'json',
});

export default {
  namespaced: true,
  state: {
    userInfo: {
      login: false,
      platformId: [],
      cart: [],
      checkoutLesson: [],
      token: '',
      profile: {
        id: '',
        customerId: '',
        userName: '',
        icon: '',
        introduction: '',
        countLearn: 0,
        countClear: 0,
      },
      lesson: {
        purchase: [],
        post: [],
        complete: [],
      },
    },
  },
  mutations: {
    login(state, auth) {
      const user = state;
      user.userInfo.login = true;
      user.userInfo.token = auth.data.token;
      user.userInfo.profile.customerId = auth.data.user.stripe_customer_id;
      user.userInfo.cart = auth.data.cart.lessons;
      user.userInfo.profile.id = auth.data.user.id;
      user.userInfo.profile.userName = auth.data.user.name;
      user.userInfo.profile.avatar = auth.data.user.avatar;
      user.userInfo.profile.introduction = auth.data.user.introduction;
    },
    logout(state) {
      const user = state;
      user.userInfo = {
        login: false,
        platformId: [],
        cart: [],
        checkoutLesson: [],
        token: '',
        profile: {
          id: '',
          userName: '',
          avatar: '',
          introduction: '',
          countLearn: 0,
          countClear: 0,
        },
        lesson: {
          purchase: [],
          post: [],
          complete: [],
        },
      };
    },
    // 学習日数,クリアレッスン数を保存
    saveCount(state, count) {
      const user = state;
      user.userInfo.profile.countLearn = count.learn;
      user.userInfo.profile.countClear = count.clear;
    },
    // プロフィール更新情報を保存
    saveProfile(state, profile) {
      const user = state;
      const date = new Date();
      const addDate = `${profile.avatar}?${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getSeconds()}`;
      user.userInfo.profile.avatar = addDate;
      user.userInfo.profile.userName = profile.userName;
      user.userInfo.profile.introduction = profile.introduction;
    },
    // chapterContinueを保存
    saveContinue(state, continueInfo) {
      const user = state;
      const index = user.userInfo.lesson.purchase
        .findIndex((lesson) => lesson.id === continueInfo.lessonId);
      user.userInfo.lesson.purchase[index].inProgress.continue = continueInfo.chapterId;
    },
    saveCart(state, lessonInfo) {
      const user = state;
      user.userInfo.cart.push(lessonInfo);
    },
    // 購入済みレッスン情報の保存
    savePurchaseLesson(state, lessonInfo) {
      const user = state;
      user.userInfo.lesson.purchase = [];
      lessonInfo.forEach((lesson) => {
        user.userInfo.lesson.purchase.push(lesson);
      });
    },
    // 投稿済みレッスンの保存
    savePostLesson(state, lessonInfo) {
      const user = state;
      user.userInfo.lesson.post = [];
      lessonInfo.forEach((lesson) => {
        user.userInfo.lesson.post.push(lesson);
      });
    },
    saveNewPostLesson(state, lessonInfo) {
      const user = state;
      user.userInfo.lesson.post.unshift(lessonInfo);
    },
    saveUpdatePostLesson(state, lessonInfo) {
      const user = state;
      const targetIndex = user.userInfo.lesson.post
        .findIndex((lesson) => lesson.id === lessonInfo.id);
      user.userInfo.lesson.post.splice(targetIndex, 1, lessonInfo);
    },
    saveDeletePostLesson(state, lessonId) {
      const user = state;
      const targetIndex = user.userInfo.lesson.post
        .findIndex((lesson) => lesson.id === lessonId);
      user.userInfo.lesson.post.splice(targetIndex, 1);
    },
    saveDeleteCart(state, lessonId) {
      const user = state;
      const targetIndex = user.userInfo.cart
        .findIndex((lessonInfo) => lessonInfo.id === lessonId);
      user.userInfo.cart.splice(targetIndex, 1);
    },
    saveResetCart(state) {
      const user = state;
      user.userInfo.cart = [];
    },
    saveCheckoutLesson(state, checkoutLesson) {
      const user = state;
      user.userInfo.checkoutLesson = checkoutLesson;
    },
    saveResetCheckoutLesson(state) {
      const user = state;
      user.userInfo.checkoutLesson = [];
    },
  },
  actions: {
    // ログイン処理
    async checkAuth(context, authInfo) {
      try {
        const user = context;
        const res = await axiosBase.get(`/api/login/${authInfo.provider}/callback`, { params: authInfo.payload });
        await user.commit('login', res);
        return true;
      } catch (e) {
        if (authInfo.provider === 'twitter'
          && e.response.data !== null
          && e.response.data.error === 'twitterアカウントにEmailがありませんでした') {
          return e.response.data.error;
        }
        if (e.response.data !== null
          && e.response.data.error === 'このユーザは停止されています') {
          return e.response.data.error;
        }

        return false;
      }
    },
    // 学習日数、クリアレッスン数を取得
    async loadCount(context) {
      try {
        const user = context;
        axiosBase.defaults.headers.common.Authorization = `Bearer ${user.state.userInfo.token}`;
        const res = await axiosBase.get('/api/auth/count');
        await user.commit('saveCount', res.data);
        return true;
      } catch (e) {
        if (e.response.status === 401) return e.response.status;
        return false;
      }
    },
    // ユーザープロフィールを更新する
    async updateProfile(context, updateProfile) {
      try {
        const user = context;

        // 更新データ整理
        const data = {};
        if (updateProfile.avatar !== null) data.avatar = updateProfile.avatar;
        if (updateProfile.userName !== null) data.username = updateProfile.userName;
        if (updateProfile.introduction !== null) data.introduction = updateProfile.introduction;

        // 更新データ送信
        axiosBase.defaults.headers.common.Authorization = `Bearer ${user.state.userInfo.token}`;
        const res = await axiosBase.put('/api/auth/user', data);

        // Stateのプロフィールを更新
        const profile = await {
          userName: res.data.name,
          avatar: res.data.avatar,
          introduction: res.data.introduction,
        };
        await user.commit('saveProfile', profile);
        return true;
      } catch (e) {
        if (e.response.status === 401) return e.response.status;
        return false;
      }
    },
    // 画面離脱前最後に滞在したChapterIdを送信
    async submitContinue(context, continueInfo) {
      try {
        const user = context;
        axiosBase.defaults.headers.common.Authorization = `Bearer ${user.state.userInfo.token}`;
        await axiosBase.put(`/api/auth/lessons/progress/${continueInfo.lessonId}`, { continue: continueInfo.chapterId });
        await user.commit('saveContinue', continueInfo);
        return true;
      } catch (e) {
        if (e.response.status === 401) return e.response.status;
        return false;
      }
    },
    // 購入済みレッスンの読み込み
    async loadPurchaseLesson(context) {
      try {
        const user = context;
        axiosBase.defaults.headers.common.Authorization = `Bearer ${user.state.userInfo.token}`;
        const res = await axiosBase.get('/api/auth/lessons/buy');
        const purchaseLessonList = await res.data.map((lesson) => ({
          id: lesson.id,
          title: lesson.title,
          image: lesson.thumbnail,
          chapter: lesson.chapter,
          author: lesson.author,
          avatar: lesson.author_avatar,
          updatedDate: lesson.updated_at,
          description: lesson.description,
          level: lesson.level,
          inProgress: {
            continue: lesson.continue,
            completeChapter: lesson.clearChapter,
            progressRate: lesson.progress,
          },
        }));
        await user.commit('savePurchaseLesson', purchaseLessonList);
        return true;
      } catch (e) {
        if (e.response.status === 401) return e.response.status;
        return false;
      }
    },
    // 投稿済みレッスンの読み込み
    async loadPostLesson(context) {
      try {
        const user = context;
        axiosBase.defaults.headers.common.Authorization = `Bearer ${user.state.userInfo.token}`;
        const res = await axiosBase.get('api/creator/lessons');
        const postLessonList = res.data;
        postLessonList.sort((a, b) => (a.updated_at < b.updated_at ? 1 : -1));
        await user.commit('savePostLesson', postLessonList);
        return true;
      } catch (e) {
        if (e.response.status === 401) return e.response.status;
        return false;
      }
    },
    // 新規で投稿したレッスンの追加
    async addNewPostLesson(context, lessonInfo) {
      const user = context;
      user.commit('saveNewPostLesson', lessonInfo);
    },
    // 投稿したレッスンの更新
    async updatePostLesson(context, lessonInfo) {
      const user = context;
      user.commit('saveUpdatePostLesson', lessonInfo);
    },
    // 自身が投稿したレッスン情報を削除
    async deletePostLesson(context, lessonId) {
      const user = context;
      user.commit('saveDeletePostLesson', lessonId);
    },
    // カート情報の削除
    async deleteCart(context, lessonId) {
      const user = context;
      axiosBase.defaults.headers.common.Authorization = `Bearer ${user.state.userInfo.token}`;
      await axiosBase.delete('api/auth/cart', {
        params: {
          lesson_id: lessonId,
        },
      });
      user.commit('saveDeleteCart', lessonId);
    },
  },
};
