<template>
  <p class='text-normal'><slot /></p>
</template>

<script>
export default {
};
</script>

<style scoped>
.text-normal {
  font-size: 16px;
  font-weight: 300;
  width: 100%;
  word-break: break-all;
  white-space: pre-wrap;
}
</style>
