<template>
  <div class='cart-checkout'>
    <layout-header />
    <heading-title>
      <template v-slot:main>
        <p>Checkout</p>
      </template>
      <template v-slot:sub>
        <p>決済_</p>
      </template>
    </heading-title>
    <section class='cart-checkout__main center'>
      <div class='cart-checkout__main__left'>
        <heading-normal>クレジットカード情報</heading-normal>
        <div class='cart-checkout__main__left__card' v-show='!isLoading.loadCard'>
          <div class='cart-checkout__main__left__card__form'>
            <div class='cart-checkout__main__left__card__form__input' v-show='!hasCardInfo'>
              <div id='card-element' />
              <div id='card-errors' role='alert' />
            </div>
            <ul class='cart-checkout__main__left__card__info' v-show='hasCardInfo'>
              <li>ブランド: {{ cardInfo.brand }}</li>
              <li>期限: {{ cardInfo.exp }}</li>
              <li>下4桁: {{ cardInfo.last4 }}</li>
            </ul>
            <button-normal
              class='cart-checkout__main__left__card__form__regist'
              :disabled='!isVaild'
              :loading='isLoading.registCard'
              v-if='!hasCardInfo'
              @emit='getTokenCardInfo'
            >登録する</button-normal>
          </div>
        </div>
        <div v-show='isLoading.loadCard'>
          <icon-loading />
        </div>
        <div class='cart-checkout__main__left__lesson'>
          <heading-normal>選択レッスン</heading-normal>
          <ul class='cart-checkout__main__left__lesson__select'>
            <li
              class='cart-checkout__main__left__lesson__select__list'
              v-for='lessonInfo in userInfo.cart'
              :key='lessonInfo.id'
            >
              <img
                class='cart-checkout__main__left__lesson__select__list__left'
                :src='lessonInfo.thumbnail'
                @error='setErrorThumbnail'
              >
              <div class='cart-checkout__main__left__lesson__select__list__right'>
                <h3>{{ lessonInfo.title }}</h3>
                <p>¥{{ lessonInfo.price }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class='cart-checkout__main__right'>
        <heading-normal>注文内容</heading-normal>
        <div class='cart-checkout__main__right__top'>
          <div class='cart-checkout__main__right__top__subtotal'>
            <p>小計</p>
            <p>¥{{ setTotalPrice }}</p>
          </div>
          <div class='cart-checkout__main__right__top__total'>
            <p>ご請求額</p>
            <p>¥{{ setTotalPrice }}</p>
          </div>
        </div>
        <div class='cart-checkout__main__right__bottom'>
          <div class='cart-checkout__main__right__bottom__description'>
            <p>お支払いを完了することにより、</p>
            <a
              class='cart-checkout__main__right__bottom__description__link'
              :href='getRouteData("Terms")'
              target='_blank'
              rel='noopener noreferrer'
            >利用規約</a>
            <p>・</p>
            <a
              class='cart-checkout__main__right__bottom__description__link'
              :href='getRouteData("PrivacyPolicy")'
              target='_blank'
              rel='noopener noreferrer'
            >プライバシーポリシー</a>
            <p>に同意したとみなします。</p>
          </div>
          <button-normal
            class='cart-checkout__main__right__bottom__button'
            large
            :loading='isLoading.checkout'
            :disabled='!isCheckout && !hasCardInfo'
            @emit='execCheckout()'
          >支払いを完了する</button-normal>
        </div>
      </div>
    </section>
    <layout-footer />
  </div>
</template>

<script>
import helper from '@/mixins/general/methods/helper';
import { loadStripe } from '@stripe/stripe-js';
import { mapState, mapActions } from 'vuex';
import { LayoutHeader, LayoutFooter } from '@/components/layouts/general/';
import { HeadingTitle, HeadingNormal } from '@/components/atoms/heading';
import { IconLoading } from '@/components/atoms/icon';
import { ButtonNormal } from '@/components/atoms/button';

export default {
  mixins: [helper],
  components: {
    LayoutHeader,
    LayoutFooter,
    HeadingTitle,
    HeadingNormal,
    ButtonNormal,
    IconLoading,
  },
  data() {
    return {
      isVaild: false,
      isCheckout: false,
      isLoading: {
        checkout: false,
        registCard: false,
        loadCard: false,
      },
      cardInfo: {
        brand: null,
        exp: null,
        last4: null,
      },
      stripe: null,
      cardElement: null,
    };
  },
  computed: {
    ...mapState('user', ['userInfo']),
    setTotalCount() {
      return this.userInfo.cart.totlaCount;
    },
    setTotalPrice() {
      const priceList = this.userInfo.cart.map((lesson) => lesson.price);
      if (priceList.length !== 0) {
        const totalPrice = priceList.reduce((a, x) => a + x);
        return totalPrice;
      }
      return '';
    },
    hasCardInfo() {
      if (this.cardInfo.brand !== null
          && this.cardInfo.exp !== null
          && this.cardInfo.last4 !== null) {
        return true;
      }
      return false;
    },
  },
  async created() {
    window.addEventListener('beforeunload', this.reloadHandler);
    const res = await this.retrieveCardtoCustomer();
    if (res) {
      this.cardInfo.brand = res.brand;
      this.cardInfo.exp = res.exp;
      this.cardInfo.last4 = res.last4;
    }
  },
  async mounted() {
    // カード入力エレメント生成・testKey利用
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
    const elements = this.stripe.elements();
    this.cardElement = elements.create('card', { hidePostalCode: true });
    this.cardElement.mount('#card-element');

    this.cardElement.addEventListener('change', (event) => {
      this.isVaild = event.complete;
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  },
  methods: {
    ...mapActions('user', ['deleteCart', 'loadPurchaseLesson']),
    loading(target) {
      this.isLoading[target] = !this.isLoading[target];
    },
    setErrorThumbnail(e) {
      e.target.src = `${process.env.VUE_APP_S3_URL}/image/thumbnail/manabiba.png`;
    },
    getRouteData(name) {
      return this.$router.resolve({ name }).href;
    },
    async getTokenCardInfo() {
      this.loading('registCard');
      this.stripe.createToken(this.cardElement).then((result) => {
        if (result.error) {
          // エラー表示.
          const errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
          this.loading('registCard');
        } else {
          // トークンをサーバに送信
          this.isCheckout = result.token;
          this.setCardtoCustomer(result.token);
        }
      });
    },
    async setCardtoCustomer(token) {
      try {
        this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
        const { data } = await this.$http.post('/api/auth/user/customer/card', {
          token: JSON.stringify(token.id),
        });
        this.cardInfo.brand = data.brand;
        this.cardInfo.exp = data.exp;
        this.cardInfo.last4 = data.last4;
        this.showNoticeBar('success', 'カード情報の登録を完了しました');
        this.loading('registCard');
      } catch (e) {
        this.loading('registCard');
        if (e.response.status === 401) {
          this.$store.commit('user/logout');
          this.movePage('Login', '', { url: this.$route.path });
        }
        this.showNoticeBar('error', 'カード情報の登録に失敗しました');
      }
    },
    async execCheckout() {
      try {
        this.loading('checkout');
        // 購入済みレッスンをフォーマット整形
        const checkoutLesson = this.userInfo.cart.map((lessonInfo) => ({
          id: lessonInfo.id,
          title: lessonInfo.title,
          description: lessonInfo.description,
          evaluation: lessonInfo.evaluation,
          image: lessonInfo.thumbnail,
          level: lessonInfo.level,
          price: lessonInfo.price,
          updatedDate: lessonInfo.updated_at,
          author: lessonInfo.author,
          avatar: lessonInfo.author_avatar,
        }));
        // レッスン購入処理
        const checkoutLessonIdList = checkoutLesson.map((lesson) => lesson.id);
        await this.buyLesson(checkoutLessonIdList);

        // 購入済みレッスン読み込み
        const res = await this.loadPurchaseLesson();
        if (res === false) throw new Error('購入済みレッスン読み込み失敗');

        // cartは空に
        this.$store.commit('user/saveResetCart');

        // vuexに保存
        this.$store.commit('user/saveCheckoutLesson', checkoutLesson);
        this.loading('checkout');
        this.movePage('CartCheckoutComplete');
      } catch (e) {
        this.loading('checkout');
        this.showNoticeBar('error', 'レッスンの購入に失敗しました');
      }
    },
    async buyLesson(id) {
      this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
      await this.$http.post('/api/auth/lessons/buy', { lesson_ids: id });
    },
    async retrieveCardtoCustomer() {
      try {
        this.loading('loadCard');
        this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
        const { data } = await this.$http.get('/api/auth/user/customer/card');
        this.loading('loadCard');

        return data;
      } catch (e) {
        this.loading('loadCard');
        if (e.response.status === 401) return e.response.status;
        return false;
      }
    },
    reloadHandler(e) {
      const event = e;
      event.returnValue = 'リロードすると決済情報が保存されない場合があります';
    },
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.reloadHandler);
  },
};
</script>

<style scoped>
.cart-checkout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.cart-checkout__main {
  margin: 80px auto;
  display: flex;
  justify-content: space-between;
}

.cart-checkout__main__left {
  width: 60%;
  margin-right: 40px;
}

.cart-checkout__main__left__card {
  margin-bottom: 80px;
}

.cart-checkout__main__left__card__info {
  list-style: none;
  display: flex;
  justify-content: left;
  background-color: #F9F9FA;
  padding: 10px;
  font-size: 14px;
}

.cart-checkout__main__left__card__info li {
  margin-left: 20px;
}

.cart-checkout__main__left__card__info li:first-child {
  margin-left: 0;
}

.cart-checkout__main__left__card__form {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.cart-checkout__main__left__card__form__input {
  width: 400px;
  background-color: #F2EEEE;
  padding: 20px;
  border-radius: 8px;
  font-size: 12px;
  margin-right: 20px;
}

.cart-checkout__main__left__lesson__select {
  list-style: none;
}

.cart-checkout__main__left__lesson__select__list {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #F2EEEE;
}

.cart-checkout__main__left__lesson__select__list__left {
  width: 120px;
  height: 67px;
  margin-right: 10px;
}

.cart-checkout__main__left__lesson__select__list__right h3 {
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
}

.cart-checkout__main__left__lesson__select__list__right p {
  font-size: 16px;
  font-weight: 600;
}

.cart-checkout__main__right {
  width: 40%;
  margin-left: 40px;
  border: 3px solid #F2EEEE;
  border-radius: 12px;
  height: 100%;
  padding: 20px;
}

.cart-checkout__main__right__top__subtotal {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #F2EEEE;
  padding: 20px 0;
}

.cart-checkout__main__right__top__total {
  font-size: 16px;
  font-weight: 600;
  color: #E74860;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.cart-checkout__main__right__bottom {
  display: flex;
  flex-direction: column;
}

.cart-checkout__main__right__bottom__description {
  display: flex;
  font-size: 11px;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.cart-checkout__main__right__bottom__description__link {
  color: #E74860;
}

.cart-checkout__main__right__bottom__button {
  margin: 0 auto;
}
</style>
