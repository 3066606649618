<template>
  <button :class='addClass' :disabled='disabled||loading' @click='$emit("emit")'>
    <slot v-if='!loading' />
    <vue-loading v-if='loading' :type='loadIcon.type' :size='loadIcon.size' />
  </button>
</template>

<script>
import { VueLoading } from 'vue-loading-template';

export default {
  props: {
    // カラー系
    white: Boolean,
    // サイズ系
    small: Boolean,
    large: Boolean,
    // 表示系
    outline: Boolean,
    disabled: Boolean,
    loading: Boolean,
  },
  components: {
    VueLoading,
  },
  data() {
    return {
      loadIcon: {
        type: 'spin',
        size: { width: '20px', height: '20px' },
      },
    };
  },
  computed: {
    addClass() {
      return {
        'button-normal': true,
        'button-normal--white': this.white,
        'button-normal--small': this.small,
        'button-normal--large': this.large,
        'button-normal--outline': this.outline,
        'button-normal--disabled': this.disabled,
      };
    },
  },
};
</script>

<style scoped>
.button-normal {
  position: relative;
  min-width: 95px;
  padding: 0 10px;
  height: 30px;
  transition: 0.3s;
  color: #3c3f4c;
  border: 2px solid #4CAF50;
  border-radius: 20px;
  background-color: #4CAF50;
  font-size: 12px;
  font-weight: 500;
}

.button-normal:hover {
  background-color: #ffda22;
}

/* カラー系 */
.button-normal--white {
  color: #3c3f4c;
  border: 2px solid #fff;
  background-color: #fff;
}

.button-normal--white:hover {
  background-color: rgb(245, 245, 245);
}

/* サイズ系 */
.button-normal--small {
  min-width: 70px;
  font-size: 12px;
}

.button-normal--large {
  min-width: 150px;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
}

/* 表示系 */
.button-normal--outline {
  transition: 0.3s;
  background-color: rgba(19, 53, 202, 0);
}

.button-normal--outline:hover {
  background-color: #fdd3001f;
}

.button-normal--outline.button-normal--white {
  color: #fff;
  border: 2px solid #fff;
}

.button-normal--outline.button-normal--white:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.button-normal--disabled {
  color: #CBCBCB;
  border: 2px solid #CBCBCB;
  background-color: #fff;
}

.button-normal--disabled:hover {
  background-color: #fff;
}
</style>
