<template>
  <div class="login">
    <notifications group='top-right' />
    <form-login
      :form='title.form'
      :userName='title.userName'
      :password='title.password'
      :isLoading="isLoading"
      @emit-login="login"
    />
    <modal-check-secret
      v-if='switchModal'
      title='2段階認証を行います'
      text='Authenticatorに表示された6桁の数字を入力してください'
      acceptBtn='2段階認証'
      cancelBtn='戻る'
      @emit='loginComplete'
      disabledOverlay
    />
  </div>
</template>

<script>
import cryptoJS from 'crypto-js';
import speakeasy from 'speakeasy';
import { mapActions, mapState } from 'vuex';
import helper from '@/mixins/admin/methods/helper';
import { ModalCheckSecret } from '@/components/molecules/other';
import FormLogin from '@/components/molecules/admin/login/FormLogin';

export default {
  components: {
    ModalCheckSecret,
    FormLogin,
  },
  data() {
    return {
      switchModal: false,
      adminData: {},
      title: {
        form: 'manabiba Admin ログイン',
        userName: 'アカウント名',
        password: 'パスワード',
      },
      isLoading: false,
    };
  },
  mixins: [helper],
  computed: {
    ...mapState('admin', ['adminInfo']),
  },
  methods: {
    ...mapActions('admin', ['checkAdmin', 'setAdmin']),
    switch2FAModal() {
      this.switchModal = !this.switchModal;
    },
    async login(loginInfo) {
      try {
        this.isLoading = !this.isLoading;
        const { data } = await this.checkAdmin(loginInfo);
        if (data === undefined) throw new Error();
        this.isLoading = !this.isLoading;

        this.adminData = data;
        if (this.adminData) {
          this.switch2FAModal();
        }
      } catch (e) {
        this.isLoading = !this.isLoading;
        this.showNoticeBar('error', 'ログインに失敗しました');
      }
    },
    async loginComplete(res) {
      try {
        if (res.answer) {
          // 暗号化されたsecretKeyをhex形式に戻す
          const decrypted = cryptoJS.AES.decrypt(
            this.adminData.secret,
            process.env.VUE_APP_CRYPTION_KEY,
          );
          const decryptedStrings = decrypted.toString(cryptoJS.enc.Utf8);
          const verified = speakeasy.totp.verify({
            secret: decryptedStrings,
            encoding: 'hex',
            token: res.token,
          });
          if (verified) {
            await this.setAdmin(this.adminData);
            this.movePage('AdminLessons');
          } else {
            throw new Error();
          }
        } else {
          this.switch2FAModal();
        }
      } catch (e) {
        this.switch2FAModal();
        this.showNoticeBar('error', '2段階認証に失敗しました');
      }
    },
  },
  created() {
    if (this.adminInfo.token) {
      this.movePage('AdminLessons');
    }

    // sphone判定
    const ua = window.navigator.userAgent.toLowerCase();
    if ((ua.indexOf('iphone') !== -1 || ua.indexOf('ipad') !== -1 || (ua.indexOf('macintosh') && 'ontouchend' in document))
      || ua.indexOf('android') !== -1) {
      this.movePage('Sphone');
    }
  },
};
</script>

<style scoped>
.login {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f9fa;
}
</style>
