<template>
  <div class='not-found'>
    <layout-header class='not-found__header'/>
    <div  class='not-found__contents'>
      <h3 class='not-found__contents__title'>{{ title }}</h3>
      <p class='not-found__contents__text'>{{ text }}</p>
    </div>
    <img class='not-found__background' src='@/assets/img/peach.svg' alt='桃 peach ロゴ'>
    <layout-footer class='not-found__footer' hidePageUp simpleFooter/>
  </div>
</template>

<script>
import { LayoutHeader, LayoutFooter } from '@/components/layouts/general/';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
  },
  data() {
    return {
      title: 'NOT FOUND',
      text: '該当のページは見つかりませんでした',
    };
  },
};
</script>

<style scoped>
.not-found {
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: 100vh;
  background-color: #4CAF50;
  overflow: hidden;
}

.not-found__header {
  z-index: 2;
}
.not-found__contents {
  position: absolute;
  top: 30%;
  left: 15%;
  color: #fff;
  z-index: 2;
}
.not-found__contents__title {
  font-size : 70px;
  font-weight: 500;
  font-family: 'Tahoma', 'Meiryo';
}

.not-found__contents__text {
  font-size: 20px;
  font-weight: 500;
  margin-top: 5px;
}

.not-found__background {
  z-index: 1;
  width: 880px;
  position: absolute;
  bottom: -60px;
  right: -120px;
  opacity: 0.8;
}

.not-found__footer {
  z-index: 2;
}
</style>
