<template>
  <h3 :class='addClass'>
    <slot />
  </h3>
</template>

<script>
export default {
  props: {
    // サイズ系
    small: Boolean,
    large: Boolean,
    title: Boolean,
    // カラー系
    white: Boolean,
    yellow: Boolean,
  },
  computed: {
    addClass() {
      return {
        'heading-normal': true,
        'heading-normal--small': this.small,
        'heading-normal--large': this.large,
        'heading-normal--title': this.title,
        'heading-normal--white': this.white,
        'heading-normal--yellow': this.yellow,
      };
    },
  },
};
</script>

<style scoped>
.heading-normal {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}

.heading-normal--small {
  font-size: 16px;
  font-weight: 400;
}

.heading-normal--large {
  font-size: 24px;
  font-weight: 500;
}

.heading-normal--title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
}

.heading-normal--white {
  color: #fff;
}

.heading-normal--yellow {
  color: #4CAF50;
}
</style>
