<template>
  <div class='list'>
    <LayoutHeader />
    <div class='list__contents'>
      <list-menu
        class='list__contents__left'
        :targetList='createTitleList'
        :defaultSelectId=2
        @emit='movePageMenu'
      />
      <div class='list__contents__right'>
        <title-normal
          class='list__contents__right__title'
          :title='titleBar.title'
          :button='titleBar.button'
          :count='titleBar.count'
          :label='titleBar.label'
          @emit='movePage("AdminCmsCreate")'
        />
        <icon-loading v-if='isLoading' blue />
        <table-normal
          v-if='!isLoading'
          type='detail'
          :headingList='table.headingList'
          :rows='table.rows'
          :rowButton='table.rowButton'
          @emit='rowButtonEvent'
        />
        <modal-normal
          v-if = 'isActive'
          :title = 'modalText.title'
          :text = 'modalText.text'
          :cancelBtn = 'modalText.cancelBtn'
          :acceptBtn = 'modalText.acceptBtn'
          @emit='modalEvent'>
        </modal-normal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import helper from '@/mixins/admin/methods/helper';
import pageList from '@/mixins/admin/data/pageList';
import LayoutHeader from '@/components/layouts/admin/LayoutHeader';
import { TitleNormal, TableNormal, ModalNormal } from '@/components/molecules/other';
import { ListMenu } from '@/components/atoms/list';
import { IconLoading } from '@/components/atoms/icon';

export default {
  components: {
    LayoutHeader,
    TitleNormal,
    ListMenu,
    TableNormal,
    ModalNormal,
    IconLoading,
  },
  data() {
    return {
      isActive: false,
      isLoading: false,
      thisId: Number,
      idList: [],
      modalText: {
        title: 'コンテンツ削除',
        text: 'コンテンツを削除すると二度と復元できません。本当に削除してよろしいですか？',
        acceptBtn: '削除する',
        cancelBtn: 'キャンセル',
      },
      titleBar: {
        title: 'コンテンツ管理',
        button: '新規作成',
        count: 0,
        label: ['お知らせ'],
      },
      table: {
        headingList: [
          'ID',
          '種別',
          'タイトル',
          '投稿ユーザー',
          '投稿日',
          '更新日',
          '削除',
          '詳細',
        ],
        rows: [],
        rowButton: ['削除', '詳細'],
      },
    };
  },
  created() {
    this.loadContents();
  },
  mixins: [helper, pageList],
  computed: {
    ...mapState('admin', ['adminInfo']),
    createTitleList() {
      const titleList = this.pageList.map((page) => page.title);
      return titleList;
    },
  },
  methods: {
    rowButtonEvent(event) {
      // コンテンツ詳細画面に遷移
      if (event.btnFlg === 0) {
        this.isActive = !this.isActive;
        this.thisId = event.id;
      }
      if (event.btnFlg === 1) {
        this.movePage('AdminCmsDetail', { id: event.id });
      }
    },
    movePageMenu(index) {
      const { name } = this.pageList[index];
      this.$router.push({ name }, () => {});
    },
    modalEvent(data) {
      if (data === false) {
        this.isActive = data;
      } else {
        this.deleteContents();
      }
    },
    async deleteContents() {
      try {
        this.isActive = false;
        this.$http.defaults.headers.common.Authorization = `Bearer ${this.adminInfo.token}`;
        await this.$http.delete(`api/admin/notices/${this.thisId}`);
        this.table.rows.splice(this.idList.indexOf(this.thisId), 1);
        this.idList.splice(this.idList.indexOf(this.thisId), 1);
        this.titleBar.count -= 1;
        this.showNoticeBar('success', 'コンテンツの削除を完了しました');
      } catch (e) {
        if (e.response !== undefined && e.response.status === 401) {
          this.unAdminAuthenticate();
        } else {
          this.showNoticeBar('error', 'コンテンツの削除に失敗しました');
        }
      }
    },
    async loadContents() {
      try {
        this.isLoading = !this.isLoading;
        this.$http.defaults.headers.common.Authorization = `Bearer ${this.adminInfo.token}`;
        const res = await this.$http.get('api/admin/notices');
        await res.data.forEach((data) => {
          let category = '未定義';
          if (data.notice_category_id === 1) category = 'お知らせ';
          let user;
          if (!data.username) {
            user = '削除済ユーザー';
          } else {
            user = data.username;
          }
          this.table.rows.push({
            id: data.id,
            type: category,
            title: data.title,
            user,
            create: data.created_at,
            update: data.updated_at,
          });
          this.idList.push(data.id);
          this.titleBar.count += 1;
        });
        this.isLoading = !this.isLoading;
      } catch (e) {
        if (e.response !== undefined && e.response.status === 401) {
          this.unAdminAuthenticate();
        } else {
          this.showNoticeBar('error', 'コンテンツの読み込みに失敗しました');
        }
      }
    },
  },
};
</script>

<style scoped>
.list {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f9fa;
}

.list__contents {
  display: flex;
  margin: 20px;
}

.list__contents__left {
  width: 15%;
  background-color: #fff;
  padding: 20px;
}

.list__contents__right {
  width: 85%;
  margin-left: 20px;
}

.list__contents__right__title {
  margin-bottom: 10px;
}
</style>
