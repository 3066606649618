import HeadingList from './HeadingList';
import HeadingNormal from './HeadingNormal';
import HeadingTitle from './HeadingTitle';
import HeadingTop from './HeadingTop';

export {
  HeadingList,
  HeadingNormal,
  HeadingTitle,
  HeadingTop,
};
