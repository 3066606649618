var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list" },
    [
      _c("LayoutHeader"),
      _c(
        "div",
        { staticClass: "list__contents" },
        [
          _c("list-menu", {
            staticClass: "list__contents__left",
            attrs: { targetList: _vm.createTitleList, defaultSelectId: 0 },
            on: { emit: _vm.movePageMenu }
          }),
          _c(
            "div",
            { staticClass: "list__contents__right" },
            [
              _c("title-normal", {
                staticClass: "list__contents__right__title",
                attrs: {
                  title: _vm.titleBar.title,
                  count: _vm.titleBar.count,
                  disabled: !_vm.isLoading,
                  label: _vm.titleBar.label
                },
                on: { "emit-heading": _vm.changeLessonList }
              }),
              !_vm.isLoading
                ? _c("icon-loading", { attrs: { blue: "" } })
                : _vm._e(),
              _vm.isLoading && _vm.judge === 1
                ? _c("table-normal", {
                    attrs: {
                      type: "review",
                      headingList: _vm.table.headingList.nojudge,
                      rows: _vm.table.rows,
                      rowButton: _vm.table.rowButton.release
                    },
                    on: { emit: _vm.active }
                  })
                : _vm._e(),
              _vm.isLoading && _vm.judge !== 1
                ? _c("table-normal", {
                    attrs: {
                      type: "review",
                      headingList: _vm.table.headingList.judge,
                      rows: _vm.table.rows,
                      rowButton: _vm.table.rowButton.judge
                    },
                    on: { emit: _vm.active }
                  })
                : _vm._e(),
              _vm.isActive.delete
                ? _c("modal-normal", {
                    attrs: {
                      title: _vm.modalText.delete.title,
                      text: _vm.modalText.delete.text,
                      cancelBtn: _vm.modalText.delete.cancelBtn,
                      acceptBtn: _vm.modalText.delete.acceptBtn
                    },
                    on: { emit: _vm.modalEvent }
                  })
                : _vm._e(),
              _vm.isActive.failureLesson
                ? _c("modal-input", {
                    attrs: {
                      title: _vm.modalText.failureLesson.title,
                      text: _vm.modalText.failureLesson.text,
                      cancelBtn: _vm.modalText.failureLesson.cancelBtn,
                      acceptBtn: _vm.modalText.failureLesson.acceptBtn,
                      acceptBtnDisabled: _vm.failureReason === "",
                      placeholder: _vm.modalText.failureLesson.placeholder,
                      rows: 6
                    },
                    on: {
                      emit: _vm.modalEvent,
                      emitTextarea: function($event) {
                        return _vm.saveFailureReason($event)
                      }
                    }
                  })
                : _vm._e(),
              _vm.isActive.passLesson
                ? _c("modal-normal", {
                    attrs: {
                      title: _vm.modalText.passLesson.title,
                      text: _vm.modalText.passLesson.text,
                      cancelBtn: _vm.modalText.passLesson.cancelBtn,
                      acceptBtn: _vm.modalText.passLesson.acceptBtn
                    },
                    on: { emit: _vm.modalEvent }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }