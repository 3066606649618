var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "error" },
    [
      _c("layout-header", { staticClass: "error__header" }),
      _c("div", { staticClass: "error__contents" }, [
        _c("h3", { staticClass: "error__contents__title" }, [
          _vm._v(_vm._s(_vm.title))
        ]),
        _c("p", { staticClass: "error__contents__text" }, [
          _vm._v(_vm._s(_vm.text))
        ])
      ]),
      _c("layout-footer", {
        staticClass: "error__footer",
        attrs: { hidePageUp: "", simpleFooter: "" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }