var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "top" },
    [
      _c("layout-header", { staticClass: "top__header" }),
      _c("section-welcome", {
        attrs: {
          username: _vm.userInfo.profile.userName,
          attendLessonList: _vm.filterPurchase,
          welcomeLessonList: _vm.createWelcomeLessonList,
          isLoading: _vm.isLoading.popular
        }
      }),
      _c("main", { staticClass: "top__main" }, [
        _vm.showLessonSection("recommend")
          ? _c(
              "section",
              { staticClass: "top__main__recommend" },
              [
                _c("heading-top", {
                  staticClass: "top__main__recommend__heading",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "main",
                        fn: function() {
                          return [_c("p", [_vm._v("Recommend Lessons")])]
                        },
                        proxy: true
                      },
                      {
                        key: "sub",
                        fn: function() {
                          return [_c("p", [_vm._v("あなたにオススメ")])]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    514090910
                  )
                }),
                _c(
                  "div",
                  { staticClass: "top__main__recommend__lessonlist" },
                  [
                    !_vm.isLoading.recommend &&
                    _vm.lessonInfo.recommend.length > 4
                      ? _c("button-arrow", {
                          staticClass: "top__main__recommend__lessonlist__prev",
                          attrs: { direction: "left" },
                          on: {
                            emit: function($event) {
                              return _vm.moveCarousel("prev", "recommend")
                            }
                          }
                        })
                      : _vm._e(),
                    !_vm.isLoading.recommend &&
                    _vm.lessonInfo.recommend.length !== 0
                      ? _c(
                          "vue-slick-carousel",
                          _vm._b(
                            {
                              ref: "recommend",
                              staticClass:
                                "top__main__recommend__lessonlist__carousel"
                            },
                            "vue-slick-carousel",
                            _vm.carouselRecommend.setting,
                            false
                          ),
                          _vm._l(_vm.lessonInfo.recommend, function(info) {
                            return _c(
                              "div",
                              { key: info.id },
                              [
                                _c(
                                  "lesson-card",
                                  {
                                    staticClass:
                                      "top__main__recommend__lessonlist__carousel__card",
                                    attrs: {
                                      lessonInfo: info,
                                      levelLabel: _vm.levelLabel
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticClass:
                                        "top__main__recommend__lessonlist__carousel__back",
                                      attrs: {
                                        src: require("@/assets/img/lessoncard-back.svg"),
                                        alt: ""
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    !_vm.isLoading.recommend &&
                    _vm.lessonInfo.recommend.length > 4
                      ? _c("button-arrow", {
                          staticClass: "top__main__recommend__lessonlist__next",
                          attrs: { direction: "right" },
                          on: {
                            emit: function($event) {
                              return _vm.moveCarousel("next", "recommend")
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.isLoading.recommend ? _c("icon-loading") : _vm._e()
                  ],
                  1
                ),
                !_vm.isLoading.recommend
                  ? _c("div", { staticClass: "top__main__recommend__back" })
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _vm.showLessonSection("popular")
          ? _c(
              "section",
              { staticClass: "top__main__popular" },
              [
                _c("heading-top", {
                  staticClass: "top__main__popular__heading",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "main",
                        fn: function() {
                          return [_c("p", [_vm._v("Popular Lessons")])]
                        },
                        proxy: true
                      },
                      {
                        key: "sub",
                        fn: function() {
                          return [_c("p", [_vm._v("人気のレッスン")])]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    1956933214
                  )
                }),
                _c(
                  "div",
                  { staticClass: "top__main__popular__lessonlist" },
                  [
                    !_vm.isLoading.popular && _vm.lessonInfo.popular.length > 5
                      ? _c("button-arrow", {
                          staticClass: "top__main__popular__lessonlist__prev",
                          attrs: { direction: "left" },
                          on: {
                            emit: function($event) {
                              return _vm.moveCarousel("prev", "popular")
                            }
                          }
                        })
                      : _vm._e(),
                    !_vm.isLoading.popular &&
                    _vm.lessonInfo.popular.length !== 0
                      ? _c(
                          "vue-slick-carousel",
                          _vm._b(
                            {
                              ref: "popular",
                              staticClass:
                                "top__main__popular__lessonlist__carousel"
                            },
                            "vue-slick-carousel",
                            _vm.carouselPopular.setting,
                            false
                          ),
                          _vm._l(_vm.lessonInfo.popular, function(info) {
                            return _c(
                              "div",
                              { key: info.id },
                              [
                                _c("lesson-card-popular", {
                                  staticClass:
                                    "top__main__popular__lessonlist__carousel__card",
                                  attrs: {
                                    lessonInfo: info,
                                    levelLabel: _vm.levelLabel
                                  }
                                })
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    !_vm.isLoading.popular && _vm.lessonInfo.popular.length > 5
                      ? _c("button-arrow", {
                          staticClass: "top__main__popular__lessonlist__next",
                          attrs: { direction: "right" },
                          on: {
                            emit: function($event) {
                              return _vm.moveCarousel("next", "popular")
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.isLoading.popular ? _c("icon-loading") : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _c(
          "section",
          { staticClass: "top__main__new", attrs: { id: "lesson-new" } },
          [
            _c(
              "div",
              { staticClass: "top__main__new__heading" },
              [
                _c("heading-top", {
                  scopedSlots: _vm._u([
                    {
                      key: "main",
                      fn: function() {
                        return [_c("p", [_vm._v("New Lessons")])]
                      },
                      proxy: true
                    },
                    {
                      key: "sub",
                      fn: function() {
                        return [_c("p", [_vm._v("新着レッスン")])]
                      },
                      proxy: true
                    }
                  ])
                }),
                _c("button-list", {
                  staticClass: "top__main__new__heading__label",
                  attrs: {
                    title: "難易度選択:",
                    disabled: _vm.isLoading.new,
                    labelList: _vm.headingInfo.new.label
                  },
                  on: { emit: _vm.execLoadLesson }
                })
              ],
              1
            ),
            _c(
              "ul",
              { staticClass: "top__main__new__lessonlist center" },
              _vm._l(_vm.lessonInfo.new, function(info) {
                return _c(
                  "li",
                  {
                    key: info.id,
                    staticClass: "top__main__new__lessonlist__lesson"
                  },
                  [
                    _c("lesson-card-wide", {
                      attrs: { lessonInfo: info, levelLabel: _vm.levelLabel }
                    })
                  ],
                  1
                )
              }),
              0
            ),
            _vm.isLoading.new ? _c("icon-loading") : _vm._e(),
            !_vm.lessonInfo.new.length && !_vm.isLoading.new
              ? _c("p", { staticClass: "top__main__new__notfound" }, [
                  _vm._v(" レッスンが存在しません ")
                ])
              : _vm._e(),
            _c("pager-normal", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showPager(0),
                  expression: "showPager(0)"
                }
              ],
              staticClass: "top__main__new__pager",
              attrs: {
                totalCount: _vm.pagerInfo.count.totalLesson,
                display: _vm.pagerInfo.limit
              },
              on: { emit: _vm.setCount }
            }),
            _c("pager-normal", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showPager(1),
                  expression: "showPager(1)"
                }
              ],
              staticClass: "top__main__new__pager",
              attrs: {
                totalCount: _vm.pagerInfo.count.totalLesson,
                display: _vm.pagerInfo.limit
              },
              on: { emit: _vm.setCount }
            }),
            _c("pager-normal", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showPager(2),
                  expression: "showPager(2)"
                }
              ],
              staticClass: "top__main__new__pager",
              attrs: {
                totalCount: _vm.pagerInfo.count.totalLesson,
                display: _vm.pagerInfo.limit
              },
              on: { emit: _vm.setCount }
            }),
            _c("pager-normal", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showPager(3),
                  expression: "showPager(3)"
                }
              ],
              staticClass: "top__main__new__pager",
              attrs: {
                totalCount: _vm.pagerInfo.count.totalLesson,
                display: _vm.pagerInfo.limit
              },
              on: { emit: _vm.setCount }
            })
          ],
          1
        )
      ]),
      _c("layout-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }