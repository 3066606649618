var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      staticClass: "lesson-card-wide",
      attrs: { to: { name: "LessonDetail", params: { id: _vm.lessonInfo.id } } }
    },
    [
      _c("div", { staticClass: "lesson-card-wide__left" }, [
        _c("img", {
          staticClass: "lesson-card-wide__left__thumbnail",
          attrs: { src: _vm.lessonInfo.image, alt: "レッスンのサムネイル" },
          on: { error: _vm.setErrorThumbnail }
        }),
        !_vm.hideAuthor
          ? _c("div", { staticClass: "lesson-card-wide__left__author" }, [
              _c("img", {
                staticClass: "lesson-card-wide__left__author__avatar",
                attrs: { src: _vm.setAvatar, alt: "avatar ユーザーアイコン" },
                on: { error: _vm.setErrorAvatar }
              }),
              _c("p", { staticClass: "lesson-card-wide__left__author__name" }, [
                _vm._v(_vm._s(_vm.setAuthor))
              ])
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "lesson-card-wide__center" }, [
        _c(
          "div",
          { staticClass: "lesson-card-wide__center__top" },
          [
            _c("icon-level", {
              staticClass: "lesson-card-wide__center__top__level",
              attrs: { level: _vm.lessonInfo.level }
            }),
            _c("h3", [_vm._v(_vm._s(_vm.lessonInfo.title))])
          ],
          1
        ),
        _c("p", { staticClass: "lesson-card-wide__center__bottom" }, [
          _vm._v(" " + _vm._s(_vm.lessonInfo.description) + " ")
        ])
      ]),
      _c(
        "div",
        { staticClass: "lesson-card-wide__right" },
        [
          _c(
            "icon-post-date",
            {
              staticClass: "lesson-card-wide__right__date",
              attrs: { date: _vm.lessonInfo.updatedDate }
            },
            [_vm._v(" UPDATE ")]
          ),
          _c("review-star", { attrs: { score: _vm.lessonInfo.evaluation } }),
          _c("p", { staticClass: "lesson-card-wide__right__price" }, [
            _vm._v(_vm._s(_vm.setPrice))
          ]),
          _c(
            "div",
            { staticClass: "lesson-card-wide__right__bottom" },
            [
              _c(
                "button-stripe",
                {
                  attrs: { arrow: "" },
                  on: {
                    emit: function($event) {
                      return _vm.$emit("lesson-detail", {
                        id: _vm.lessonInfo.id
                      })
                    }
                  }
                },
                [_vm._v("レッスンを見る")]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }