import LayoutFooter from './LayoutFooter';
import LayoutHeader from './LayoutHeader';
import LayoutLogo from './LayoutLogo';
import LayoutLogoPixel from './LayoutLogoPixel';

export {
  LayoutFooter,
  LayoutHeader,
  LayoutLogo,
  LayoutLogoPixel,
};
