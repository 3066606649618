<template>
  <div class='dropdown-user'>
    <router-link class='dropdown-user__userinfo' :to='{ name: "UserInfoSetting"}'>
      <button-user class='dropdown-user__userinfo__icon' :src='userIcon' />
      <p class='dropdown-user__userinfo__text'>{{ userName }}</p>
    </router-link>
    <div class='dropdown-user__lesson'>
      <p class='dropdown-user__lesson__text'>受講中のレッスン</p>
      <ul class='dropdown-user__lesson__list'>
        <router-link class='dropdown-user__lesson__list__content'
          v-for='lesson in purchaseList.slice(0,5)'
          :key='lesson.id'
          :to='{ name: "LessonAttend", params: { id: lesson.id, num: setContinue(lesson.id) }}'
        >
          <img
            class='dropdown-user__lesson__list__content__left'
            :src='lesson.image'
            alt='レッスンのサムネイル'>
          <div class='dropdown-user__lesson__list__content__right'>
            <p class='dropdown-user__lesson__list__content__right__title'>{{ lesson.title }}</p>
            <bar-progress :progress='lesson.inProgress.progressRate' small />
          </div>
        </router-link>
        <li class='dropdown-user__lesson__all' v-if='purchaseList.length > 2'>
          <router-link :to='{ name: "UserInfoLessons" }'>
            すべてのレッスン
          </router-link>
        </li>
      </ul>
      <p class='dropdown-user__lesson__notfound' v-if='purchaseList.length === 0'>受講中のレッスンはありません</p>
    </div>
    <router-link class='dropdown-user__create' :to='{ name: "LessonCreate"}'>
      <i class='fas fa-file-upload' />
      <p>レッスンを投稿</p>
    </router-link>
    <div class='dropdown-user__logout' @click='$emit("emit-logout")'>
      <i class='fas fa-sign-out-alt' />
      <p class='dropdown-user__logout__text'>ログアウト</p>
    </div>
  </div>
</template>

<script>
import BarProgress from '@/components/atoms/other/BarProgress';
import { ButtonUser } from '@/components/atoms/button';

export default {
  components: {
    BarProgress,
    ButtonUser,
  },
  props: {
    userIcon: String,
    userName: String,
    purchaseList: Array,
  },
  methods: {
    setContinue(lessonId) {
      const target = this.purchaseList.filter((lesson) => lesson.id === lessonId)[0];
      return target.inProgress.continue;
    },
  },
};
</script>

<style scoped>
.dropdown-user {
  background-color: #fff;
  position: relative;
  width: 250px;
  padding: 10px;
  box-shadow:  0 8px 10px 4px rgba(0, 0, 0, 0.02);
  z-index: 999;
}

.dropdown-user::after {
  content: "";
  position: absolute;
  right: 0;
  top: -12px;
  left: 190px;
  width: 0px;
  height: 0px;
  margin: auto;
  border-right: 10px solid transparent;
  border-bottom: 16px solid #fff;
  border-left: 10px solid transparent;
}

.dropdown-user__userinfo {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-user__userinfo:hover {
  background-color: #F7F7F7;
}

.dropdown-user__userinfo__icon {
  border: 4px solid #F7F7F7;
  border-radius: 20px;
  margin-right: 10px;
}

.dropdown-user__userinfo__text {
  font-weight: bold;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
}

.dropdown-user__lesson {
  border-top: 1px solid #E1E1E1;
  border-bottom: 1px solid #E1E1E1;
  margin: 10px 0;
  padding: 5px;
}

.dropdown-user__lesson__text {
  font-weight: bold;
  font-size: 12px;
  padding: 5px 5px 0 5px;
}

.dropdown-user__lesson__list {
  list-style: none;
  margin-bottom: 5px;
}

.dropdown-user__lesson__list__content {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

.dropdown-user__lesson__list__content:hover {
  background-color: #F7F7F7;
}

.dropdown-user__lesson__list__content__left {
  width: 60px;
  height: 33.75px;
}

.dropdown-user__lesson__list__content__right {
  width: 100%;
  margin-left: 5px;
  overflow: hidden;
}

.dropdown-user__lesson__list__content__right__title {
  font-size: 8px;
  line-height: 10px;
  display: -webkit-box;
  margin-bottom: 5px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  overflow: hidden;
  word-break: break-all;
}

.dropdown-user__lesson__all {
  padding: 5px;
}

.dropdown-user__lesson__all a {
  font-size: 10px;
  padding: 10px;
  background-color: #4CAF50;
  transition: 0.3s;
  width: 100%;
  font-weight: bold;
  text-align: center;
}

.dropdown-user__lesson__all a:hover {
  background-color: #ffdb2a;
}

.dropdown-user__lesson__notfound {
  font-size: 10px;
  text-align: center;
  margin-bottom: 10px;
}

.dropdown-user__create {
  font-weight: bold;
  padding: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-user__create:hover {
  background-color: #d1d3d936;
}

.dropdown-user__create i {
  font-size: 14px;
  margin-right: 5px;
}

.dropdown-user__create p {
  font-size: 12px;
}

.dropdown-user__logout {
  font-weight: bold;
  padding: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-user__logout:hover {
  background-color: #d1d3d936;
}

.dropdown-user__logout i {
  font-size: 14px;
  margin-right: 5px;
}

.dropdown-user__logout p {
  font-size: 12px;
}
</style>
