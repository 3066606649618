/**
 * material.js
 * 複数ページで共通して用いる資材を管理する
 */
import axios from 'axios';

export default {
  namespaced: true,
  state: {
    maintenance: {
      status: 200,
    },
  },
  mutations: {
    saveMaintenanceStatus(state, status) {
      const { maintenance } = state;
      maintenance.status = status;
    },
  },
  actions: {
    async getMaintenanceStatus(context) {
      const maintenance = context;
      const date = new Date();
      const addDate = `${process.env.VUE_APP_S3_URL}/maintenance/status.json?${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getSeconds()}`;
      const res = await axios.get(addDate);
      maintenance.commit('saveMaintenanceStatus', res.data.status);
    },
  },
};
