var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.addClass }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.emitNumber,
          expression: "emitNumber"
        }
      ],
      attrs: {
        type: "Number",
        max: _vm.maxNumber,
        min: _vm.minNumber,
        step: _vm.step,
        required: ""
      },
      domProps: { value: _vm.emitNumber },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.emitNumber = $event.target.value
        }
      }
    }),
    _vm.errorMsg
      ? _c("p", { staticClass: "input-number__error" }, [
          _vm._v(_vm._s(_vm.errorMsg))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }