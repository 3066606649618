<template>
  <div class='detail'>
    <LayoutHeader />
    <div class='detail__contents'>
      <list-menu
        class='detail__contents__left'
        :targetList='detailTitleList'
        :defaultSelectId=2
        @emit='movePageMenu'
      />
      <div class='detail__contents__right'>
        <title-normal
          class='list__contents__right__top'
          :title='titleBar.title'
          :button='titleBar.button'
          :disabled='!isVaild'
          :loading='isLoading.putDetail'
          @emit='updateContent'
        />
        <icon-loading v-if='isLoading.loadDetail' blue/>
        <div v-if='!isLoading.loadDetail' class='detail__contents__right__bottom'>
          <div class='detail__contents__right__bottom__title'>
            <heading-normal>タイトル</heading-normal>
            <input-text :text='defaultInfo.title' @emit='saveTitle'/>
          </div>
          <div class='detail__contents__right__bottom__type'>
            <heading-normal>種別</heading-normal>
            <select v-model='submitInfo.selectType'>
              <option
                v-for='(type, index) in types'
                :value='index'
                :key='type.id'
                selected
              >{{ type }}</option>
            </select>
          </div>
          <div class='detail__contents__right__bottom__markdown'>
            <heading-normal>本文</heading-normal>
            <vue-simplemde
              v-model='submitInfo.markdown'
              ref='markdownEditor'
              :configs='configs'
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSimplemde from 'vue-simplemde';
import { mapState } from 'vuex';
import helper from '@/mixins/admin/methods/helper';
import pageList from '@/mixins/admin/data/pageList';
import LayoutHeader from '@/components/layouts/admin/LayoutHeader';
import { TitleNormal } from '@/components/molecules/other';
import { ListMenu } from '@/components/atoms/list';
import { InputText } from '@/components/atoms/input';
import { HeadingNormal } from '@/components/atoms/heading';
import { IconLoading } from '@/components/atoms/icon';

export default {
  components: {
    VueSimplemde,
    LayoutHeader,
    TitleNormal,
    ListMenu,
    InputText,
    HeadingNormal,
    IconLoading,
  },
  data() {
    return {
      currentPath: String,
      contentId: String,
      isLoading: {
        loadDetail: false,
        putDetail: false,
      },
      titleBar: {
        title: 'コンテンツ詳細',
        button: '更新',
      },
      types: ['お知らせ'],
      configs: {
        status: ['autosave', 'lines', 'words', 'cursor'],
        spellChecker: false,
      },
      submitInfo: {
        title: '',
        markdown: '',
        selectType: 0,
      },
      defaultInfo: {
        title: '',
        markdown: '',
        selectType: Number,
      },
    };
  },
  created() {
    this.loadContentDetail();
  },
  mixins: [helper, pageList],
  computed: {
    ...mapState('admin', ['adminInfo']),
    detailTitleList() {
      const titleList = this.pageList.map((page) => page.title);
      return titleList;
    },
    isVaild() {
      // 入力内容が変わっていないパターン
      if (this.submitInfo.title === this.defaultInfo.title
        && this.submitInfo.markdown === this.defaultInfo.markdown
        && this.submitInfo.selectType === this.defaultInfo.selectType) {
        return false;
      }
      // フォーム空パターン
      if (!this.submitInfo.title || !this.submitInfo.markdown) {
        return false;
      }
      return true;
    },
  },
  methods: {
    movePage(name, params) {
      this.$router.push({ name, params }, () => {});
    },
    isSelect(index) {
      if (index === this.defaultInfo.selectType) return true;
      return false;
    },
    movePageMenu(index) {
      const { name } = this.pageList[index];
      this.$router.push({ name }, () => {});
    },
    saveTitle(title) {
      this.submitInfo.title = title;
    },
    async loadContentDetail() {
      try {
        this.isLoading.loadDetail = !this.isLoading.loadDetail;
        this.$http.defaults.headers.common.Authorization = `Bearer ${this.adminInfo.token}`;
        this.currentPath = this.$route.path;
        this.contentId = this.currentPath.split('/');
        this.contentId = this.contentId.pop();
        await this.$http.get(`api/admin/notices/${this.contentId}`).then((res) => {
          this.defaultInfo.title = res.data.title;
          this.defaultInfo.markdown = res.data.body;
          this.defaultInfo.selectType = res.data.notice_category_id - 1;
        });
        this.submitInfo.title = this.defaultInfo.title;
        this.submitInfo.markdown = this.defaultInfo.markdown;
        this.submitInfo.selectType = this.defaultInfo.selectType;
        this.isLoading.loadDetail = !this.isLoading.loadDetail;
      } catch (e) {
        if (e.response !== undefined && e.response.status === 401) {
          this.unAdminAuthenticate();
        } else {
          this.movePage('AdminError');
        }
      }
    },
    async updateContent() {
      try {
        this.isLoading.putDetail = !this.isLoading.putDetail;
        this.$http.defaults.headers.common.Authorization = `Bearer ${this.adminInfo.token}`;
        const params = {
          title: this.submitInfo.title,
          category: this.submitInfo.selectType + 1,
          text: this.submitInfo.markdown,
        };
        await this.$http.put(`api/admin/notices/${this.contentId}`, params);

        this.defaultInfo.title = this.submitInfo.title;
        this.defaultInfo.markdown = this.submitInfo.markdown;
        this.defaultInfo.selectType = this.submitInfo.selectType;

        this.isLoading.putDetail = !this.isLoading.putDetail;
        this.showNoticeBar('success', 'コンテンツの更新を完了しました');
      } catch (e) {
        this.isLoading.putDetail = !this.isLoading.putDetail;
        if (e.response !== undefined && e.response.status === 401) {
          this.unAdminAuthenticate();
        } else {
          this.showNoticeBar('error', 'コンテンツの更新に失敗しました');
        }
      }
    },
  },
};
</script>

<style scoped>
.detail {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f9fa;
}

.detail__contents {
  display: flex;
  margin: 20px;
}

.detail__contents__left {
  width: 15%;
  background-color: #fff;
  padding: 20px;
}

.detail__contents__right {
  width: 85%;
  margin-left: 20px;
}

.detail__contents__right__bottom {
  top: 0;
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
}

.detail__contents__right__bottom__type {
  margin-top: 20px;
}

.detail__contents__right__bottom__type select {
  -webkit-appearance: menulist;
  border: 2px solid #d1d3d9;
  font-size: 14px;
  outline: none;
}

.detail__contents__right__bottom__markdown {
  margin-top: 20px;
}
</style>
