<template>
  <div class='input-search'>
    <i class="fas fa-search" />
    <input
      type="text"
      :placeholder='placeholder'
      v-model='text'
      @keydown.enter='emitSearch'
    />
  </div>
</template>

<script>
export default {
  props: {
    placeholder: String,
  },
  data() {
    return {
      text: '',
    };
  },
  methods: {
    emitSearch(event) {
      // 日本語入力時のEnter、form空時のEnterには反応しない
      if (event.keyCode !== 13 || this.text === '') return;
      this.$emit('emit-search', 'Search', '', { text: this.text });
    },
  },
};
</script>

<style scoped>
.input-search {
  font-size: 12px;
  color: #fff;
  background-color: #fff;
  border: solid 2px #fff;
  border-radius: 50px;
  padding: 6px 10px 6px 8px;
  display: flex;
  align-items: center;
}

.input-search input {
  width: 200px;
}

.input-search i {
  color: #4CAF50;
  margin-right: 6px;
  font-size: 16px;
}
</style>
