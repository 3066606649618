<template>
  <router-link
    class='lesson-card-popular'
    :to='{ name: "LessonDetail", params: { id: lessonInfo.id }}'
  >
    <div class="lesson-card-popular__description__slide">
      <img
        class='lesson-card-popular__description__slide__thumbnail'
        :src='lessonInfo.image'
        alt='レッスンのサムネイル'
        @error='setErrorThumbnail'>
      <div class="lesson-card-popular__description__slide__up">
        <div class="lesson-card-popular__description__slide__up__top">
          <review-star :score='lessonInfo.evaluation' />
          <p class='lesson-card-popular__description__slide__up__top__author'>
            {{ setAuthor }}
          </p>
          <img
            class='lesson-card-popular__description__slide__up__top__avatar'
            :src='setAvatar'
            alt='avatar ユーザーアイコン'
            @error='setErrorAvatar'>
        </div>
        <p class='lesson-card-popular__description__slide__up__text'>
          {{ lessonInfo.description }}
        </p>
      </div>
    </div>
    <div class='lesson-card-popular__info'>
      <h3>{{ lessonInfo.title }}</h3>
      <div class='lesson-card-popular__info__bottom'>
        <icon-level :level='lessonInfo.level' />
        <p class='lesson-card-popular__info__bottom__price'>{{ setPrice }}</p>
      </div>
    </div>
  </router-link>
</template>

<script>
import { ReviewStar } from '@/components/atoms/reviewStar';
import { IconLevel } from '@/components/atoms/icon';

export default {
  props: {
    lessonInfo: Object,
    levelLabel: Array,
  },
  components: {
    ReviewStar,
    IconLevel,
  },
  computed: {
    setPrice() {
      if (this.lessonInfo.price === 0) {
        return '無料';
      }
      return `¥${this.lessonInfo.price}`;
    },
    setAuthor() {
      if (this.lessonInfo.author === null) return '削除されたユーザー';
      return this.lessonInfo.author;
    },
    setAvatar() {
      if (this.lessonInfo.avatar === null) return `${process.env.VUE_APP_S3_URL}/image/avatar/unknown.png`;
      return this.lessonInfo.avatar;
    },
  },
  methods: {
    setErrorAvatar(e) {
      e.target.src = `${process.env.VUE_APP_S3_URL}/image/avatar/unknown.png`;
    },
    setErrorThumbnail(e) {
      e.target.src = `${process.env.VUE_APP_S3_URL}/image/thumbnail/manabiba.png`;
    },
  },
};
</script>

<style scoped>
.lesson-card-popular {
  background-color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.lesson-card-popular__description__slide {
  position: relative;
  width: 100%;
}

.lesson-card-popular__description__slide__thumbnail {
  width: 100%;
  height: 165px; /* 220px/75% */
  object-fit: cover;
}

.lesson-card-popular__description__slide__up {
  position:absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left:0;
  padding: 10px;
  opacity: 0;
  color: #fff;
  transition: 0.3s;
  z-index: 999;
}

.lesson-card-popular__description__slide__up::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  background-color:rgba(32,178,170,0.8);
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: 0.5s;
}

.lesson-card-popular:hover .lesson-card-popular__description__slide__up::after {
  top: 0;
  transition: 0.3s;
}

.lesson-card-popular__description__slide__up__top {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.lesson-card-popular__description__slide__up__top__author {
  margin-left: 5px;
  font-size: 12px;
  width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lesson-card-popular__description__slide__up__top__avatar {
  position: absolute;
  top: -25px;
  right: 5px;
  width: 55px;
  height: 55px;
  margin-left: 10px;
  background-color: #F1EFFC;
  border: 3px solid #fff;
  border-radius: 27.5px;
}

.lesson-card-popular__description__slide__up__text {
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  word-break: break-all;
}

.lesson-card-popular:hover .lesson-card-popular__description__slide__up{
  opacity: 1;
}

.lesson-card-popular__info {
  background-color: #fff;
  margin-top: -10px;
  padding-top: 10px;
  z-index: 999;
}

.lesson-card-popular__info h3 {
  font-size: 16px;
  font-weight: 500;
  height: 50px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  overflow: hidden;
}

.lesson-card-popular__info__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.lesson-card-popular__info__bottom__price {
  font-size: 20px;
  font-weight: 500;
}
</style>
