<template>
  <div>
    <ul class='bank-form__bottom'>
      <li class='bank-form__bottom__bank__code'>
        <div class='bank-form__bottom__bank__code__left'>
          <label for='bank__code'>銀行コード<span>(半角数字)</span></label>
        </div>
        <div class='bank-form__bottom__bank__code__right'>
          <input-bank
            name='bankCode'
            placeholder='0001'
            :maxLength='rule.bankCode.maxLength'
            :text='upload.bankCode'
            :errorMsg='errorMsg.bankCode'
            @emit='saveUpdateBank'
            style='width: 70px;'
          />
        </div>
      </li>
      <li class='bank-form__bottom__bank__branch'>
        <div class='bank-form__bottom__bank__branch__left'>
          <label for='bank__branch'>支店コード<span>(半角数字)</span></label>
        </div>
        <div class='bank-form__bottom__bank__branch__right'>
          <input-bank
            name='bankBranch'
            placeholder='001'
            :maxLength='rule.bankBranch.maxLength'
            :text='upload.bankBranch'
            :errorMsg='errorMsg.bankBranch'
            @emit='saveUpdateBank'
            style='width: 70px;'
          />
        </div>
      </li>
      <li class='bank-form__bottom__bank__number'>
        <div class='bank-form__bottom__bank__number__left'>
          <label for='bank__number'>口座番号<span>(半角数字)</span></label>
        </div>
        <div class='bank-form__bottom__bank__number__right'>
          <input-bank
            name='bankNumber'
            :placeholder="bankNumberPlaceHolder ? bankNumberPlaceHolder : '1234567'"
            :maxLength='rule.bankNumber.maxLength'
            :text="!bankNumberPlaceHolder ? upload.bankNumber : ''"
            :errorMsg='errorMsg.bankNumber'
            @emit='saveUpdateBank'
            style='width: 90px;'
          />
        </div>
      </li>
      <li class='bank-form__bottom__bank__uname'>
        <div class='bank-form__bottom__bank__uname__left'>
          <label for='bank__uname'>口座名義カナ<span>(全角)</span></label>
        </div>
        <div class='bank-form__bottom__bank__uname__right'>
          <input-bank
            name='bankUname'
            placeholder='ドンブラタロウ'
            :text='upload.bankUname'
            :errorMsg='errorMsg.bankUname'
            @emit='saveUpdateBank'
          />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { InputBank } from '@/components/atoms/input';

export default {
  components: {
    InputBank,
  },
  props: {
    isLoading: Boolean,
    bankNumberPlaceHolder: String,
    upload: Object,
    errorMsg: Object,
  },
  data() {
    return {
      rule: {
        bankCode: {
          maxLength: 4,
        },
        bankBranch: {
          maxLength: 3,
        },
        bankNumber: {
          maxLength: 7,
        },
      },
    };
  },
  methods: {
    saveUpdateBank(content, target) {
      this.upload[target] = content;
    },
  },
  watch: {
    checkString(inputdata) {
      const re = /[^0-9]+/i;
      return re.test(inputdata);
    },
    // リアルタイムバリデーション
    'upload.bankCode': function (to) {
      if (to !== null) {
        const result = /^[0-9]*$/g;
        if (to.length !== this.rule.bankCode.maxLength) {
          this.errorMsg.bankCode = '銀行コードは4桁です';
        } else if (!result.test(this.upload.bankCode)) {
          this.errorMsg.bankCode = '半角数字で入力してください';
        } else {
          this.errorMsg.bankCode = '';
        }
      }
    },
    'upload.bankBranch': function (to) {
      if (to !== null) {
        const result = /^[0-9]*$/g;
        if (to.length !== this.rule.bankBranch.maxLength) {
          this.errorMsg.bankBranch = '支店コードは3桁です';
        } else if (!result.test(this.upload.bankBranch)) {
          this.errorMsg.bankBranch = '半角数字で入力してください';
        } else {
          this.errorMsg.bankBranch = '';
        }
      }
    },
    'upload.bankNumber': function (to) {
      if (to !== null) {
        const result = /^[0-9]*$/g;
        if (to.length !== this.rule.bankNumber.maxLength) {
          this.errorMsg.bankNumber = '口座番号は7桁です';
        } else if (to.length === 0) {
          this.errorMsg.bankNumber = '口座番号を入力してください';
        } else if (!result.test(this.upload.bankNumber)) {
          this.errorMsg.bankNumber = '半角数字で入力してください';
        } else {
          this.errorMsg.bankNumber = '';
        }
      }
    },
    'upload.bankUname': function (to) {
      if (to !== null) {
        const result = /^[ァ-ンヴー]+$/g;
        if (to.length === 0) {
          this.errorMsg.bankUname = '口座名義カナを入力してください';
        } else if (!result.test(this.upload.bankUname)) {
          this.errorMsg.bankUname = '全角カナで入力してください';
        } else {
          this.errorMsg.bankUname = '';
        }
      }
    },
  },
};
</script>

<style scoped>
  .bank-form {
    padding: 40px;
    border-bottom: 1px solid #F2EEEE;
  }

  .bank-form span{
    font-weight: normal;
    color: #93a5b1;
    font-size: 12.5px;
  }

  .bank-form__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bank-form__bottom div {
    flex-basis: 160px;
  }

  .bank-form__bottom label {
    font-weight: 500;
    font-size: 14px;
  }

  .bank-form__bottom li {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .kind__btn {
    display: none;
  }

  .kind__btn:checked + label {
    color: #000;
    padding: 0.15em 0.4em;
    border: solid 1px #cfdce6;
    border-radius: 4px;
  }
</style>
