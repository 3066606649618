<template>
  <button
    class='button-notice'
    @mouseover='isActive = true'
    @mouseleave='isActive = false'
  >
    <i class='fas fa-bell' />
    <transition class='button-notice__hover'>
      <div
        class='button-notice__hover__bar'
        v-if='isActive'
      />
    </transition>
  </button>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
    };
  },
};
</script>

<style scoped>
.button-notice {
  position: relative;
}

.button-notice i {
  font-size: 20px;
  color: #fff;
}

.button-notice__hover__bar {
  position: absolute;
  width: 15px;
  height: 4px;
  background-color: #d1ae00;
  border-radius: 20px;
  bottom: -18px;
  left: 1px;
}

.v-enter-active, .v-leave-active {
  transition: opacity 0.05s;
}

.v-enter, .v-leave-to {
  opacity: 0;
}
</style>
