<template>
  <div :class='addClass'>
    <img :src='setLogo' alt='桃 peach'>
  </div>
</template>
<script>
import logoWhite from '@/assets/img/donbler-logo.svg';
import logoWhiteIconOnly from '@/assets/img/donbler-logo-icononly.svg';
import logoYellowIconOnly from '@/assets/img/donbler-logo-yellow-icononly.svg';

export default {
  props: {
    // サイズ系
    small: Boolean,
    // パターン系
    iconOnly: Boolean,
    // カラー系
    yellow: Boolean,
  },
  computed: {
    addClass() {
      return {
        'layout-logo': true,
        'layout-logo--small': this.small,
      };
    },
    setLogo() {
      if (this.iconOnly) {
        if (this.yellow) return logoYellowIconOnly;
        return logoWhiteIconOnly;
      }
      return logoWhite;
    },
  },
};
</script>>
<style scoped>
.layout-logo {
  display: flex;
  align-items: center;
}
.layout-logo img {
  height: 29px;
}

.layout-logo--small img {
  height: 25px;
}
</style>
