var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user" },
    [
      _c("layout-header"),
      _c("section-user-info", {
        attrs: {
          isLoading: _vm.isLoading,
          ambassador: _vm.userInfo.ambassador,
          userName: _vm.userInfo.userName,
          src: _vm.userInfo.avatar
        }
      }),
      !_vm.isLoading
        ? _c("div", { staticClass: "user__contents center" }, [
            _c(
              "div",
              { staticClass: "user__contents__introduction" },
              [
                _c("heading-normal", { attrs: { large: "", yellow: "" } }, [
                  _vm._v("自己紹介_")
                ]),
                _c(
                  "text-normal",
                  { staticClass: "user__contents__introduction__text" },
                  [_vm._v(" " + _vm._s(_vm.setDescription) + " ")]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "user__contents__lessons" },
              [
                _c(
                  "div",
                  { staticClass: "user__contents__lessons__title" },
                  [
                    _c(
                      "heading-normal",
                      { attrs: { id: "user-lesson", large: "", yellow: "" } },
                      [_vm._v("投稿レッスン_")]
                    )
                  ],
                  1
                ),
                _vm.lessonInfo.totalCount === 0
                  ? _c(
                      "p",
                      {
                        staticClass: "user__contents__lessons__notfound",
                        attrs: { id: "user" }
                      },
                      [_vm._v(" 投稿したレッスンはありません ")]
                    )
                  : _vm._e(),
                _vm.lessonInfo.totalCount !== 0
                  ? _c(
                      "ul",
                      _vm._l(
                        _vm.lessonInfo.list[_vm.pagerInfo.count - 1],
                        function(info) {
                          return _c(
                            "li",
                            {
                              key: info.id,
                              staticClass: "user__contents__lessons__postinfo"
                            },
                            [
                              _c("lesson-card-wide", {
                                attrs: {
                                  lessonInfo: info,
                                  levelLabel: _vm.levelLabel,
                                  hideAuthor: ""
                                }
                              })
                            ],
                            1
                          )
                        }
                      ),
                      0
                    )
                  : _vm._e(),
                _c("pager-normal", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.pagerInfo.maxPage !== 1,
                      expression: "pagerInfo.maxPage !== 1"
                    }
                  ],
                  staticClass: "user__contents__lessons__pager",
                  attrs: {
                    totalCount: _vm.lessonInfo.totalCount,
                    display: _vm.pagerInfo.limit
                  },
                  on: {
                    emit: function($event) {
                      return _vm.setPagerCount($event)
                    }
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c("layout-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }