var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "section-top-info" }, [
    _c(
      "div",
      { staticClass: "section-top-info__main center" },
      [
        !_vm.isLoading.loadLesson
          ? _c("div", { staticClass: "section-top-info__main__left" }, [
              _c("img", {
                staticClass: "section-top-info__main__left__thumbnail",
                attrs: { src: _vm.setThumbnail, alt: "レッスンのサムネイル" }
              })
            ])
          : _vm._e(),
        !_vm.isLoading.loadLesson
          ? _c("div", { staticClass: "section-top-info__main__right" }, [
              _c(
                "h3",
                { staticClass: "section-top-info__main__right__title" },
                [_vm._v(_vm._s(_vm.lessonInfo.title))]
              ),
              _c(
                "div",
                { staticClass: "section-top-info__main__right__bottom" },
                [
                  _c("review-star-multi", {
                    attrs: { score: _vm.lessonInfo.evaluation }
                  }),
                  _c(
                    "div",
                    { staticClass: "section-top-info__main__right__tag" },
                    [
                      _vm.lessonInfo.category.length
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "section-top-info__main__right__tag__text"
                            },
                            [_vm._v(" カテゴリー ")]
                          )
                        : _vm._e(),
                      _c(
                        "ul",
                        {
                          staticClass:
                            "section-top-info__main__right__tag__list"
                        },
                        _vm._l(_vm.lessonInfo.category, function(lang) {
                          return _c(
                            "li",
                            { key: lang.id },
                            [_c("icon-lang", [_vm._v(_vm._s(lang))])],
                            1
                          )
                        }),
                        0
                      )
                    ]
                  )
                ],
                1
              ),
              _vm.pageType === "creator" && _vm.lessonInfo.judge === 2
                ? _c(
                    "p",
                    { staticClass: "section-top-info__main__right__warn" },
                    [_vm._v(" レッスン更新審査中のため、新規更新できません ")]
                  )
                : _vm._e()
            ])
          : _vm._e(),
        _vm.isLoading.loadLesson
          ? _c("icon-loading", { staticClass: "section-top-info__main__load" })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }