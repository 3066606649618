var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: "" + _vm.auth,
      on: {
        click: function($event) {
          return _vm.$emit("emit", _vm.auth)
        }
      }
    },
    [_c("i", { class: "fab fa-" + _vm.auth })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }