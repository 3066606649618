var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bar-progress" },
    [
      _c("div", { staticClass: "bar-progress__bar" }, [
        _c("div", {
          class:
            "bar-progress__front " +
            (_vm.large ? "large" : "") +
            " " +
            (_vm.small ? "small" : ""),
          style: _vm.addStyle
        }),
        _c("div", {
          class:
            "bar-progress__back " +
            (_vm.large ? "large" : "") +
            " " +
            (_vm.small ? "small" : "")
        })
      ]),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }