var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lesson-detail" },
    [
      _c("layout-header", { staticClass: "lesson-detail__header" }),
      _c("div", { staticClass: "lesson-detail__back" }),
      _c(
        "div",
        { staticClass: "lesson-detail__front center" },
        [
          _c("section-top-info", {
            attrs: {
              isLoading: _vm.isLoading,
              lessonInfo: _vm.lessonInfo,
              levelLabel: _vm.levelLabel,
              pageType: _vm.pageType
            }
          }),
          !_vm.isLoading.loadLesson
            ? _c("section-tool-bar", {
                attrs: {
                  lessonInfo: _vm.lessonInfo,
                  purchaseList: _vm.userInfo.lesson.purchase,
                  postList: _vm.userInfo.lesson.post,
                  isLoading: _vm.isLoading,
                  pageType: _vm.pageType,
                  status: _vm.status,
                  checkCart: _vm.checkCart
                },
                on: {
                  "emit-move-page": _vm.clickContinue,
                  "emit-move-page-update": function($event) {
                    return _vm.movePage("LessonUpdate", {
                      id: _vm.lessonInfo.id
                    })
                  },
                  "emit-reset-progress": function($event) {
                    return _vm.onResetBtn(_vm.lessonInfo.id)
                  },
                  "emit-change-status-public": function($event) {
                    return _vm.active("setLessonPublic")
                  },
                  "emit-change-status-private": function($event) {
                    return _vm.active("setLessonPrivate")
                  },
                  "emit-add-cart": function($event) {
                    return _vm.addCart()
                  }
                }
              })
            : _vm._e(),
          !_vm.isLoading.loadLesson
            ? _c("section", { staticClass: "lesson-detail__info" }, [
                _vm.pageType === "afterBuy"
                  ? _c(
                      "div",
                      {
                        staticClass: "lesson-detail__info__accordion",
                        on: { click: _vm.showAccordion }
                      },
                      [
                        !_vm.isActive.accordion
                          ? _c("i", {
                              staticClass:
                                "lesson-detail__info__accordion__btn fas fa-caret-up"
                            })
                          : _vm._e(),
                        _vm.isActive.accordion
                          ? _c("i", {
                              staticClass:
                                "lesson-detail__info__accordion__btn fas fa-caret-down"
                            })
                          : _vm._e(),
                        _c(
                          "p",
                          {
                            staticClass: "lesson-detail__info__accordion__text"
                          },
                          [_vm._v("レッスン内容と対象ユーザを見る")]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.isActive.accordion
                  ? _c(
                      "div",
                      { staticClass: "lesson-detail__info__description" },
                      [
                        _c("heading-normal", { attrs: { large: "" } }, [
                          _vm._v("レッスン内容")
                        ]),
                        _c("text-normal", [
                          _vm._v(_vm._s(_vm.lessonInfo.description))
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isActive.accordion
                  ? _c(
                      "div",
                      { staticClass: "lesson-detail__info__target" },
                      [
                        _c("heading-normal", { attrs: { large: "" } }, [
                          _vm._v("対象ユーザー")
                        ]),
                        _c("text-normal", [
                          _vm._v(_vm._s(_vm.lessonInfo.target))
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "lesson-detail__info__syllabus" },
                  [
                    _c("heading-normal", { attrs: { large: "" } }, [
                      _vm._v("シラバス")
                    ]),
                    _c(
                      "ul",
                      { staticClass: "lesson-detail__info__syllabus__list" },
                      _vm._l(_vm.chapterList, function(chapter, index) {
                        return _c(
                          "li",
                          { key: chapter.id },
                          [
                            _vm.completeChapter.length !== 0 &&
                            _vm.pageType !== "creator"
                              ? _c("list-chapter", {
                                  attrs: {
                                    num: index,
                                    id: _vm.lessonInfo.id,
                                    title: chapter,
                                    complete:
                                      _vm.completeChapter[index].complete,
                                    login: _vm.userInfo.login
                                  },
                                  on: {
                                    "emit-move-page": function($event) {
                                      return _vm.movePage("LessonAttend", {
                                        id: _vm.lessonInfo.id,
                                        num: index + 1
                                      })
                                    }
                                  }
                                })
                              : _vm._e(),
                            _vm.completeChapter.length === 0 &&
                            _vm.pageType !== "creator" &&
                            _vm.lessonInfo.price !== 0
                              ? _c("list-chapter", {
                                  attrs: {
                                    num: index,
                                    id: _vm.lessonInfo.id,
                                    title: chapter,
                                    login: _vm.userInfo.login,
                                    lock:
                                      _vm.lessonInfo.freeChapterNum <= index
                                        ? true
                                        : false,
                                    hideCheck: ""
                                  },
                                  on: {
                                    "emit-move-page": function($event) {
                                      return _vm.moveFreeRangeAttend(index + 1)
                                    }
                                  }
                                })
                              : _vm._e(),
                            _vm.completeChapter.length === 0 &&
                            _vm.pageType !== "creator" &&
                            _vm.lessonInfo.price === 0
                              ? _c("list-chapter", {
                                  attrs: {
                                    num: index,
                                    id: _vm.lessonInfo.id,
                                    title: chapter,
                                    login: _vm.userInfo.login,
                                    free: ""
                                  },
                                  on: {
                                    "emit-move-page": function($event) {
                                      return _vm.onChapter(_vm.lessonInfo.id)
                                    }
                                  }
                                })
                              : _vm._e(),
                            _vm.pageType === "creator"
                              ? _c("list-chapter", {
                                  attrs: {
                                    num: index,
                                    id: _vm.lessonInfo.id,
                                    title: chapter,
                                    disabled: "",
                                    hideCheck: ""
                                  },
                                  on: {
                                    "emit-move-page": function($event) {
                                      return _vm.onChapter(_vm.lessonInfo.id)
                                    }
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      ),
      Object.keys(this.authorInfo).length !== 0 && !_vm.isLoading.loadLesson
        ? _c("section-author", {
            attrs: { authorInfo: _vm.authorInfo, pointer: "" }
          })
        : _vm._e(),
      Object.keys(this.authorInfo).length === 0 && !_vm.isLoading.loadLesson
        ? _c("section-author", { attrs: { authorInfo: _vm.unknownAuthorInfo } })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "lesson-detail__modal" },
        [
          _vm.isActive.setLessonPublic
            ? _c("modal-normal", {
                attrs: {
                  title: "レッスンを公開する",
                  text:
                    "レッスンを公開すると全てのユーザーがレッスンを閲覧可能になりますがよろしいですか？",
                  acceptBtn: "公開する",
                  cancelBtn: "キャンセル"
                },
                on: {
                  emit: function($event) {
                    return _vm.changeStatus($event, 1)
                  }
                }
              })
            : _vm._e(),
          _vm.isActive.setLessonPrivate
            ? _c("modal-normal", {
                attrs: {
                  title: "レッスンを非公開にする",
                  text:
                    "レッスンを非公開にすると既に購入済みのユーザーを除くユーザーがレッスンを閲覧できなくなりますがよろしいですか？",
                  acceptBtn: "非公開にする",
                  cancelBtn: "キャンセルする"
                },
                on: {
                  emit: function($event) {
                    return _vm.changeStatus($event, 2)
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("layout-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }