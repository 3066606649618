<template>
  <p class='icon-level'>
    {{ setLevel }}
  </p>
</template>

<script>
export default {
  props: {
    level: Number,
  },
  computed: {
    setLevel() {
      if (this.level === 0) return '初心者';
      if (this.level === 1) return '中級者';
      if (this.level === 2) return '上級者';
      return 'その他';
    },
  },
};
</script>

<style scoped>
.icon-level {
  font-size: 11px;
  border: 1px solid #707070;
  border-radius: 10px;
  width: 50px;
  text-align: center;
}
</style>
