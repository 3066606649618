<template>
  <div class='help'>
    <layout-header />
    <heading-title>
      <template v-slot:main>
        <p>Q&A</p>
      </template>
      <template v-slot:sub>
        <p>サービスに関する質問_</p>
      </template>
    </heading-title>
    <div class='help__main center'>
      <ul class='help__main__slide'>
        <list-slide
          class='help__main__slide__list'
          v-for='help in helpList'
          :key='help.id'
          :title='help.title'
          :text='help.text'
        />
      </ul>
    </div>
    <layout-footer />
  </div>
</template>

<script>
import { LayoutHeader, LayoutFooter } from '@/components/layouts/general/';
import { HeadingTitle } from '@/components/atoms/heading';
import { ListSlide } from '@/components/atoms/list';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    HeadingTitle,
    ListSlide,
  },
  data() {
    return {
      helpList: [
        {
          title: '対象ブラウザは何ですか？',
          text: 'Google Chrome最新版でのみ動作確認をしています。\nGoogle Chrome以外のブラウザでの動作については保証致しかねますので予めご了承下さい。',
        },
        {
          title: 'スマートフォンやタブレットには対応していますか？',
          text: 'PCのみの対応になります。',
        },
        {
          title: 'アカウントを削除する方法を教えて下さい',
          text: '下記ページの「アカウントを削除される方はこちら」から退会することができます。\nhttps://donbler.com/userinfo/setting',
        },
        {
          title: 'ログインができません',
          text: 'Github, Twitter, Googleのログインページで正しいアカウント名、パスワードが入力できているかご確認ください。\nTwitterでのログインをする場合、Twitterアカウントにメールアドレスを紐づけていないと登録できませんのでお手数ですが、\nメールアドレス紐付け後、改めてお試しください。',
        },
        {
          title: 'Google、Twitter、Github、Facebook以外のアカウントやメールアドレスでログインすることはできますか？',
          text: '申し訳ございませんが、Google、Twitter、Github、Facebookのいずれかのアカウントがないとログインすることは出来ません。',
        },
        {
          title: 'プロフィールを変更したいのですが、どこから変更することができますか？',
          text: '以下URLから変更ができます。\nhttps://donbler.com/userInfo/setting',
        },
        {
          title: 'レッスンを初めからやり直したいです',
          text: 'レッスン詳細ページで「最初から始める」を押すと一から受講することができます。',
        },
        {
          title: 'レッスンに学習期限はありますか？',
          text: 'ありません、レッスン投稿者がレッスンを削除しない限りはいつでも受講できます。',
        },
        {
          title: '無料のレッスンなのに「購入済みレッスン」に表示されるのは何故ですか？',
          text: 'サービスの仕様により0円のレッスンを購入したと処理されるためです。代金のお支払い等一切ございませんのでご安心ください。',
        },
        {
          title: '著作権を侵害したレッスンや、ユーザーを攻撃するコードが含まれるレッスンを発見した場合、どのように報告すればよいですか？',
          text: 'こちらのフォームからお問い合わせください。\nhttps://forms.gle/9rR8SufyRvfRU8Pt9',
        },
        {
          title: '一度受講したレッスンにつけた評価は変更できますか？',
          text: 'はい、変更できます。再度レッスンをクリアし新たに評価を入力すれば変更されます。',
        },
        {
          title: '会員登録は無料ですか？',
          text: '無料です。',
        },
        {
          title: 'レッスンはだれでも投稿できますか？',
          text: 'manabibaにログインしているユーザーであれば以下URLから無料レッスンを投稿することができます。\n投稿の仕方やテンプレートなども提供しているので是非ご覧ください。\nhttps://donbler.com/lessons/create\n有料レッスンを投稿する場合は以下URLから有料レッスン投稿申請を行う必要があります。\nhttps://donbler.com/userinfo/sales',
        },
        {
          title: '投稿したレッスンを非公開にしたいです',
          text: '以下URLから非公開にすることができます。逆に非公開状態のレッスンの公開も可能です。\n非公開にすると、既に購入しているユーザーを除いてmanabiba上でレッスンを参照できなくなります。\nhttps://donbler.com/userInfo/lessons',
        },
        {
          title: 'レッスンを受講するにはどうしたらいいですか？',
          text: '以下ページにアクセスしてください。\nhttps://donbler.com\n画面上に並ぶレッスンから学んでみたいレッスンを選びクリックし、遷移したレッスン詳細画面で「最初から始める」を押すと\n受講が開始できます',
        },
        {
          title: 'レッスン投稿後はどうような手順で公開されますか？',
          text: '投稿後は一週間前後レッスンに問題がないか内容を審査させていただきます。\n問題がない場合、審査後公開されます。問題がある場合、ログインしているGithub,Twitter,Googleアカウントに紐づくメールアドレスに審査不合格である旨のご連絡を致します。',
        },
        {
          title: '審査は通常何日程度で完了しますか？',
          text: '平均5営業日前後お時間を頂戴しております。',
        },
        {
          title: 'レッスンの内容を更新する方法を教えて下さい。',
          text: '以下URLの「投稿済み」から対象レッスンを探し、「更新」ボタンを押して下さい。\nhttps://donbler.com/userInfo/lessons\nレッスン更新ページに遷移するため更新したいレッスンファイルをアップロードしてください。',
        },
      ],
    };
  },
};
</script>

<style scope>
.help {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.help__main {
  margin: 80px auto;
}

.help__main__slide {
  margin: 0 40px;
}
</style>
