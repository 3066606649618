<template>
  <div class='privacy-policy'>
    <layout-header />
    <heading-title>
      <template v-slot:main>
        <p>PP</p>
      </template>
      <template v-slot:sub>
        <p>プライバシーポリシー_</p>
      </template>
    </heading-title>
    <privacy-policy-md class='privacy-policy__markdown center mdbase' />
    <layout-footer />
  </div>
</template>

<script>
import PrivacyPolicyMd from '@/assets/markdown/privacypolicy.md';
import { LayoutHeader, LayoutFooter } from '@/components/layouts/general/';
import { HeadingTitle } from '@/components/atoms/heading/';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    HeadingTitle,
    PrivacyPolicyMd,
  },
};
</script>

<style scoped>
.privacy-policy {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.privacy-policy__markdown {
  margin-top: 80px;
  margin-bottom: 80px;
  padding: 0 60px;
}
</style>
