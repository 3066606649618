var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "article-normal" }, [
    _c("div", { staticClass: "article-normal__top" }, [
      _c("h1", [_vm._v(_vm._s(_vm.title))]),
      _c("p", [_vm._v(_vm._s(_vm.date) + "に投稿")])
    ]),
    _c("div", {
      staticClass: "article-normal__markdown mdbase",
      domProps: { innerHTML: _vm._s(_vm.parseMarkdown) }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }