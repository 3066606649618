<template>
  <div class='bar-progress'>
    <div class='bar-progress__bar'>
      <div
        :class='`bar-progress__front ${large ? "large" : ""} ${small ? "small" : ""}`'
        :style='addStyle'
      />
      <div :class='`bar-progress__back ${large ? "large" : ""} ${small ? "small" : ""}`' />
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    progress: Number,
    large: Boolean,
    small: Boolean,
  },
  computed: {
    addStyle() {
      return `width: ${this.progress}%`;
    },
  },
};
</script>
<style scoped>
.bar-progress {
  display: flex;
  align-items: center;
}
.bar-progress__bar {
  position: relative;
  width: 100%;
}

.bar-progress__front {
  position: absolute;
  top: 0;
  left: 0;
  height: 12px;
  border-radius: 20px;
  background-color: #90cce6;
}

.bar-progress__back {
  width: 100%;
  height: 12px;
  border-radius: 40px;
  background-color: #F1EFFC;
}

.small {
  height: 5px;
}

.large {
  height: 18px;
}
</style>
