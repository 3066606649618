<template>
  <div :class='addClass'>
    <p class='input-file__title' v-if='title'>
      {{title}}
    </p>
    <label>
      ファイルを選択
      <input
        type='file'
        :accept='accept'
        @change='fileChange'
      >
    </label>
    <p class='input-file__maxsize' v-if='description'>
      {{description}}
    </p>
    <div class='input-file__check' v-if='!errorMsg && uploadCheck && !resetCheck'>
      <i class="fas fa-check"></i>
    </div>
    <p class='input-file__error' v-if='errorMsg'>{{ errorMsg }}</p>
  </div>
</template>

<script>
export default {
  props: {
    accept: String,
    name: String,
    errorMsg: String,
    title: String,
    description: String,
    resetCheck: Boolean,
  },
  data() {
    return {
      uploadCheck: false,
    };
  },
  computed: {
    addClass() {
      return {
        'input-file': true,
        'input-file--error': this.errorMsg,
      };
    },
  },
  methods: {
    fileChange(e) {
      if (e.target.files[0]) {
        this.uploadCheck = true;
        return this.$emit('emit', e.target.files[0], this.name);
      }
      return null;
    },
  },
};
</script>

<style scoped>
.input-file {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.input-file input {
  display: none;
}

.input-file__title {
  margin-right: 15px;
}

.input-file label {
  font-size: 12px;
  cursor: pointer;
  background-color: #F7F7F7;
  font-weight: bold;
  text-align: center;
  padding: 10px 20px;
  border-radius: 8px;
}

.input-file__check {
  margin-left: 10px;
  font-size: 18px;
  color: #2E64D4;
}
.input-file__error {
  color: rgb(255, 147, 169);
  margin-top: 5px;
  font-size: 12px;
  width: 100%;
}

.input-file--error label {
  background-color: rgb(255, 147, 169);
}

.input-file__title {
  font-size: 12px;
  font-weight: bold;
}

.input-file__maxsize {
  margin-left: 10px;
  font-size: 11px;
}
</style>
