<template>
  <p :class='addClass'>{{buttonText}}</p>
</template>

<script>
export default {
  props: {
    buttonText: String,
  },
  computed: {
    addClass() {
      return {
        'icon-status': true,
        'icon-status--navy': this.buttonText === '公開中',
        'icon-status--gray': this.buttonText === '非公開' || this.buttonText === '審査中',
      };
    },
  },
};
</script>

<style scoped>
.icon-status {
  padding: 9px 20px;
  height: 30px;
  font-size: 12px;
  border-radius: 15px;
  line-height: 12px;
  min-width: 70px;
  color: white;
}

.icon-status--navy {
  background-color: #012755;
}

.icon-status--gray {
  background-color: #CBCBCB;
}
</style>
