var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sphone" }, [
    _vm._m(0),
    _c("section", { staticClass: "sphone__main__top" }, [
      _c("div", { staticClass: "sphone__main__top__wrapper" }, [
        _vm._m(1),
        _c("img", {
          staticClass: "sphone__main__top__img",
          attrs: {
            src: require("@/assets/img/sphone/sphone_attendpage.png"),
            alt: "manabibaの受講ページ"
          }
        }),
        _vm._m(2),
        _c(
          "div",
          { staticClass: "sphone__main__sns" },
          [
            _c(
              "share-network",
              {
                staticClass: "sphone__main__sns__btn",
                attrs: {
                  network: _vm.shareInfo.twitter.network,
                  url: _vm.shareInfo.twitter.url,
                  title: _vm.shareInfo.twitter.title,
                  hashtags: _vm.shareInfo.twitter.hashtags
                }
              },
              [
                _c("i", {
                  staticClass: "fab fa-twitter sphone__main__sns__twitter"
                })
              ]
            ),
            _c(
              "share-network",
              {
                staticClass: "sphone__main__sns__btn",
                attrs: {
                  network: _vm.shareInfo.facebook.network,
                  url: _vm.shareInfo.facebook.url,
                  title: _vm.shareInfo.facebook.title,
                  hashtags: _vm.shareInfo.facebook.hashtags
                }
              },
              [
                _c("i", {
                  staticClass:
                    "fab fa-facebook-square sphone__main__sns__facebook"
                })
              ]
            ),
            _c(
              "share-network",
              {
                staticClass: "sphone__main__sns__btn",
                attrs: {
                  network: _vm.shareInfo.line.network,
                  url: _vm.shareInfo.line.url,
                  title: _vm.shareInfo.line.title,
                  description: _vm.shareInfo.line.description
                }
              },
              [_c("i", { staticClass: "fab fa-line sphone__main__sns__line" })]
            ),
            _c(
              "share-network",
              {
                staticClass: "sphone__main__sns__btn",
                attrs: {
                  network: _vm.shareInfo.pocket.network,
                  url: _vm.shareInfo.pocket.url,
                  title: _vm.shareInfo.pocket.title
                }
              },
              [
                _c("i", {
                  staticClass: "fab fa-get-pocket sphone__main__sns__pocket"
                })
              ]
            )
          ],
          1
        )
      ])
    ]),
    _vm._m(3),
    _vm._m(4),
    _vm._m(5),
    _vm._m(6),
    _c("section", { staticClass: "sphone__main__form" }),
    _c(
      "section",
      { staticClass: "sphone__main__sns sphone__main__sns__wrapper" },
      [
        _c(
          "share-network",
          {
            staticClass: "sphone__main__sns__btn",
            attrs: {
              network: _vm.shareInfo.twitter.network,
              url: _vm.shareInfo.twitter.url,
              title: _vm.shareInfo.twitter.title,
              hashtags: _vm.shareInfo.twitter.hashtags
            }
          },
          [
            _c("i", {
              staticClass: "fab fa-twitter sphone__main__sns__twitter"
            })
          ]
        ),
        _c(
          "share-network",
          {
            staticClass: "sphone__main__sns__btn",
            attrs: {
              network: _vm.shareInfo.facebook.network,
              url: _vm.shareInfo.facebook.url,
              title: _vm.shareInfo.facebook.title,
              hashtags: _vm.shareInfo.facebook.hashtags
            }
          },
          [
            _c("i", {
              staticClass: "fab fa-facebook-square sphone__main__sns__facebook"
            })
          ]
        ),
        _c(
          "share-network",
          {
            staticClass: "sphone__main__sns__btn",
            attrs: {
              network: _vm.shareInfo.line.network,
              url: _vm.shareInfo.line.url,
              title: _vm.shareInfo.line.title,
              description: _vm.shareInfo.line.description
            }
          },
          [_c("i", { staticClass: "fab fa-line sphone__main__sns__line" })]
        ),
        _c(
          "share-network",
          {
            staticClass: "sphone__main__sns__btn",
            attrs: {
              network: _vm.shareInfo.pocket.network,
              url: _vm.shareInfo.pocket.url,
              title: _vm.shareInfo.pocket.title
            }
          },
          [
            _c("i", {
              staticClass: "fab fa-get-pocket sphone__main__sns__pocket"
            })
          ]
        )
      ],
      1
    ),
    _vm._m(7),
    _vm._m(8)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      { staticClass: "sphone__header sphone__header__wrapper" },
      [
        _c("img", {
          staticClass: "sphone__header__logo",
          attrs: {
            src: require("@/assets/img/sphone/sphone_textlogo_white.svg"),
            alt: "ロゴ"
          }
        }),
        _c("p", { staticClass: "sphone__header__text" }, [
          _vm._v("プログラミング学習レッスン プラットフォーム manabiba")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "sphone__main__top__title" }, [
      _vm._v("脱プログラミング初心者！"),
      _c("br"),
      _vm._v("次の一歩に悩むあなたへ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "sphone__main__top__text" }, [
      _vm._v(" manabibaは環境構築不要で、すぐ学べる"),
      _c("br"),
      _vm._v("プログラミング学習レッスンプラットフォームです。 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "sphone__main__feature" }, [
      _c("div", { staticClass: "sphone__main__feature__wrapper" }, [
        _c("h2", { staticClass: "sphone__main__feature__title" }, [
          _c("img", {
            staticClass: "sphone__main__feature__title__img",
            attrs: {
              src: require("@/assets/img/sphone/sphone_logo_white.svg"),
              alt: "ロゴ"
            }
          }),
          _c("p", { staticClass: "sphone__main__feature__title__text" }, [
            _vm._v("manabibaの特徴 ")
          ])
        ]),
        _c("ul", [
          _c("li", { staticClass: "sphone__main__feature__list" }, [
            _c("img", {
              staticClass: "sphone__main__feature__list__img",
              attrs: {
                src: require("@/assets/img/sphone/sphone_attendpage_zoom.png"),
                alt: "doblerの受講ページ"
              }
            }),
            _c(
              "div",
              { staticClass: "sphone__main__feature__list__contents" },
              [
                _c(
                  "h3",
                  {
                    staticClass: "sphone__main__feature__list__contents__title"
                  },
                  [
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticClass:
                            "sphone__main__feature__list__contents__title__num"
                        },
                        [_vm._v("1")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass:
                            "sphone__main__feature__list__contents__title__period"
                        },
                        [_vm._v(".")]
                      )
                    ]),
                    _c(
                      "p",
                      {
                        staticClass:
                          "sphone__main__feature__list__contents__title__text"
                      },
                      [
                        _vm._v(" 開発環境の準備不要！"),
                        _c("br"),
                        _vm._v("ブラウザ上ですぐ学べる ")
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "sphone__main__feature__list__contents__text"
                  },
                  [
                    _vm._v(" ブラウザ上でコードを実行できるため"),
                    _c("br"),
                    _vm._v("難しい環境構築の必要はありません！ ")
                  ]
                )
              ]
            )
          ]),
          _c("li", { staticClass: "sphone__main__feature__list" }, [
            _c("img", {
              staticClass: "sphone__main__feature__list__img",
              attrs: {
                src: require("@/assets/img/sphone/sphone_feature_variety.png"),
                alt: "プログラミング言語"
              }
            }),
            _c(
              "div",
              { staticClass: "sphone__main__feature__list__contents" },
              [
                _c(
                  "h3",
                  {
                    staticClass: "sphone__main__feature__list__contents__title"
                  },
                  [
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticClass:
                            "sphone__main__feature__list__contents__title__num"
                        },
                        [_vm._v("2")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass:
                            "sphone__main__feature__list__contents__title__period"
                        },
                        [_vm._v(".")]
                      )
                    ]),
                    _c(
                      "p",
                      {
                        staticClass:
                          "sphone__main__feature__list__contents__title__text"
                      },
                      [
                        _vm._v(" 基礎から応用まで幅広い"),
                        _c("br"),
                        _vm._v("レベルのレッスンを提供 ")
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "sphone__main__feature__list__contents__text"
                  },
                  [
                    _vm._v(
                      " 投稿型サービスなので多種多様なレッスンの中からあなたに合ったレッスンを受講できます。 "
                    )
                  ]
                )
              ]
            )
          ]),
          _c("li", { staticClass: "sphone__main__feature__list" }, [
            _c("img", {
              staticClass: "sphone__main__feature__list__img",
              attrs: {
                src: require("@/assets/img/sphone/sphone_feature_knowledge.png"),
                alt: "プログラミングの学習の時の悩み"
              }
            }),
            _c(
              "div",
              { staticClass: "sphone__main__feature__list__contents" },
              [
                _c(
                  "h3",
                  {
                    staticClass: "sphone__main__feature__list__contents__title"
                  },
                  [
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticClass:
                            "sphone__main__feature__list__contents__title__num"
                        },
                        [_vm._v("3")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass:
                            "sphone__main__feature__list__contents__title__period"
                        },
                        [_vm._v(".")]
                      )
                    ]),
                    _c(
                      "p",
                      {
                        staticClass:
                          "sphone__main__feature__list__contents__title__text"
                      },
                      [_vm._v(" 独学で必要な予備知識を徹底解説(準備中) ")]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "sphone__main__feature__list__contents__text"
                  },
                  [
                    _vm._v(
                      " 学習ロードマップやどこまで学べば 次へ進んでよいかなど、独学する上で悩みがちな箇所の解説を発信します。 "
                    )
                  ]
                )
              ]
            )
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "sphone__main__procedure" }, [
      _c("div", { staticClass: "sphone__main__procedure__wrapper" }, [
        _c("div", { staticClass: "sphone__main__procedure__title" }, [
          _c("img", {
            staticClass: "sphone__main__procedure__title__img",
            attrs: {
              src: require("@/assets/img/sphone/sphone_logo_white.svg"),
              alt: "ロゴ"
            }
          }),
          _c("h2", { staticClass: "sphone__main__procedure__title__text" }, [
            _vm._v("レッスンの受講手順")
          ])
        ]),
        _c("ul", [
          _c("li", { staticClass: "sphone__main__procedure__list" }, [
            _c(
              "div",
              { staticClass: "sphone__main__procedure__list__contents" },
              [
                _c(
                  "h3",
                  {
                    staticClass:
                      "sphone__main__procedure__list__contents__title"
                  },
                  [
                    _vm._v(" 1. パソコンでmanabibaに"),
                    _c("br"),
                    _vm._v("アクセス ")
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "sphone__main__procedure__list__contents__text"
                  },
                  [
                    _vm._v(" manabibaはPCで利用するサービスです。"),
                    _c("br"),
                    _vm._v("Github/Twitter/Google/Facebook"),
                    _c("br"),
                    _vm._v("アカウントで登録できます。 ")
                  ]
                )
              ]
            ),
            _c("img", {
              staticClass: "sphone__main__procedure__list__img",
              attrs: {
                src: require("@/assets/img/sphone/sphone_access.png"),
                alt: "OKのサインをする人"
              }
            }),
            _c("div", {
              staticClass: "sphone__main__procedure__list__triangle"
            })
          ]),
          _c("li", { staticClass: "sphone__main__procedure__list" }, [
            _c(
              "div",
              { staticClass: "sphone__main__procedure__list__contents" },
              [
                _c(
                  "h3",
                  {
                    staticClass:
                      "sphone__main__procedure__list__contents__title"
                  },
                  [_vm._v(" 2. レッスンを選択 ")]
                ),
                _c(
                  "p",
                  {
                    staticClass: "sphone__main__procedure__list__contents__text"
                  },
                  [_vm._v(" 自分に合ったレッスンを選択してください。 ")]
                )
              ]
            ),
            _c("img", {
              staticClass: "sphone__main__procedure__list__img",
              attrs: {
                src: require("@/assets/img/sphone/sphone_choose.png"),
                alt: "プログラミングのコード"
              }
            }),
            _c("div", {
              staticClass: "sphone__main__procedure__list__triangle"
            })
          ]),
          _c("li", { staticClass: "sphone__main__procedure__list" }, [
            _c(
              "div",
              { staticClass: "sphone__main__procedure__list__contents" },
              [
                _c(
                  "h3",
                  {
                    staticClass:
                      "sphone__main__procedure__list__contents__title"
                  },
                  [_vm._v(" 3. 受講スタート ")]
                ),
                _c(
                  "p",
                  {
                    staticClass: "sphone__main__procedure__list__contents__text"
                  },
                  [_vm._v(" ブラウザ内で学習を開始できます。 ")]
                )
              ]
            ),
            _c("img", {
              staticClass: "sphone__main__procedure__list__img",
              attrs: {
                src: require("@/assets/img/sphone/sphone_start.png"),
                alt: "猿のぬいぐるみ"
              }
            })
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "sphone__main__lang" }, [
      _c("div", { staticClass: "sphone__main__lang__wrapper" }, [
        _c("h2", { staticClass: "sphone__main__lang__title" }, [
          _c("img", {
            staticClass: "sphone__main__lang__title__img",
            attrs: {
              src: require("@/assets/img/sphone/sphone_logo_white.svg"),
              alt: "ロゴ"
            }
          }),
          _c("p", { staticClass: "sphone__main__lang__title__text" }, [
            _vm._v("対応言語")
          ])
        ]),
        _c("ul", { staticClass: "sphone__main__lang__contents" }, [
          _c("li", { staticClass: "sphone__main__lang__contents__list" }, [
            _c(
              "div",
              { staticClass: "sphone__main__lang__contents__htmlcss" },
              [
                _c("i", {
                  staticClass: "fab fa-html5 sphone__main__lang__contents__html"
                }),
                _c("i", {
                  staticClass:
                    "fab fa-css3-alt sphone__main__lang__contents__css"
                })
              ]
            ),
            _c(
              "p",
              { staticClass: "sphone__main__lang__contents__list__text" },
              [_vm._v("HTML/CSS")]
            )
          ]),
          _c("li", { staticClass: "sphone__main__lang__contents__list" }, [
            _c("i", {
              staticClass: "fab fa-js sphone__main__lang__contents__js"
            }),
            _c(
              "p",
              { staticClass: "sphone__main__lang__contents__list__text" },
              [_vm._v("Javascript")]
            )
          ]),
          _c("li", { staticClass: "sphone__main__lang__contents__list" }, [
            _c("i", {
              staticClass: "fab fa-php sphone__main__lang__contents__php"
            }),
            _c(
              "p",
              { staticClass: "sphone__main__lang__contents__list__text" },
              [_vm._v("PHP")]
            )
          ]),
          _c("li", { staticClass: "sphone__main__lang__contents__list" }, [
            _c("img", {
              staticClass: "sphone__main__lang__contents__other",
              attrs: {
                src: require("@/assets/img/sphone/sphone_language.svg"),
                alt: "桃 peach"
              }
            }),
            _c(
              "p",
              { staticClass: "sphone__main__lang__contents__list__text" },
              [_vm._v("その他"), _c("br"), _vm._v("順次対応中")]
            )
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "sphone__main__creater" }, [
      _c("div", { staticClass: "sphone__main__creater__wrapper" }, [
        _c("h2", { staticClass: "sphone__main__creater__title" }, [
          _vm._v("レッスンを"),
          _c("br"),
          _vm._v("作ってみたい方も")
        ]),
        _c("p", { staticClass: "sphone__main__creater__text" }, [
          _vm._v(" manabibaではレッスンを"),
          _c("br"),
          _vm._v("作成してくれる方を募集しています。"),
          _c("br"),
          _c("br"),
          _vm._v(" テキストとソースコードがあれば"),
          _c("br"),
          _vm._v("投稿できて、副業としても最適です。"),
          _c("br"),
          _c("br"),
          _vm._v(" manabibaを通じて次世代の"),
          _c("br"),
          _vm._v("エンジニア育成に貢献しませんか？ ")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "sphone__main__official" }, [
      _c(
        "a",
        {
          staticClass: "sphone__main__official__img__wrap",
          attrs: { href: "https://twitter.com/manabiba_jp", target: "_blank" }
        },
        [_c("i", { staticClass: "fab fa-twitter sphone__main__official__img" })]
      ),
      _c("div", { staticClass: "sphone__main__official__contents" }, [
        _c("p", { staticClass: "sphone__main__official__contents__title" }, [
          _vm._v("SNS")
        ]),
        _c("p", { staticClass: "sphone__main__official__contents__text" }, [
          _vm._v(" manabibaの最新情報は公式Twitter"),
          _c("br"),
          _vm._v("@manabiba_jp でチェックできます。 ")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sphone__footer" }, [
      _c("p", [_vm._v("©︎ 2021 manabiba, Inc.")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }