var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.addClass }, [
    _c("img", { attrs: { src: _vm.setLogo, alt: "桃 peach" } })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }