<template>
  <router-link class='lesson-card-purchase'
    :to='{ name: "LessonDetail", params: { id: lessonInfo.id }}'
  >
    <div class='lesson-card-purchase__left'>
      <img
        class='lesson-card-purchase__left__thumbnail'
        :src='lessonInfo.image'
        alt='レッスンのサムネイル'
        @error='setErrorThumbnail' />
      <div class='lesson-card-purchase__left__author'>
        <img
          class='lesson-card-purchase__left__author__avatar'
          :src='setAvatar'
          alt='avatar ユーザーアイコン'
          @error='setErrorAvatar'>
        <p class='lesson-card-purchase__left__author__name'>{{ setAuthor }}</p>
      </div>
    </div>
    <div class='lesson-card-purchase__info'>
      <div class='lesson-card-purchase__info__title'>
        <icon-level class='lesson-card-purchase__info__title__level' :level='lessonInfo.level' />
        <h3 class='lesson-card-purchase__info__title__text'>{{ lessonInfo.title }}</h3>
      </div>
      <p class='lesson-card-purchase__info__description'>{{ lessonInfo.description }}</p>
      <div class='lesson-card-purchase__info__progress'>
        <div>
          <bar-progress
            class='lesson-card-purchase__info__progress__bar'
            :progress='lessonInfo.inProgress.progressRate'
          />
        </div>
        <p>{{ lessonInfo.inProgress.progressRate }}%</p>
      </div>
    </div>
  </router-link>
</template>

<script>
import { BarProgress } from '@/components/atoms/other';
import { IconLevel } from '@/components/atoms/icon';

export default {
  components: {
    BarProgress,
    IconLevel,
  },
  props: {
    lessonInfo: Object,
  },
  computed: {
    setAuthor() {
      if (this.lessonInfo.author === null) return '削除されたユーザー';
      return this.lessonInfo.author;
    },
    setAvatar() {
      if (this.lessonInfo.avatar === null) return `${process.env.VUE_APP_S3_URL}/image/avatar/unknown.png`;
      return this.lessonInfo.avatar;
    },
  },
  methods: {
    setErrorAvatar(e) {
      e.target.src = `${process.env.VUE_APP_S3_URL}/image/avatar/unknown.png`;
    },
    setErrorThumbnail(e) {
      e.target.src = `${process.env.VUE_APP_S3_URL}/image/thumbnail/manabiba.png`;
    },
  },
};
</script>

<style scoped>
img {
  display: block;
}

.lesson-card-purchase {
  display: flex;
  width: 100%;
  cursor: pointer;
  position: relative;
  padding: 15px;
  transition-duration: 0.2s;
  border: 10px solid #fff;
}

.lesson-card-purchase:hover {
  border: 10px solid #F1EFFC;
}

.lesson-card-purchase__left__thumbnail {
  width: 220px;
  height: 123.75px;
}

.lesson-card-purchase__left__author {
  display: flex;
  align-items: center;
  position: relative;
  top: -10px;
}

.lesson-card-purchase__left__author__avatar {
  background-color: #F1EFFC;
  width: 55px;
  height: 55px;
  border-radius: 27.5px;
  border: 3px solid #fff;
  margin-left: 10px;
}

.lesson-card-purchase__left__author__name {
  margin-left: 10px;
  font-size: 12px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-all;
}

.lesson-card-purchase__info {
  margin-left: 20px;
  text-align: left;
  width: 100%;
}

.lesson-card-purchase__info__title {
  display: flex;
  align-items: flex-start;
}
.lesson-card-purchase__info__title__level {
  margin-top: 7px;
  margin-right: 10px;
}
.lesson-card-purchase__info__title__text {
  font-size: 20px;
  font-weight: 500;
  width: 340px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
}

.lesson-card-purchase__info__description {
  margin-top: 10px;
  font-size: 12px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
}

.lesson-card-purchase__info__progress {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.lesson-card-purchase__info__progress__bar {
  width: 280px;
}

.lesson-card-purchase__info__progress p {
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
}
</style>
