var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-custom" }, [
    _c(
      "div",
      { staticClass: "modal-custom__main" },
      [
        _vm._t("default"),
        !_vm.hideButton
          ? _c("button-hide", {
              on: {
                emit: function($event) {
                  _vm.$emit("emit-hide"), _vm.switchOverflow()
                }
              }
            })
          : _vm._e()
      ],
      2
    ),
    !_vm.hideOverlay
      ? _c("div", {
          staticClass: "modal-custom__overlay",
          on: {
            click: function($event) {
              _vm.$emit("emit-hide"), _vm.switchOverflow()
            }
          }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }