<template>
  <div class='lesson-create-confirm'>
    <layout-header />
    <heading-title>
      <template v-slot:main>
        {{ $route.fullPath.includes('create') ?
          'Lesson Create Confirm' : 'Lesson Update Confirm' }}
      </template>
      <template v-slot:sub>
        {{ $route.fullPath.includes('create') ?
          'レッスン投稿確認_' : 'レッスン更新確認_' }}
      </template>
    </heading-title>
    <section class='lesson-create-confirm__info'>
      <div class='lesson-create-confirm__info__back' />
      <div class='lesson-create-confirm__info__front center'>
        <section-top-info-confirm
          class='lesson-create-confirm__info__front__top'
          :thumbnail="postLessonInfo.thumbnail
            ? postLessonInfo.thumbnail
            : defaultThumbnail"
          :lessonInfo='postLessonInfo.setting'
          :levelLabel='levelLabel'
        />
        <div class='lesson-create-confirm__info__front__description'>
          <heading-normal large>レッスン内容</heading-normal>
          <text-normal>{{ postLessonInfo.setting.description }}</text-normal>
        </div>
        <div class='lesson-create-confirm__info__front__target'>
          <heading-normal large>対象ユーザー</heading-normal>
          <text-normal>{{ postLessonInfo.setting.target }}</text-normal>
        </div>
        <div class='lesson-create-confirm__info__front__syllabus'>
          <heading-normal large>シラバス</heading-normal>
          <p class='lesson-create-confirm__info__front__syllabus__description'>
            クリックするとプレビューページで動作を確認できます
          </p>
          <ul class='lesson-create-confirm__info__front__syllabus__list'>
            <li :key='chapter.id' v-for='(chapter, index) in postLessonInfo.setting.chapter'>
              <div
                v-if='postLessonInfo.setting.freeChapterNum === index'
                class='lesson-create-confirm__info__front__syllabus__list__free'
              >↑ここまで無料公開↑</div>
              <list-chapter
                :num='index'
                :title='chapter'
                :disabled='isLoading.submit'
                hideCheck
                @emit-move-page='openPreview(index + 1)'
              />
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section-author :authorInfo='setAuthorInfo' />
    <section class='lesson-create-confirm__post center'>
      <div class='lesson-create-confirm__post__policy'>
        <checkbox-normal
          class='lesson-create-confirm__post__policy__checkbox'
          @emit='active("term")'
          :isActive='isActive.term'
        />
        <div class='lesson-create-confirm__post__policy__text'>
          <p>右規約に同意します</p>
          <a
            class='lesson-create-confirm__post__policy__text__link'
            :href='getRouteData("Terms")'
            target='_blank'
            rel='noopener noreferrer'
          >利用規約</a>
          <p>と</p>
          <a
            class='lesson-create-confirm__post__policy__text__link'
            :href='getRouteData("PrivacyPolicy")'
            target='_blank'
            rel='noopener noreferrer'
          >プライバシーポリシー</a>
        </div>
      </div>
      <button-normal
        :disabled='!isActive.term'
        :loading='isLoading.submit'
        @emit='submitLesson'
        large
      >{{ this.$route.fullPath.includes('create') ? '投稿する' : '更新する' }}</button-normal>
    </section>
    <modal-normal
      v-if='isActive.modalPost'
      :title="this.$route.fullPath.includes('create') ? 'レッスン投稿を完了しました' : 'レッスン更新を完了しました'"
      :text="this.$route.fullPath.includes('create')
        ? 'レッスンは審査完了後公開されます'
        : 'レッスン更新情報は審査完了後に反映されます'"
      acceptBtn='レッスン管理へ'
      cancelBtn='トップへ'
      @emit='postComplete'
      disabledOverlay
    />
    <layout-footer />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import helper from '@/mixins/general/methods/helper';
import { LayoutHeader, LayoutFooter } from '@/components/layouts/general';
import {
  SectionTopInfoConfirm, SectionAuthor, ModalNormal,
} from '@/components/molecules/other';
import { ListChapter } from '@/components/atoms/list';
import { HeadingNormal, HeadingTitle } from '@/components/atoms/heading';
import TextNormal from '@/components/atoms/text/TextNormal';
import { ButtonNormal } from '@/components/atoms/button';
import { CheckboxNormal } from '@/components/atoms/other';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    ListChapter,
    HeadingNormal,
    TextNormal,
    HeadingTitle,
    SectionTopInfoConfirm,
    ButtonNormal,
    CheckboxNormal,
    SectionAuthor,
    ModalNormal,
  },
  data() {
    return {
      isActive: {
        term: false,
        submit: false,
        modalPost: false,
      },
      isLoading: {
        submit: false,
      },
      defaultThumbnail: `${process.env.VUE_APP_S3_URL}/image/thumbnail/manabiba.png`,
      levelLabel: ['初心者', '中級者', '上級者'],
    };
  },
  mixins: [helper],
  computed: {
    ...mapState('lessonCreate', ['postLessonInfo']),
    ...mapState('user', ['userInfo']),
    setAuthorInfo() {
      const authorInfo = {
        name: this.userInfo.profile.userName,
        img: this.userInfo.profile.avatar,
        description: this.userInfo.profile.introduction,
        id: this.userInfo.profile.id,
      };
      return authorInfo;
    },
  },
  methods: {
    ...mapActions('lessonCreate', ['execDeletePostLesson']),
    ...mapActions('lessonAttend', ['loadPreviewLesson']),
    ...mapActions('user', ['addNewPostLesson', 'updatePostLesson']),
    active(target) {
      this.isActive[target] = !this.isActive[target];
    },
    getRouteData(name) {
      return this.$router.resolve({ name }).href;
    },
    // プレビュー画面を別タブで表示する
    openPreview(chapterNum) {
      // プレビュー用レッスン情報をLessonAttend.jsに格納
      this.loadPreviewLesson(this.postLessonInfo.setting);
      this.movePage('LessonPreview', { num: chapterNum }, { path: this.$route.path });
    },
    async submitLesson() {
      try {
        this.isLoading.submit = !this.isLoading.submit;

        if (this.$route.fullPath.includes('create')) {
          // バックエンドに渡すデータの生成
          const data = new FormData();
          if (this.postLessonInfo.setting !== null) {
            const postSetting = {
              title: this.postLessonInfo.setting.title,
              level: this.postLessonInfo.setting.level,
              price: this.postLessonInfo.setting.price,
              version: this.postLessonInfo.setting.version,
              description: this.postLessonInfo.setting.description,
              target: this.postLessonInfo.setting.target,
              tag: this.postLessonInfo.setting.tag,
              chapter: this.postLessonInfo.setting.chapter,
              free_chapter_num: this.postLessonInfo.setting.freeChapterNum,
            };
            data.append('lessonInfo', JSON.stringify(postSetting));
          }
          if (this.postLessonInfo.thumbnail !== null) data.append('thumbnail', this.postLessonInfo.thumbnail);
          if (this.postLessonInfo.content !== null) data.append('content', JSON.stringify(this.postLessonInfo.content));
          if (this.postLessonInfo.freeContent.length !== 0) data.append('free_content', JSON.stringify(this.postLessonInfo.freeContent));

          // バックエンド側に送信
          this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
          const res = await this.$http.post('/api/creator/lessons', data, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
          await this.addNewPostLesson(res.data);
        } else {
          // バックエンドに渡すデータの生成
          const data = new URLSearchParams();
          if (this.postLessonInfo.change.setting === true) {
            const postSetting = {
              title: this.postLessonInfo.setting.title,
              level: this.postLessonInfo.setting.level,
              price: this.postLessonInfo.setting.price,
              version: this.postLessonInfo.setting.version,
              description: this.postLessonInfo.setting.description,
              target: this.postLessonInfo.setting.target,
              tag: this.postLessonInfo.setting.tag,
              chapter: this.postLessonInfo.setting.chapter,
              free_chapter_num: this.postLessonInfo.setting.freeChapterNum,
            };
            data.append('lessonInfo', JSON.stringify(postSetting));
          }
          if (this.postLessonInfo.change.thumbnail === true) data.append('thumbnail', this.postLessonInfo.thumbnail);
          if (this.postLessonInfo.change.content === true) data.append('content', JSON.stringify(this.postLessonInfo.content));
          if (this.postLessonInfo.change.freeChapterNum === true) data.append('free_content', JSON.stringify(this.postLessonInfo.freeContent));
          // レッスン更新
          this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
          const res = await this.$http.put(`/api/creator/lessons/${this.$route.params.id}`, data, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          });
          await this.updatePostLesson(res.data);
        }

        this.isLoading.submit = !this.isLoading.submit;
        this.active('modalPost');
        this.active('submit');
      } catch (e) {
        this.isLoading.submit = !this.isLoading.submit;
        if (e.response.status === 401) {
          this.active('submit');
          this.$store.commit('user/logout');
          this.movePage('Login', '', { url: this.$route.path });
        } else {
          this.showNoticeBar(
            'error',
            'レッスンの投稿に失敗しました',
          );
        }
      }
    },
    postComplete(answer) {
      if (answer) {
        this.movePage('UserInfoLessons');
      } else {
        this.movePage('Top');
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'LessonPreview') {
      next();
    } else if (this.isActive.submit) {
      // Stateのレッスン情報を破棄
      this.execDeletePostLesson();
      next();
    } else {
      const answer = window.confirm('レッスン情報は削除されますがよろしいですか？');
      if (answer) {
        // レッスン情報削除
        this.execDeletePostLesson();
        next();
      } else {
        next(false);
      }
    }
  },
};
</script>

<style scoped>
.lesson-create-confirm {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

.lesson-create-confirm__info {
  position: relative;
}

.lesson-create-confirm__info__back {
  width: 100%;
  height: 50px;
  position: absolute;
  z-index: 1;
  background-color: #4CAF50;
}

.lesson-create-confirm__info__front {
  position: relative;
  padding: 0 60px;
  z-index: 2;
}

.lesson-create-confirm__info__front__description {
  padding: 40px 20px;
  border-bottom: 1px solid #F2EEEE;
}

.lesson-create-confirm__info__front__target {
  padding: 40px 20px;
  border-bottom: 1px solid #F2EEEE;
}

.lesson-create-confirm__info__front__syllabus {
  padding: 40px 20px;
  overflow: hidden;
}

.lesson-create-confirm__info__front__syllabus__description {
  font-size: 14px;
  color: #E74860;
}

.lesson-create-confirm__info__front__syllabus__list {
  list-style: none;
}

.lesson-create-confirm__info__front__syllabus__list__free {
  font-size: 14px;
  background-color: #2E64D4;
  padding: 5px 0;
  color: #fff;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.lesson-create-confirm__info__front__syllabus__list li {
  margin-top: 10px;
}

.lesson-create-confirm__post {
  padding: 40px 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lesson-create-confirm__post__policy {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #F2EEEE;
}

.lesson-create-confirm__post__policy__checkbox {
  margin: 5px 5px 0 0;
}

.lesson-create-confirm__post__policy__text {
  display: flex;
}

.lesson-create-confirm__post__policy__text__link {
  color: #E74860;
}
</style>
