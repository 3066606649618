<template>
  <label class='input-file-lesson center'>
    <div :class='addClass'>
      <i class='fas fa-folder fa-5x input-file-lesson__form__icon' />
      <div class="input-file-lesson__form__text">
        <slot />
      </div>
    </div>
    <input
      class='input-file-lesson__hide'
      id='lessonForm'
      type='file'
      webkitdirectory
      @change='loadPostLesson'
    >
    <div class='input-file-lesson__error'>
      {{errorMsg}}
    </div>
  </label>
</template>

<script>
export default {
  props: {
    errorMsg: String,
  },
  computed: {
    addClass() {
      return {
        'input-file-lesson__form': true,
        'input-file-lesson__form--error': this.errorMsg,
      };
    },
  },
  methods: {
    async loadPostLesson($event) {
      await this.$emit('emit-post-lesson', $event);
      const lessonForm = await document.getElementById('lessonForm');
      lessonForm.value = await null;
    },
  },
};
</script>

<style scoped>
.input-file-lesson__form {
  border: 5px dashed #F7F7F7;
  background-color: white;
  padding: 60px 0px;
  text-align: center;
  cursor: pointer;
}

.input-file-lesson__form:hover {
  background-color: #fafafaf3;
}

.input-file-lesson__form__icon {
  text-align: center;
  color: #4CAF50;
}

.input-file-lesson__form__text {
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
}

.input-file-lesson__hide {
  display: none;
}

.input-file-lesson__error {
  color: rgb(255, 147, 169);
  margin-top: 5px;
  font-size: 12px;
}
.input-file-lesson__form--error {
  border: 5px dashed rgb(255, 147, 169);
}
</style>
