var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "lesson-output" }, [
    _c(
      "div",
      { staticClass: "lesson-output__top" },
      [
        _c("lesson-output-tab", {
          attrs: { tabInfo: _vm.tabInfo },
          on: { emit: _vm.changeTab }
        }),
        _c(
          "div",
          { staticClass: "lesson-output__top__func" },
          [
            _vm.select.output === 0
              ? _c("input-url", {
                  staticClass: "lesson-output__top__func__url",
                  attrs: {
                    target: "assignment",
                    path: _vm.viewHtml.assignment
                  },
                  on: { "emit-path": _vm.savePath }
                })
              : _vm._e(),
            _vm.select.output === 1
              ? _c("input-url", {
                  staticClass: "lesson-output__top__func__url",
                  attrs: { target: "sample", path: _vm.viewHtml.sample },
                  on: { "emit-path": _vm.savePath }
                })
              : _vm._e()
          ],
          1
        )
      ],
      1
    ),
    _c("div", { staticClass: "lesson-output__bottom" }, [
      _c("iframe", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.select.output === 0,
            expression: "select.output === 0"
          }
        ],
        class: _vm.addIframeClass("assignment"),
        attrs: { id: "assignment", sandbox: "allow-same-origin" }
      }),
      _c("iframe", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.select.output === 1,
            expression: "select.output === 1"
          }
        ],
        class: _vm.addIframeClass("sample"),
        attrs: { id: "sample", sandbox: "allow-same-origin" }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }