var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-search" }, [
    _c("i", { staticClass: "fas fa-search" }),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.text,
          expression: "text"
        }
      ],
      attrs: { type: "text", placeholder: _vm.placeholder },
      domProps: { value: _vm.text },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.emitSearch($event)
        },
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.text = $event.target.value
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }