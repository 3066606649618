<template>
  <div class='form-signup'>
    <div class='form-signup__main'>
      <heading-normal class='form-signup__main__title' large>{{ form }}</heading-normal>
      <div class='form-signup__main__input input__margin__top'>
        <heading-normal small>{{ userName }}</heading-normal>
        <input-text @emit='saveUsername' :errorMsg='errorMsg.username' />
      </div>
      <div class='form-signup__main__input'>
        <heading-normal small>{{ password }}</heading-normal>
        <input-password @emit='savePassword' :errorMsg='errorMsg.password'/>
      </div>
      <div class='form-signup__main__input'>
        <heading-normal small>{{ repassword }}</heading-normal>
        <input-password @emit='saveRepassword' :errorMsg='errorMsg.repassword'/>
      </div>
      <div class='form-signup__main__input'>
        <heading-normal small>{{ hash }}</heading-normal>
        <input-text @emit='saveHash' />
      </div>
      <div class='form-signup__main__button'>
        <button-normal
          @emit='switchQRModal'
          :disabled='signinButttonDisabled'
        large>2段階認証へ</button-normal>
      </div>
    </div>
    <modal-confirm
      v-if='switchModal'
      :isLoading='isLoading'
      title='QRコードを用いて2段階認証を行います'
      text='Google Authenticatorをお使いのスマホにインストールしてください'
      acceptBtn='サインアップ'
      cancelBtn='戻る'
      @emit='signupComplete'
      disabledOverlay
    />
  </div>
</template>

<script>
import { ModalConfirm } from '@/components/molecules/other';
import { HeadingNormal } from '@/components/atoms/heading/';
import { InputText, InputPassword } from '@/components/atoms/input';
import { ButtonNormal } from '@/components/atoms/button';

export default {
  components: {
    ModalConfirm,
    HeadingNormal,
    InputText,
    ButtonNormal,
    InputPassword,
  },
  props: {
    form: String,
    userName: String,
    password: String,
    repassword: String,
    hash: String,
    isLoading: Boolean,
  },
  data() {
    return {
      switchModal: false,
      signupInfo: {
        userName: '',
        password: '',
        repassword: '',
        hash: '',
      },
      errorMsg: {
        username: '',
        password: '',
        repassword: '',
      },
    };
  },
  computed: {
    signinButttonDisabled() {
      if (this.signupInfo.userName.length >= 8
        && this.signupInfo.password.length >= 8
        && this.signupInfo.repassword === this.signupInfo.password
        && this.signupInfo.hash.length > 0) { return false; }
      return true;
    },
  },
  methods: {
    switchQRModal() {
      this.switchModal = !this.switchModal;
    },
    saveUsername(userName) {
      this.signupInfo.userName = userName;
      if (userName.length === 0) {
        this.errorMsg.username = '';
      } else if (userName.length > 0 && userName.length < 8) {
        this.errorMsg.username = 'ユーザーネームは8文字以上入力してください';
      } else if (userName.length >= 8) {
        this.errorMsg.username = '';
      }
    },
    savePassword(password) {
      this.signupInfo.password = password;
      if (password.length === 0) {
        this.errorMsg.password = '';
      } else if (password.length > 0 && password.length < 8) {
        this.errorMsg.password = 'パスワードは8文字以上入力してください';
      } else if (password.length >= 8) {
        this.errorMsg.password = '';
      }
    },
    saveRepassword(repassword) {
      this.signupInfo.repassword = repassword;
      if (repassword.length === 0) {
        this.errorMsg.repassword = '';
      } else if (this.signupInfo.password === repassword) {
        this.errorMsg.repassword = '';
      } else {
        this.errorMsg.repassword = 'パスワードと同じ値を入力してください';
      }
    },
    saveHash(hash) {
      this.signupInfo.hash = hash;
    },
    async signupComplete(res) {
      if (res.answer) {
        await this.$emit('emit-admin-signup', {
          signupInfo: this.signupInfo,
          secret: res.secret,
          token: res.token,
        });
      } else {
        this.switchQRModal();
      }
    },
  },
};
</script>

<style>
.form-signup {
  width: 700px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 40px;
}

.form-signup__main__title {
  text-align: center;
}

.form-signup__main__input {
  margin-bottom: 20px;
  font-weight: 500;
}

.input__margin__top {
  margin-top: 40px;
}

.form-signup__main__button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}
</style>
