var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-confirm" }, [
    _c(
      "div",
      { staticClass: "modal-confirm__main" },
      [
        _c("h3", { staticClass: "modal-confirm__main__title" }, [
          _vm._v(_vm._s(_vm.title))
        ]),
        _c("p", { staticClass: "modal-confirm__main__text" }, [
          _vm._v(_vm._s(_vm.text))
        ]),
        !_vm.isLoading
          ? _c("img", { attrs: { src: _vm.qrCode, alt: "QRコード" } })
          : _vm._e(),
        _vm.isLoading ? _c("icon-loading") : _vm._e(),
        _c("heading-normal", { attrs: { small: "" } }, [
          _vm._v("表示された6桁の数字を入力してください")
        ]),
        _c("input-text", {
          attrs: { maxLength: _vm.maxLength },
          on: { emit: _vm.saveToken }
        }),
        _c(
          "div",
          { staticClass: "modal-confirm__main__btn" },
          [
            _c(
              "button-normal",
              {
                staticClass: "modal-confirm__main__btn__cancel",
                attrs: { outline: "" },
                on: {
                  emit: function($event) {
                    _vm.$emit("emit", { answer: false }), _vm.switchOverflow()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.cancelBtn))]
            ),
            _c(
              "button-normal",
              {
                attrs: { disabled: _vm.isLoading || !_vm.token },
                on: {
                  emit: function($event) {
                    _vm.$emit("emit", {
                      answer: true,
                      token: _vm.token,
                      secret: _vm.secret
                    }),
                      _vm.switchOverflow()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.acceptBtn))]
            )
          ],
          1
        )
      ],
      1
    ),
    !_vm.disabledOverlay
      ? _c("div", {
          staticClass: "modal-confirm__overlay",
          on: {
            click: function($event) {
              _vm.$emit("emit", false), _vm.switchOverflow()
            }
          }
        })
      : _vm._e(),
    _vm.disabledOverlay
      ? _c("div", { staticClass: "modal-confirm__overlay" })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }