<template>
  <div class='sales'>
    <layout-header />
    <div class='sales__main center'>
      <section-menu
        :userInfo='userInfo'
        :pageList='createTitleList'
        @emit-menu-index='movePageMenu'
        :defaultSelectId='3'
      />
      <div class='sales__main__right'>
        <heading-normal  class='sales__main__right__title' white large>販売管理_</heading-normal>
        <div v-if='!isLoading.accountInfo'>
          <section class='sales__main__right__before-judge' v-if='!checkStepComplete'>
            <div class='sales__main__right__before-judge__description'>
              <heading-normal yellow>有料レッスン投稿申請_</heading-normal>
              <p>有料レッスンを投稿するには以下2STEPの申請を行う必要があります。<br>それぞれのフォームに項目を入力し申請を行ってください</p>
            </div>
            <div class='sales__main__right__before-judge__stepone'>
              <div
                class='sales__main__right__before-judge__stepone__noattend'
                v-if='!accountCheck.step1Complete'
              >
                <div class='sales__main__right__before-judge__stepone__noattend__top'>
                  <div class='sales__main__right__before-judge__stepone__noattend__top__left'>
                    <h3>STEP1</h3>
                    <p>お名前、ご住所などお客様情報を入力してください</p>
                    <div class='sales__main__right__before-judge__stepone__description'>
                      <p>申請を行うことで</p>
                      <a
                        class='sales__main__right__before-judge__stepone__description__link'
                        :href='getRouteData("Terms")'
                        target='_blank'
                        rel='noopener noreferrer'
                      >利用規約</a>
                      <p>、</p>
                      <a
                        class='sales__main__right__before-judge__stepone__description__link'
                        :href='getRouteData("PrivacyPolicy")'
                        target='_blank'
                        rel='noopener noreferrer'
                      >プライバシーポリシー</a>
                      <p>、</p>
                      <a
                        class='sales__main__right__before-judge__stepone__description__link'
                        :href='getRouteData("CheckoutService")'
                        target='_blank'
                        rel='noopener noreferrer'
                      >決済について</a>
                      <p>に同意したとみなします</p>
                    </div>
                  </div>
                  <button-normal
                    small
                    :loading='isLoading.applyCustomAccount'
                    @emit='applyCustomAccount()'
                  >申請</button-normal>
                </div>
              </div>
              <div
                class='sales__main__right__before-judge__stepone__attend'
                v-if='accountCheck.step1Complete'
              >
                <div>
                  <h3>STEP1</h3>
                  <i class='fas fa-check'/>
                </div>
                <p>お名前、ご住所などお客様情報を入力してください</p>
              </div>
            </div>
            <div
              class='sales__main__right__before-judge__steptwo'
              v-if='!isLoading.accountInfo'
            >
              <div class='sales__main__right__before-judge__steptwo__top'>
                <div class='sales__main__right__before-judge__steptwo__top__left'>
                  <h3>STEP2</h3>
                  <p>売上を送金するお客様の口座情報を入力してください</p>
                </div>
                <button-normal
                  :disabled='!isValidBank'
                  :loading='isLoading.updateBank'
                  @emit='execUpdateBank'
                  small
                >申請</button-normal>
              </div>
              <bank-form :upload='upload' :errorMsg="errorMsg"/>
            </div>
          </section>
          <section class='sales__main__right__after-judge' v-else>
            <div class='sales__main__right__after-judge__info'>
              <heading-normal yellow>売上情報_</heading-normal>
              <ul class='sales__main__right__after-judge__info__data'>
                <li>
                  <div>
                    <p>売上残高</p>
                    <h3>¥{{ setBalance }}</h3>
                  </div>
                </li>
                <li>
                  <div>
                    <p>振込可能額</p>
                    <h3>¥{{ setAvailableWithOutFee }}</h3>
                  </div>
                  <p
                    v-if='payoutStatus'
                  >
                    {{ payoutStatus }}です
                  </p>
                  <button-normal
                    v-else
                    :disabled='judgeEnableTransfer || isLoading.updateBank'
                    :loading='isLoading.payout'
                    small
                    @emit='modalActive'
                  >振込申請</button-normal>
                </li>
              </ul>
              <div class='sales__main__right__after-judge__info__warn'>
                <p>・振込可能額が¥3,000以上の場合、振込申請が可能になります</p>
                <p>・振込可能額は売上残高から事務手数料、振込手数料を引いた額です</p>
              </div>
            </div>
            <div class='sales__main__right__after-judge__bank'>
              <heading-normal yellow>口座情報_</heading-normal>
              <bank-form
                v-if='!isLoading.accountInfo'
                :bankNumberPlaceHolder='bankNumberPlaceHolder'
                :upload='upload'
                :errorMsg='errorMsg'
              />
              <div class='sales__main__right__after-judge__bank__info'>
                <button-normal
                  large
                  :disabled='!isValidBank || bankUpdateDisabled'
                  :loading='isLoading.updateBank'
                  @emit='execUpdateBank'
                >口座情報を更新</button-normal>
              </div>
            </div>
          </section>
        </div>
        <div class='sales__main__right__loading' v-else>
          <icon-loading/>
        </div>
      </div>
    </div>
    <modal-normal
      v-if='isActive.modalRequestTransfer'
      title='売上振込'
      :text="'振込可能額¥' + setAvailableWithOutFee + '円を「口座情報」に指定された口座に振り込みます。'"
      cancelBtn='キャンセル'
      acceptBtn='振込'
      @emit='execPayout'
    />
    <layout-footer />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { loadStripe } from '@stripe/stripe-js';
import helper from '@/mixins/general/methods/helper';
import pageList from '@/mixins/general/data/userInfoPageList';
import { LayoutHeader, LayoutFooter } from '@/components/layouts/general/';
import { IconLoading } from '@/components/atoms/icon/';
import { ModalNormal } from '@/components/molecules/other';
import { HeadingNormal } from '@/components/atoms/heading';
import { ButtonNormal } from '@/components/atoms/button';
import BankForm from '@/components/molecules/general/userInfo/BankForm';
import SectionMenu from '@/components/molecules/general/userInfo/SectionMenu';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    IconLoading,
    BankForm,
    SectionMenu,
    ButtonNormal,
    HeadingNormal,
    ModalNormal,
  },
  data() {
    return {
      isActive: {
        modalRequestTransfer: false,
      },
      isLoading: {
        accountInfo: false,
        applyCustomAccount: false,
        updateBank: false,
        payout: false,
      },
      upload: {
        bankCode: null,
        bankBranch: null,
        bankNumber: null,
        bankUname: null,
      },
      errorMsg: {
        bankCode: '',
        bankBranch: '',
        bankNumber: '',
        bankUname: '',
      },
      stripe: null,
      onBoardingUrl: '',
      accountCheck: {
        exist: false,
        step1Complete: false,
        step2Complete: false,
      },
      bankUpdateDisabled: false,
      bankNumberPlaceHolder: null, // 口座番号用プレースホルダー
      balance: null, // 残金残高
      available: null, // 入金可能残高
      availableWithoutFee: null, // 手数料を除いた入金可能額(ユーザーが実際に口座に移動できる金額はこちら)
      transfer: null, // 振込金額
      minTransferPrice: 3000, // 最低踏み込み可能金額
      payoutStatus: null, // 入金申請のステータス
    };
  },
  async created() {
    this.loading('accountInfo');
    await this.getAccountInfo();
    this.loading('accountInfo');
  },
  mixins: [helper, pageList],
  computed: {
    ...mapState('user', ['userInfo']),
    createTitleList() {
      const titleList = this.pageList.map((page) => page.title);
      return titleList;
    },
    isValidBank() {
      // step1完了チェック
      if (this.accountCheck.step1Complete === false) return false;

      // アップロード情報Nullチェック
      if (this.upload.bankCode === null
          || this.upload.bankBranch === null
          || this.upload.bankNumber === null
          || this.upload.bankUname === null) {
        return false;
      }

      // エラーメッセージ空チェック
      if (this.errorMsg.bankCode === ''
          && this.errorMsg.bankBranch === ''
          && this.errorMsg.bankNumber === ''
          && this.errorMsg.bankUname === '') {
        return true;
      }
      return false;
    },
    checkStepComplete() {
      return this.accountCheck.step1Complete && this.accountCheck.step2Complete;
    },
    judgeEnableTransfer() {
      return this.availableWithoutFee < this.minTransferPrice;
    },
    setBalance() {
      return this.balance !== null
        ? this.balance.toLocaleString() : 0;
    },
    setAvailableWithOutFee() {
      return this.availableWithoutFee !== null
        ? this.availableWithoutFee.toLocaleString() : 0;
    },
    setTransfer() {
      return this.transfer !== null
        ? this.transfer.toLocaleString() : 0;
    },
  },
  methods: {
    ...mapActions('user', ['createPayout', 'loadAccountInfo', 'setPayoutApply']),
    movePageMenu(index) {
      const { name } = this.pageList[index];
      this.$router.push({ name }, () => {});
    },
    getRouteData(name) {
      return this.$router.resolve({ name }).href;
    },
    active(target) {
      this.isActive[target] = !this.isActive[target];
    },
    loading(target) {
      this.isLoading[target] = !this.isLoading[target];
    },
    modalActive() {
      this.active('modalRequestTransfer');
    },
    async execPayout(event) {
      this.active('modalRequestTransfer');
      this.loading('payout');
      if (event) await this.createPayoutAndApply(this.availableWithoutFee);
      this.loading('payout');
    },
    async execUpdateBank() {
      try {
        this.loading('updateBank');
        if (!this.isValidBank) throw new Error();

        const token = await this.getBankInfoToken();
        const res = await this.setBanktoAccount(token);
        if (!this.accountCheck.step1Complete
            || !this.accountCheck.step2Complete) {
          await this.getAccountInfo();
        }

        if (res === 401) {
          this.$store.commit('user/logout');
          this.movePage('Login', '', { url: this.$route.path });
        } else if (await res) {
          this.showNoticeBar('success', '口座情報の申請を完了しました');
        } else {
          throw new Error();
        }
        this.bankUpdateDisabled = true;
        this.loading('updateBank');
      } catch (e) {
        this.loading('updateBank');
        this.showNoticeBar('error', '口座情報の申請に失敗しました');
      }
    },
    async setBanktoAccount(token) {
      try {
        this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
        await this.$http.post('/api/auth/user/account/bank', {
          token: JSON.stringify(token.id),
        });
        if (!this.accountCheck.step2Complete) this.accountCheck.step2Complete = true;
        return true;
      } catch (e) {
        if (e.response.status === 401) return e.response.status;
        return false;
      }
    },
    async applyCustomAccount() {
      this.loading('applyCustomAccount');
      if (!this.accountCheck.exist) await this.createAccount();
      await this.createAccountLink();
      await this.getAccountInfo();
    },
    async createAccount() {
      try {
        this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
        await this.$http.post('/api/auth/user/account/create');
      } catch (e) {
        this.movePage('Error');
      }
    },
    async createAccountLink() {
      try {
        this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
        const { data } = await this.$http.get('/api/auth/user/account/link');
        this.onBoardingUrl = data.url;
      } catch (e) {
        this.movePage('Error');
      }
    },
    async createPayoutAndApply(payoutAmount) {
      try {
        this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
        const { data } = await this.$http.post('/api/auth/user/account/payout', {
          amount: payoutAmount,
        });
        if (data.errors) {
          this.showNoticeBar('error', '口座への入金申請に失敗しました');
        } else {
          this.payoutStatus = '申請中';
          this.showNoticeBar('success', '只今申請中です');
        }
      } catch (e) {
        if (e.response.status === 401) this.movePage('Login', '', { url: this.$route.path });
        if (e.response.status === 500) this.movePage('Error');
        this.showNoticeBar('error', '口座への入金申請に失敗しました');
      }
    },
    async getAccountInfo() {
      try {
        this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
        const { data } = await this.$http.get('/api/auth/user/account');
        this.accountCheck.exist = true;
        this.accountCheck.step1Complete = data.charges_enabled;
        this.accountCheck.step2Complete = data.payouts_enabled;
        if (data.charges_enabled && data.payouts_enabled) {
          await this.getBalance();
          await this.getBankInfo();
        }
        if (data.metadata.payout_status === '審査中') {
          this.payoutStatus = data.metadata.payout_status;
        }
        if (data.metadata.payout_event_id) {
          if (data.failureMessage === null) {
            this.showNoticeBar('success', '口座への入金申請に成功しました');
          } else {
            this.showNoticeBar('error', '口座への入金申請に失敗しました');
          }
        }
      } catch (e) {
        if (e.response.status === 401) this.movePage('Login', '', { url: this.$route.path });
        if (e.response.status === 500) this.movePage('Error');
      }
    },
    async getBankInfo() {
      this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
      const { data } = await this.$http.get('/api/auth/user/account/bank');
      this.upload.bankCode = data.routing_number.substr(0, 4);
      this.upload.bankBranch = data.routing_number.substr(4);
      this.upload.bankNumber = null;
      this.upload.bankUname = data.account_holder_name;
      this.bankNumberPlaceHolder = `XXX${data.last4}`;
    },
    async getBankInfoToken() {
      this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
      const result = await this.stripe.createToken('bank_account', {
        country: 'JP',
        currency: 'jpy',
        routing_number: this.upload.bankCode + this.upload.bankBranch,
        account_number: this.upload.bankNumber,
        account_holder_name: this.upload.bankUname,
        account_holder_type: 'individual',
      });
      return result.token;
    },
    async getBalance() {
      this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
      const { data } = await this.$http.get('/api/auth/user/account/balance');
      this.available = Math.floor(data.available);
      this.balance = Math.floor(data.balance);
    },
  },
  watch: {
    upload: {
      handler() {
        this.bankUpdateDisabled = false;
      },
      deep: true,
    },
    onBoardingUrl() {
      window.location.href = this.onBoardingUrl;
    },
    checkString(inputdata) {
      const re = /[^0-9]+/i;
      return re.test(inputdata);
    },
    available(to) {
      // 振込可能額から手数料分をマイナスし、availableWithoutFeeに返却
      // 事務手数料(toの0.25%, 100円), 送金手数料(250円) + 税
      const tax = 1.1;
      const transfer = (to * 0.0025 * tax) + (100 * tax) + (250 * tax);
      const priceWithoutFee = to - transfer;
      this.availableWithoutFee = Math.sign(priceWithoutFee) !== -1
        ? Math.floor(priceWithoutFee) : 0;
    },
  },
};
</script>

<style scoped>
.sales {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #4CAF50;
}

.sales__main {
  width: auto;
  display: flex;
  align-items: flex-start;
  margin-top: 40px;
  margin-bottom: 80px;
}

.sales__main__right {
  width: 700px;
  margin-left: 20px;
  margin-top: 50px;
}

.sales__main__right__loading {
  background-color: #fff;
  padding: 20px 0;
}

.sales__main__right__title {
  padding-bottom: 10px;
}

.sales__main__right__before-judge {
  background-color: #fff;
}

.sales__main__right__before-judge__description {
  padding: 40px;
  border-bottom: 1px solid #F2EEEE;
}

.sales__main__right__before-judge__stepone {
  padding: 40px;
  border-bottom: 1px solid #F2EEEE;
}

.sales__main__right__before-judge__stepone__noattend__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sales__main__right__before-judge__stepone__noattend__top__left h3 {
  font-weight: 600;
  font-size: 20px;
}

.sales__main__right__before-judge__stepone__attend div {
  display: flex;
  align-items: center;
}

.sales__main__right__before-judge__stepone__attend div i {
  color: #2E64D4;
  font-size: 18px;
  margin-left: 5px;
}

.sales__main__right__before-judge__stepone__description {
  display: flex;
  font-size: 11px;
  flex-wrap: wrap;
}

.sales__main__right__before-judge__stepone__description__link {
  color: #E74860;
}

.sales__main__right__before-judge__steptwo {
  padding: 40px;
  border-bottom: 1px solid #F2EEEE;
}

.sales__main__right__before-judge__steptwo span{
  font-weight: normal;
  color: #93a5b1;
  font-size: 12.5px;
}

.sales__main__right__before-judge__steptwo__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sales__main__right__before-judge__steptwo__bottom div {
  flex-basis: 180px;
}

.sales__main__right__before-judge__steptwo__bottom label {
  font-weight: 700;
}

.sales__main__right__before-judge__steptwo__bottom li {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.kind__btn {
  display: none;
}

.sales__main__right__before-judge__steptwo__bottom__bank__kind__right label {
  width: 300px;
  font-size: 0.9rem;
  font-weight: 100;
  border-radius: 4px;
  padding: 0.15em 0.4em;
  color: #93a5b1;
}

.kind__btn:checked + label {
  color: #000;
  padding: 0.15em 0.4em;
  border: solid 1px #cfdce6;
  border-radius: 4px;
}

.sales__main__right__after-judge {
  background-color: #fff;
}

.sales__main__right__after-judge__info {
  padding: 40px;
  border-bottom: 1px solid #F2EEEE;
}

.sales__main__right__after-judge__info__data {
  list-style: none;
  display: flex;
  width: 100%;
}

.sales__main__right__after-judge__info__data li {
  background-color: #F7F7F7;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sales__main__right__after-judge__info__data li:first-child {
  margin-left: 0;
}

.sales__main__right__after-judge__info__data li p {
  font-size: 14px;
}

.sales__main__right__after-judge__info__data li h3 {
  font-size: 20px;
  font-weight: 600;
}

.sales__main__right__after-judge__info__warn{
  font-size: 12px;
  margin-top: 10px;
}

.sales__main__right__after-judge__transfer__info__left {
  display: flex;
  align-items: center;
}

.sales__main__right__after-judge__transfer__info__left p {
  font-size: 14px;
}

.sales__main__right__after-judge__transfer__info__left h3 {
  font-size: 20px;
  margin-left: 10px;
}

.sales__main__right__after-judge__bank {
  padding: 40px;
}

.sales__main__right__after-judge__bank__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}
</style>
