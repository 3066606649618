<template>
  <div :id='id' class='lesson-ace' @keyup='emitSource'/>
</template>

<script>

export default {
  props: {
    id: Number,
    filename: String,
    source: String,
    resetSource: String,
    resetFlg: Boolean,
    readonly: Boolean,
  },
  data() {
    return {
      editSource: String,
      language: String,
    };
  },
  methods: {
    emitSource() {
      this.editSource = this.editor.getValue();
      this.$emit('emit-source', this.filename, this.editSource);
    },
  },
  mounted() {
    // コードハイライト処理
    const lang = (this.filename).substr(this.filename.indexOf('.') + 1);
    if (lang === 'js') {
      this.language = 'javascript';
    } else {
      this.language = lang;
    }

    // editor基本設定
    this.editor = window.ace.edit(String(this.id));
    this.editor.setValue(this.source, -1);

    if (this.readonly) {
      this.editor.setReadOnly(true);
    }

    // テーマ・ハイライト設定
    this.editor.getSession().setMode(`ace/mode/${this.language}`);
    this.editor.setTheme('ace/theme/merbivore_soft');

    // その他オプション
    this.editor.setOptions({
      tabSize: 2,
      enableBasicAutocompletion: true,
      enableSnippets: true,
      enableLiveAutocompletion: true,
    });

    // 初回読み込みのソースコードをEmit
    this.emitSource();
  },
  watch: {
    // コード実行イベント取得後codeRunメソッド実行 実行イベントを親に返却
    resetFlg() {
      this.editor.setValue(this.resetSource, -1);
      this.editSource = this.editor.getValue();
      this.$emit('emit-source', this.filename, this.editSource);
    },
  },
};
</script>

<style scoped>
.lesson-ace {
  width: 100%;
  height: calc(100vh - 106px);
}
</style>
