<template>
  <div class='icon-role'>
    <div class='icon-role__ambassador' v-if='ambassador'>
      <i class='fas fa-check' />
      <p>Ambassador</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ambassador: Boolean,
  },
};
</script>

<style scoped>
.icon-role__ambassador {
  display: flex;
  align-items: center;
  padding: 5px 8px;
  border-radius: 20px;
  background-color: rgb(89, 0, 255);
  color: #fff;
}

.icon-role__ambassador i {
  font-size: 16px;
}

.icon-role__ambassador p {
  font-size: 12px;
  font-weight: 600;
  margin-left: 5px
}
</style>
