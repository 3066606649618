import Vue from 'vue';
import VueRouter from 'vue-router';
import Store from '@/store/index.js';
import axios from 'axios';

import Top from '@/views/general/Top';
import Login from '@/views/general/Login';
import Categories from '@/views/general/Categories';
import Search from '@/views/general/Search';
import LessonDetail from '@/views/general/LessonDetail';
import LessonAttend from '@/views/general/LessonAttend';
import UserInfoSetting from '@/views/general/UserInfo/Setting';
import UserInfoSales from '@/views/general/UserInfo/Sales';
import UserInfoLessons from '@/views/general/UserInfo/Lessons';
import UserInfoBilling from '@/views/general/UserInfo/Billing';
import NotFound from '@/views/general/NotFound';
import Error from '@/views/general/Error';
import LessonCreate from '@/views/general/LessonCreate';
import LessonCreateConfirm from '@/views/general/LessonCreateConfirm';
import PrivacyPolicy from '@/views/general/PrivacyPolicy';
import CheckoutService from '@/views/general/CheckoutService';
import Terms from '@/views/general/Terms';
import Help from '@/views/general/Help';
import NewsList from '@/views/general/NewsList';
import News from '@/views/general/News';
import User from '@/views/general/User';
import About from '@/views/general/About';
// import Company from '@/views/general/Company';
import Cart from '@/views/general/Cart';
import CartCheckout from '@/views/general/CartCheckout';
import CartCheckoutComplete from '@/views/general/CartCheckoutComplete';
import Maintenance from '@/views/general/Maintenance';
import Callback from '@/views/general/Callback';

import Sphone from '@/views/general/sphone/Top';

import AdminNotFound from '@/views/admin/NotFound';
import AdminError from '@/views/admin/Error';
import AdminLogin from '@/views/admin/Login';
import AdminSignup from '@/views/admin/Signup';
import AdminCmsList from '@/views/admin/cms/List';
import AdminCmsCreate from '@/views/admin/cms/Create';
import AdminCmsDetail from '@/views/admin/cms/Detail';
import AdminLessons from '@/views/admin/lesson/List';
import AdminUsers from '@/views/admin/user/List';
import AdminAdminUserList from '@/views/admin/adminuser/List';
import AdminAdminUserCreate from '@/views/admin/adminuser/Create';
import AdminAdminUserDetail from '@/views/admin/adminuser/Detail';
import AdminMaintenance from '@/views/admin/Maintenance';

const axiosBase = axios.create({
  baseURL: process.env.VUE_APP_HOST,
  headers: {
    'Content-Type': 'application/json',
  },
  credentials: true,
  responseType: 'json',
});

Vue.use(VueRouter, axiosBase);

const routes = [
  // 一般ユーザー向け
  {
    path: '/',
    name: 'Top',
    component: Top,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/categories/:category',
    name: 'Categories',
    component: Categories,
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
  },
  {
    path: '/lessons/:id(\\d+)',
    name: 'LessonDetail',
    component: LessonDetail,
  },
  {
    path: '/lessons/:id(\\d+)/:num(\\d+)',
    name: 'LessonAttend',
    component: LessonAttend,
    meta: {
      requireAuth: true,
    },
    beforeEnter: async (to, from, next) => {
      // チャプター番号が不正ならnotfoundページへ
      function validation() {
        // 購入済みチェック
        const attendLessonInfo = Store.state.user.userInfo.lesson.purchase
          .filter((lesson) => lesson.id === Number(to.params.id))[0];
        if (attendLessonInfo === undefined) return false;

        // chapterNumチェック
        const chapterCount = attendLessonInfo.chapter.length;
        if (to.params.num > chapterCount || to.params.num <= 0) return false;

        return true;
      }

      // バリデーション
      const validRes = validation();
      if (!validRes) {
        next({ path: '/notfound' });
      } else {
        // 再読み込み判定
        let data = false;
        const { currentLesson } = Store.state.lessonAttend;
        if (currentLesson.id === Number(to.params.id)) {
          const expireRes = await axiosBase.get('/api/lessons/expire', {
            params: {
              id: to.params.id,
              update: currentLesson.update,
            },
          });
          data = expireRes.data;
        }
        if (currentLesson.id === Number(to.params.id) && currentLesson.content === '') data = true;
        // レッスン読み込み
        if (data || currentLesson.id !== Number(to.params.id)) {
          const res = await Store.dispatch('lessonAttend/loadLesson', {
            id: to.params.id,
            token: Store.state.user.userInfo.token,
          });
          if (res) {
            next();
          } else {
            next({ path: '/error' });
          }
        } else {
          next();
        }
      }
    },
  },
  {
    path: '/lessons/free/:id(\\d+)/:num(\\d+)',
    name: 'LessonAttendFreeRange',
    component: LessonAttend,
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      function validation() {
        // currentLessonチェック
        if (Number(to.params.id) !== Store.state.lessonAttend.currentLesson.id) return false;
        // 購入済みでないかチェック
        if (Store.state.user.userInfo.lesson.purchase
          .filter((lesson) => lesson.id === Number(to.params.id)).length !== 0) return false;
        // 異常なChapterNumでないかチェック
        if (Number(to.params.num) > Store.state.lessonAttend.currentLesson.chapterTitle.length
          || Number(to.params.num) === 0) {
          return false;
        }
        return true;
      }
      if (validation()) {
        next();
      } else {
        next({ path: 'notfound' });
      }
    },
  },
  {
    path: '/lessons/preview/:num(\\d+)',
    name: 'LessonPreview',
    component: LessonAttend,
    meta: {
      requireAuth: true,
    },
    beforeEnter: async (to, from, next) => {
      const allowFromRoute = [
        'LessonCreateConfirm',
        'LessonUpdateConfirm',
      ];
      function validation() {
        if (!allowFromRoute.includes(from.name)) return false;
        if (Store.state.lessonCreate.postLessonInfo.content.length === 0) return false;
        return true;
      }
      function choiceNextPage() {
        if (from.name === 'LessonCreateConfirm') return { name: 'LessonCreate' };
        if (from.name === 'LessonUpdateConfirm') return { name: 'LessonUpdate', params: { id: from.params.id } };
        return { path: 'notfound' };
      }
      if (validation()) {
        next();
      } else {
        next(choiceNextPage());
      }
    },
  },
  {
    path: '/lessons/create',
    name: 'LessonCreate',
    component: LessonCreate,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/lessons/create/confirm',
    name: 'LessonCreateConfirm',
    component: LessonCreateConfirm,
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      const allowFromRoute = [
        'LessonCreate',
        'LessonPreview',
      ];
      function validation() {
        if (allowFromRoute.includes(from.name)
          && Store.state.lessonCreate.postLessonInfo.content.length !== 0) return true;
        Store.dispatch('lessonCreate/execDeletePostLesson');
        return false;
      }
      if (validation()) {
        next();
      } else {
        next({ path: '/lessons/create' });
      }
    },
  },
  {
    path: '/lessons/update/:id(\\d+)',
    name: 'LessonUpdate',
    component: LessonCreate,
    meta: {
      requireAuth: true,
    },
    beforeEnter: async (to, from, next) => {
      if (from.name === 'LessonUpdateConfirm') next();
      // レッスンのJudge値チェック
      const { data } = await axiosBase.get(`/api/lessons/${to.params.id}`);
      // 過去に投稿したレッスンがない、または指定のレッスンidが自身の物ではない場合notfoundへ
      const { userInfo } = Store.state.user;
      const myLesson = userInfo.lesson.post.filter(
        (lesson) => lesson.id === Number(to.params.id),
      );
      if (userInfo.lesson.post.length === 0 || !myLesson.length || data.lesson.judge === 2) {
        next({ path: '/notfound' });
      } else if (from.name !== 'LessonUpdateConfirm') {
        next();
      }
    },
  },
  {
    path: '/lessons/update/confirm/:id(\\d+)',
    name: 'LessonUpdateConfirm',
    component: LessonCreateConfirm,
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      const allowFromRoute = [
        'LessonUpdate',
        'LessonPreview',
      ];
      function validation() {
        if (allowFromRoute.includes(from.name)
        && Store.state.lessonCreate.postLessonInfo.content.length !== 0) return true;
        Store.dispatch('lessonCreate/execDeletePostLesson');
        return false;
      }
      if (validation()) {
        next();
      } else {
        next({ path: `/lessons/update/${to.params.id}` });
      }
    },
  },
  {
    path: '/users/:id',
    name: 'User',
    component: User,
  },
  {
    path: '/userinfo/setting',
    name: 'UserInfoSetting',
    component: UserInfoSetting,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/userinfo/sales',
    name: 'UserInfoSales',
    component: UserInfoSales,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/userinfo/lessons',
    name: 'UserInfoLessons',
    component: UserInfoLessons,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/userinfo/billing',
    name: 'UserInfoBilling',
    component: UserInfoBilling,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/news',
    name: 'NewsList',
    component: NewsList,
  },
  {
    path: '/news/:id',
    name: 'News',
    component: News,
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
  },
  {
    path: '/error',
    name: 'Error',
    component: Error,
  },
  {
    path: '/privacypolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
  },
  {
    path: '/checkoutservice',
    name: 'CheckoutService',
    component: CheckoutService,
  },
  {
    path: '/help',
    name: 'Help',
    component: Help,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    beforeEnter: (to, from, next) => {
      if (from.name === 'Login' && Store.state.user.userInfo.login) {
        next({ path: '/' });
      } else {
        next();
      }
    },
  },
  // {
  //   path: '/company',
  //   name: 'Company',
  //   component: Company,
  // },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/cart/checkout',
    name: 'CartCheckout',
    component: CartCheckout,
    meta: {
      requireAuth: true,
    },
    beforeEnter(to, from, next) {
      if (from.name !== 'Cart' || Store.state.user.userInfo.cart.length === 0) {
        next({ path: '/cart' });
      } else {
        next();
      }
    },
  },
  {
    path: '/cart/checkout/complete',
    name: 'CartCheckoutComplete',
    component: CartCheckoutComplete,
    meta: {
      requireAuth: true,
    },
    beforeEnter(to, from, next) {
      if (from.name !== 'CartCheckout') {
        next({ path: '/cart' });
      } else {
        next();
      }
    },
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance,
    meta: {
      ignoreMaintenanceMode: true,
    },
    beforeEnter(to, from, next) {
      if (Store.state.material.maintenance.status === 200) {
        next({ path: '/' });
      } else {
        next();
      }
    },
  },
  {
    path: '/login/github/callback',
    component: Callback,
  },
  {
    path: '/login/google/callback',
    component: Callback,
  },
  {
    path: '/login/facebook/callback',
    component: Callback,
  },
  {
    path: '/login/twitter/callback',
    component: Login,
  },

  // spユーザー向け
  {
    path: '/sphone',
    name: 'Sphone',
    component: Sphone,
  },

  // adminユーザー向け
  {
    path: '/admin/login',
    name: 'AdminLogin',
    component: AdminLogin,
    meta: {
      ignoreMaintenanceMode: true,
    },
  },
  {
    path: '/admin/signup',
    name: 'AdminSignup',
    component: AdminSignup,
    meta: {
      ignoreMaintenanceMode: true,
    },
  },
  {
    path: '/admin/lessons',
    name: 'AdminLessons',
    component: AdminLessons,
    meta: {
      requireAdminAuth: true,
      ignoreMaintenanceMode: true,
    },
  },
  {
    path: '/admin/lessons/preview/:id(\\d+)/:num(\\d+)',
    name: 'AdminLessonPreview',
    component: LessonAttend,
    meta: {
      requireAdminAuth: true,
      ignoreMaintenanceMode: true,
    },
    beforeEnter: (to, from, next) => {
      function validation() {
        const currentId = Store.state.lessonAttend.currentLesson.id;
        if (currentId !== Number(to.params.id)) return false;
        return true;
      }
      if (validation()) {
        next();
      } else {
        next({ path: '/admin/error' });
      }
    },
  },
  {
    path: '/admin/users',
    name: 'AdminUsers',
    component: AdminUsers,
    meta: {
      requireAdminAuth: true,
      ignoreMaintenanceMode: true,
    },
  },
  {
    path: '/admin/cms',
    name: 'AdminCmsList',
    component: AdminCmsList,
    meta: {
      requireAdminAuth: true,
      ignoreMaintenanceMode: true,
    },
  },
  {
    path: '/admin/cms/create',
    name: 'AdminCmsCreate',
    component: AdminCmsCreate,
    meta: {
      requireAdminAuth: true,
      ignoreMaintenanceMode: true,
    },
  },
  {
    path: '/admin/cms/:id',
    name: 'AdminCmsDetail',
    component: AdminCmsDetail,
    meta: {
      requireAdminAuth: true,
      ignoreMaintenanceMode: true,
    },
  },
  {
    path: '/admin/adminuser',
    name: 'AdminAdminUserList',
    component: AdminAdminUserList,
    meta: {
      requireAdminAuth: true,
      ignoreMaintenanceMode: true,
    },
  },
  {
    path: '/admin/adminuser/create',
    name: 'AdminAdminUserCreate',
    component: AdminAdminUserCreate,
    meta: {
      requireAdminAuth: true,
      requireStateAdmin: true,
      ignoreMaintenanceMode: true,
    },
  },
  {
    path: '/admin/adminuser/:id',
    name: 'AdminAdminUserDetail',
    component: AdminAdminUserDetail,
    meta: {
      requireAdminAuth: true,
      ignoreMaintenanceMode: true,
    },
  },
  {
    path: '/admin/maintenance',
    name: 'AdminMaintenance',
    component: AdminMaintenance,
    meta: {
      requireAdminAuth: true,
      ignoreMaintenanceMode: true,
    },
  },
  {
    path: '/admin/error',
    name: 'AdminError',
    component: AdminError,
    meta: {
      requireAdminAuth: true,
      ignoreMaintenanceMode: true,
    },
  },
  {
    path: '/admin/*',
    name: 'AdminNotFound',
    component: AdminNotFound,
    meta: {
      requireAdminAuth: true,
      ignoreMaintenanceMode: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  await Store.dispatch('material/getMaintenanceStatus');
  if (Store.state.material.maintenance.status === 503) {
    // メンテナンスステータスが503の場合メンテナンスページにリダイレクト
    if (to.matched.some((page) => page.meta.ignoreMaintenanceMode)) {
      // adminページの場合リダイレクトしない
      next();
    } else {
      // adminページ以外の場合リダイレクト
      next({ path: '/maintenance' });
    }
  } else if (to.matched.some((page) => page.meta.requireAuth) && !Store.state.user.userInfo.login) {
    // 公開設定でないかつ未ログインの場合ログイン画面にリダイレクト
    next({ path: '/login' });
  } else if (to.matched.some((page) => page.meta.requireStateAdmin)
  && Store.state.admin.adminInfo.profile.status !== 99) {
    // 権限が管理者でない場合エラーページへ
    next({ path: '/admin/error' });
  } else if (to.matched.some((page) => page.meta.requireAdminAuth)
  && !Store.state.admin.adminInfo.login) {
    next({ path: '/admin/login' });
  } else {
    next();
  }
});

export default router;
