<template>
  <div class='modal-lesson-complete'>
    <div class='modal-lesson-complete__main'>
      <div class='modal-lesson-complete__main__top'>
        <img src='@/assets/img/lessonattend-congratulations.svg' alt='congratulations'>
        <h1>レッスン修了おめでとうございます！</h1>
      </div>
      <div class='modal-lesson-complete__main__bottom'>
        <div class='modal-lesson-complete__main__bottom__evaluation'>
          <p>評価を選ぶ</p>
          <review-star-select @emit-evaluation='relayEvaluation'/>
        </div>
        <button-normal @emit='$emit("emit-move-detail")' large>
          レッスン詳細に戻る
        </button-normal>
        <button-share
          class='modal-lesson-complete__main__bottom__share'
          auth='twitter'
          @emit='$emit("emit-move-twitter")'
        />
      </div>
    </div>
    <div class='modal-lesson-complete__overlay' />
    <img
      class='modal-lesson-complete__confetti-first'
      src='@/assets/img/lessonattend-confetti.svg'
      alt='confetti 紙吹雪'>
    <img
      class='modal-lesson-complete__confetti-second'
      src='@/assets/img/lessonattend-confetti.svg'
      alt='confetti 紙吹雪'>
    <img
      class='modal-lesson-complete__finger-first'
      src='@/assets/img/lessonattend-finger-orange.svg'
      alt='hand 手'>
    <img
      class='modal-lesson-complete__finger-second'
      src='@/assets/img/lessonattend-finger-blue.svg'
      alt='hand 手'>
    <img
      class='modal-lesson-complete__finger-third'
      src='@/assets/img/lessonattend-finger-lightgreen.svg'
      alt='hand 手'>
    <img
      class='modal-lesson-complete__finger-fourth'
      src='@/assets/img/lessonattend-finger-green.svg'
      alt='hand 手'>
  </div>
</template>

<script>
import { ReviewStarSelect } from '@/components/atoms/reviewStar';
import { ButtonNormal, ButtonShare } from '@/components/atoms/button';

export default {
  components: {
    ReviewStarSelect,
    ButtonNormal,
    ButtonShare,
  },
  mounted() {
    this.switchOverflow();
  },
  methods: {
    // modalが立ち上がってる時はスクロールできないよう制御
    switchOverflow() {
      const body = document.querySelector('body');
      if (body.style.overflow === '') {
        body.style.overflow = 'hidden';
      } else {
        body.style.overflow = '';
      }
    },
    relayEvaluation(evaluation) {
      this.$emit('emit-evaluation', evaluation);
    },
  },
  destroyed() {
    const body = document.querySelector('body');
    body.style.overflow = '';
  },
};
</script>

<style scoped>
.modal-lesson-complete__main {
  text-align: center;
  position: fixed;
  padding: 30px;
  width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 997;
}

.modal-lesson-complete__main__top {
  border-bottom: 3px solid #fff;
  padding-bottom: 20px;
}

.modal-lesson-complete__main__top img {
  width: 500px;
}

.modal-lesson-complete__main__top h1 {
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  margin-top: 10px;
}

.modal-lesson-complete__main__bottom {
  margin-top: 20px;
}

.modal-lesson-complete__main__bottom__evaluation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.modal-lesson-complete__main__bottom__evaluation p {
  color: #fff;
  margin-right: 10px;
}

.modal-lesson-complete__main__bottom__share {
  margin: 40px 0 0 auto;
}

.modal-lesson-complete__overlay {
  position: fixed;
  z-index: 995;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
}

.modal-lesson-complete__confetti-first {
  z-index: 996;
  position: fixed;
  top: -100px;
  left: 0;
}

.modal-lesson-complete__confetti-second {
  z-index: 996;
  position: fixed;
  top: -200px;
  right: 0;
  transform: scale(-1, 1);
}

.modal-lesson-complete__finger-first {
  z-index: 996;
  position: fixed;
  bottom: -400px;
  left: 0;
  width: 450px;
}

.modal-lesson-complete__finger-second {
  z-index: 996;
  position: fixed;
  bottom: -550px;
  left: 450px;
  width: 350px;
  transform: rotate(-20deg);
}

.modal-lesson-complete__finger-third {
  z-index: 996;
  position: fixed;
  bottom: -500px;
  right: 0;
  width: 450px;
}

.modal-lesson-complete__finger-fourth {
  z-index: 996;
  position: fixed;
  bottom: -100px;
  right: -350px;
  width: 650px;
}
</style>
