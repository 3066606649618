var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "lesson-output" }, [
    _c(
      "div",
      { staticClass: "lesson-output__top" },
      [
        _c("lesson-output-tab", {
          attrs: { tabInfo: _vm.tabInfo },
          on: { emit: _vm.changeFocus }
        })
      ],
      1
    ),
    _c("div", { staticClass: "lesson-output__bottom" }, [
      _c("p", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.selectId === 0,
            expression: "selectId === 0"
          }
        ],
        domProps: { innerHTML: _vm._s(_vm.runResult.user) }
      }),
      _c("p", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.selectId === 1,
            expression: "selectId === 1"
          }
        ],
        domProps: { innerHTML: _vm._s(_vm.runResult.sample) }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }