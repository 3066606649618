var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "maintenance" },
    [
      _c(
        "modal-custom",
        {
          staticClass: "maintenance__modal",
          attrs: { hideOverlay: "", hideButton: "" }
        },
        [
          _c(
            "div",
            { staticClass: "maintenance__modal__contents" },
            [
              _c("layout-logo", { attrs: { yellow: "", iconOnly: "" } }),
              _c("h3", { staticClass: "maintenance__modal__contents__title" }, [
                _vm._v("ただいまメンテナンスを行っています")
              ]),
              _c("p", { staticClass: "maintenance__modal__contents__text" }, [
                _vm._v(
                  " 現在メンテナンス中のため、manabibaをご利用頂く事がができません。"
                ),
                _c("br"),
                _vm._v(
                  "ご不便をおかけ致しますがご理解いただけるようお願い申し上げます。 "
                )
              ])
            ],
            1
          )
        ]
      ),
      _c("layout-footer", { attrs: { hidePageUp: "", simpleFooter: "" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }