export default {
  methods: {
    movePage(name, params, query) {
      this.$router.push({ name, params, query }, () => {});
    },
    judgeShouldLogout(error) {
      return !!(error.response !== undefined && error.response.status === 401);
    },
    unAuthenticate() {
      this.$store.commit('user/logout');
      this.movePage('Login', '', { url: this.$route.path });
    },
    showNoticeBar(type, title, text) {
      this.$notify({
        title,
        text,
        type,
        duration: 3000,
        group: 'top-right',
      });
    },
  },
};
