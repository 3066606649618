<template>
  <router-link :class='addClass'
    :to='{ name: "LessonDetail", params: { id: lessonInfo.id }}'
  >
    <div class='lesson-card-post__left'>
      <img
        class='lesson-card-post__left__thumbnail'
        :src='lessonInfo.thumbnail'
        alt='レッスンのサムネイル'
        @error='setErrorThumbnail' />
      <div class='lesson-card-post__left__info'>
        <icon-post-date :date='lessonInfo.updated_at'>UPDATE</icon-post-date>
        <p class='lesson-card-post__left__info__sale'>販売数: {{ lessonInfo.sale }}</p>
      </div>
    </div>
    <div class='lesson-card-post_right'>
      <div class='lesson-card-post_right__top'>
        <div class='lesson-card-post_right__top__title'>
          <icon-level class='lesson-card-post_right__top__title__level' :level='lessonInfo.level' />
          <h3 class='lesson-card-post_right__top__title__text'>{{ lessonInfo.title }}</h3>
        </div>
        <p class='lesson-card-post_right__top__description'>{{ lessonInfo.description }}</p>
      </div>
      <div class='lesson-card-post_right__contents'>
        <icon-status
          class='lesson-card-post_right__contents__buttons'
          :buttonText='buttonText'
        />
        <div class='lesson-card-post_right__contents__right'>
          <review-star
            class='lesson-card-post_right__contents__right__star'
            :score='lessonInfo.evaluation'
          />
          <p class='lesson-card-post_right__contents__right__price'>{{ setPrice }}</p>
        </div>
      </div>
    </div>
    <p
      class='lesson-card-post__exam'
      v-if='lessonInfo.status === 0'
    >
      現在審査中のため選択できません
    </p>
  </router-link>
</template>

<script>
import { ReviewStar } from '@/components/atoms/reviewStar';
import { IconStatus, IconLevel, IconPostDate } from '@/components/atoms/icon';

export default {
  components: {
    ReviewStar,
    IconStatus,
    IconLevel,
    IconPostDate,
  },
  props: {
    lessonInfo: Object,
    buttonText: String,
  },
  computed: {
    addClass() {
      return {
        'lesson-card-post': true,
        'lesson-card-post--disabled': this.lessonInfo.status === 0, // status=0は審査中のフラグ
      };
    },
    setPrice() {
      if (this.lessonInfo.price === 0) {
        return '無料';
      }
      return `￥${this.lessonInfo.price}`;
    },
  },
  methods: {
    setErrorThumbnail(e) {
      e.target.src = `${process.env.VUE_APP_S3_URL}/image/thumbnail/manabiba.png`;
    },
  },
};
</script>

<style scoped>
img {
  display: block;
}

.lesson-card-post {
  display: flex;
  width: 100%;
  cursor: pointer;
  position: relative;
  padding: 15px;
  transition-duration: 0.2s;
  border: 10px solid #fff;
}

.lesson-card-post--disabled {
  cursor: default;
  pointer-events: none;
}

.lesson-card-post:hover {
  border: 10px solid #F1EFFC;
}

.lesson-card-post--disabled:hover {
  border: 10px solid #fff;
}

.lesson-card-post__exam {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f1effcde;
  font-weight: 500;
  cursor: default;
}

.lesson-card-post__left__thumbnail {
  width: 220px;
  height: 123.75px;
  align-self: flex-start;
}

.lesson-card-post__left__info {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.lesson-card-post__left__info__sale {
  font-size: 12px;
  margin-left: 10px;
  padding: 5.5px 0;
}

.lesson-card-post_right {
  margin-left: 20px;
  text-align: left;
  width: 100%;
}

.lesson-card-post_right__top {
  height: 110px;
}

.lesson-card-post_right__top__title {
  display: flex;
  align-items: center;
}

.lesson-card-post_right__top__title__level {
  margin-top: 5px;
  margin-right: 10px;
}

.lesson-card-post_right__top__title__text {
  font-size: 20px;
  font-weight: 500;
  overflow: hidden;
  width: 340px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
}

.lesson-card-post_right__top__description {
  font-size: 12px;
  margin-top: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
}

.lesson-card-post_right__contents {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 10px;
}

.lesson-card-post_right__contents__right {
  text-align: right;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: right;
}

.lesson-card-post_right__contents__right__price {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
}

.lesson-card-post_right__contents__right__star {
  justify-content: flex-end;
  margin-right: 2px;
}

</style>
