var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "pager-normal" },
    [
      _c("li", [
        _c(
          "button",
          {
            staticClass: "pager-normal__prev",
            class: _vm.activePage === 1 ? "disabled" : "",
            on: {
              click: function($event) {
                _vm.activePage !== 1 ? _vm.selectPage(_vm.activePage - 1) : ""
              }
            }
          },
          [
            _c("i", {
              staticClass: "fas fa-chevron-left pager-normal__prev__btn"
            })
          ]
        )
      ]),
      _vm._l(_vm.pages, function(page) {
        return _c("li", { key: page.id, staticClass: "pager-normal__list" }, [
          _c(
            "button",
            {
              class: _vm.activePage === page ? "select" : "",
              on: {
                click: function($event) {
                  return _vm.selectPage(page)
                }
              }
            },
            [_vm._v(" " + _vm._s(page) + " ")]
          )
        ])
      }),
      _c("li", [
        _c(
          "button",
          {
            staticClass: "pager-normal__next",
            class: _vm.activePage === _vm.pageCount ? "disabled" : "",
            on: {
              click: function($event) {
                _vm.activePage !== _vm.pageCount
                  ? _vm.selectPage(_vm.activePage + 1)
                  : ""
              }
            }
          },
          [
            _c("i", {
              staticClass: "fas fa-chevron-right pager-normal__next__btn"
            })
          ]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }