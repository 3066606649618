<template>
  <div :class='addClass' v-if='showSwitch'>
    <div class='bar-announce__text'>
      <slot />
    </div>
    <button @click='hideBar'>×</button>
  </div>
</template>

<script>
export default {
  props: {
    caution: Boolean,
    warn: Boolean,
  },
  data() {
    return {
      showSwitch: true,
    };
  },
  computed: {
    addClass() {
      return {
        'bar-announce': true,
        'bar-announce--caution': this.caution,
        'bar-announce--warn': this.warn,
      };
    },
  },
  methods: {
    hideBar() {
      this.showSwitch = false;
    },
  },
};
</script>

<style scoped>
.bar-announce {
  border: 2px solid #90CCE6;
  background-color: #b2d8e7;
  font-size: 14px;
  padding: 20px;
  position: relative;
}

.bar-announce--caution {
  border: 2px solid #ffdc68;
  background-color: #ffebaa;
}

.bar-announce--warn {
  border: 2px solid #ff5e5e;
  background-color: #ff8e8e;
}

.bar-announce__text {
  text-align: center;
  display: flex;
  justify-content: center;
}

.bar-announce button {
  margin-left: 20px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
</style>
