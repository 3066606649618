<template>
  <div class='list'>
    <LayoutHeader />
    <div class='list__contents'>
      <list-menu
        class='list__contents__left'
        :targetList='createTitleList'
        :defaultSelectId=3
        @emit='movePageMenu'
      />
      <div class='list__contents__right'>
        <title-normal
          class='list__contents__right__title'
          :title='titleBar.title'
          :button='titleBar.button'
          :count='titleBar.count'
          :label='titleBar.label'
          :disabled='adminInfo.profile.status != 99'
          @emit='movePage("AdminAdminUserCreate")'
        />
        <icon-loading v-if='isLoading' blue/>
        <table-normal
          v-if='!isLoading'
          type='detail'
          :headingList='table.headingList'
          :rows='table.rows'
          :rowButton='table.rowButton'
          :disabled='adminInfo.profile.status != 99'
          @emit='rowButtonEvent'
        />
        <modal-normal
          v-if = 'isActive'
          :title = 'modalText.title'
          :text = 'modalText.text'
          :cancelBtn = 'modalText.cancelBtn'
          :acceptBtn = 'modalText.acceptBtn'
          @emit='modalEvent'>
        </modal-normal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import helper from '@/mixins/admin/methods/helper';
import pageList from '@/mixins/admin/data/pageList';
import LayoutHeader from '@/components/layouts/admin/LayoutHeader';
import { TitleNormal, TableNormal, ModalNormal } from '@/components/molecules/other';
import { IconLoading } from '@/components/atoms/icon/';
import { ListMenu } from '@/components/atoms/list';

export default {
  components: {
    LayoutHeader,
    TitleNormal,
    TableNormal,
    ModalNormal,
    IconLoading,
    ListMenu,
  },
  data() {
    return {
      isLoading: false,
      isActive: false,
      deleteAdminId: null,
      modalText: {
        title: '管理ユーザー削除',
        text: '管理ユーザーを削除すると二度と復元できません。本当に削除してよろしいですか？',
        acceptBtn: '削除する',
        cancelBtn: 'キャンセル',
      },
      titleBar: {
        title: '管理ユーザー一覧',
        button: '新規作成',
        count: null,
      },
      table: {
        headingList: [
          'ID',
          'アカウント名',
          'メールアドレス',
          '登録日',
          '最終ログイン',
          '削除',
          '詳細',
        ],
        rows: [],
        rowButton: ['削除', '詳細'],
      },
    };
  },
  created() {
    this.loadAdminUsers();
  },
  mixins: [helper, pageList],
  computed: {
    ...mapState('admin', ['adminInfo']),
    createTitleList() {
      const titleList = this.pageList.map((page) => page.title);
      return titleList;
    },
  },
  methods: {
    movePageMenu(index) {
      const { name } = this.pageList[index];
      this.$router.push({ name }, () => {});
    },
    rowButtonEvent(event) {
      if (event.btnFlg === 0) {
        this.isActive = !this.isActive;
        this.deleteAdminId = event.id;
      }
      // 管理ユーザー詳細画面に遷移
      if (event.btnFlg === 1) {
        this.movePage('AdminAdminUserDetail', { id: event.id });
      }
    },
    modalEvent(data) {
      if (data === false) {
        this.isActive = !this.isActive;
      } else {
        this.isActive = !this.isActive;
        this.deleteAdminUser();
      }
    },
    async loadAdminUsers() {
      try {
        this.isLoading = !this.isLoading;
        this.$http.defaults.headers.common.Authorization = `Bearer ${this.adminInfo.token}`;
        const res = await this.$http.get('/api/admin/adminusers');
        await res.data.forEach((data) => {
          this.table.rows.push({
            id: data.id,
            userName: data.username ? data.username : '',
            email: data.email ? data.email : '',
            create: data.created_at ? data.created_at : '',
            update: data.updated_at ? data.updated_at : '',
          });
        });
        this.titleBar.count = res.data.length;
        this.isLoading = !this.isLoading;
      } catch (e) {
        this.isLoading = !this.isLoading;
        if (e.response !== undefined && e.response.status === 401) {
          this.unAdminAuthenticate();
        } else {
          this.showNoticeBar('error', 'アカウントの削除を失敗しました');
        }
      }
    },
    async deleteAdminUser() {
      try {
        this.isLoading = !this.isLoading;
        this.$http.defaults.headers.common.Authorization = `Bearer ${this.adminInfo.token}`;
        await this.$http.delete(`/api/admin/adminusers/${this.deleteAdminId}`);
        this.table.rows = this.table.rows.filter((admin) => (admin.id !== this.deleteAdminId));
        this.titleBar.count -= 1;
        this.showNoticeBar('success', 'アカウントの削除を完了しました');
        this.isLoading = !this.isLoading;
      } catch (e) {
        this.isLoading = !this.isLoading;
        if (e.response !== undefined && e.response.status === 401) {
          this.unAdminAuthenticate();
        } else {
          this.showNoticeBar('error', 'アカウントの削除を失敗しました');
        }
      }
    },
  },
};
</script>

<style scoped>
.list {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f9fa;
}

.list__contents {
  display: flex;
  margin: 20px;
}

.list__contents__left {
  width: 15%;
  background-color: #fff;
  padding: 20px;
}

.list__contents__right {
  width: 85%;
  margin-left: 20px;
}

.list__contents__right__title {
  margin-bottom: 10px;
}
</style>
