var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "review-star-select" }, [
    _c(
      "ul",
      { staticClass: "review-star-select__star" },
      _vm._l(3, function(n) {
        return _c(
          "li",
          {
            key: n,
            class: _vm.selectNum >= n ? "select" : "",
            on: {
              click: function($event) {
                return _vm.clickStar(n)
              }
            }
          },
          [_vm._v("★")]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }