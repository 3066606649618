var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "section-welcome" },
    [
      !_vm.isLoading
        ? _c("div", { staticClass: "section-welcome__main" }, [
            _vm.welcomeLessonList.length !== 0 && _vm.username.length !== 0
              ? _c("div", { staticClass: "section-welcome__main__lesson" }, [
                  _c(
                    "div",
                    { staticClass: "section-welcome__main__lesson__left" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "section-welcome__main__lesson__left__select"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "section-welcome__main__lesson__left__select__content"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "section-welcome__main__lesson__left__select__content__top"
                                },
                                [
                                  _c("h3", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.welcomeLessonList[
                                          _vm.welcomeLesson.selected
                                        ].title
                                      )
                                    )
                                  ]),
                                  _c("img", {
                                    attrs: {
                                      src: _vm.setAvatar,
                                      alt: "avatar ユーザーのアイコン"
                                    },
                                    on: { error: _vm.setErrorAvatar }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "section-welcome__main__lesson__left__select__content__left"
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "section-welcome__main__lesson__left__select__content__left__description"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.welcomeLessonList[
                                              _vm.welcomeLesson.selected
                                            ].description
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "section-welcome__main__lesson__left__select__content__left__price"
                                    },
                                    [_vm._v(" " + _vm._s(_vm.setPrice) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "section-welcome__main__lesson__left__select__content__left__button"
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "LessonDetail",
                                              params: {
                                                id:
                                                  _vm.welcomeLessonList[
                                                    _vm.welcomeLesson.selected
                                                  ].id
                                              }
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "button-stripe",
                                            { attrs: { arrow: "" } },
                                            [_vm._v("レッスンを見る")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "section-welcome__main__lesson__left__select__thumbnail",
                              attrs: {
                                to: {
                                  name: "LessonDetail",
                                  params: {
                                    id:
                                      _vm.welcomeLessonList[
                                        _vm.welcomeLesson.selected
                                      ].id
                                  }
                                }
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm.setWelcomeThumbnail,
                                  alt: "レッスンのサムネイル"
                                },
                                on: { error: _vm.setErrorThumbnail }
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "ul",
                        {
                          staticClass:
                            "section-welcome__main__lesson__left__thumbnaillist"
                        },
                        _vm._l(_vm.welcomeLessonList, function(lesson, index) {
                          return _c("li", { key: index }, [
                            _c("img", {
                              attrs: {
                                src: lesson.image,
                                alt: "レッスンのサムネイル"
                              },
                              on: {
                                mouseover: function($event) {
                                  return _vm.changeSelectWelcomeLesson(index)
                                },
                                error: _vm.setErrorThumbnail
                              }
                            })
                          ])
                        }),
                        0
                      )
                    ]
                  ),
                  _vm.username !== "" && _vm.attendLessonList.length !== 0
                    ? _c(
                        "div",
                        { staticClass: "section-welcome__main__lesson__right" },
                        [
                          _c("icon-user-lesson", {
                            staticClass:
                              "section-welcome__main__lesson__right__author",
                            attrs: {
                              avatar:
                                _vm.attendLessonList[_vm.attendLesson.selected]
                                  .avatar,
                              username:
                                _vm.attendLessonList[_vm.attendLesson.selected]
                                  .author
                            }
                          }),
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "section-welcome__main__lesson__right__top",
                              attrs: {
                                to: {
                                  name: "LessonDetail",
                                  params: {
                                    id:
                                      _vm.attendLessonList[
                                        _vm.attendLesson.selected
                                      ].id
                                  }
                                }
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src:
                                    _vm.attendLessonList[
                                      _vm.attendLesson.selected
                                    ].image,
                                  alt: "レッスンのサムネイル"
                                },
                                on: { error: _vm.setErrorThumbnail }
                              })
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "section-welcome__main__lesson__right__bottom"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "section-welcome__main__lesson__right__bottom__welcome"
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "section-welcome__main__lesson__right__bottom__welcome__username"
                                    },
                                    [_vm._v(" " + _vm._s(_vm.username) + " ")]
                                  ),
                                  _c("p", [_vm._v("さんお帰りなさい")])
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "section-welcome__main__lesson__right__bottom__lessoninfo"
                                },
                                [
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "section-welcome__main__lesson__right__bottom__lessoninfo__lesson"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.attendLessonList[
                                              _vm.attendLesson.selected
                                            ].title
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "section-welcome__main__lesson__right__bottom__lessoninfo__chapter"
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.setChapterTitle) + " "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "section-welcome__main__lesson__right__bottom__progress"
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("bar-progress", {
                                        staticClass:
                                          "section-welcome__main__lesson__right__bottom__progress__bar",
                                        attrs: {
                                          progress:
                                            _vm.attendLessonList[
                                              _vm.attendLesson.selected
                                            ].inProgress.progressRate
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.attendLessonList[
                                          _vm.attendLesson.selected
                                        ].inProgress.progressRate
                                      ) + "%"
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "section-welcome__main__lesson__right__bottom__bottom"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "section-welcome__main__lesson__right__bottom__bottom__select"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "section-welcome__main__lesson__right__bottom__bottom__select__move"
                                        },
                                        [
                                          _c("button-arrow", {
                                            attrs: {
                                              direction: "left",
                                              disabled: _vm.setDisabledLeft,
                                              small: ""
                                            },
                                            on: {
                                              emit: function($event) {
                                                return _vm.changeSelectAttendLesson(
                                                  -1
                                                )
                                              }
                                            }
                                          }),
                                          _c("p", [
                                            _vm._v(_vm._s(_vm.setPageCount))
                                          ]),
                                          _c("button-arrow", {
                                            attrs: {
                                              direction: "right",
                                              disabled: _vm.setDisabledRight,
                                              small: ""
                                            },
                                            on: {
                                              emit: function($event) {
                                                return _vm.changeSelectAttendLesson(
                                                  1
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._m(0)
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "section-welcome__main__lesson__right__bottom__button"
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "LessonDetail",
                                          params: {
                                            id:
                                              _vm.attendLessonList[
                                                _vm.attendLesson.selected
                                              ].id
                                          }
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "button-stripe",
                                        { attrs: { arrow: "" } },
                                        [_vm._v("レッスンを続ける")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _vm.welcomeLessonList.length === 0 || _vm.username.length === 0
              ? _c("div", { staticClass: "section-welcome__main__intro" }, [
                  _c(
                    "div",
                    { staticClass: "section-welcome__main__intro__left" },
                    [
                      _vm._m(1),
                      _vm._m(2),
                      _c(
                        "router-link",
                        { attrs: { to: { name: "About" } } },
                        [
                          _c(
                            "button-normal",
                            { attrs: { white: "", large: "" } },
                            [_vm._v(" manabibaについて詳しく ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._m(3)
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm.isLoading
        ? _c("icon-loading", { staticClass: "section-welcome__load" })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "section-welcome__main__lesson__right__bottom__bottom__text"
      },
      [_c("p", [_vm._v("もう一踏ん張り頑張りましょう!")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", [
      _vm._v("脱プログラミング初心者！"),
      _c("br"),
      _vm._v("次の一歩に悩むあなたへ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("manabibaは環境構築不要で、すぐ学べる"),
      _c("br"),
      _vm._v("プログラミング学習レッスンプラットフォームです。")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section-welcome__main__intro__right" }, [
      _c("img", {
        staticClass: "section-welcome__main__intro__right__attend",
        attrs: {
          src: require("@/assets/img/sphone/sphone_attendpage.png"),
          alt: ""
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }