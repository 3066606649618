<template>
  <div class="signup">
    <notifications group='top-right' />
    <form-signup
      :form='title.form'
      :userName='title.userName'
      :password='title.password'
      :repassword='title.repassword'
      :hash='title.hash'
      :isLoading="isLoading"
      @emit-admin-signup='adminSignup'
    />
  </div>
</template>

<script>
import cryptoJS from 'crypto-js';
import speakeasy from 'speakeasy';
import { mapState, mapActions } from 'vuex';
import helper from '@/mixins/admin/methods/helper';
import FormSignup from '@/components/molecules/admin/signup/FormSignup';

export default {
  components: {
    FormSignup,
  },
  data() {
    return {
      title: {
        form: 'manabiba Admin サインアップ',
        userName: 'ユーザー名',
        password: 'パスワード',
        repassword: 'パスワード再入力',
        hash: 'ハッシュ値',
      },
      isLoading: false,
    };
  },
  created() {
    // sphone判定
    const ua = window.navigator.userAgent.toLowerCase();
    if ((ua.indexOf('iphone') !== -1 || ua.indexOf('ipad') !== -1 || (ua.indexOf('macintosh') && 'ontouchend' in document))
      || ua.indexOf('android') !== -1) {
      this.movePage('Sphone');
    }
  },
  mixins: [helper],
  computed: {
    ...mapState('admin', ['adminInfo']),
  },
  methods: {
    ...mapActions('admin', ['signup', 'saveSecret']),
    // サインイン処理
    async adminSignup(res) {
      try {
        const verified = speakeasy.totp.verify({
          secret: res.secret.hex,
          encoding: 'hex',
          token: res.token,
        });
        if (verified) {
          this.isLoading = !this.isLoading;
          const utf8Plain = cryptoJS.enc.Utf8.parse(res.secret.hex);
          const encrypted = cryptoJS.AES.encrypt(utf8Plain, process.env.VUE_APP_CRYPTION_KEY);
          // 暗号化されたsecretkey
          const encryptedStrings = encrypted.toString();

          let result = await this.signup(res.signupInfo);
          if (result === 401) {
            this.$store.commit('admin/logout');
            this.movePage('AdminLogin', '', { url: this.$route.path });
          } else if (result === true) {
            result = await this.saveSecret(
              {
                secretKey: encryptedStrings,
                token: this.adminInfo.token,
              },
            );
            if (result) {
              this.isLoading = !this.isLoading;
              this.movePage('AdminLogin');
            } else {
              this.isLoading = !this.isLoading;
              throw new Error();
            }
          } else {
            this.isLoading = !this.isLoading;
            throw new Error();
          }
        } else {
          throw new Error();
        }
      } catch (e) {
        this.showNoticeBar('error', 'サインアップに失敗しました');
      }
    },
  },
};
</script>

<style scoped>
.signup {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f9fa;
}
</style>
