<template>
  <div class='review-star'>
    <p class='review-star__star'>★</p>
    <p class='review-star__score'>
      {{ displayScore }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    score: Number,
    white: Boolean,
  },
  data() {
    return {
      // 表示する評価スコアは少数第一位まで表示、第二位は四捨五入する
      displayScore: this.score.toFixed(1),
    };
  },
};
</script>

<style scoped>
.review-star {
  display: flex;
  align-items: center;
}

.review-star__star {
  color: #E74860;
  font-size: 20px;
}

.review-star__score {
  margin-left: 5px;
  font-size: 16px;
}

.white {
  color: #fff;
}
</style>
