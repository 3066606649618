var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cart-checkout-complete" },
    [
      _c("layout-header"),
      _c("heading-title", {
        scopedSlots: _vm._u([
          {
            key: "main",
            fn: function() {
              return [_c("p", [_vm._v("Checkout Complete")])]
            },
            proxy: true
          },
          {
            key: "sub",
            fn: function() {
              return [_c("p", [_vm._v("決済完了_")])]
            },
            proxy: true
          }
        ])
      }),
      _c("section", { staticClass: "cart-checkout-complete__main center" }, [
        _c(
          "div",
          { staticClass: "cart-checkout-complete__main__top" },
          [
            _c("heading-normal", { attrs: { large: "" } }, [
              _vm._v("レッスンの購入が完了しました！")
            ]),
            _c("p", [_vm._v("レッスンを選択し、早速学習をスタートしましょう")])
          ],
          1
        ),
        _c(
          "ul",
          { staticClass: "cart-checkout-complete__main__bottom" },
          _vm._l(_vm.checkoutLesson, function(lessonInfo) {
            return _c(
              "li",
              { key: lessonInfo.id },
              [_c("lesson-card-wide", { attrs: { lessonInfo: lessonInfo } })],
              1
            )
          }),
          0
        )
      ]),
      _c("layout-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }