<template>
  <div class='news-list'>
    <layout-header />
    <heading-title>
      <template v-slot:main>
        <p>NEWS</p>
      </template>
      <template v-slot:sub>
        <p>お知らせ_</p>
      </template>
    </heading-title>
    <ul class='news-list__contents center'>
      <router-link
        class='news-list__contents__list'
        v-for='(notice, index) in noticeList'
        :key='index'
        :to='{ name: "News", params: { id: notice.id }}'
      >
        <h3 class='news-list__contents__list__title'>{{ notice.title }}</h3>
        <p class='news-list__contents__list__date'>{{ notice.updated_at | moment }}</p>
      </router-link>
    </ul>
    <layout-footer />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import helper from '@/mixins/general/methods/helper';
import { LayoutHeader, LayoutFooter } from '@/components/layouts/general/';
import { HeadingTitle } from '@/components/atoms/heading/';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    HeadingTitle,
  },
  mixins: [helper],
  computed: {
    ...mapState('notice', ['noticeList']),
  },
  methods: {
    ...mapActions('notice', ['loadNoticeList']),
  },
  async created() {
    const res = await this.loadNoticeList(1);
    if (!res) this.movePage('Error');
  },
  filters: {
    moment(date) {
      moment.locale('ja');
      return moment(date).fromNow();
    },
  },
};
</script>

<style scoped>
.news-list {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.news-list__contents {
  margin: 80px auto;
}

.news-list__contents__list {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #F2EEEE;
  padding: 30px;
  margin: 0 40px;
  cursor: pointer;
}

.news-list__contents__list:first-child {
  border-top: 1px solid #F2EEEE;
}

.news-list__contents__list__title {
  font-weight: 500;
  font-size: 16px;
  width: 90%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-all;
  overflow: hidden;
}

.news-list__contents__list__date {
  font-size: 14px;
  color: #858899;
}
</style>
