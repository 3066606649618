<template>
  <ul class='lesson-editor-tab'>
    <li
      v-for='(tab, index) in tabInfo'
      :key='tab.id'
      :class='`lesson-editor-tab__list ${ selectId === index ? "focus" : ""}`'
      @click="$emit('emit', index), changeFocus(index)"
    >
      <p class="lesson-editor-tab__list__text">{{ tab }}</p>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    tabInfo: Array,
  },
  data() {
    return {
      selectId: 0,
    };
  },
  methods: {
    changeFocus(index) {
      this.selectId = index;
    },
  },
};
</script>

<style scoped>
.lesson-editor-tab {
  display: flex;
  list-style: none;
  width: 100%;
  background-color: #262525;
  overflow: scroll;
}

.lesson-editor-tab::-webkit-scrollbar {
  display: none;
}

.lesson-editor-tab__list {
  padding: 8px 16px;
  font-size: 12px;
  color: #9195a3;
  max-width: 120px;
  cursor: pointer;
}

.lesson-editor-tab__list:hover {
  background-color: #302f2f;
}

.lesson-editor-tab__list__text{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.focus {
  color: #fff;
  border-bottom: 2px solid #E74860;
}
</style>
