var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.addClass }, [
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.emitText,
          expression: "emitText"
        }
      ],
      attrs: {
        rows: _vm.rows,
        maxlength: _vm.maxLength,
        placeholder: _vm.placeholder
      },
      domProps: { value: _vm.emitText },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.emitText = $event.target.value
        }
      }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "input-textarea__text" }, [
      _c(
        "p",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.errorMsg,
              expression: "errorMsg"
            }
          ],
          staticClass: "input-textarea__text__error"
        },
        [_vm._v(_vm._s(_vm.errorMsg))]
      ),
      _vm.maxLength
        ? _c("p", { staticClass: "input-textarea__text__count" }, [
            _vm._v(
              " " + _vm._s(_vm.countLength) + "/" + _vm._s(_vm.maxLength) + " "
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }