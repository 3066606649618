<template>
  <div class='input-url'>
    <p>/</p>
    <input
      type='text'
      v-model='inputPath'
      @keydown.enter='emitPath'
    >
  </div>
</template>

<script>
export default {
  props: {
    path: String,
    target: String,
  },
  data() {
    return {
      inputPath: String,
    };
  },
  created() {
    this.inputPath = this.path;
  },
  methods: {
    emitPath(event) {
      if (event.keyCode !== 13) return;
      this.$emit('emit-path', this.inputPath, this.target);
    },
  },
  watch: {
    path(to) {
      this.inputPath = to;
    },
  },
};
</script>

<style scoped>
.input-url  {
  display: flex;
  background-color: #F9F9FA;
  padding: 10px;
  font-size: 10px;
}

.input-url input {
  margin-left: 2px;
  width: 100%;
}
</style>
