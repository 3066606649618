<template>
  <div class='form-login'>
    <div class='form-login__main'>
      <heading-normal class='form-login__main__title' large>{{ form }}</heading-normal>
      <div class='form-login__main__input input__margin__top' v-if='!isLoading'>
        <heading-normal small>{{ userName }}</heading-normal>
        <input-text @emit='saveUsername' :text='loginInfo.userName'/>
      </div>
      <div class='form-login__main__input' v-if='!isLoading'>
        <heading-normal small>{{ password }}</heading-normal>
        <input-password @emit='savePassword' :text='loginInfo.password'/>
      </div>
      <p class='form-login__main__loadtext' v-if='isLoading'>ログインしています...</p>
      <div class='form-login__main__button'>
        <button-normal
          @emit="$emit('emit-login', loginInfo)"
          :disabled='!(loginInfo.userName && loginInfo.password)'
          :loading='isLoading'
          large
        >ログイン</button-normal>
      </div>
    </div>
  </div>
</template>

<script>
import { ButtonNormal } from '@/components/atoms/button';
import { HeadingNormal } from '@/components/atoms/heading';
import { InputText, InputPassword } from '@/components/atoms/input';

export default {
  components: {
    ButtonNormal,
    HeadingNormal,
    InputText,
    InputPassword,
  },
  props: {
    form: String,
    userName: String,
    password: String,
    isLoading: Boolean,
  },
  data() {
    return {
      loginInfo: {
        userName: '',
        password: '',
      },
    };
  },
  methods: {
    saveUsername(userName) {
      this.loginInfo.userName = userName;
    },
    savePassword(password) {
      this.loginInfo.password = password;
    },
  },
};
</script>

<style>
.form-login__main {
  width: 700px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 40px;
}

.form-login__main__title {
  text-align: center;
}

.form-login__main__input {
  margin-bottom: 20px;
}

.input__margin__top {
  margin-top: 40px;
}

.form-login__main__button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.form-login__main__loadtext {
  margin: 10px 0 20px;
  font-size: 14px;
  text-align: center;
}
</style>
