var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "input-radio" },
    _vm._l(_vm.radioInfo.dataList, function(inputData, index) {
      return _c("li", { key: index }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.checkValue,
              expression: "checkValue"
            }
          ],
          attrs: {
            type: "radio",
            id: _vm.radioInfo.name + "-" + inputData.value,
            name: _vm.radioInfo.name
          },
          domProps: {
            value: inputData.value,
            checked: _vm._q(_vm.checkValue, inputData.value)
          },
          on: {
            change: function($event) {
              _vm.checkValue = inputData.value
            }
          }
        }),
        _c(
          "label",
          { attrs: { for: _vm.radioInfo.name + "-" + inputData.value } },
          [_vm._v(_vm._s(inputData.label))]
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }