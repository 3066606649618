<template>
  <div class='section-top-info-confirm'>
    <div class='section-top-info-confirm__main center'>
      <div class='section-top-info-confirm__main__left' v-if='!isLoading'>
        <img
          class='section-top-info-confirm__main__left__thumbnail'
          :src='thumbnail'
          alt='レッスンのサムネイル'>
      </div>
      <div class='section-top-info-confirm__main__right' v-if='!isLoading'>
        <h3 class='section-top-info-confirm__main__right__title'>{{ lessonInfo.title }}</h3>
        <div class='section-top-info-confirm__main__right__bottom'>
          <div class='section-top-info-confirm__main__right__tag'>
            <p class='section-top-info-confirm__main__right__tag__text'
              v-if='lessonInfo.tag.length'>
              カテゴリー
            </p>
            <ul class='section-top-info-confirm__main__right__tag__list'>
              <li :key='lang.id' v-for='lang in lessonInfo.tag'>
                <icon-lang>{{ lang }}</icon-lang>
              </li>
            </ul>
          </div>
          <div class='section-top-info-confirm__main__right__infolist'>
            <icon-level
              class='section-top-info-confirm__main__right__infolist__level'
              :level='lessonInfo.level' />
          </div>
          <p class='section-top-info-confirm__main__right__price'>
            {{ setPrice }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IconLang, IconLevel } from '@/components/atoms/icon';

export default {
  components: {
    IconLang,
    IconLevel,
  },
  props: {
    thumbnail: String,
    lessonInfo: Object,
    levelLabel: Array,
    isLoading: Boolean,
  },
  computed: {
    setPrice() {
      if (this.lessonInfo.price === 0) return '無料';
      return `${this.lessonInfo.price}円`;
    },
  },
};
</script>

<style scoped>
img {
  display: block;
}
.section-top-info-confirm__main {
  width: 100%;
  display: flex;
  background-color: white;
}

.section-top-info-confirm__main__left {
  position: relative;
  width: 50%;
}

.section-top-info-confirm__main__left__thumbnail {
  width: 100%;
}

.section-top-info-confirm__main__right {
  width: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.section-top-info-confirm__main__right__title {
  font-size: 24px;
  width: 100%;
  font-weight: 500;
  line-height: 32px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-all;
}

.section-top-info-confirm__main__right__infolist {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.section-top-info-confirm__main__right__infolist__level {
  margin-top: 2px;
}

.section-top-info-confirm__main__right__infolist__version {
  font-size: 16px;
  margin-left: 10px;
}

.section-top-info-confirm__main__right__tag {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.section-top-info-confirm__main__right__tag__text {
  margin-right: 5px;
}

.section-top-info-confirm__main__right__tag__list {
  display: flex;
  list-style: none;
}

.section-top-info-confirm__main__right__tag__list li {
  margin-left: 5px
}

.section-top-info-confirm__main__right__price {
  margin-top: 5px;
  font-size: 24px;
}

.section-top-info-confirm__main__load {
  margin: 0 auto;
}
</style>
