<template>
  <div class='article-normal'>
    <div class='article-normal__top'>
      <h1>{{ title }}</h1>
      <p>{{ date }}に投稿</p>
    </div>
    <div class='article-normal__markdown mdbase' v-html='parseMarkdown' />
  </div>
</template>

<script>
import marked from 'marked';
import dompurify from 'dompurify';

export default {
  props: {
    title: String,
    text: String,
    date: String,
  },
  computed: {
    parseMarkdown() {
      const md = marked(this.text);
      return dompurify.sanitize(md);
    },
  },
};
</script>

<style scoped>
.article-normal__top {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.article-normal__top h1 {
  font-size: 32px;
  width: 70%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-all;
  overflow: hidden;
}

.article-normal__top p {
  width: 30%;
  text-align: right;
}

.article-normal__markdown {
  line-height: 24px;
}
</style>
