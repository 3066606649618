var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.addClass }, [
    _vm.title
      ? _c("p", { staticClass: "input-file__title" }, [
          _vm._v(" " + _vm._s(_vm.title) + " ")
        ])
      : _vm._e(),
    _c("label", [
      _vm._v(" ファイルを選択 "),
      _c("input", {
        attrs: { type: "file", accept: _vm.accept },
        on: { change: _vm.fileChange }
      })
    ]),
    _vm.description
      ? _c("p", { staticClass: "input-file__maxsize" }, [
          _vm._v(" " + _vm._s(_vm.description) + " ")
        ])
      : _vm._e(),
    !_vm.errorMsg && _vm.uploadCheck && !_vm.resetCheck
      ? _c("div", { staticClass: "input-file__check" }, [
          _c("i", { staticClass: "fas fa-check" })
        ])
      : _vm._e(),
    _vm.errorMsg
      ? _c("p", { staticClass: "input-file__error" }, [
          _vm._v(_vm._s(_vm.errorMsg))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }