var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "component-checkoutservice" }, [
      _c("h2", [_vm._v("決済について")]),
      _c("p", [
        _vm._v("manabibaではStripe("),
        _c("a", { attrs: { href: "https://stripe.com/jp" } }, [
          _vm._v("https://stripe.com/jp")
        ]),
        _vm._v(")を用いて決済を行います。"),
        _c("br"),
        _vm._v(
          " manabibaでアカウント作成時にレッスン購入用、有料レッスン投稿申請時に有料レッスン販売用のStripeアカウントをそれぞれ発行します。"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }