<template>
  <div class='icon-user-lesson'>
    <img :src='setAvatar' alt='ユーザーアイコン' @error='setErrorAvatar'>
    <p>EDITOR: {{ setUsername }}</p>
  </div>
</template>

<script>
export default {
  props: {
    avatar: String,
    username: String,
  },
  computed: {
    setUsername() {
      if (this.username === null) return '削除されたユーザー';
      return this.username;
    },
    setAvatar() {
      if (this.avatar === null) return `${process.env.VUE_APP_S3_URL}/image/avatar/unknown.png`;
      return this.avatar;
    },
  },
  methods: {
    setErrorAvatar(e) {
      e.target.src = `${process.env.VUE_APP_S3_URL}/image/avatar/unknown.png`;
    },
  },
};
</script>

<style scoped>
.icon-user-lesson {
  position: relative;
}

.icon-user-lesson img {
  width: 70px;
  height: 70px;
  border-radius: 70px;
  border: 3px solid #fff;
  position: absolute;
  top: -20px;
  right: 30px;
  z-index: 2;
}

.icon-user-lesson p {
  background-color: #fdd300ec;
  font-size: 12px;
  width: 170px;
  padding: 15px;
  color: #fff;
  transform: rotate( 270deg );
  position: absolute;
  top: 101px;
  right: -20px;
  z-index: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
