var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "dropdown-header-category" },
    _vm._l(_vm.categoryNameList, function(categoryName, key) {
      return _c(
        "li",
        { key: categoryName.id, staticClass: "dropdown-header-category__list" },
        [
          _c(
            "router-link",
            {
              staticClass: "dropdown-header-category__list__btn",
              attrs: { to: { name: "Categories", params: { category: key } } }
            },
            [
              _c("i", { class: "fab fa-" + _vm.setCategoryClass(key) }),
              _c("p", [_vm._v(_vm._s(categoryName))])
            ]
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }