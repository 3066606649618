<template>
  <div class='lesson-create'>
    <layout-header />
    <heading-title>
      <template v-slot:main>
        {{ $route.fullPath.includes('create') ? 'LessonCreate' : 'LessonUpdate' }}
      </template>
      <template v-slot:sub>
        {{ $route.fullPath.includes('create') ? 'レッスン投稿_' : 'レッスン更新_' }}
      </template>
    </heading-title>
    <section class='lesson-create__main'>
      <div class='lesson-create__main__back' />
      <div class='lesson-create__main__loading center' v-show='isLoading.loadStripeInfo'>
        <div>
          <icon-loading />
        </div>
      </div>
      <div class='lesson-create__main__front center' v-show='!isLoading.loadStripeInfo'>
        <div class='lesson-create__main__front__form'>
          <canvas id='canvas' />
          <div
            class='lesson-create__main__front__form__apply'
            v-if='!isActive.enableCreatePaidLesson'
          >
            <p>現在は無料レッスンのみ投稿できます。有料レッスンを投稿するには別途申請が必要です。</p>
            <button-normal small @emit='movePage("UserInfoSales")'>申請</button-normal>
          </div>
          <div class='lesson-create__main__front__form__level'>
            <heading-normal>レベル</heading-normal>
            <input-radio
              :radioInfo='radioInfo.level'
              :checked='radioChecked.level'
              @emit-radio-value='saveLevel'
            />
          </div>
          <div class='lesson-create__main__front__form__category'>
            <heading-normal>カテゴリー</heading-normal>
            <v-select
              class='lesson-create__main__front__form__category__select'
              v-model='uploadFileList.setting.category'
              :options='setCategorySelect'
              multiple
            />
          </div>
          <div class='lesson-create__main__front__form__category'>
            <heading-normal>setting.yml</heading-normal>
            <input-file
              class='lesson-create__main__front__form__category__select'
              :accept='setYmlAccept'
              :errorMsg='errorMsg.settingYml'
              @emit='saveYmlFile'
            />
          </div>
          <div
            class='lesson-create__main__front__form__category'
            v-if='isActive.enableCreatePaidLesson'
          >
            <heading-normal>価格</heading-normal>
            <input-radio
              class='lesson-create__main__front__form__category__select'
              :radioInfo='radioInfo.price'
              :checked='radioChecked.price'
              @emit-radio-value='ActivePrice'
            />
            <div class='lesson-create__main__front__form__price__wrapper' v-if='isActive.price'>
              <div class='lesson-create__main__front__form__price__wrapper__top'>
                <p>レッスンの価格を1000-30000円の間で入力してください。</p>
                <input-number
                  class='lesson-create__main__front__form__price__wrapper__top__input'
                  :maxNumber='validateRule.price.max'
                  :minNumber='validateRule.price.min'
                  :step='validateRule.price.step'
                  :number='setPrice'
                  :errorMsg='errorMsg.price'
                  @emit='savePrice'
                />
              </div>
              <div class='lesson-create__main__front__form__price__wrapper__bottom'>
                <p>無料枠として公開するチャプター数を選択してください。(例: 2を選択するとチャプター1,2が無料公開されます)</p>
                <input-number
                  class='lesson-create__main__front__form__price__wrapper__bottom__input'
                  :maxNumber='validateRule.freeChapterNum.max'
                  :minNumber='validateRule.freeChapterNum.min'
                  :step='validateRule.freeChapterNum.step'
                  :number='setFreeChapterNum'
                  :errorMsg='errorMsg.freeChapterNum'
                  @emit='saveFreeChapterNum'
                />
              </div>
            </div>
          </div>
          <div class='lesson-create__main__front__form__category'>
            <heading-normal>サムネイル</heading-normal>
            <input-file
              class='lesson-create__main__front__form__category__select'
              :accept='setThumbnailAccept'
              :description='inputFile.description'
              :errorMsg='errorMsg.thumbnail'
              @emit='saveThumbnaiImg'
            />
          </div>
          <div>
            <heading-normal>コンテンツアップロード</heading-normal>
            <input-file-lesson
              @emit-post-lesson='buildLessonFormat'
              :errorMsg='errorMsg.content'>
              {{ setInputText }}
            </input-file-lesson>
          </div>
          <div class="lesson-create__button">
            <button-card
              class='lesson-create__button__left'
              icon='download'
              @emit='openNewTab(githubLink.lessonTemplate)'
            >レッスンのテンプレートをダウンロード</button-card>
            <button-card
              class='lesson-create__button__right'
              icon='sticky-note'
              @emit='openNewTab(setPostLink)'
            >{{ $route.fullPath.includes('create') ? 'レッスン投稿手順' : 'レッスン更新手順' }}</button-card>
          </div>
          <div class='lesson-create__main__front__form__nextbtn'>
            <button-normal
              :disabled='!buttonValidation'
              @emit='moveConfirmPage'
              large
            >{{ $route.fullPath.includes('create') ? '投稿内容を確認' : '更新内容を確認' }}</button-normal>
          </div>
        </div>
      </div>
    </section>
    <layout-footer />
  </div>
</template>

<script>
import axios from 'axios';
import jsYml from 'js-yaml';
import vSelect from 'vue-select';
// import 'vue-select/dist/vue-select.css';
import { mapState, mapActions } from 'vuex';
import helper from '@/mixins/general/methods/helper';
import { HeadingTitle, HeadingNormal } from '@/components/atoms/heading/';
import { LayoutHeader, LayoutFooter } from '@/components/layouts/general/';
import { IconLoading } from '@/components/atoms/icon/';
import { ButtonCard, ButtonNormal } from '@/components/atoms/button/';
import {
  InputFileLesson, InputRadio, InputFile, InputNumber,
} from '@/components/atoms/input/';

export default {
  components: {
    vSelect,
    HeadingTitle,
    HeadingNormal,
    ButtonCard,
    ButtonNormal,
    LayoutHeader,
    LayoutFooter,
    InputFileLesson,
    InputRadio,
    InputNumber,
    InputFile,
    IconLoading,
  },
  data() {
    return {
      inputFile: {
        description: '最大5MB',
      },
      isLoading: {
        loadContent: false,
        loadStripeInfo: false,
      },
      isActive: {
        loadComplete: {
          content: false,
          setting: false,
        },
        price: false,
        update: false,
        enableCreatePaidLesson: false,
      },
      uploadFileList: {
        thumbnail: null,
        markdown: [],
        source: [],
        image: {},
        setting: {
          title: null,
          level: null,
          description: null,
          target: null,
          price: 0,
          category: [],
          chapter: [],
          freeChapterNum: null,
        },
        change: {
          setting: false,
          thumbnail: false,
          content: false,
          freeChapterNum: false,
        },
      },
      imageDirectryName: '',
      allowExtension: {
        image: ['png', 'jpg', 'jpeg'],
        source: ['html', 'css', 'js', 'php'],
      },
      validateRule: {
        level: [0, 1, 2],
        category: ['htmlcss', 'javascript', 'php'],
        type: ['new'],
        chapter: {
          change: false,
          minCount: 1,
          maxCount: 10,
        },
        maxLength: {
          title: 100,
          chapterTitle: 100,
          description: 1000,
          target: 1000,
        },
        lessonSize: {
          max: 5000000,
          total: 0,
        },
        filename: {
          html: 'index.html',
          css: 'style.css',
          js: 'index.js',
          php: 'index.php',
        },
        thumbnail: {
          accept: ['image/png', 'image/jpeg'],
          maxSize: 5000000,
          total: 0,
        },
        lessonMaterialSize: {
          max: 2000000,
          total: 0,
        },
        price: {
          max: 30000,
          min: 1000,
          step: 100,
        },
        freeChapterNum: {
          max: 9,
          min: 0,
          step: 1,
        },
      },
      errorMsg: {
        thumbnail: '',
        settingYml: '',
        content: '',
        price: '',
        freeChapterNum: '',
      },
      githubLink: {
        lessonTemplate: 'https://github.com/donbler/lesson-template/releases',
        lessonCreateProcedure: 'https://www.notion.so/70104fcb7fcc40a6b2c5fdb5b8df03cc',
        lessonUpdateProcedure: 'https://www.notion.so/3946f54392c8408cb8d0f7c692c22620',
      },
      regex: {
        replace: /\/\/ :replace\(["']?(.+)["']?\)/g,
      },
      inputFileText: this.$route.fullPath.includes('create')
        ? '投稿したいレッスンフォルダを選択してください(最大5MB)'
        : '更新したいレッスンフォルダを選択してください(最大5MB)',
      radioChecked: {
        level: 0,
        price: 'free',
      },
      radioInfo: {
        level: {
          name: 'level',
          dataList: [
            {
              value: 0,
              label: '初心者',
            },
            {
              value: 1,
              label: '中級者',
            },
            {
              value: 2,
              label: '上級者',
            },
          ],
        },
        price: {
          name: 'price',
          dataList: [
            {
              value: 'free',
              label: '無料',
            },
            {
              value: 'paid',
              label: '有料',
            },
          ],
        },
      },
      categoryList: ['htmlcss', 'javascript', 'php'],
    };
  },
  mixins: [helper],
  computed: {
    ...mapState('user', ['userInfo']),
    setYmlAccept() {
      return 'application/x-yaml';
    },
    setThumbnailAccept() {
      return this.validateRule.thumbnail.accept.join();
    },
    setInputText() {
      if (this.isLoading.loadContent) return 'レッスンを読み込んでいます...';
      if (this.isActive.loadComplete.content) return 'レッスンの読み込みを完了しました';
      return this.inputFileText;
    },
    setPostLink() {
      if (this.$route.fullPath.includes('create')) return this.githubLink.lessonCreateProcedure;
      return this.githubLink.lessonUpdateProcedure;
    },
    setPrice() {
      if (this.uploadFileList.setting.price === 0) return 1000;
      return this.uploadFileList.setting.price;
    },
    setFreeChapterNum() {
      if (this.uploadFileList.setting.freeChapterNum === null) return 0;
      return this.uploadFileList.setting.freeChapterNum;
    },
    setCategorySelect() {
      const selectedCategory = this.uploadFileList.setting.category;
      const unselectedCategory = [];
      // 既に選択済のカテゴリー以外をunselectedCategoryにまとめて返却
      this.categoryList.forEach((category) => {
        if (!selectedCategory.includes(category)) {
          unselectedCategory.push(category);
        }
      });
      return unselectedCategory;
    },
    buttonValidation() {
      if (this.errorMsg.settingYml !== ''
        || this.errorMsg.content !== ''
        || this.errorMsg.thumbnail !== ''
        || this.errorMsg.freeChapterNum !== ''
        || this.errorMsg.price !== '') return false;

      if (this.$route.fullPath.includes('create')) {
        if (!this.isActive.loadComplete.content
          || !this.isActive.loadComplete.setting) return false;
      }

      if (this.$route.fullPath.includes('update')) {
        if (!this.isActive.update) return false;
      }
      return true;
    },
  },
  async created() {
    // 有料レッスンの取り扱いが可能か検証
    this.judgeEnableCreatePaidLesson();
    // レッスン更新の場合現在選択している値を取得
    if (this.$route.fullPath.includes('update')) {
      const target = this.userInfo.lesson.post
        .filter((lesson) => lesson.id === Number(this.$route.params.id))[0];
      this.changeFormatContent(target.content);
      if (target !== undefined) {
        this.uploadFileList.setting.category = target.category;
        this.uploadFileList.setting.chapter = target.chapter;
        this.uploadFileList.setting.title = target.title;
        this.uploadFileList.setting.description = target.description;
        this.uploadFileList.setting.target = target.target;
        this.uploadFileList.setting.price = target.price;
        this.uploadFileList.setting.freeChapterNum = target.free_chapter_num;
        if (target.price !== 0) this.radioChecked.price = 'paid';
        this.uploadFileList.thumbnail = target.thumbnail;
        this.radioChecked.level = target.level;
        this.validateRule.chapter.minCount = target.chapter.length;
        this.validateRule.freeChapterNum.max = target.chapter.length - 1;
      }
    }
  },
  methods: {
    ...mapActions('lessonCreate', ['loadPostLesson']),
    ...mapActions('user', ['loadAccountInfo']),
    loading(target) {
      this.isLoading[target] = !this.isLoading[target];
    },
    active(target) {
      this.isActive[target] = !this.isActive[target];
    },
    ActivePrice(price) {
      this.radioChecked.price = price;
      this.errorMsg.price = this.validationPrice().message;
      this.errorMsg.freeChapterNum = this.validationFreeChapterNum().message;
      if (price === 'free') {
        this.isActive.price = false;
        if (this.$route.fullPath.includes('update')
          && this.uploadFileList.setting.price !== 0) {
          this.updateCheck('setting');
        }
      } else {
        this.isActive.price = true;
        if (this.uploadFileList.setting.price < 1000) this.savePrice(1000);
      }
    },
    openNewTab(url) {
      window.open(url, '_blank');
    },
    async judgeEnableCreatePaidLesson() {
      try {
        this.loading('loadStripeInfo');
        this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
        const { data } = await this.$http.get('/api/auth/user/account/status');
        if (data.charges_enabled && data.payouts_enabled) {
          this.active('enableCreatePaidLesson');
        }
        this.loading('loadStripeInfo');
      } catch (e) {
        if (e.response.status === 401) this.movePage('Login', '', { url: this.$route.path });
        if (e.response.status === 500) this.movePage('Error');
        this.loading('loadStripeInfo');
      }
    },
    updateCheck(target) {
      if (this.$route.fullPath.includes('update')) {
        this.uploadFileList.change[target] = true;
        this.isActive.update = true;
      }
    },
    saveLevel(level) {
      this.uploadFileList.setting.level = level;
    },
    savePrice(price) {
      this.uploadFileList.setting.price = Number(price);
      this.errorMsg.price = this.validationPrice().message;
      if (this.$route.fullPath.includes('update')) {
        this.updateCheck('setting');
      }
    },
    saveFreeChapterNum(freeChapterNum) {
      this.uploadFileList.setting.freeChapterNum = Number(freeChapterNum);
      this.errorMsg.freeChapterNum = this.validationFreeChapterNum().message;
      if (this.$route.fullPath.includes('update')) {
        this.updateCheck('setting');
        this.updateCheck('freeChapterNum');
      }
    },
    async changeFormatContent(contentUrl) {
      try {
        const res = await axios.get(contentUrl);
        res.data.forEach((content, index) => {
          this.uploadFileList.markdown.push({
            chapterNum: index + 1,
            text: content.markdown,
          });
          content.source.forEach((source) => {
            this.uploadFileList.source.push({
              chapterNum: index + 1,
              filename: source.filename,
              lang: source.filename.split('.')[1],
              source: {
                assignment: source.assignment,
                sanple: source.sample,
              },
            });
          });
        });
      } catch (e) {
        this.movePage('Error');
      }
    },
    saveYmlFile(content) {
      try {
        this.errorMsg.settingYml = '';
        const fileReader = new FileReader();
        fileReader.onload = (ev) => {
          try {
            const setting = jsYml.safeLoad(ev.target.result);
            this.uploadFileList.setting.title = setting.title.trim();
            this.uploadFileList.setting.description = setting.description.trim();
            this.uploadFileList.setting.target = setting.target.trim();
            this.uploadFileList.setting.chapter = setting.chapter.map((title) => title.trim());
            this.validateRule.freeChapterNum.max = setting.chapter.length - 1;
            this.errorMsg.settingYml = this.validationSettingYml().message;
            this.errorMsg.freeChapternum = this.validationFreeChapterNum().message;
          } catch (e) {
            this.errorMsg.settingYml = 'setting.ymlに入力ミスがあります';
          }
        };
        fileReader.readAsText(content);
        if (this.$route.fullPath.includes('update')) {
          this.updateCheck('setting');
        } else {
          this.isActive.loadComplete.setting = true;
        }
      } catch (e) {
        this.errorMsg.settingYml = 'setting.ymlに入力ミスがあります';
      }
    },
    saveThumbnaiImg(content) {
      this.errorMsg.thumbnail = '';
      // バリデーション
      if (content.size > this.validateRule.thumbnail.maxSize) {
        // ファイルサイズ
        this.errorMsg.thumbnail = `${this.validateRule.thumbnail.maxSize / 1000000}MB以上のファイルはアップロードできません`;
        return;
      } if (!this.validateRule.thumbnail.accept.includes(content.type)) {
        // 拡張子チェック
        this.errorMsg.thumbnail = '拡張子がpng、jpg、jpeg以外のファイルはアップロードできません';
        return;
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        const img = new Image();
        img.src = ev.target.result;
        const canvas = document.getElementById('canvas');
        const ctx = canvas.getContext('2d');
        const readerThis = this;
        const cut = {
          width: 0,
          height: 0,
        };
        img.onerror = function () {
          readerThis.errorMsg.thumbnail = 'ファイルが破損している可能性があります';
        };
        img.onload = function () {
          const canvasSize = {
            width: 0,
            height: 0,
          };
          if (img.width * 9 > img.height * 16) {
            canvasSize.width = (img.height * 16) / 9;
            canvasSize.height = img.height;
            cut.width = img.width - canvasSize.width;
          } else {
            canvasSize.width = img.width;
            canvasSize.height = (img.width * 9) / 16;
            cut.height = img.height - canvasSize.height;
          }
          canvas.width = canvasSize.width;
          canvas.height = canvasSize.height;
          ctx.fillStyle = '#fff';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, cut.width / 2, cut.height / 2, canvasSize.width,
            canvasSize.height, 0, 0, canvasSize.width, canvasSize.height);
          let imgData = canvas.toDataURL('image/png', 1);
          const blob = readerThis.base64ToBlob(imgData);
          const capacityRatio = 2000000 / blob.size;
          imgData = canvas.toDataURL('image/jpeg', capacityRatio);
          readerThis.uploadFileList.thumbnail = imgData;
        };
      };
      this.updateCheck('thumbnail');
      fileReader.readAsDataURL(content);
    },
    base64ToBlob(base64) {
      const base64Data = base64.split(',')[1];
      const data = window.atob(base64Data);
      const buff = new ArrayBuffer(data.length);
      const arr = new Uint8Array(buff);
      let i;
      let dataLen;
      for (i = 0, dataLen = data.length; i < dataLen; i += 1) {
        arr[i] = data.charCodeAt(i);
      }
      const blob = new Blob([arr], { type: 'image/jpeg' });
      return blob;
    },
    // 受け取ったファイルをmanabibaレッスンフォーマットに変換する
    async buildLessonFormat(event) {
      let res;
      this.loading('loadContent');
      this.isActive.loadComplete.content = false;
      this.resetUploadFile();
      await this.loadFile(event).then(() => {
        res = this.validationContent();
      });
      this.errorMsg.content = res.message;
      if (Object.keys(this.uploadFileList.image).length && this.errorMsg.content === '') {
        try {
          this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
          const imageUrl = await this.$http.post('/api/creator/lessons/image', {
            image: JSON.stringify(this.uploadFileList.image),
          });
          this.imageDirectryName = `${process.env.VUE_APP_S3_URL}/${imageUrl.data}`;
          this.changeImageLink();
        } catch (e) {
          this.errorMsg.content = 'contentのアップロードに失敗しました';
        }
      }
      if (await res.result) {
        if (this.$route.fullPath.includes('update')) {
          this.updateCheck('content');
        }
        this.loading('loadContent');
        this.isActive.loadComplete.content = true;
      } else {
        this.loading('loadContent');
      }
    },
    changeImageLink() {
      this.uploadFileList.markdown.forEach((md, mdIndex) => {
        this.uploadFileList.markdown[mdIndex].text = md.text
          .replace(/\.\.\/image\//g, this.imageDirectryName);
      });
      this.uploadFileList.source.forEach((source, sourceIndex) => {
        this.uploadFileList.source[sourceIndex].source.assignment = source.source.assignment
          .replace(/\.\.\/\.\.\/image\//g, this.imageDirectryName);
        this.uploadFileList.source[sourceIndex].source.sample = source.source.sample
          .replace(/\.\.\/\.\.\/image\//g, this.imageDirectryName);
      });
    },
    // event内から投稿されたファイルを取り出す
    loadFile(event) {
      return new Promise((resolve) => {
        let fileCount = 0;
        const flg = {
          loadImg: false,
          loadFile: true,
        };
        const fileLength = event.target.files.length;
        event.target.files.forEach((file) => {
          this.validateRule.lessonSize.total += file.size;
          const filePath = file.webkitRelativePath.split('/').slice(1);
          const fileReader = new FileReader();
          fileReader.onload = (ev) => {
            if (filePath[0] === 'markdown' && file.name.split('.')[1] === 'md') {
              // markdown読み込み
              this.uploadFileList.markdown.push({
                chapterNum: Number(file.name.split('.')[0]),
                text: ev.target.result,
              });
            } else if (filePath[0] === 'source' && this.allowExtension.source.includes(file.name.split('.')[1])) {
              // ソースコード読み込み
              this.separateSource(file, ev);
            } else if (filePath[0] === 'image' && filePath.length === 2 && file.name) {
              const invisibleFileCheck = file.name.split(/\.(?=[^.]+$)/)[0];
              if (invisibleFileCheck) {
                this.uploadFileList.image[file.name] = ev.target.result;
                this.validateRule.lessonMaterialSize.total += file.size;
              }
            }
          };
          fileReader.onloadend = () => {
            fileCount += 1;
            if (fileLength === fileCount) {
              flg.loadFile = false;
              if (!flg.loadImg) {
                resolve();
                if (this.uploadFileList.markdown.length
                  !== this.uploadFileList.setting.chapter.length) {
                  this.validateRule.chapter.change = true;
                }
              }
            }
          };
          if (this.allowExtension.image.includes(file.name.split('.')[1])
            || filePath[0] === 'image') {
            fileReader.readAsDataURL(file);
          } else {
            fileReader.readAsText(file);
          }
        });
      });
    },
    resetUploadFile() {
      this.uploadFileList.markdown = [];
      this.uploadFileList.source = [];
      this.uploadFileList.image = {};
      this.errorMsg.content = '';
      this.validateRule.lessonMaterialSize.total = 0;
      this.validateRule.lessonSize.total = 0;
    },
    moveConfirmPage() {
      try {
        if (this.radioChecked.price === 'free') {
          this.uploadFileList.setting.price = 0;
          this.uploadFileList.setting.freeChapterNum = null;
        }
        if (this.uploadFileList.setting.freeChapterNum === 0) {
          this.uploadFileList.setting.freeChapterNum = null;
        }
        // price,freeChapterNumのバリデーション
        if (!this.validationPrice().result || !this.validationFreeChapterNum().result) throw new Error('レッスンの投稿に失敗しました');

        // レッスン読み込み済みの場合のみ処理続行
        if (this.$route.fullPath.includes('create')) {
          if (!this.isActive.loadComplete.content && !this.isActive.loadComplete.setting) throw new Error('レッスンの投稿に失敗しました');
        } else if (!this.isActive.update) {
          throw new Error('レッスンの投稿に失敗しました');
        }

        if (this.validateRule.chapter.change) {
          const res = this.validationChapter();
          if (!res.result) throw new Error(res.message);
        }
        // Stateに読み込んだレッスンを格納し、確認ページに移動
        this.loadPostLesson(this.uploadFileList);
        if (this.$route.fullPath.includes('create')) {
          this.movePage('LessonCreateConfirm');
        } else {
          this.movePage('LessonUpdateConfirm', { id: this.$route.params.id });
        }
      } catch (e) {
        this.showNoticeBar(
          'error',
          e.message,
        );
      }
    },
    // ソースコードをユーザー入力用・見本用に分離する
    separateSource(file, event) {
      const extension = file.name.split('.')[1];
      const chapterNum = Number(file.webkitRelativePath.split('/').slice(1)[1]);
      // 行単位で処理するため\nを区切り字とした配列を作成する
      const splitSource = event.target.result.split('\n');

      // 見本用ソースコード対応
      let assignmentSource = '';
      let sampleSource = '';

      // JS用処理
      if (extension === 'js') {
        splitSource.forEach((row) => {
          if (row.match(/\/\/ :cut/)) {
          // :cut対応 行内の文字列を削除する
            assignmentSource += '\n';
            sampleSource += `${row.replace(/\/\/ :cut/, '')}\n`;
            // 以下replace対応は一時保留
            // } else if (row.match(/\/\/ :replace/)) {
            //   // :replace対応 行内の文字列を:replace()のカッコ内の文字列に置換する,文字列が取得できない場合改行設置
            //   const replaceWord = this.regex.replace.exec(row);
            //   if (replaceWord) {
            //     assignmentSource += `${replaceWord[1]}\n`;
            //   } else {
            //     assignmentSource += '\n';
            //   }
          } else {
          // 通常対応
            assignmentSource += `${row}\n`;
            sampleSource += `${row}\n`;
          }
        });
      }

      // HTML用処理
      if (extension === 'html') {
        splitSource.forEach((row) => {
          if (row.match(/<!-- :cut -->/)) {
          // :cut対応 行内の文字列を削除する
            assignmentSource += '\n';
            sampleSource += `${row.replace(/<!-- :cut -->/, '')}\n`;
          } else {
          // 通常対応
            assignmentSource += `${row}\n`;
            sampleSource += `${row}\n`;
          }
        });
      }

      // CSS用処理
      if (extension === 'css') {
        splitSource.forEach((row) => {
          if (row.match(/\/\* :cut \*\//)) {
          // :cut対応 行内の文字列を削除する
            assignmentSource += '\n';
            sampleSource += `${row.replace(/\/\* :cut \*\//, '')}\n`;
          } else {
          // 通常対応
            assignmentSource += `${row}\n`;
            sampleSource += `${row}\n`;
          }
        });
      }

      // PHP用処理
      if (extension === 'php') {
        splitSource.forEach((row) => {
          if (row.match(/\/\/ :cut/)) {
          // :cut対応 行内の文字列を削除する
            assignmentSource += '\n';
            sampleSource += `${row.replace(/\/\/ :cut/, '')}\n`;
          } else {
          // 通常対応
            assignmentSource += `${row}\n`;
            sampleSource += `${row}\n`;
          }
        });
      }

      // ソースコード情報をpush
      this.uploadFileList.source.push({
        chapterNum,
        filename: file.name,
        lang: file.name.split('.')[1],
        source: {
          assignment: assignmentSource,
          sample: sampleSource,
        },
      });
    },
    validationSettingYml() {
      try {
        /* settingバリデーション */
        // 入力値未定義チェック
        if (this.uploadFileList.setting.title === undefined
          || this.uploadFileList.setting.title === null
          || this.uploadFileList.setting.title === '') {
          return {
            result: false,
            message: 'setting.ymlにtitleの情報がありません',
          };
        }
        if (this.uploadFileList.setting.description === undefined
          || this.uploadFileList.setting.description === null
          || this.uploadFileList.setting.description === '') {
          return {
            result: false,
            message: 'setting.ymlにdescriptionの情報がありません',
          };
        }
        if (this.uploadFileList.setting.target === undefined
          || this.uploadFileList.setting.target === null
          || this.uploadFileList.setting.target === '') {
          return {
            result: false,
            message: 'setting.ymlにtargetの情報がありません',
          };
        }
        if (this.uploadFileList.setting.chapter === undefined
          || this.uploadFileList.setting.chapter === null
          || this.uploadFileList.setting.chapter.length === 0) {
          return {
            result: false,
            message: 'setting.ymlにチャプターの情報がありません',
          };
        }

        // 文字数チェック
        if (this.uploadFileList.setting.title.length > this.validateRule.maxLength.title) {
          return {
            result: false,
            message: 'タイトルが長すぎます',
          };
        }
        if (this.uploadFileList.setting.description.length
          > this.validateRule.maxLength.description) {
          return {
            result: false,
            message: '説明のテキストが長すぎます',
          };
        }
        if (this.uploadFileList.setting.target.length > this.validateRule.maxLength.target) {
          return {
            result: false,
            message: '対象層のテキストが長すぎます',
          };
        }
        // チャプター件数チェック
        if (this.uploadFileList.setting.chapter.length > this.validateRule.chapter.minCount) {
          this.validateRule.chapter.change = true;
        } else if (this.uploadFileList.setting.chapter.length
          < this.validateRule.chapter.minCount) {
          return {
            result: false,
            message: 'チャプター数を減らす更新はできません',
          };
        }
        if (this.uploadFileList.setting.chapter.length > this.validateRule.chapter.maxCount) {
          return {
            result: false,
            message: `チャプターの最大件数${this.validateRule.chapter.maxCount}件を超えています`,
          };
        }
        // チャプタータイトル未入力チェック
        const noTitleChapters = this.uploadFileList.setting.chapter.filter(
          (chapter) => chapter.length === 0,
        );
        if (noTitleChapters.length !== 0) {
          return {
            result: false,
            message: 'チャプタータイトルを入力してください',
          };
        }

        // チャプタータイトル文字数チェック
        const lengthOverTitleChapters = this.uploadFileList.setting.chapter.filter(
          (chapter) => chapter.length > this.validateRule.maxLength.chapterTitle,
        );
        if (lengthOverTitleChapters.length !== 0) {
          return {
            result: false,
            message: 'チャプタータイトルが長すぎます',
          };
        }

        return {
          result: true,
          message: '',
        };
      } catch (e) {
        return {
          result: false,
          message: 'setting.ymlに入力ミスがあります',
        };
      }
    },
    validationPrice() {
      try {
        // free選択時
        if (this.radioChecked.price === 'free') {
          return {
            result: true,
            message: '',
          };
        }

        // 価格設定バリデーション
        if (this.uploadFileList.setting.price % 1 !== 0) {
          return {
            result: false,
            message: '価格は整数以外指定できません',
          };
        }

        if (this.uploadFileList.setting.price < 1000 || this.uploadFileList.setting.price > 30000) {
          return {
            result: false,
            message: '価格は¥1000-30000以外指定できません',
          };
        }
        return {
          result: true,
          message: '',
        };
      } catch (e) {
        return { result: false };
      }
    },
    validationFreeChapterNum() {
      try {
        // free選択時
        if (this.radioChecked.price === 'free') {
          return {
            result: true,
            message: '',
          };
        }

        // 無料枠設置バリデーション
        if (this.uploadFileList.setting.freeChapterNum % 1 !== 0) {
          return {
            result: false,
            message: '無料枠は整数以外指定できません',
          };
        }
        if (this.uploadFileList.setting.chapter.length === 0
          && this.uploadFileList.setting.freeChapterNum > this.validateRule.freeChapterNum.max) {
          return {
            result: false,
            message: `最大無料枠設定数は${this.validateRule.freeChapterNum.max}です`,
          };
        }
        if (this.uploadFileList.setting.chapter.length === 0
          && this.uploadFileList.setting.freeChapterNum < this.validateRule.freeChapterNum.min) {
          return {
            result: false,
            message: '最小無料枠設定数は0です',
          };
        }
        if (this.uploadFileList.setting.chapter.length !== 0
          && (this.uploadFileList.setting.chapter.length
            <= this.uploadFileList.setting.freeChapterNum)) {
          return {
            result: false,
            message: `最大無料枠設定数は${this.uploadFileList.setting.chapter.length - 1}です`,
          };
        }
        return {
          result: true,
          message: '',
        };
      } catch (e) {
        return { result: false };
      }
    },
    validationChapter() {
      try {
        // チャプタータイトル件数とマークダウン件数が一致しているか確認
        if (this.uploadFileList.setting.chapter.length !== this.uploadFileList.markdown.length) {
          return {
            result: false,
            message: 'チャプターのタイトル数とチャプターのコンテンツ数が一致しません',
          };
        }
        return { result: true };
      } catch (e) {
        return { result: false };
      }
    },
    validationContent() {
      try {
        /* image,sourceのファイル名チェック */
        const sourceFilenameCheck = this.uploadFileList.source
          .filter((source) => !RegExp(/^[a-zA-Z0-9]+$/).test(source.filename.split(/\.(?=[^.]+$)/)[0]));
        const imageFilenameCheck = Object.keys(this.uploadFileList.image)
          .filter((image) => !RegExp(/^[a-zA-Z0-9]+$/).test(image.split(/\.(?=[^.]+$)/)[0]));
        if (sourceFilenameCheck.length !== 0 || imageFilenameCheck.length !== 0) {
          return {
            result: false,
            message: 'ファイル名に半角英数字以外の文字が含まれています',
          };
        }

        /* md,sourceに使用する画像のバリデーション */
        const imageBinaryData = Object.keys(this.uploadFileList.image).map((key) => {
          let data;
          if (this.uploadFileList.image[key].includes('data:image/jpeg;base64')) {
            data = Uint8Array.from(atob(this.uploadFileList.image[key].replace('data:image/jpeg;base64,', '')), (c) => c.charCodeAt(0));
          } else if (this.uploadFileList.image[key].includes('data:image/png;base64')) {
            data = Uint8Array.from(atob(this.uploadFileList.image[key].replace('data:image/png;base64,', '')), (c) => c.charCodeAt(0));
          } else {
            data = '';
          }
          return data;
        });
        const filtereImageBinaryData = imageBinaryData.filter((binaryData) => !(
          (binaryData[0] === 137
            && binaryData[1] === 80
            && binaryData[2] === 78
            && binaryData[3] === 71
            && binaryData[4] === 13
            && binaryData[5] === 10
            && binaryData[6] === 26
            && binaryData[7] === 10)
          || (binaryData[0] === 255// jpegファイルのシグネチャーバリデーション
            && binaryData[1] === 216
            && binaryData.slice(-2)[0] === 255
            && binaryData.slice(-1)[0] === 217)));
        if (imageBinaryData.includes('')) {
          return {
            result: false,
            message: `画像の種類は${this.allowExtension.image.join()}のみです`,
          };
        } if (filtereImageBinaryData.length !== 0) {
          return {
            result: false,
            message: '不正な画像ファイルが存在します',
          };
        }
        /* ファイルサイズチェック */
        if (this.validateRule.lessonSize.total > this.validateRule.lessonSize.max) {
          return {
            result: false,
            message: 'レッスンサイズが大きすぎます',
          };
        }

        /* imageファイルサイズチェック */
        if (this.validateRule.lessonMaterialSize.total > this.validateRule.lessonMaterialSize.max) {
          return {
            result: false,
            message: '画像の最大容量は2MBです',
          };
        }
        /* md命名規則チェック */
        const filteredMarkdown = this.uploadFileList.markdown
          .filter((markdown) => markdown.chapterNum);
        const filteredChapterNumMarkdown = filteredMarkdown
          .sort((a, b) => a.chapterNum - b.chapterNum)
          .filter((value, index) => value.chapterNum === index + 1);
        if (filteredMarkdown.length !== this.uploadFileList.markdown.length
          || filteredMarkdown.length !== filteredChapterNumMarkdown.length
          || filteredMarkdown.length === 0) {
          return {
            result: false,
            message: 'マークダウン名が正しくありません',
          };
        }

        /* source命名規則チェック */
        const sourceChapterList = this.uploadFileList.source.map((source) => source.chapterNum)
          .filter((x, i, self) => self.indexOf(x) === i)
          .sort((a, b) => a - b);

        if (sourceChapterList.filter((value, index) => value === index + 1).length
          !== sourceChapterList.length) {
          return {
            result: false,
            message: 'ソース名が正しくありません',
          };
        }

        // sourceの番号確認
        if (sourceChapterList.length !== this.uploadFileList.markdown.length) {
          return {
            result: false,
            message: 'マークダウンとソースの件数が一致しません',
          };
        }

        /* jsバリデーション */
        const filterJsList = this.uploadFileList.source.filter((sourceInfo) => sourceInfo.lang === 'js');
        // 1レッスン1ファイルかどうか
        const filterJsChapterNum = filterJsList
          .map((sourceInfo) => sourceInfo.chapterNum) // chapterNumのみの配列形成
          .filter((value, index, self) => self.indexOf(value) // 重複Numを取得
          === index && self.lastIndexOf(value) !== index);
        if (filterJsChapterNum.length !== 0) {
          return {
            result: false,
            message: 'jsファイルは1チャプターあたり1ファイルしか設置できません',
          };
        }
        // ファイル名が適切か
        const filterJsFilename = filterJsList
          .map((sourceInfo) => sourceInfo.filename)
          .filter((filename) => filename !== this.validateRule.filename.js);
        if (filterJsFilename.length !== 0) {
          return {
            result: false,
            message: 'jsファイル名はindex.js以外設定できません',
          };
        }

        /* htmlバリデーション */
        const filterHtmlList = this.uploadFileList.source.filter((sourceInfo) => sourceInfo.lang === 'html');
        for (let i = 1; i < filterHtmlList.length; i += 1) {
          const filterChapterFilename = filterHtmlList
            .filter((sourceInfo) => sourceInfo.chapterNum === i)
            .map((sourceInfo) => sourceInfo.filename);
          if (filterChapterFilename.length === 0) break;
          if (!filterChapterFilename.includes(this.validateRule.filename.html)) {
            // index.htmlが存在するか
            return {
              result: false,
              message: 'htmlファイルをチャプターに加える場合、index.htmlを必ず設置してください',
            };
          } if (filterChapterFilename.length > 5) {
            // htmlの最大件数を超えてないか
            return {
              result: false,
              message: '1チャプターあたりのhtmlの最大件数5件を超えています',
            };
          }
        }

        /* cssバリデーション */
        const filterCssList = this.uploadFileList.source.filter((sourceInfo) => sourceInfo.lang === 'css');
        // 1レッスン1ファイルかどうか
        const filterCssChapterNum = filterCssList
          .map((sourceInfo) => sourceInfo.chapterNum) // chapterNumのみの配列形成
          .filter((value, index, self) => self.indexOf(value) // 重複Numを取得
          === index && self.lastIndexOf(value) !== index);
        if (filterCssChapterNum.length !== 0) {
          return {
            result: false,
            message: 'cssファイルは1チャプターあたり1ファイルしか設置できません',
          };
        }

        // ファイル名が適切か
        const filterCssFilename = filterCssList
          .map((sourceInfo) => sourceInfo.filename)
          .filter((filename) => filename !== this.validateRule.filename.css);
        if (filterCssFilename.length !== 0) {
          return {
            result: false,
            message: 'cssファイル名はstyle.css以外設定できません',
          };
        }

        /* phpバリデーション */
        const filterPhpList = this.uploadFileList.source.filter((sourceInfo) => sourceInfo.lang === 'php');
        // 1チャプター1ファイルかどうか
        const filterPhpChapterNum = filterPhpList
          .map((sourceInfo) => sourceInfo.chapterNum) // chapterNumのみの配列形成
          .filter((value, index, self) => self.indexOf(value) // 重複Numを取得
          === index && self.lastIndexOf(value) !== index);
        if (filterPhpChapterNum.length !== 0) {
          return {
            result: false,
            message: 'phpファイルは1チャプターあたり1ファイルしか設置できません',
          };
        }
        // ファイル名が適切か
        const filterPhpFilename = filterPhpList
          .map((sourceInfo) => sourceInfo.filename)
          .filter((filename) => filename !== this.validateRule.filename.php);
        if (filterPhpFilename.length !== 0) {
          return {
            result: false,
            message: 'phpファイル名はindex.php以外設定できません',
          };
        }
        return {
          result: true,
          message: '',
        };
      } catch (e) {
        return {
          result: false,
          message: 'コンテンツのアップロードに失敗しました',
        };
      }
    },
  },
  watch: {
    'uploadFileList.setting.level': function (to) {
      if (this.$route.fullPath.includes('update')) {
        const target = this.userInfo.lesson.post
          .filter((lesson) => lesson.id === Number(this.$route.params.id))[0];
        if (target.level !== to) {
          this.updateCheck('setting');
        }
      }
    },
    'uploadFileList.setting.category': function (to) {
      if (this.$route.fullPath.includes('update')) {
        const target = this.userInfo.lesson.post
          .filter((lesson) => lesson.id === Number(this.$route.params.id))[0];
        if (target.category !== to) {
          this.updateCheck('setting');
        }
      }
    },
  },
};
</script>

<style scoped>
.lesson-create {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.lesson-create__main {
  position: relative;
}

.lesson-create__main__back {
  width: 100%;
  height: 50px;
  position: absolute;
  z-index: 1;
  background-color: #4CAF50;
}

.lesson-create__main__front {
  position: relative;
  z-index: 2;
}

.lesson-create__main__loading {
  position: relative;
  z-index: 2;
}

.lesson-create__main__loading div {
  margin: 0 60px;
  padding: 20px 0;
  background-color: #fff;
}

.lesson-create__main__front__form {
  margin: 0 60px 60px;
  padding: 40px;
  background-color: #fff;
  position: relative;
  z-index: 2;
}

.lesson-create__main__front__form__apply {
  background-color: #90CCE6;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin-bottom: 40px;
}

.lesson-create__main__front__form__level {
  margin-bottom: 40px;
}

.lesson-create__main__front__form__category {
  margin-bottom: 40px;
}

.lesson-create__main__front__form__category__select {
  width: 500px;
}

.lesson-create__main__front__form__category__form {
  width: 110px;
  margin-top: 15px;
}

.lesson-create__main__front__form__price__wrapper__top {
  margin: 10px 0;
}

.lesson-create__main__front__form__price__wrapper__top p {
  font-size: 14px;
  margin-bottom: 5px;
}

.lesson-create__main__front__form__price__wrapper__top__input {
  width: 100px;
}

.lesson-create__main__front__form__price__wrapper__bottom p {
  font-size: 14px;
  margin-bottom: 5px;
}

.lesson-create__main__front__form__price__wrapper__bottom__input {
  width: 100px;
}

.lesson-create__main__front__form__nextbtn {
  padding-top: 40px;
  border-top: 1px solid #F2EEEE;
  display: flex;
  justify-content: center;
}

.lesson-create__button {
  margin: 10px 0;
  display: flex;
}

.lesson-create__button__left {
  margin-right: 10px;
}

.lesson-create__button__right {
  margin-left: 10px;
}

#canvas {
  display: none;
}
</style>
