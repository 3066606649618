var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "not-found" },
    [
      _c("layout-header", { staticClass: "not-found__header" }),
      _c("div", { staticClass: "not-found__contents" }, [
        _c("h3", { staticClass: "not-found__contents__title" }, [
          _vm._v(_vm._s(_vm.title))
        ]),
        _c("p", { staticClass: "not-found__contents__text" }, [
          _vm._v(_vm._s(_vm.text))
        ])
      ]),
      _c("img", {
        staticClass: "not-found__background",
        attrs: { src: require("@/assets/img/peach.svg"), alt: "桃 peach ロゴ" }
      }),
      _c("layout-footer", {
        staticClass: "not-found__footer",
        attrs: { hidePageUp: "", simpleFooter: "" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }