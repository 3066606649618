<template>
  <div class='modal-auth'>
    <div class='modal-auth__main' v-if='!load'>
      <div class='modal-auth__main__content'>
        <h3 class='modal-auth__main__title'>ログイン</h3>
        <ul class='modal-auth__main__iconlist'>
          <li :key='auth.id' v-for='auth in authList'>
            <button-sns
              class='modal-auth__main__iconlist__sns'
              :auth='auth'
              @emit='$emit("emit-login", auth)'>
            </button-sns>
          </li>
        </ul>
        <div class='modal-auth__main__description'>
          <p>いずれかのサービスのアカウントでログインしてください。</p>
          <p>続行することで</p>
          <a
            class='modal-auth__main__description__link'
            :href='getRouteData("Terms")'
            target='_blank'
            rel='noopener noreferrer'
          >利用規約</a>
          <p>、</p>
          <a
            class='modal-auth__main__description__link'
            :href='getRouteData("PrivacyPolicy")'
            target='_blank'
            rel='noopener noreferrer'
          >プライバシーポリシー</a>
          <p>、</p>
          <a
            class='modal-auth__main__description__link'
            :href='getRouteData("CheckoutService")'
            target='_blank'
            rel='noopener noreferrer'
          >決済について</a>
          <p>に同意したとみなします。</p>
        </div>
      </div>
    </div>
    <div class='modal-auth__main' v-if='load'>
      <icon-loading>
        ログインしています... 少々お待ちください
      </icon-loading>
    </div>
  </div>
</template>

<script>
import { ButtonSns } from '@/components/atoms/button';
import { IconLoading } from '@/components/atoms/icon';

export default {
  components: {
    ButtonSns,
    IconLoading,
  },
  props: {
    load: Boolean,
  },
  data() {
    return {
      // authList: ['github', 'twitter', 'google', 'facebook'],
      // authList: ['github', 'twitter', 'google'], // リリース時facebook除外
      authList: ['github'], // リリース時facebook除外
    };
  },
  methods: {
    getRouteData(name) {
      return this.$router.resolve({ name }).href;
    },
  },
};
</script>

<style scoped>
.modal-auth {
  height: 510px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.modal-auth__main {
  background-color: #fff;
  text-align: center;
  padding: 20px;
  width: 470px;
  height: 470px;
  z-index: 998;
  border-radius: 50%;
  transition-duration: 0.3s;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.modal-auth__main:hover {
  width: 510px;
  height: 510px;
}

.modal-auth__main__content {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.modal-auth__main__title {
  font-size: 24px;
}

.modal-auth__main__iconlist {
  list-style: none;
  display: flex;
  justify-content: center;
  font-size: 24px;
  margin: 30px 0;
}

.modal-auth__main__iconlist__sns {
  height: 50px;
  border-radius: 25px;
}

.modal-auth__main__description {
  display: flex;
  justify-content: center;
  font-size: 12px;
  flex-wrap: wrap;
  width: 380px;
}

.modal-auth__main__description__link {
  color: #E74860;
}
</style>
