var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.addClass }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.emitText,
          expression: "emitText"
        }
      ],
      attrs: { type: "text", maxlength: _vm.maxLength },
      domProps: { value: _vm.emitText },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.emitText = $event.target.value
        }
      }
    }),
    _vm.errorMsg
      ? _c("p", { staticClass: "input-text__error" }, [
          _vm._v(_vm._s(_vm.errorMsg))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }