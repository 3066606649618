var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-check-secret" }, [
    _c(
      "div",
      { staticClass: "modal-check-secret__main" },
      [
        _c("h3", { staticClass: "modal-check-secret__main__title" }, [
          _vm._v(_vm._s(_vm.title))
        ]),
        _c("p", { staticClass: "modal-check-secret__main__text" }, [
          _vm._v(_vm._s(_vm.text))
        ]),
        _c("input-text", {
          attrs: { maxLength: _vm.maxLength },
          on: { emit: _vm.saveToken }
        }),
        _c(
          "div",
          { staticClass: "modal-check-secret__main__btn" },
          [
            _c(
              "button-normal",
              {
                staticClass: "modal-check-secret__main__btn__cancel",
                attrs: { outline: "" },
                on: {
                  emit: function($event) {
                    _vm.$emit("emit", { answer: false }), _vm.switchOverflow()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.cancelBtn))]
            ),
            _c(
              "button-normal",
              {
                on: {
                  emit: function($event) {
                    _vm.$emit("emit", {
                      answer: true,
                      token: _vm.token
                    }),
                      _vm.switchOverflow()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.acceptBtn))]
            )
          ],
          1
        )
      ],
      1
    ),
    !_vm.disabledOverlay
      ? _c("div", {
          staticClass: "modal-check-secret__overlay",
          on: {
            click: function($event) {
              _vm.$emit("emit", false), _vm.switchOverflow()
            }
          }
        })
      : _vm._e(),
    _vm.disabledOverlay
      ? _c("div", { staticClass: "modal-check-secret__overlay" })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }