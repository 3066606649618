var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list" },
    [
      _c("LayoutHeader"),
      _c(
        "div",
        { staticClass: "list__contents" },
        [
          _c("list-menu", {
            staticClass: "list__contents__left",
            attrs: { targetList: _vm.createTitleList, defaultSelectId: 2 },
            on: { emit: _vm.movePageMenu }
          }),
          _c(
            "div",
            { staticClass: "list__contents__right" },
            [
              _c("title-normal", {
                staticClass: "list__contents__right__title",
                attrs: {
                  title: _vm.titleBar.title,
                  button: _vm.titleBar.button,
                  count: _vm.titleBar.count,
                  label: _vm.titleBar.label
                },
                on: {
                  emit: function($event) {
                    return _vm.movePage("AdminCmsCreate")
                  }
                }
              }),
              _vm.isLoading
                ? _c("icon-loading", { attrs: { blue: "" } })
                : _vm._e(),
              !_vm.isLoading
                ? _c("table-normal", {
                    attrs: {
                      type: "detail",
                      headingList: _vm.table.headingList,
                      rows: _vm.table.rows,
                      rowButton: _vm.table.rowButton
                    },
                    on: { emit: _vm.rowButtonEvent }
                  })
                : _vm._e(),
              _vm.isActive
                ? _c("modal-normal", {
                    attrs: {
                      title: _vm.modalText.title,
                      text: _vm.modalText.text,
                      cancelBtn: _vm.modalText.cancelBtn,
                      acceptBtn: _vm.modalText.acceptBtn
                    },
                    on: { emit: _vm.modalEvent }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }