var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "section-author" }, [
    _c("div", { staticClass: "section-author__background" }),
    _c("div", { staticClass: "section-author__info center" }, [
      _c(
        "div",
        { staticClass: "section-author__info__person" },
        [
          _c(
            "router-link",
            {
              class: _vm.pointer
                ? "section-author__info__person__img--pointer"
                : "section-author__info__person__img--disabled",
              attrs: { to: { name: "User", params: { id: _vm.authorInfo.id } } }
            },
            [
              _c("icon-user", {
                attrs: { src: _vm.setAvatar, large: "" },
                on: { error: _vm.setErrorAvatar }
              })
            ],
            1
          ),
          _c("h3", { staticClass: "section-author__info__person__name" }, [
            _vm._v(_vm._s(_vm.authorInfo.name))
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section-author__info__description" },
        [
          _c(
            "heading-normal",
            {
              staticClass: "section-author__info__description__title",
              attrs: { white: "" }
            },
            [_vm._v(" 自己紹介_ ")]
          ),
          _c("p", { staticClass: "section-author__info__description__text" }, [
            _vm._v(" " + _vm._s(_vm.setDescription) + " ")
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }