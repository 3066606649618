var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lesson-attend" },
    [
      _c("lesson-header", {
        attrs: {
          lessonInfo: _vm.currentLesson,
          userInfo: _vm.userInfo,
          chapterNum: _vm.params.num,
          isLoading: _vm.isLoading,
          previewMode: _vm.previewMode,
          adminPreviewMode: _vm.adminPreviewMode
        },
        on: {
          "emit-chapter-list": function($event) {
            return _vm.active("modalChapterList")
          },
          "emit-reset": function($event) {
            return _vm.active("modalReset")
          },
          "emit-compare-source": function($event) {
            return _vm.active("modalCompareSource")
          },
          "emit-move-confirm": function($event) {
            return _vm.$router.push({ path: _vm.$route.query.path })
          },
          "emit-prev-page": function($event) {
            return _vm.movePageChapter("LessonAttend", {
              id: _vm.currentLesson.id,
              num: _vm.params.num - 1
            })
          },
          "emit-next-page": function($event) {
            return _vm.movePageChapter("LessonAttend", {
              id: _vm.currentLesson.id,
              num: _vm.params.num + 1
            })
          },
          "emit-admin-code-load": function($event) {
            return _vm.active("adminCodeLoad")
          }
        }
      }),
      !_vm.isLoading.loadChapter
        ? _c(
            "main",
            { staticClass: "lesson-attend__main" },
            [
              _c(
                "multipane",
                {
                  staticClass: "lesson-attend__main__vertical-panes",
                  attrs: { layout: "vertical" },
                  on: {
                    paneResizeStop: _vm.saveWidth,
                    paneResizeStart: function($event) {
                      return _vm.active("resizer")
                    }
                  }
                },
                [
                  _c("lesson-markdown", {
                    class: _vm.addClass("markdown"),
                    style: _vm.addMarkdownStyle,
                    attrs: {
                      id: "markdown",
                      markdown: _vm.attendInfo.chapterContent.markdown
                    }
                  }),
                  _c("multipane-resizer", {
                    staticClass: "lesson-attend__main__vertical-panes__pane"
                  }),
                  _c("lesson-editor", {
                    class: _vm.addClass("editor"),
                    style: _vm.addEditorStyle,
                    attrs: {
                      id: "editor",
                      sourceList: _vm.attendInfo.chapterContent.source,
                      resetSourceList:
                        _vm.attendInfo.chapterContent.resetSource,
                      isActiveCodeReset: _vm.isActive.codeReset,
                      runButtonClient: _vm.lessonLang.js,
                      runButtonServer: _vm.lessonLang.php,
                      isActiveGeneratedServerEnv:
                        _vm.isActive.generatedServerEnv
                    },
                    on: {
                      "emit-source": _vm.saveEditSource,
                      "emit-reset": function($event) {
                        return _vm.active("codeReset")
                      },
                      "emit-code-run": function($event) {
                        return _vm.active("codeRun")
                      }
                    }
                  }),
                  _c("multipane-resizer", {
                    staticClass: "lesson-attend__main__vertical-panes__pane"
                  }),
                  _c(
                    "div",
                    { class: _vm.addClass("output"), style: { flexGrow: 1 } },
                    [
                      _vm.isActive.resizer &&
                      (_vm.lessonLang.htmlcss || _vm.lessonLang.php)
                        ? _c("div", {
                            staticClass:
                              "lesson-attend__main__vertical-panes__iframe__overlay"
                          })
                        : _vm._e(),
                      _vm.showOutput
                        ? _c(
                            "div",
                            [
                              _vm.lessonLang.js && !_vm.lessonLang.htmlcss
                                ? _c("lesson-output-js", {
                                    attrs: {
                                      editSource:
                                        _vm.attendInfo.editSource[0].source,
                                      sampleSource:
                                        _vm.attendInfo.chapterContent.source[0]
                                          .sample,
                                      isActiveCodeRun: _vm.isActive.codeRun
                                    },
                                    on: {
                                      "emit-code-run": function($event) {
                                        return _vm.active("codeRun")
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm.lessonLang.htmlcss && !_vm.lessonLang.js
                                ? _c("lesson-output-html-css", {
                                    attrs: {
                                      editSource: _vm.attendInfo.editSource,
                                      sampleSource:
                                        _vm.attendInfo.chapterContent.source
                                    }
                                  })
                                : _vm._e(),
                              _vm.lessonLang.htmlcss && _vm.lessonLang.js
                                ? _c("lesson-output-html-css-js", {
                                    attrs: {
                                      editSource: _vm.attendInfo.editSource,
                                      sampleSource:
                                        _vm.attendInfo.chapterContent.source,
                                      isActiveCodeRun: _vm.isActive.codeRun
                                    },
                                    on: {
                                      "emit-code-run": function($event) {
                                        return _vm.active("codeRun")
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm.lessonLang.php
                                ? _c("lesson-output-php", {
                                    attrs: {
                                      editSource:
                                        _vm.attendInfo.editSource[0].source,
                                      sampleSource:
                                        _vm.attendInfo.chapterContent.source[0]
                                          .sample,
                                      isActiveCodeRun: _vm.isActive.codeRun,
                                      isActiveDatabaseReset:
                                        _vm.isActive.databaseReset
                                    },
                                    on: {
                                      "emit-code-run": function($event) {
                                        return _vm.active("codeRun")
                                      },
                                      "emit-database-reset": function($event) {
                                        return _vm.active("databaseReset")
                                      },
                                      "emit-generated-server-env": function(
                                        $event
                                      ) {
                                        return _vm.active("generatedServerEnv")
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.adminPreviewMode && !_vm.isActive.adminCodeLoad
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "lesson-attend__main__vertical-panes__output__codeload"
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-exclamation-triangle"
                              }),
                              _c("p", [
                                _vm._v(
                                  "悪意のあるコードが実行される可能性があるため十分に確認したのちCodeLoadボタンを押しコードを実行してください"
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "lesson-attend__main__modal" },
                [
                  _vm.isActive.modalCompareSource
                    ? _c("modal-compare-source", {
                        attrs: {
                          defaultSourceList:
                            _vm.attendInfo.chapterContent.source,
                          editSourceList: _vm.attendInfo.editSource
                        },
                        on: {
                          "emit-hide": function($event) {
                            return _vm.active("modalCompareSource")
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.isActive.modalReset
                    ? _c("modal-normal", {
                        attrs: {
                          title: _vm.modalInfo.reset.title,
                          text: _vm.modalInfo.reset.text,
                          acceptBtn: _vm.modalInfo.reset.acceptBtn,
                          cancelBtn: _vm.modalInfo.reset.cancelBtn
                        },
                        on: { emit: _vm.codeReset }
                      })
                    : _vm._e(),
                  _vm.isActive.modalPreviewComplete
                    ? _c("modal-normal", {
                        attrs: {
                          title: _vm.modalInfo.previewComplete.title,
                          text: _vm.modalInfo.previewComplete.text,
                          cancelBtn: _vm.modalInfo.previewComplete.cancelBtn,
                          acceptBtn: _vm.modalInfo.previewComplete.acceptBtn,
                          disabledOverlay: ""
                        },
                        on: { emit: _vm.closePreview }
                      })
                    : _vm._e(),
                  _vm.isActive.modalAdminPreviewComplete
                    ? _c("modal-normal", {
                        attrs: {
                          title: _vm.modalInfo.adminPreviewComplete.title,
                          text: _vm.modalInfo.adminPreviewComplete.text,
                          cancelBtn:
                            _vm.modalInfo.adminPreviewComplete.cancelBtn,
                          acceptBtn:
                            _vm.modalInfo.adminPreviewComplete.acceptBtn,
                          disabledOverlay: ""
                        },
                        on: { emit: _vm.closeAdminPreview }
                      })
                    : _vm._e(),
                  _vm.isActive.modalFreeRangeComplete
                    ? _c("modal-normal", {
                        attrs: {
                          title: _vm.modalInfo.freeRangeComplete.title,
                          text: _vm.modalInfo.freeRangeComplete.text,
                          cancelBtn: _vm.modalInfo.freeRangeComplete.cancelBtn,
                          acceptBtn: _vm.modalInfo.freeRangeComplete.acceptBtn,
                          disabledOverlay: ""
                        },
                        on: { emit: _vm.closeFreeRange }
                      })
                    : _vm._e(),
                  _vm.isActive.modalChapterList
                    ? _c(
                        "modal-custom",
                        {
                          on: {
                            "emit-hide": function($event) {
                              return _vm.active("modalChapterList")
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "lesson-attend__main__modal__chapter"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "lesson-attend__main__modal__chapter__top"
                                },
                                [
                                  _c("h3", [
                                    _vm._v(_vm._s(_vm.currentLesson.title))
                                  ]),
                                  _c(
                                    "button-normal",
                                    {
                                      on: {
                                        emit: function($event) {
                                          return _vm.movePage("LessonDetail", {
                                            id: _vm.currentLesson.id
                                          })
                                        }
                                      }
                                    },
                                    [_vm._v(" レッスン詳細 ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "ul",
                                {
                                  staticClass:
                                    "lesson-attend__main__modal__chapter__bottom"
                                },
                                _vm._l(_vm.currentLesson.chapterTitle, function(
                                  title,
                                  index
                                ) {
                                  return _c(
                                    "li",
                                    { key: title.id },
                                    [
                                      !_vm.freeRangeMode
                                        ? _c("list-chapter-mini", {
                                            attrs: {
                                              num: index,
                                              id: _vm.currentLesson.id,
                                              title: title,
                                              complete:
                                                _vm.attendInfo.completeChapter[
                                                  index
                                                ].complete,
                                              current:
                                                _vm.params.num - 1 === index
                                                  ? true
                                                  : false
                                            },
                                            on: {
                                              "emit-move-page": function(
                                                $event
                                              ) {
                                                return _vm.movePage(
                                                  "LessonAttend",
                                                  {
                                                    id: _vm.currentLesson.id,
                                                    num: index + 1
                                                  }
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      _vm.freeRangeMode
                                        ? _c("list-chapter-mini", {
                                            attrs: {
                                              num: index,
                                              id: _vm.currentLesson.id,
                                              title: title,
                                              current:
                                                _vm.params.num - 1 === index
                                                  ? true
                                                  : false,
                                              hideCheck: ""
                                            },
                                            on: {
                                              "emit-move-page": function(
                                                $event
                                              ) {
                                                return _vm.movePage(
                                                  "LessonAttendFreeRange",
                                                  {
                                                    id: _vm.currentLesson.id,
                                                    num: index + 1
                                                  }
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.isActive.modalLessonComplete
                    ? _c("modal-lesson-complete", {
                        on: {
                          "emit-evaluation": _vm.saveEvaluation,
                          "emit-move-twitter": _vm.movePageTwitter,
                          "emit-move-detail": function($event) {
                            _vm.submitEvaluation(_vm.currentLesson.id),
                              _vm.movePage("LessonDetail", {
                                id: _vm.currentLesson.id
                              })
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.isActive.modalNeedComplete
                    ? _c(
                        "modal-custom",
                        {
                          on: {
                            "emit-hide": function($event) {
                              return _vm.active("modalNeedComplete")
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "lesson-attend__main__modal__needcomplete"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "lesson-attend__main__modal__needcomplete__top"
                                },
                                [
                                  _c("h3", [
                                    _vm._v(
                                      _vm._s(_vm.modalInfo.needComplete.title)
                                    )
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(_vm.modalInfo.needComplete.text)
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "button-normal",
                                {
                                  attrs: { large: "" },
                                  on: {
                                    emit: function($event) {
                                      return _vm.movePage("LessonDetail", {
                                        id: _vm.currentLesson.id
                                      })
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.modalInfo.needComplete.acceptBtn
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("img", {
                staticClass: "lesson-attend__main__background__first",
                attrs: {
                  src: require("@/assets/img/background-top-pink.svg"),
                  alt: ""
                }
              }),
              _c("img", {
                staticClass: "lesson-attend__main__background__second",
                attrs: {
                  src: require("@/assets/img/background-top-pink.svg"),
                  alt: ""
                }
              }),
              _c("img", {
                staticClass: "lesson-attend__main__background__third",
                attrs: {
                  src: require("@/assets/img/background-top-pink.svg"),
                  alt: ""
                }
              })
            ],
            1
          )
        : _vm._e(),
      this.isLoading.loadChapter
        ? _c("div", { staticClass: "lesson-attend__load" })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }