<template>
  <div class='list'>
    <LayoutHeader />
    <div class='list__contents'>
      <list-menu
        class='list__contents__left'
        :targetList='createTitleList'
        :defaultSelectId=0
        @emit='movePageMenu'
      />
      <div class='list__contents__right'>
        <title-normal
          class='list__contents__right__title'
          :title='titleBar.title'
          :count='titleBar.count'
          :disabled='!isLoading'
          :label='titleBar.label'
          @emit-heading='changeLessonList'
        />
        <icon-loading
        v-if='!isLoading'
        blue/>
        <table-normal
          v-if='isLoading && judge === 1'
          type='review'
          :headingList='table.headingList.nojudge'
          :rows='table.rows'
          :rowButton='table.rowButton.release'
          @emit='active'>
        </table-normal>
        <table-normal
          v-if='isLoading && judge !==1'
          type='review'
          :headingList='table.headingList.judge'
          :rows='table.rows'
          :rowButton='table.rowButton.judge'
          @emit='active'>
        </table-normal>
        <modal-normal
          v-if = 'isActive.delete '
          :title = 'modalText.delete.title'
          :text = 'modalText.delete.text'
          :cancelBtn = 'modalText.delete.cancelBtn'
          :acceptBtn = 'modalText.delete.acceptBtn'
          @emit='modalEvent'>
        </modal-normal>
        <modal-input
          v-if = 'isActive.failureLesson'
          :title = 'modalText.failureLesson.title'
          :text = 'modalText.failureLesson.text'
          :cancelBtn = 'modalText.failureLesson.cancelBtn'
          :acceptBtn = 'modalText.failureLesson.acceptBtn'
          :acceptBtnDisabled = 'failureReason === ""'
          :placeholder = 'modalText.failureLesson.placeholder'
          :rows = 6
          @emit='modalEvent'
          @emitTextarea='saveFailureReason($event)'>
        </modal-input>
        <modal-normal
          v-if = 'isActive.passLesson'
          :title = 'modalText.passLesson.title'
          :text = 'modalText.passLesson.text'
          :cancelBtn = 'modalText.passLesson.cancelBtn'
          :acceptBtn = 'modalText.passLesson.acceptBtn'
          @emit='modalEvent'>
        </modal-normal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import helper from '@/mixins/admin/methods/helper';
import pageList from '@/mixins/admin/data/pageList';
import LayoutHeader from '@/components/layouts/admin/LayoutHeader';
import {
  TitleNormal, TableNormal, ModalNormal, ModalInput,
} from '@/components/molecules/other';
import { ListMenu } from '@/components/atoms/list';
import { IconLoading } from '@/components/atoms/icon/';

export default {
  components: {
    LayoutHeader,
    TitleNormal,
    ListMenu,
    TableNormal,
    ModalNormal,
    ModalInput,
    IconLoading,
  },
  data() {
    return {
      clickData: {
        btnFlg: Number,
        rowIndex: Number,
        rowId: Number,
      },
      failureReason: '',
      judge: 1,
      isLoading: true,
      isActive: {
        delete: false,
        failureLesson: false,
        failureLessonBtn: false,
        passLesson: false,
      },
      modalText: {
        delete: {
          title: 'レッスン削除',
          text: 'レッスンを削除すると二度と復元できません。本当に削除してよろしいですか？',
          acceptBtn: '削除する',
          cancelBtn: 'キャンセル',
        },
        failureLesson: {
          placeholder: '不合格理由を入力してください',
          title: 'レッスンを不合格にする',
          text: '対象のレッスンは公開されず、情報が削除されますがよろしいですか？',
          acceptBtn: '不合格にする',
          cancelBtn: 'キャンセル',
        },
        passLesson: {
          title: 'レッスンを合格にする',
          text: '対象のレッスンが公開されますがよろしいですか？',
          acceptBtn: '合格にする',
          cancelBtn: 'キャンセル',
        },
      },
      titleBar: {
        title: 'レッスン一覧',
        button: '新規作成',
        count: 0,
        label: ['審査済み', '審査前(新規投稿)', '審査前(更新)'],
      },
      gasPostData: [],
      table: {
        headingList: {
          judge: [
            'サムネイル',
            'ID',
            'タイトル',
            '難易度',
            '評価',
            '公開設定',
            '価格',
            '購入数',
            '投稿日',
            '更新日',
            '不合格',
            '合格',
            'プレビュー',
          ],
          nojudge: [
            'サムネイル',
            'ID',
            'タイトル',
            '難易度',
            '評価',
            '公開設定',
            '価格',
            '購入数',
            '投稿日',
            '更新日',
            '削除',
            'プレビュー',
          ],
        },
        rows: [],
        rowButton: {
          judge: ['不合格にする', '合格にする', 'プレビュー'],
          release: ['削除する', 'プレビュー'],
        },
      },
      contentList: [],
    };
  },
  created() {
    this.loadLesson();
  },
  mixins: [helper, pageList],
  computed: {
    ...mapState('admin', ['adminInfo']),
    createTitleList() {
      const titleList = this.pageList.map((page) => page.title);
      return titleList;
    },
    setEnv() {
      if (process.env.VUE_APP_HOST.includes('dev')) {
        return 'dev';
      } if (process.env.VUE_APP_HOST.includes('stg')) {
        return 'stg';
      }
      return 'prd';
    },
  },
  methods: {
    ...mapActions('lessonAttend', ['loadAdminPreviewLesson']),
    movePageMenu(index) {
      const { name } = this.pageList[index];
      this.$router.push({ name }, () => {});
    },
    active(event) {
      const pickRowsId = [];
      this.table.rows.forEach((rows) => {
        pickRowsId.push(rows.id);
      });
      this.clickData.rowIndex = pickRowsId.indexOf(event.id);
      this.clickData.btnFlg = event.btnFlg;
      this.clickData.rowId = event.id;
      if (this.judge === 1 && event.btnFlg === 0) {
        this.isActive.delete = !this.isActive.delete;
      } else if (this.judge !== 1
        && (event.btnFlg === 1)) {
        this.isActive.passLesson = !this.isActive.passLesson;
      } else if (event.btnFlg === 2 || (this.judge === 1 && event.btnFlg === 1)) {
        // レッスンプレビュー
        const chapterInfo = this.contentList.filter((content) => content.id === event.id)[0];
        const lessonInfo = this.table.rows.filter((row) => row.id === event.id)[0];
        this.loadAdminPreviewLesson({
          chapterInfo,
          lessonInfo,
        });
        window.open(`lessons/preview/${event.id}/1`, '_blank');
      } else {
        this.isActive.failureLesson = !this.isActive.failureLesson;
      }
    },
    saveFailureReason(text) {
      this.failureReason = text;
    },
    async postFailureReason() {
      const failureLesson = this.gasPostData
        .filter((lesson) => this.clickData.rowId === lesson.id)[0];
      const date = new Date();
      try {
        if (this.failureReason === '') throw Error();
        await axios.get(process.env.VUE_APP_GAS_URL, {
          params: {
            failureReason: this.failureReason,
            userId: failureLesson.userId,
            userName: failureLesson.author,
            email: failureLesson.email,
            updateAt: failureLesson.update,
            adminUserId: this.adminInfo.profile.id,
            lessonTitle: failureLesson.title,
            authToken: process.env.VUE_APP_GAS_ACCESS_TOKEN,
            date: `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
            judge: this.judge,
            envName: this.setEnv,
          },
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    async modalEvent(data) {
      this.$http.defaults.headers.common.Authorization = `Bearer ${this.adminInfo.token}`;
      this.isLoading = !this.isLoading;
      if (data === false) {
        this.isActive.delete = data;
        this.isActive.failureLesson = data;
        this.isActive.passLesson = data;
        this.isLoading = !this.isLoading;
        this.failureReason = '';
      } else if (this.judge !== 1) {
        await this.changeLessonJudge();
      } else if (this.clickData.btnFlg === 0) {
        await this.deleteLesson();
      }
    },
    changeLessonList(event) {
      if (event === 0) {
        this.judge = 1;
        this.loadLesson();
      } else if (event === 1) {
        this.judge = 0;
        this.loadLesson();
      } else {
        this.judge = 2;
        this.loadLesson();
      }
    },
    async changeLessonJudge() {
      try {
        this.isActive.failureLesson = false;
        this.isActive.passLesson = false;

        if (this.clickData.btnFlg === 0) {
          if (await this.postFailureReason() === false) throw Error();
          this.failureReason = '';
        }
        const params = {
          accept: this.clickData.btnFlg,
          type: this.judge === 0 ? 'create' : 'update',
        };
        await this.$http.put(`/api/admin/lessons/judge/${this.clickData.rowId}`, params);
        await this.showNoticeBar('success', 'レッスンの審査ステータスの変更を完了しました');
        await this.loadLesson();
      } catch (e) {
        if (e.response !== undefined && e.response.status === 401) {
          this.unAdminAuthenticate();
        } else {
          this.showNoticeBar('error', 'レッスンの審査ステータスの変更を失敗しました');
        }
        await this.loadLesson();
      }
    },
    async changeLessonStatus() {
      try {
        this.isActive.failureLesson = false;
        this.isActive.passLesson = false;
        const params = {
          // 0を1に、1,2を0に反転
          status: (Math.round(this.table.rows[this.clickData.rowIndex].release * 0.5) + 1) % 2,
        };
        await this.$http.put(`/api/admin/lessons/${this.clickData.rowId}`, params);
        await this.showNoticeBar('success', 'レッスンの審査ステータスの変更を完了しました');
        await this.loadLesson();
      } catch (e) {
        if (e.response !== undefined && e.response.status === 401) {
          this.unAdminAuthenticate();
        } else {
          this.showNoticeBar('error', 'レッスンの審査ステータスの変更を失敗しました');
        }
      }
    },
    async deleteLesson() {
      try {
        this.isActive.delete = false;
        await this.$http.delete(`/api/admin/lessons/${this.clickData.rowId}`);
        await this.showNoticeBar('success', 'レッスンの削除を完了しました');
        await this.loadLesson();
      } catch (e) {
        if (e.response !== undefined && e.response.status === 401) {
          this.unAdminAuthenticate();
        } else {
          this.showNoticeBar('error', 'レッスンの削除に失敗しました');
        }
      }
    },
    async loadLesson() {
      try {
        this.isLoading = false;
        this.table.rows.splice(-this.table.rows.length);
        this.$http.defaults.headers.common.Authorization = `Bearer ${this.adminInfo.token}`;
        const res = await this.$http.get('/api/admin/lessons', {
          params: {
            judge: this.judge,
          },
        });
        await res.data.forEach((data) => {
          this.table.rows.push({
            id: data.id,
            title: data.title,
            level: data.level,
            evaluation: data.evaluation,
            image: data.thumbnail,
            release: data.status,
            price: data.price,
            purchase: data.sale,
            create: data.created_at,
            update: data.updated_at,
          });

          this.gasPostData.push({
            id: data.id,
            userId: data.author_user_id,
            author: data.author,
            title: data.title,
            email: data.author_email,
            update: data.updated_at,
          });

          this.contentList.push({
            id: data.id,
            content: data.content,
            chapterList: data.chapter,
          });
        });
        this.titleBar.count = this.table.rows.length;
        this.isLoading = !this.isLoading;
      } catch (e) {
        this.isLoading = !this.isLoading;
        if (e.response !== undefined && e.response.status === 401) {
          this.unAdminAuthenticate();
        } else {
          this.showNoticeBar('error', 'レッスン一覧の取得に失敗しました');
        }
      }
    },
  },
};
</script>

<style scoped>
.list {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f9fa;
}

.list__contents {
  display: flex;
  margin: 20px;
}

.list__contents__left {
  width: 15%;
  padding: 20px;
  background-color: #fff;
}

.list__contents__right {
  width: 85%;
  margin-left: 20px;
}

.list__contents__right__title {
  margin-bottom: 10px;
}
</style>
