<template>
  <router-link
    class='lesson-card'
    :to='{ name: "LessonDetail", params: { id: lessonInfo.id }}'
  >
    <div class='lesson-card__container'>
      <img
        class='lesson-card__container__thumbnail'
        :src='lessonInfo.image' alt='レッスンのサムネイル' @error='setErrorThumbnail'>
      <div class='lesson-card__container__info'>
        <div class='lesson-card__container__info__top'>
          <h3 class='lesson-card__container__info__top__title'>{{ lessonInfo.title }}</h3>
          <div class='lesson-card__container__info__top__sub'>
            <icon-level
              class='lesson-card__container__info__top__sub__level'
              :level='lessonInfo.level' />
            <review-star-multi :score='lessonInfo.evaluation' />
          </div>
          <p class='lesson-card__container__info__top__description'>{{ lessonInfo.description }}</p>
        </div>
        <div class='lesson-card__container__info__bottom'>
          <icon-post-date :date='lessonInfo.updatedDate'>UPDATE</icon-post-date>
          <p class='lesson-card__container__info__bottom__price'>{{ setPrice }}</p>
        </div>
      </div>
      <icon-user-lesson
        class='lesson-card__container__author'
        :avatar='lessonInfo.avatar'
        :username='lessonInfo.author'
      />
    </div>
    <img class='lesson-card__back' src='@/assets/img/lessoncard-back.svg' alt=''>
  </router-link>
</template>

<script>
import { ReviewStarMulti } from '@/components/atoms/reviewStar';
import { IconLevel, IconPostDate, IconUserLesson } from '@/components/atoms/icon';

export default {
  components: {
    ReviewStarMulti,
    IconLevel,
    IconPostDate,
    IconUserLesson,
  },
  props: {
    lessonInfo: Object,
    levelLabel: Array,
  },
  computed: {
    setPrice() {
      if (this.lessonInfo.price === 0) {
        return '無料';
      }
      return `¥${this.lessonInfo.price}`;
    },
  },
  methods: {
    setErrorThumbnail(e) {
      e.target.src = `${process.env.VUE_APP_S3_URL}/image/thumbnail/manabiba.png`;
    },
  },
};
</script>

<style scoped>
.lesson-card {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 260px;
  position: relative;
}

.lesson-card__container {
  transition: all 0.5s ease;
}

.lesson-card__container__thumbnail {
  width: 100%;
  height: 146px;
}

.lesson-card__container__info {
  background-color: #fff;
  padding: 15px;
  text-align: left;
  width: 100%;
  height: 220px;
  margin-top: -5px;
  z-index: 1;
}

.lesson-card__container__info__top {
  height: 160px;
}

.lesson-card__container__info__top__title {
  font-size: 16px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  font-weight: 500;
  width: 200px;
}

.lesson-card__container__info__top__sub {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.lesson-card__container__info__top__sub__level {
  margin: 2px 5px 0 0;
}

.lesson-card__container__info__top__description {
  font-size: 12px;
  font-weight: 400;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-all;
}

.lesson-card__container__info__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lesson-card__container__info__bottom__price {
  font-weight: 500;
  font-size: 20px;
}

.lesson-card__container__author {
  position: absolute;
  top: 0;
  right: -55px;
}

.lesson-card__back {
  position: absolute;
  top: 15px;
  width: 100%;
  z-index: -1;
}

.lesson-card:hover .lesson-card__container {
  transform: translate(-3%, -2.3%);
  transition: all 0.5s ease;
  top: 5px;
  right: -50px;
}
</style>
