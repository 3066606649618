var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: _vm.addClass,
      attrs: { disabled: _vm.disabled },
      on: {
        click: function($event) {
          return _vm.$emit("emit")
        }
      }
    },
    [_c("i", { class: "fas fa-chevron-" + _vm.direction })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }