var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "component-privacypolicy" }, [
      _c("p", [
        _vm._v(
          "このプライバシーポリシー（以下「本ポリシー」といいます。）は、株式会社Gizumo（以下「当社」といいます。）が運営するサービス「manabiba」（以下「本サービス」といいます。）における利用者に関する情報の取り扱いについて説明させていただくものです。 なお、本ポリシーにおける用語は、特に定めがある場合を除き、「manabiba」利用規約に準じるものとします。"
        )
      ]),
      _c("h2", [_vm._v("第１条 （適用範囲）")]),
      _c("p", [
        _vm._v(
          "１ 当社は、利用者情報等の保護実現のため、個人情報保護法及びその他関連する法令等を遵守し、個人情報を含む利用者情報等の適切な取扱い及び保護に努めます。"
        ),
        _c("br"),
        _vm._v(
          " ２ 本ポリシーは、本サービスの利用に関し適用されます。また、当社が、本サービス上に掲載するプライバシーポリシーその他の個人情報保護方針又は利用規約等においてユーザー情報の取扱いについて規定する場合、当該規定も適用されるものとし、当該規定が本ポリシーと抵触する場合には、本ポリシーが優先されるものとします。"
        ),
        _c("br"),
        _vm._v(
          " ３ 当社が本サービスに関して提携する外部のサービスその他当社以外の者が提供するサービス（以下「提携サービス等」といいます。）については、本ポリシーの規定は適用されません。提携サービス等における利用者情報の取扱いについては、当該提携サービス等を提供する事業者が別途定めるプライバシーポリシー等をご参照ください。"
        )
      ]),
      _c("h2", [_vm._v("第２条 （当社が取得する情報）")]),
      _c("p", [
        _vm._v(
          "当社は、次条に定める利用目的を達成するため、本サービスの利用者及び登録希望者（以下「ユーザー」といい、ユーザーが法人の場合にはその担当者を含みます。）に関する次に定める情報（以下「ユーザー情報」といいます。）を取得します。"
        ),
        _c("br"),
        _vm._v(" (1) 本サービスの利用にあたりユーザーにご提供いただく情報"),
        _c("br"),
        _vm._v(
          " 氏名又は名称、生年月日、住所、年齢、性別、電話番号、メールアドレス、クレジット番号、ID、パスワード、本サービスのプロフィール欄に記入いただく情報、その他本サービスにおいて当社が指定する情報"
        ),
        _c("br"),
        _vm._v(" (2) 本サービスの利用にあたり当社が収集する情報"),
        _c("br"),
        _vm._v(
          " 端末ID（デバイスID）、IPアドレス、端末位置情報、サーバーアクセスログ情報、Cookie、その他本サービスにおいてユーザーが送信する情報（検索履歴、テキストデータ、画像、動画、本サービスへの投稿等）、ユーザーの同意に基づき提携サービス等から取得する情報（提携サービス等におけるユーザーのID等）"
        )
      ]),
      _c("h2", [_vm._v("第３条 （利用目的）")]),
      _c("p", [
        _vm._v(
          "１ 当社は、ユーザー情報を、次の各号に定める目的の範囲内で、適正に取り扱います。"
        ),
        _c("br"),
        _vm._v(" (1) 本サービスの利用の登録審査のため"),
        _c("br"),
        _vm._v(" (2) 本サービスの提供及び本人確認のため"),
        _c("br"),
        _vm._v(" (3) 本サービスの利用料の請求及び決済のため"),
        _c("br"),
        _vm._v(" (4) 本サービスに関するご案内、お問い合わせ等への対応のため"),
        _c("br"),
        _vm._v(
          " (5) 本サービスに関する利用規約又は本ポリシーの変更、本サービスの停止・中止・契約解除その他本サービスに関する重要なお知らせ等の通知のため"
        ),
        _c("br"),
        _vm._v(" (6) 当社又は第三者の商品・サービスに関する広告の配信のため"),
        _c("br"),
        _vm._v(" (7) 当社又は第三者の求人情報の掲載又は提供のため"),
        _c("br"),
        _vm._v(" (8) 本サービスの維持、改善及び新たなサービス等を検討するため"),
        _c("br"),
        _vm._v(" (9) 本サービスの利用状況等を調査及び分析するため"),
        _c("br"),
        _vm._v(
          " ２ 当社は、前項の利用目的を、変更前の利用目的と関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合には、ユーザーに対し、通知又は本サービス上若しくは当社の運営するウェブサイトでの掲示その他分かりやすい方法により公表します。"
        )
      ]),
      _c("h2", [_vm._v("第４条 （匿名加工情報）")]),
      _c("p", [
        _vm._v(
          "１ 当社は、ユーザー情報を、ユーザー個人を特定できないようにした匿名加工情報（個人情報保護法第２条第９項に定義された「匿名加工情報」といい、以下同様とします。）又は統計的な情報に加工し、これを利用することがあります。"
        ),
        _c("br"),
        _vm._v(
          " ２ 当社は、本サービスの提供期間中、ユーザー情報に基づく匿名加工情報の作成にあたっては、●●を削除し、●●を一般化することにより匿名加工情報を作成します。"
        ),
        _c("br"),
        _vm._v(
          " ３ 当社は、以下のとおり匿名加工情報を第三者に提供する場合があります。"
        ),
        _c("br"),
        _vm._v(
          " (1) 第三者に提供する匿名加工情報に含まれる個人に関する情報の項目"
        ),
        _c("br"),
        _vm._v(" ●●、●●"),
        _c("br"),
        _vm._v(" (2) 提供の方法"),
        _c("br"),
        _vm._v(
          " 匿名加工情報を含むファイルを電子メールに添付して当該第三者に送信する、又は、当該ファイルをサーバーにアップロードして当該第三者にアクセスさせる"
        )
      ]),
      _c("h2", [_vm._v("第５条 （第三者提供）")]),
      _c("p", [
        _vm._v(
          "当社は、本人の同意を得ずに、個人データ（個人情報保護法第２条第６項により定義された「個人データ」をいい、以下同様とします。）を第三者に提供しません。ただし、次に掲げる場合に、第三者（日本国外にある者を含みます。）に提供する場合はこの限りではありません。 (1) 本ポリシーに定める利用目的の達成に必要な範囲内において個人データの取扱いの全部又は一部を委託することに伴って当該個人データが提供される場合 (2) 合併その他の事由による事業の承継に伴って個人データが提供される場合 (3) 特定の者との間で共同して利用される個人データが当該特定の者に提供される場合であって、その旨並びに共同して利用される個人データの項目、共同して利用する者の範囲、利用する者の利用目的及び当該個人データの管理について責任を有する者の氏名又は名称について、あらかじめ、本人に通知し、又は本人が容易に知り得る状態に置いているとき (4) 前各号に定めるもののほか、個人情報保護法その他の法令に基づく場合"
        )
      ]),
      _c("h2", [_vm._v("第６条 （個人情報の取扱いの委託）")]),
      _c("p", [
        _vm._v(
          "当社は、利用目的の達成に必要な範囲内において、利用者から取得した個人情報の全部又は一部の取扱いを第三者に委託することがあります。この場合、当社は、当該委託先との間で本ポリシーに準じる内容の秘密保持契約等をあらかじめ締結するとともに、当該委託先において情報の適切な安全管理が図られるよう、必要かつ適切な監督を行います。"
        )
      ]),
      _c("h2", [_vm._v("第７条 （情報収集モジュールの利用について）")]),
      _c("p", [
        _vm._v(
          "本サービスには、本サービスの利用状況及び広告効果等の情報を解析するため、当社が選定する以下の情報収集モジュールが組み込まれています。これに伴い、当社は、以下の情報収集モジュールの提供者（日本国外にある者を含みます。）に対しユーザー情報の提供を行う場合があります。これらの情報収集モジュールは、個人を特定する情報を含むことなくユーザー情報を収集し、収集された情報は、各情報収集モジュール提供者の定めるプライバシーポリシーその他の規定に基づき管理されます。 ①名称 ：Google Analytics 提供者：Google Inc. 提供者による情報開示： "
        ),
        _c(
          "a",
          {
            attrs: {
              href:
                "https://policies.google.com/technologies/partner-sites?hl=ja"
            }
          },
          [
            _vm._v(
              "https://policies.google.com/technologies/partner-sites?hl=ja"
            )
          ]
        )
      ]),
      _c("h2", [_vm._v("第８条 （個人情報の開示・訂正等の権利）")]),
      _c("p", [
        _vm._v(
          "１ 当社は、ユーザーから、個人情報保護法の定めに基づき当社が保有するデータベース上に存在する個人データの利用目的の通知、開示、訂正、追加、削除、停止又は消去（以下「開示等」といいます。）を求められたときは、ユーザーご本人からのご請求であることを確認の上で、遅滞なく対応いたします。ただし、個人情報保護法を含む法令により当社がこれらの義務を負わない場合については、この限りではありません。 ２ 開示等の請求にあたっては、第１０条の問い合わせ窓口宛にご連絡ください。 ３ 個人データの利用目的の通知又は開示につきましては、1件あたり１，０００円の手数料を頂戴いたします。"
        )
      ]),
      _c("h2", [_vm._v("第９条 （本ポリシーの変更）")]),
      _c("p", [
        _vm._v(
          "１ 当社は以下の場合に、当社が必要と認めた場合は、本ポリシーを変更することがあります。"
        ),
        _c("br"),
        _vm._v(
          " (1) 本ポリシー規約の変更が、ユーザーの一般の利益に適合するとき"
        ),
        _c("br"),
        _vm._v(
          " (2) 本ポリシーの変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき"
        ),
        _c("br"),
        _vm._v(
          " ２ 前項の場合、当社は、変更後の本ポリシーの効力発生日の２週間前までに、本ポリシーを変更する旨及び変更後の本ポリシーの内容とその効力発生日を当社のウェブサイト又は本サービス上に掲示し、又はユーザーに電子メール等の電磁的記録方法で通知します。"
        ),
        _c("br"),
        _vm._v(
          " ３ 前二項に定めるほか、当社は、当社が定めた方法でユーザーの同意を得ることにより、本ポリシーを変更することがあります。この場合、変更後の本ポリシーは、当社所定の方法で変更に同意したユーザーに対してのみ適用されるものとします。"
        )
      ]),
      _c("h2", [_vm._v("第１０条 （お問い合わせ）")]),
      _c("p", [
        _vm._v(
          "当社の取り扱うユーザー情報に関するご意見、ご質問、開示等の要求、苦情のお申出その他ユーザー情報の取扱いに関するお問合せは、以下のお問合せ窓口へご連絡ください。 株式会社Gizumo 個人情報取扱いに関するお問合せ窓口 住所：東京都渋谷区渋谷3-28-15 渋谷S.野口ビル7F 個人情報取扱責任者：原田 （なお、受付時間は、平日10時から17時までとさせていただきます。）"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }