var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.scY > 0,
            expression: "scY > 0"
          }
        ],
        staticClass: "layout-logo-pixel",
        on: { click: _vm.scrollTop }
      },
      [
        _c("a", { staticClass: "layout-logo-pixel__bubble" }, [_vm._v("TOP")]),
        _c("img", {
          staticClass: "hover",
          attrs: { src: require("@/assets/img/peach.svg"), alt: "桃 peach" }
        }),
        _c("img", {
          staticClass: "shadow",
          attrs: { src: require("@/assets/img/peach-shadow.svg"), alt: "" }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }