var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      staticClass: "lesson-card-purchase",
      attrs: { to: { name: "LessonDetail", params: { id: _vm.lessonInfo.id } } }
    },
    [
      _c("div", { staticClass: "lesson-card-purchase__left" }, [
        _c("img", {
          staticClass: "lesson-card-purchase__left__thumbnail",
          attrs: { src: _vm.lessonInfo.image, alt: "レッスンのサムネイル" },
          on: { error: _vm.setErrorThumbnail }
        }),
        _c("div", { staticClass: "lesson-card-purchase__left__author" }, [
          _c("img", {
            staticClass: "lesson-card-purchase__left__author__avatar",
            attrs: { src: _vm.setAvatar, alt: "avatar ユーザーアイコン" },
            on: { error: _vm.setErrorAvatar }
          }),
          _c("p", { staticClass: "lesson-card-purchase__left__author__name" }, [
            _vm._v(_vm._s(_vm.setAuthor))
          ])
        ])
      ]),
      _c("div", { staticClass: "lesson-card-purchase__info" }, [
        _c(
          "div",
          { staticClass: "lesson-card-purchase__info__title" },
          [
            _c("icon-level", {
              staticClass: "lesson-card-purchase__info__title__level",
              attrs: { level: _vm.lessonInfo.level }
            }),
            _c(
              "h3",
              { staticClass: "lesson-card-purchase__info__title__text" },
              [_vm._v(_vm._s(_vm.lessonInfo.title))]
            )
          ],
          1
        ),
        _c("p", { staticClass: "lesson-card-purchase__info__description" }, [
          _vm._v(_vm._s(_vm.lessonInfo.description))
        ]),
        _c("div", { staticClass: "lesson-card-purchase__info__progress" }, [
          _c(
            "div",
            [
              _c("bar-progress", {
                staticClass: "lesson-card-purchase__info__progress__bar",
                attrs: { progress: _vm.lessonInfo.inProgress.progressRate }
              })
            ],
            1
          ),
          _c("p", [
            _vm._v(_vm._s(_vm.lessonInfo.inProgress.progressRate) + "%")
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }