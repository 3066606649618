<template>
  <div class='maintenance'>
    <modal-custom class='maintenance__modal' hideOverlay hideButton>
      <div class='maintenance__modal__contents'>
        <layout-logo yellow iconOnly />
        <h3 class='maintenance__modal__contents__title'>ただいまメンテナンスを行っています</h3>
        <p class='maintenance__modal__contents__text'>
          現在メンテナンス中のため、manabibaをご利用頂く事がができません。<br>ご不便をおかけ致しますがご理解いただけるようお願い申し上げます。
        </p>
      </div>
    </modal-custom>
    <layout-footer hidePageUp simpleFooter/>
  </div>
</template>

<script>
import { ModalCustom } from '@/components/molecules/other';
import { LayoutFooter, LayoutLogo } from '@/components/layouts/general/';

export default {
  components: {
    ModalCustom,
    LayoutFooter,
    LayoutLogo,
  },
};
</script>

<style scoped>
.maintenance {
  background-color: #4CAF50;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.maintenance__modal__contents {
  display: flex;
    flex-direction: column;
    align-items: center;
}

.maintenance__modal__contents__title {
  margin-top: 20px;
}

.maintenance__modal__contents__text {
  margin-top: 10px;
  font-size: 14px;
}
</style>
