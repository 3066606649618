var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "button-list" }, [
    _c("p", [_vm._v(_vm._s(_vm.title))]),
    _c(
      "ul",
      { staticClass: "button-list__label" },
      _vm._l(_vm.labelList, function(label, index) {
        return _c(
          "li",
          { key: index, staticClass: "button-list__label__list" },
          [
            _c(
              "button",
              {
                staticClass: "button-list__label__list__btn",
                class: { focus: _vm.selected === index },
                attrs: { disabled: _vm.disabled },
                on: {
                  click: function($event) {
                    return _vm.changeSelect(index)
                  }
                }
              },
              [_vm._v(" " + _vm._s(label) + " ")]
            )
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }