<template>
  <div class='modal-custom'>
    <div class='modal-custom__main'>
      <slot />
      <button-hide
        v-if='!hideButton'
        @emit="$emit('emit-hide'), switchOverflow()"
      />
    </div>
    <div
      class='modal-custom__overlay'
      v-if='!hideOverlay'
      @click="$emit('emit-hide'), switchOverflow()"
    />
  </div>
</template>

<script>
import { ButtonHide } from '@/components/atoms/button';

export default {
  components: {
    ButtonHide,
  },
  props: {
    hideOverlay: Boolean,
    hideButton: Boolean,
  },
  mounted() {
    this.switchOverflow();
  },
  methods: {
    // modalが立ち上がってる時はスクロールできないよう制御
    switchOverflow() {
      const body = document.querySelector('body');
      body.style.overflow = body.style.overflow === '' ? 'hidden' : '';
    },
  },
  destroyed() {
    const body = document.querySelector('body');
    body.style.overflow = '';
  },
};
</script>

<style scoped>
.modal-custom__main {
  background-color: #fff;
  text-align: center;
  position: fixed;
  padding: 30px;
  width: 550px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 997;
}

.modal-custom__overlay {
  position: fixed;
  z-index: 996;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
