var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "lesson-output" }, [
    _c(
      "div",
      { staticClass: "lesson-output__top" },
      [
        _c("lesson-output-tab", {
          attrs: { tabInfo: _vm.tabInfo },
          on: { emit: _vm.changeFocus }
        })
      ],
      1
    ),
    _c("div", { staticClass: "lesson-output__bottom" }, [
      _c("iframe", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: (_vm.selectId === 0) !== _vm.isLoading.createCodeRunEnv,
            expression: "selectId === 0 !== isLoading.createCodeRunEnv"
          }
        ],
        staticClass: "lesson-output__bottom__iframe",
        attrs: { id: "assignment", sandbox: "", src: this.ideUrl.assignment }
      }),
      _c("iframe", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.selectId === 1 && !_vm.isLoading.createCodeRunEnv,
            expression: "selectId === 1 && !isLoading.createCodeRunEnv"
          }
        ],
        staticClass: "lesson-output__bottom__iframe",
        attrs: { sandbox: "", src: this.ideUrl.sample }
      }),
      _vm.isLoading.createCodeRunEnv
        ? _c(
            "div",
            { staticClass: "lesson-output__bottom__loading" },
            [
              _c(
                "icon-loading",
                {
                  staticClass: "lesson-output__bottom__loading__icon",
                  attrs: { white: "" }
                },
                [_vm._v(" コード実行環境を構築しています... ")]
              ),
              _c("div", {
                staticClass: "lesson-output__bottom__loading__overlay"
              })
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }