var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "section-top-info-confirm" }, [
    _c("div", { staticClass: "section-top-info-confirm__main center" }, [
      !_vm.isLoading
        ? _c("div", { staticClass: "section-top-info-confirm__main__left" }, [
            _c("img", {
              staticClass: "section-top-info-confirm__main__left__thumbnail",
              attrs: { src: _vm.thumbnail, alt: "レッスンのサムネイル" }
            })
          ])
        : _vm._e(),
      !_vm.isLoading
        ? _c("div", { staticClass: "section-top-info-confirm__main__right" }, [
            _c(
              "h3",
              { staticClass: "section-top-info-confirm__main__right__title" },
              [_vm._v(_vm._s(_vm.lessonInfo.title))]
            ),
            _c(
              "div",
              { staticClass: "section-top-info-confirm__main__right__bottom" },
              [
                _c(
                  "div",
                  { staticClass: "section-top-info-confirm__main__right__tag" },
                  [
                    _vm.lessonInfo.tag.length
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "section-top-info-confirm__main__right__tag__text"
                          },
                          [_vm._v(" カテゴリー ")]
                        )
                      : _vm._e(),
                    _c(
                      "ul",
                      {
                        staticClass:
                          "section-top-info-confirm__main__right__tag__list"
                      },
                      _vm._l(_vm.lessonInfo.tag, function(lang) {
                        return _c(
                          "li",
                          { key: lang.id },
                          [_c("icon-lang", [_vm._v(_vm._s(lang))])],
                          1
                        )
                      }),
                      0
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "section-top-info-confirm__main__right__infolist"
                  },
                  [
                    _c("icon-level", {
                      staticClass:
                        "section-top-info-confirm__main__right__infolist__level",
                      attrs: { level: _vm.lessonInfo.level }
                    })
                  ],
                  1
                ),
                _c(
                  "p",
                  {
                    staticClass: "section-top-info-confirm__main__right__price"
                  },
                  [_vm._v(" " + _vm._s(_vm.setPrice) + " ")]
                )
              ]
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }