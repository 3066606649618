<template>
  <div :class='addClass'>
    <vue-loading :type='type' :size='size' />
    <p>
      <slot>読み込み中...</slot>
    </p>
  </div>
</template>
<script>
import { VueLoading } from 'vue-loading-template';

export default {
  props: {
    white: Boolean,
    blue: Boolean,
  },
  components: {
    VueLoading,
  },
  data() {
    return {
      type: 'bars',
      size: { width: '45px', height: '45px' },
    };
  },
  computed: {
    addClass() {
      return {
        'icon-loading': true,
        'icon-loading--white': this.white,
        'icon-loading--blue': this.blue,
      };
    },
  },
};
</script>

<style scoped>
.icon-loading {
  padding: 10px 0;
}

.icon-loading p {
  text-align: center;
  font-size: 14px;
  margin-top: 5px;
}

.icon-loading--white {
  color: #fff;
}

.icon-loading--blue {
  color: #46aad5;
}
</style>
