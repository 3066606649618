<template>
  <div class='layout-header'>
    <bar-announce v-if='showNoticeAnnounce'>
      対応ブラウザはGoogleChromeです。お使いのブラウザでは正常に動作しない可能性があります。
    </bar-announce>
    <notifications group="top-right" />
    <div class='layout-header__main'>
      <div class='layout-header__main__left'>
        <router-link :to='{ name: "Top" }'>
          <layout-logo />
        </router-link>
        <p class='layout-header__main__left__creator' v-if='isActive.creatorMode'>Creator Mode</p>
        <button
          class='layout-header__main__left__category'
          v-if='!isActive.creatorMode'
          @click='active("dropdownCategory")'
          @mouseover='isActive.hoverCategory = true'
          @mouseleave='isActive.hoverCategory = false'
        >
          <img src='@/assets/img/category.svg' alt='タグ tag'>
          <p>CATEGORY</p>
          <transition class='layout-header__main__left__category__hover'>
            <div
              class='layout-header__main__left__category__hover__bar'
              v-if='isActive.hoverCategory'
            />
          </transition>
        </button>
        <input-search
          class='layout-header__main__left__search'
          placeholder='キーワードを入力'
          v-if='!isActive.creatorMode'
          @emit-search='movePage'
        />
      </div>
      <div class='layout-header__main__right'>
        <!-- <p class='layout-header__main__right__subtitle' v-if='showSubtitle'>
          プログラミング学習レッスンプラットフォーム<br>manabiba
        </p> -->
        <router-link :to='{ name : "Cart"}'>
          <button-cart
            class='layout-header__main__right__notice'
            :countCart='userInfo.cart.length'
          />
        </router-link>
        <router-link :to='{ name : "NewsList"}'>
          <button-notice
            class='layout-header__main__right__notice'
          />
        </router-link>
        <button-user
          v-if='userInfo.login'
          :src='userInfo.profile.avatar'
          @emit='active("dropdownUser")'
        />
        <router-link :to='{ name: "Login"}'>
          <button-normal
            v-if='!userInfo.login'
            small
            white
          >ログイン</button-normal>
        </router-link>
      </div>
    </div>
    <div class='layout-header__dropdown'>
      <dropdown-header-user
        class='layout-header__main__dropdown__user'
        v-if='isActive.dropdownUser'
        :userIcon='userInfo.profile.avatar'
        :userName='userInfo.profile.userName'
        :purchaseList='filterPurchaseList'
        @emit-logout='logout'
      />
      <dropdown-header-category
        class='layout-header__main__dropdown__category'
        v-if='isActive.dropdownCategory'
        :categoryNameList='categoryNameList'
      />
    </div>
    <div
      class='layout-header__overlay'
      v-if='isActive.dropdownUser'
      @click='active("dropdownUser")'
    />
    <div
      class='layout-header__overlay'
      v-if='isActive.dropdownNotice'
      @click='active("dropdownNotice")'
    />
    <div
      class='layout-header__overlay'
      v-if='isActive.dropdownCategory'
      @click='active("dropdownCategory")'
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import helper from '@/mixins/general/methods/helper';
import LayoutLogo from '@/components/layouts/general/LayoutLogo';
import { DropdownHeaderUser } from '@/components/molecules/other';
import {
  ButtonNormal, ButtonUser, ButtonNotice, ButtonCart,
} from '@/components/atoms/button';
import { DropdownHeaderCategory, BarAnnounce } from '@/components/atoms/other/';
import { InputSearch } from '@/components/atoms/input';

export default {
  components: {
    LayoutLogo,
    ButtonNormal,
    ButtonUser,
    ButtonNotice,
    ButtonCart,
    DropdownHeaderUser,
    DropdownHeaderCategory,
    InputSearch,
    BarAnnounce,
  },
  data() {
    return {
      isActive: {
        dropdownUser: false,
        dropdownNotice: false,
        dropdownCategory: false,
        modalAuth: false,
        creatorMode: false,
        hoverCategory: false,
      },
      // creatorModeと判定するrouteList
      creatorModeRouteList: [
        'LessonCreate',
        'LessonCreateConfirm',
        'LessonUpdate',
        'LessonUpdateConfirm',
      ],
      // keyはparam, valueは表示名
      categoryNameList: {
        htmlcss: 'HTML/CSS',
        javascript: 'Javascript',
        php: 'PHP',
      },
    };
  },
  mixins: [helper],
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapState('notice', ['noticeList']),
    // ドロップダウンメニューに表示する受講中レッスンの表示制御
    filterPurchaseList() {
      const filterList = this.userInfo.lesson.purchase
        .filter((lesson) => lesson.inProgress.continue !== null
        && lesson.inProgress.progressRate !== 100);
      return filterList;
    },
    showSubtitle() {
      if (window.innerWidth > 1200) return true;
      return false;
    },
    showNoticeAnnounce() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return userAgent.indexOf('chrome') === -1;
    },
  },
  created() {
    // sphone判定
    const ua = window.navigator.userAgent.toLowerCase();
    if ((ua.indexOf('iphone') !== -1 || ua.indexOf('ipad') !== -1 || (ua.indexOf('macintosh') && 'ontouchend' in document))
      || ua.indexOf('android') !== -1) {
      this.movePage('Sphone');
    }

    // creatorMode判定
    if (this.creatorModeRouteList.includes(this.$route.name)) this.active('creatorMode');
  },
  methods: {
    active(target) {
      this.isActive[target] = !this.isActive[target];
    },
    async logout() {
      this.active('dropdownUser');
      this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
      try {
        await this.$http.put('api/auth/user/logout');
        this.$store.commit('user/logout');
        this.$store.commit('lessonAttend/logout');
        this.movePage('Top');
      } catch (e) {
        if (e.response !== undefined && e.response.status === 401) {
          this.unAuthenticate();
        } else {
          this.movePage('Error');
        }
      }
    },
  },
  watch: {
    $route(to) {
      this.$router.go({ name: to.name, params: { category: to.params.category } });
    },
  },
};
</script>

<style scoped>
.layout-header {
  z-index: 3;
}
.layout-header__main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: #4CAF50;
  padding: 0 80px;
  height: 80px;
}

.layout-header__main__left{
  display: flex;
  align-items: center;
}

.layout-header__main__left__creator {
  background-color: #E74860;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 8px;
  margin-left: 20px;
}

.layout-header__main__left__category {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-left: 50px;
  color: #fff;
}

.layout-header__main__left__category img {
  margin-right: 5px;
  width: 20px;
}

.layout-header__main__left__category__hover__bar {
  position: absolute;
  width: 15px;
  height: 4px;
  background-color: #d1ae00;
  border-radius: 20px;
  bottom: -20px;
  left: 45px;
}

.layout-header__main__left__search {
  margin-left: 20px;
}

.layout-header__main__right {
  display: flex;
  align-items: center;
}

.layout-header__main__right__subtitle{
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  text-align: right;
}

.layout-header__main__right__notice {
  margin-right: 20px;
}

.layout-header__main__dropdown__user {
  position: absolute;
  top: 80px;
  right: 67px;
  z-index: 999;
}

.layout-header__main__dropdown__category {
  position: absolute;
  top: 66px;
  left: 305px;
  z-index: 999;
}

.layout-header__overlay {
  position: fixed;
  z-index: 998;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.v-enter-active, .v-leave-active {
  transition: opacity 0.05s;
}

.v-enter, .v-leave-to {
  opacity: 0;
}
</style>
