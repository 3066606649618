var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: _vm.addClass,
      attrs: { disabled: _vm.disabled || _vm.loading },
      on: {
        click: function($event) {
          return _vm.$emit("emit")
        }
      }
    },
    [
      !_vm.loading ? _vm._t("default") : _vm._e(),
      _vm.loading
        ? _c("vue-loading", {
            attrs: { type: _vm.loadIcon.type, size: _vm.loadIcon.size }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }