<template>
  <div class='icon-post-date'>
    <div class='icon-post-date__left'>
      <slot />
    </div>
    <div class='icon-post-date__right'>
      {{ setUpdateDate }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    date: String,
  },
  computed: {
    setUpdateDate() {
      if (this.date !== undefined) {
        const date = new Date(this.date.replace(/-/g, '/'));
        return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
      }
      return '取得できません';
    },
  },
};
</script>

<style scoped>
.icon-post-date {
  display: flex;
  align-items: center;
}

.icon-post-date__left {
  background-color: #F1EFFC;
  font-size: 11px;
  padding: 5.5px 8px;
  border-radius: 15px 0 0 15px;
}

.icon-post-date__right {
  background-color: #F7F7F7;
  font-size: 10px;
  padding: 6px 8px;
  border-radius: 0 15px 15px 0;
}
</style>
