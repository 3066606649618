var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.addClass },
    [
      _c("vue-loading", { attrs: { type: _vm.type, size: _vm.size } }),
      _c("p", [_vm._t("default", [_vm._v("読み込み中...")])], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }