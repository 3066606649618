var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("p", { staticClass: "icon-level" }, [
    _vm._v(" " + _vm._s(_vm.setLevel) + " ")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }