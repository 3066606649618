<template>
  <div class='error'>
    <layout-header class='error__header'/>
    <div  class='error__contents'>
      <h3 class='error__contents__title'>{{ title }}</h3>
      <p class='error__contents__text'>{{ text }}</p>
    </div>
    <layout-footer class='error__footer' hidePageUp simpleFooter/>
  </div>
</template>

<script>
import { LayoutHeader, LayoutFooter } from '@/components/layouts/general/';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
  },
  data() {
    return {
      title: 'Error',
      text: 'ページの読み込み中にエラーが発生しました',
    };
  },
};
</script>

<style scoped>
.error {
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: 100vh;
  background-color: #4CAF50;
  overflow: hidden;
}

.error__header {
  z-index: 2;
}
.error__contents {
  position: absolute;
  top: 30%;
  left: 15%;
  color: #fff;
  z-index: 2;
}
.error__contents__title {
  font-size : 70px;
  font-weight: 500;
  font-family: 'Tahoma', 'Meiryo';
}

.error__contents__text {
  font-size: 20px;
  font-weight: 500;
  margin-top: 5px;
}

.error__background {
  z-index: 1;
  width: 880px;
  position: absolute;
  bottom: -60px;
  right: -120px;
  opacity: 0.8;
}

.error__footer {
  z-index: 2;
}
</style>
