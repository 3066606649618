<template>
  <button @click='$emit("emit", auth)' :class="`${auth}`">
    <i :class="`fab fa-${auth}`" />
  </button>
</template>

<script>
export default {
  props: {
    auth: String,
  },
};
</script>

<style scoped>
.google {
  padding: 5px 35px;
  background-color: #F52C2C;
  color: #fff;
  font-size: 24px;
}

.twitter {
  padding: 5px 35px;
  margin: 0 10px;
  background-color: #2CAFF5;
  color: #fff;
  font-size: 24px;
}

.github {
  padding: 5px 35px;
  margin-left: 10px;
  background-color: #505050;
  color: #fff;
  font-size: 24px;
}

.facebook {
  padding: 5px 35px;
  margin: 0 10px;
  background-color: #3E54FF;
  color: #fff;
  font-size: 24px;
}
</style>
