var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lesson-create-confirm" },
    [
      _c("layout-header"),
      _c("heading-title", {
        scopedSlots: _vm._u([
          {
            key: "main",
            fn: function() {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$route.fullPath.includes("create")
                        ? "Lesson Create Confirm"
                        : "Lesson Update Confirm"
                    ) +
                    " "
                )
              ]
            },
            proxy: true
          },
          {
            key: "sub",
            fn: function() {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$route.fullPath.includes("create")
                        ? "レッスン投稿確認_"
                        : "レッスン更新確認_"
                    ) +
                    " "
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("section", { staticClass: "lesson-create-confirm__info" }, [
        _c("div", { staticClass: "lesson-create-confirm__info__back" }),
        _c(
          "div",
          { staticClass: "lesson-create-confirm__info__front center" },
          [
            _c("section-top-info-confirm", {
              staticClass: "lesson-create-confirm__info__front__top",
              attrs: {
                thumbnail: _vm.postLessonInfo.thumbnail
                  ? _vm.postLessonInfo.thumbnail
                  : _vm.defaultThumbnail,
                lessonInfo: _vm.postLessonInfo.setting,
                levelLabel: _vm.levelLabel
              }
            }),
            _c(
              "div",
              {
                staticClass: "lesson-create-confirm__info__front__description"
              },
              [
                _c("heading-normal", { attrs: { large: "" } }, [
                  _vm._v("レッスン内容")
                ]),
                _c("text-normal", [
                  _vm._v(_vm._s(_vm.postLessonInfo.setting.description))
                ])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "lesson-create-confirm__info__front__target" },
              [
                _c("heading-normal", { attrs: { large: "" } }, [
                  _vm._v("対象ユーザー")
                ]),
                _c("text-normal", [
                  _vm._v(_vm._s(_vm.postLessonInfo.setting.target))
                ])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "lesson-create-confirm__info__front__syllabus" },
              [
                _c("heading-normal", { attrs: { large: "" } }, [
                  _vm._v("シラバス")
                ]),
                _c(
                  "p",
                  {
                    staticClass:
                      "lesson-create-confirm__info__front__syllabus__description"
                  },
                  [
                    _vm._v(
                      " クリックするとプレビューページで動作を確認できます "
                    )
                  ]
                ),
                _c(
                  "ul",
                  {
                    staticClass:
                      "lesson-create-confirm__info__front__syllabus__list"
                  },
                  _vm._l(_vm.postLessonInfo.setting.chapter, function(
                    chapter,
                    index
                  ) {
                    return _c(
                      "li",
                      { key: chapter.id },
                      [
                        _vm.postLessonInfo.setting.freeChapterNum === index
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "lesson-create-confirm__info__front__syllabus__list__free"
                              },
                              [_vm._v("↑ここまで無料公開↑")]
                            )
                          : _vm._e(),
                        _c("list-chapter", {
                          attrs: {
                            num: index,
                            title: chapter,
                            disabled: _vm.isLoading.submit,
                            hideCheck: ""
                          },
                          on: {
                            "emit-move-page": function($event) {
                              return _vm.openPreview(index + 1)
                            }
                          }
                        })
                      ],
                      1
                    )
                  }),
                  0
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("section-author", { attrs: { authorInfo: _vm.setAuthorInfo } }),
      _c(
        "section",
        { staticClass: "lesson-create-confirm__post center" },
        [
          _c(
            "div",
            { staticClass: "lesson-create-confirm__post__policy" },
            [
              _c("checkbox-normal", {
                staticClass: "lesson-create-confirm__post__policy__checkbox",
                attrs: { isActive: _vm.isActive.term },
                on: {
                  emit: function($event) {
                    return _vm.active("term")
                  }
                }
              }),
              _c(
                "div",
                { staticClass: "lesson-create-confirm__post__policy__text" },
                [
                  _c("p", [_vm._v("右規約に同意します")]),
                  _c(
                    "a",
                    {
                      staticClass:
                        "lesson-create-confirm__post__policy__text__link",
                      attrs: {
                        href: _vm.getRouteData("Terms"),
                        target: "_blank",
                        rel: "noopener noreferrer"
                      }
                    },
                    [_vm._v("利用規約")]
                  ),
                  _c("p", [_vm._v("と")]),
                  _c(
                    "a",
                    {
                      staticClass:
                        "lesson-create-confirm__post__policy__text__link",
                      attrs: {
                        href: _vm.getRouteData("PrivacyPolicy"),
                        target: "_blank",
                        rel: "noopener noreferrer"
                      }
                    },
                    [_vm._v("プライバシーポリシー")]
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "button-normal",
            {
              attrs: {
                disabled: !_vm.isActive.term,
                loading: _vm.isLoading.submit,
                large: ""
              },
              on: { emit: _vm.submitLesson }
            },
            [
              _vm._v(
                _vm._s(
                  this.$route.fullPath.includes("create")
                    ? "投稿する"
                    : "更新する"
                )
              )
            ]
          )
        ],
        1
      ),
      _vm.isActive.modalPost
        ? _c("modal-normal", {
            attrs: {
              title: this.$route.fullPath.includes("create")
                ? "レッスン投稿を完了しました"
                : "レッスン更新を完了しました",
              text: this.$route.fullPath.includes("create")
                ? "レッスンは審査完了後公開されます"
                : "レッスン更新情報は審査完了後に反映されます",
              acceptBtn: "レッスン管理へ",
              cancelBtn: "トップへ",
              disabledOverlay: ""
            },
            on: { emit: _vm.postComplete }
          })
        : _vm._e(),
      _c("layout-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }