var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-signup" },
    [
      _c(
        "div",
        { staticClass: "form-signup__main" },
        [
          _c(
            "heading-normal",
            { staticClass: "form-signup__main__title", attrs: { large: "" } },
            [_vm._v(_vm._s(_vm.form))]
          ),
          _c(
            "div",
            { staticClass: "form-signup__main__input input__margin__top" },
            [
              _c("heading-normal", { attrs: { small: "" } }, [
                _vm._v(_vm._s(_vm.userName))
              ]),
              _c("input-text", {
                attrs: { errorMsg: _vm.errorMsg.username },
                on: { emit: _vm.saveUsername }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-signup__main__input" },
            [
              _c("heading-normal", { attrs: { small: "" } }, [
                _vm._v(_vm._s(_vm.password))
              ]),
              _c("input-password", {
                attrs: { errorMsg: _vm.errorMsg.password },
                on: { emit: _vm.savePassword }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-signup__main__input" },
            [
              _c("heading-normal", { attrs: { small: "" } }, [
                _vm._v(_vm._s(_vm.repassword))
              ]),
              _c("input-password", {
                attrs: { errorMsg: _vm.errorMsg.repassword },
                on: { emit: _vm.saveRepassword }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-signup__main__input" },
            [
              _c("heading-normal", { attrs: { small: "" } }, [
                _vm._v(_vm._s(_vm.hash))
              ]),
              _c("input-text", { on: { emit: _vm.saveHash } })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-signup__main__button" },
            [
              _c(
                "button-normal",
                {
                  attrs: { disabled: _vm.signinButttonDisabled, large: "" },
                  on: { emit: _vm.switchQRModal }
                },
                [_vm._v("2段階認証へ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.switchModal
        ? _c("modal-confirm", {
            attrs: {
              isLoading: _vm.isLoading,
              title: "QRコードを用いて2段階認証を行います",
              text:
                "Google Authenticatorをお使いのスマホにインストールしてください",
              acceptBtn: "サインアップ",
              cancelBtn: "戻る",
              disabledOverlay: ""
            },
            on: { emit: _vm.signupComplete }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }