<template>
  <button :class='addClass' :disabled='disabled' @click='$emit("emit")'>
    <i :class='`fas fa-chevron-${direction}`' />
  </button>
</template>

<script>
export default {
  props: {
    direction: String,
    disabled: Boolean,
    small: Boolean,
  },
  computed: {
    addClass() {
      return {
        'button-arrow': true,
        'button-arrow--disabled': this.disabled,
        'button-arrow--small': this.small,
      };
    },
  },
};
</script>

<style scoped>
.button-arrow {
  font-size: 32px;
}

.button-arrow--small {
  width: 25px;
  height: 25px;
  border-radius: 6px;
  position: relative;
  background-color: #4CAF50;
  color: #fff;
  position: relative;
}

.button-arrow--disabled {
  background-color: #E6E6E6;
}

.button-arrow--small i {
  font-size: 14px;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
</style>
