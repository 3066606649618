<template>
  <button :class='addClass' :disabled='disabled || lock'
  @click='$emit("emit-move-page", "LessonAttend", { id: id, num: num + 1})'>
    <div class='list-chapter__left'>
      <p class='list-chapter__left__num'>{{ num + 1}}</p>
      <p class='list-chapter__left__text'>{{ title }}</p>
    </div>
    <div v-if='login || !free' class='list-chapter__right'>
      <div
        class='list-chapter__right__check'
        v-if='!hideCheck && !lock'
      >
        <i v-if='complete === true' class='fas fa-check-circle' />
        <i v-if='complete === false' class='far fa-circle' />
      </div>
      <div
        class='list-chapter__right__lock'
        v-if='lock'
      >
        <i class="fas fa-lock" />
      </div>
    </div>
  </button>
</template>

<script>

export default {
  props: {
    num: Number,
    id: Number,
    title: String,
    complete: Boolean,
    login: Boolean,
    disabled: Boolean,
    lock: Boolean,
    hideCheck: Boolean,
    free: Boolean,
  },
  computed: {
    addClass() {
      return {
        'list-chapter': true,
        'list-chapter--disabled': this.disabled && !this.lock,
        'list-chapter--lock': this.lock,
      };
    },
  },
};
</script>

<style scoped>
.list-chapter {
  display: flex;
  align-items: center;
  background-color: #F7F7F7;
  justify-content: space-between;
  padding: 0 35px;
  height: 80px;
  width: 100%;
  transition: 0.15s;
}

.list-chapter:hover {
  background-color: #ebebeb;
}

.list-chapter--disabled:hover {
  background-color: #F7F7F7;
}

.list-chapter--lock {
  background-color: #CBCBCB;
  pointer-events: none;
}
.list-chapter__left {
  display: flex;
  font-weight: bold;
  font-size: 16px;
}

.list-chapter__left__text {
  margin: 0 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-all;
}

.list-chapter__right {
  font-size: 32px;
  color: #90cce6;
}

.list-chapter__right__lock {
  color: #E74860;
  font-size: 14px;
}
</style>
