var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "lesson-output-tab" },
    _vm._l(_vm.tabInfo, function(tab, index) {
      return _c(
        "li",
        {
          key: tab.id,
          class:
            "lesson-output-tab__list " +
            (_vm.isActive === index ? "focus" : ""),
          on: {
            click: function($event) {
              _vm.$emit("emit", index), _vm.changeFocus(index)
            }
          }
        },
        [_vm._v(_vm._s(tab))]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }