<template>
  <ul class='lesson-output-tab'>
    <li
      v-for='(tab, index) in tabInfo'
      :key='tab.id'
      :class='`lesson-output-tab__list ${ isActive === index ? "focus" : "" }`'
      @click="$emit('emit', index), changeFocus(index)"
    >{{ tab }}</li>
  </ul>
</template>

<script>
export default {
  props: {
    tabInfo: Array,
  },
  data() {
    return {
      isActive: 0,
    };
  },
  methods: {
    changeFocus(index) {
      this.isActive = index;
    },
  },
};
</script>

<style scoped>
.lesson-output-tab {
  display: flex;
  list-style: none;
}

.lesson-output-tab::-webkit-scrollbar {
  display: none;
}

.lesson-output-tab__list {
  width: 100%;
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 500;
  background-color: #C4C5B0;
  cursor: pointer;
}

.focus {
  background-color: #E3E3DA;
  font-weight: 600;
}
</style>
