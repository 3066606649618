<template>
  <div :class='addClass'>
    <input
      type='Number'
      v-model='emitNumber'
      :max='maxNumber'
      :min='minNumber'
      :step='step'
      required
    >
    <p class='input-number__error' v-if='errorMsg'>{{ errorMsg }}</p>
  </div>
</template>

<script>
export default {
  props: {
    number: Number,
    name: String,
    maxNumber: Number,
    minNumber: Number,
    errorMsg: String,
    step: Number,
  },
  data() {
    return {
      emitNumber: Number,
    };
  },
  computed: {
    addClass() {
      return {
        'input-number': true,
        'input-number--error': this.errorMsg,
      };
    },
  },
  created() {
    this.emitNumber = this.number;
  },
  updated() {
    this.$emit('emit', this.emitNumber, this.name);
  },
};
</script>

<style scoped>
.input-number input {
  background-color: #F9F9FA;
  padding: 15px;
  font-size: 16px;
  width: 100%;
  border-radius: 8px;
}

.input-number--error input {
  background-color: rgb(255, 147, 169);
}

.input-number__error {
  color: rgb(255, 147, 169);
  margin-top: 5px;
  font-size: 12px;
  white-space: nowrap;
}
</style>
