var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-compare-source" }, [
    _c("div", { staticClass: "modal-compare-source__main" }, [
      _c(
        "div",
        { staticClass: "modal-compare-source__main__edit" },
        [
          _c("h3", { staticClass: "modal-compare-source__main__edit__title" }, [
            _vm._v("自分のコード")
          ]),
          _c("lesson-editor-tab", {
            attrs: { tabInfo: _vm.editInfo.tabInfo },
            on: { emit: _vm.switchEditorEdit }
          }),
          _vm._l(_vm.editSourceList, function(sourceInfo, index) {
            return _c("lesson-ace", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.editInfo.editor == index,
                  expression: "editInfo.editor == index"
                }
              ],
              key: sourceInfo.id,
              attrs: {
                id: 100 + index,
                filename: sourceInfo.filename,
                source: sourceInfo.source,
                readonly: ""
              }
            })
          })
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "modal-compare-source__main__default" },
        [
          _c(
            "h3",
            { staticClass: "modal-compare-source__main__default__title" },
            [_vm._v("答え")]
          ),
          _c("lesson-editor-tab", {
            attrs: { tabInfo: _vm.defaultInfo.tabInfo },
            on: { emit: _vm.switchEditorDefault }
          }),
          _vm._l(_vm.defaultSourceList, function(sourceInfo, index) {
            return _c("lesson-ace", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.defaultInfo.editor == index,
                  expression: "defaultInfo.editor == index"
                }
              ],
              key: sourceInfo.id,
              attrs: {
                id: 200 + index,
                filename: sourceInfo.filename,
                source: sourceInfo.sample,
                readonly: ""
              }
            })
          }),
          _c("button-hide", {
            on: {
              emit: function($event) {
                return _vm.$emit("emit-hide")
              }
            }
          })
        ],
        2
      )
    ]),
    _c("div", {
      staticClass: "modal-compare-source__overlay",
      on: {
        click: function($event) {
          return _vm.$emit("emit-hide")
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }