var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "categories" },
    [
      _c("layout-header"),
      _c("heading-title", {
        scopedSlots: _vm._u([
          {
            key: "main",
            fn: function() {
              return [
                _vm._v(" " + _vm._s(_vm.categoryNameList[_vm.category]) + " ")
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("main", { staticClass: "categories__main center" }, [
        _vm.showLessonSection("recommend")
          ? _c(
              "section",
              { staticClass: "categories__main__recommend" },
              [
                _c("heading-normal", { attrs: { large: "" } }, [
                  _vm._v(" " + _vm._s(_vm.headingInfo.recommend.title) + " ")
                ]),
                _c(
                  "div",
                  { staticClass: "categories__main__recommend__lessonlist" },
                  [
                    _c(
                      "ul",
                      {
                        staticClass:
                          "categories__main__recommend__lessonlist__info"
                      },
                      _vm._l(_vm.lessonInfo.recommend, function(info) {
                        return _c(
                          "li",
                          { key: info.id },
                          [
                            _c("lesson-card-simple", {
                              attrs: {
                                lessonInfo: info,
                                levelLabel: _vm.levelLabel
                              }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    ),
                    _vm.isLoading.recommend ? _c("icon-loading") : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.showLessonSection("popular")
          ? _c(
              "section",
              { staticClass: "categories__main__popular" },
              [
                _c("heading-normal", { attrs: { large: "" } }, [
                  _vm._v(" " + _vm._s(_vm.headingInfo.popular.title) + " ")
                ]),
                _c(
                  "div",
                  { staticClass: "categories__main__popular__lessonlist" },
                  [
                    !_vm.isLoading.popular && _vm.lessonInfo.popular.length > 4
                      ? _c("button-arrow", {
                          staticClass:
                            "categories__main__popular__lessonlist__prev",
                          attrs: { direction: "left" },
                          on: {
                            emit: function($event) {
                              return _vm.moveCarousel("prev", "popular")
                            }
                          }
                        })
                      : _vm._e(),
                    !_vm.isLoading.popular &&
                    _vm.lessonInfo.popular.length !== 0
                      ? _c(
                          "vue-slick-carousel",
                          _vm._b(
                            {
                              ref: "popular",
                              staticClass:
                                "categories__main__popular__lessonlist__carousel"
                            },
                            "vue-slick-carousel",
                            _vm.carousel.setting,
                            false
                          ),
                          _vm._l(_vm.lessonInfo.popular, function(info) {
                            return _c(
                              "div",
                              { key: info.id },
                              [
                                _c("lesson-card-popular", {
                                  staticClass:
                                    "categories__main__popular__lessonlist__carousel__card",
                                  attrs: {
                                    lessonInfo: info,
                                    levelLabel: _vm.levelLabel
                                  }
                                })
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    !_vm.isLoading.popular && _vm.lessonInfo.popular.length > 4
                      ? _c("button-arrow", {
                          staticClass:
                            "categories__main__popular__lessonlist__next",
                          attrs: { direction: "right" },
                          on: {
                            emit: function($event) {
                              return _vm.moveCarousel("next", "popular")
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.isLoading.popular ? _c("icon-loading") : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _c(
          "section",
          { staticClass: "categories__main__new", attrs: { id: "lesson-new" } },
          [
            _c(
              "div",
              { staticClass: "categories__main__new__heading" },
              [
                _c("heading-normal", { attrs: { large: "" } }, [
                  _vm._v(" " + _vm._s(_vm.headingInfo.new.title) + " ")
                ]),
                _c("button-list", {
                  staticClass: "categories__main__new__heading__label",
                  attrs: {
                    title: "難易度選択:",
                    disabled: _vm.isLoading.new,
                    labelList: _vm.headingInfo.new.label
                  },
                  on: { emit: _vm.execLoadLesson }
                })
              ],
              1
            ),
            _c(
              "ul",
              { staticClass: "categories__main__new__lessonlist" },
              _vm._l(_vm.lessonInfo.new, function(info) {
                return _c(
                  "li",
                  {
                    key: info.id,
                    staticClass:
                      "categories__main__new__lessonlist__lesson center"
                  },
                  [
                    _c("lesson-card-wide", {
                      attrs: { lessonInfo: info, levelLabel: _vm.levelLabel }
                    })
                  ],
                  1
                )
              }),
              0
            ),
            _vm.isLoading.new ? _c("icon-loading") : _vm._e(),
            !_vm.lessonInfo.new.length && !_vm.isLoading.new
              ? _c("p", { staticClass: "categories__main__new__notfound" }, [
                  _vm._v(" レッスンが存在しません ")
                ])
              : _vm._e(),
            _c("pager-normal", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showPager(0),
                  expression: "showPager(0)"
                }
              ],
              staticClass: "categories__main__new__pager",
              attrs: {
                totalCount: _vm.pagerInfo.count.totalLesson,
                display: _vm.pagerInfo.limit.new
              },
              on: { emit: _vm.setCount }
            }),
            _c("pager-normal", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showPager(1),
                  expression: "showPager(1)"
                }
              ],
              staticClass: "categories__main__new__pager",
              attrs: {
                totalCount: _vm.pagerInfo.count.totalLesson,
                display: _vm.pagerInfo.limit.new
              },
              on: { emit: _vm.setCount }
            }),
            _c("pager-normal", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showPager(2),
                  expression: "showPager(2)"
                }
              ],
              staticClass: "categories__main__new__pager",
              attrs: {
                totalCount: _vm.pagerInfo.count.totalLesson,
                display: _vm.pagerInfo.limit.new
              },
              on: { emit: _vm.setCount }
            }),
            _c("pager-normal", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showPager(3),
                  expression: "showPager(3)"
                }
              ],
              staticClass: "categories__main__new__pager",
              attrs: {
                totalCount: _vm.pagerInfo.count.totalLesson,
                display: _vm.pagerInfo.limit.new
              },
              on: { emit: _vm.setCount }
            })
          ],
          1
        )
      ]),
      _c("layout-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }