import admin from './admin';
import lessonAttend from './lessonAttend';
import lessonCreate from './lessonCreate';
import material from './material';
import notice from './notice';
import user from './user';

export {
  admin,
  lessonAttend,
  lessonCreate,
  material,
  notice,
  user,
};
