<template>
  <transition name='fade'>
    <div class='layout-logo-pixel' v-show='scY > 0' @click="scrollTop">
      <a class='layout-logo-pixel__bubble'>TOP</a>
      <img class='hover' src='@/assets/img/peach.svg' alt='桃 peach'>
      <img class='shadow' src='@/assets/img/peach-shadow.svg' alt=''>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      scTimer: 0,
      scY: 0,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style scoped>
.layout-logo-pixel {
  position: relative;
  bottom: 220px;
}

.layout-logo-pixel__bubble {
  position: absolute;
  width: 100px;
  height: 44px;
  right: 80px;
  bottom: 180px;
  border-radius: 50px;
  text-align: center;
  font-weight: bold;
  line-height: 43px;
  font-size: 24px;
  pointer-events: none;
  background-color: #E94861;
  color: #fff;
  opacity: 0;
  transition: all 0.5s ease;
  z-index: 10;
}

.layout-logo-pixel__bubble:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  border: 10px solid transparent;
  border-top: 15px solid #E94861;
  z-index: 10;
}

.hover {
  z-index: 10;
}

.hover, .shadow {
  position: absolute;
  bottom: 0;
  right: 40px;
  transition: all 0.5s ease;
}

.hover:hover {
  cursor: pointer;
  transform: translate(-8px,-8px);
  transition: all 0.5s ease;
}

.layout-logo-pixel:hover > .layout-logo-pixel__bubble {
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
