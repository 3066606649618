var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lesson-create" },
    [
      _c("layout-header"),
      _c("heading-title", {
        scopedSlots: _vm._u([
          {
            key: "main",
            fn: function() {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$route.fullPath.includes("create")
                        ? "LessonCreate"
                        : "LessonUpdate"
                    ) +
                    " "
                )
              ]
            },
            proxy: true
          },
          {
            key: "sub",
            fn: function() {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$route.fullPath.includes("create")
                        ? "レッスン投稿_"
                        : "レッスン更新_"
                    ) +
                    " "
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("section", { staticClass: "lesson-create__main" }, [
        _c("div", { staticClass: "lesson-create__main__back" }),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isLoading.loadStripeInfo,
                expression: "isLoading.loadStripeInfo"
              }
            ],
            staticClass: "lesson-create__main__loading center"
          },
          [_c("div", [_c("icon-loading")], 1)]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isLoading.loadStripeInfo,
                expression: "!isLoading.loadStripeInfo"
              }
            ],
            staticClass: "lesson-create__main__front center"
          },
          [
            _c("div", { staticClass: "lesson-create__main__front__form" }, [
              _c("canvas", { attrs: { id: "canvas" } }),
              !_vm.isActive.enableCreatePaidLesson
                ? _c(
                    "div",
                    { staticClass: "lesson-create__main__front__form__apply" },
                    [
                      _c("p", [
                        _vm._v(
                          "現在は無料レッスンのみ投稿できます。有料レッスンを投稿するには別途申請が必要です。"
                        )
                      ]),
                      _c(
                        "button-normal",
                        {
                          attrs: { small: "" },
                          on: {
                            emit: function($event) {
                              return _vm.movePage("UserInfoSales")
                            }
                          }
                        },
                        [_vm._v("申請")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "lesson-create__main__front__form__level" },
                [
                  _c("heading-normal", [_vm._v("レベル")]),
                  _c("input-radio", {
                    attrs: {
                      radioInfo: _vm.radioInfo.level,
                      checked: _vm.radioChecked.level
                    },
                    on: { "emit-radio-value": _vm.saveLevel }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "lesson-create__main__front__form__category" },
                [
                  _c("heading-normal", [_vm._v("カテゴリー")]),
                  _c("v-select", {
                    staticClass:
                      "lesson-create__main__front__form__category__select",
                    attrs: { options: _vm.setCategorySelect, multiple: "" },
                    model: {
                      value: _vm.uploadFileList.setting.category,
                      callback: function($$v) {
                        _vm.$set(_vm.uploadFileList.setting, "category", $$v)
                      },
                      expression: "uploadFileList.setting.category"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "lesson-create__main__front__form__category" },
                [
                  _c("heading-normal", [_vm._v("setting.yml")]),
                  _c("input-file", {
                    staticClass:
                      "lesson-create__main__front__form__category__select",
                    attrs: {
                      accept: _vm.setYmlAccept,
                      errorMsg: _vm.errorMsg.settingYml
                    },
                    on: { emit: _vm.saveYmlFile }
                  })
                ],
                1
              ),
              _vm.isActive.enableCreatePaidLesson
                ? _c(
                    "div",
                    {
                      staticClass: "lesson-create__main__front__form__category"
                    },
                    [
                      _c("heading-normal", [_vm._v("価格")]),
                      _c("input-radio", {
                        staticClass:
                          "lesson-create__main__front__form__category__select",
                        attrs: {
                          radioInfo: _vm.radioInfo.price,
                          checked: _vm.radioChecked.price
                        },
                        on: { "emit-radio-value": _vm.ActivePrice }
                      }),
                      _vm.isActive.price
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "lesson-create__main__front__form__price__wrapper"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "lesson-create__main__front__form__price__wrapper__top"
                                },
                                [
                                  _c("p", [
                                    _vm._v(
                                      "レッスンの価格を1000-30000円の間で入力してください。"
                                    )
                                  ]),
                                  _c("input-number", {
                                    staticClass:
                                      "lesson-create__main__front__form__price__wrapper__top__input",
                                    attrs: {
                                      maxNumber: _vm.validateRule.price.max,
                                      minNumber: _vm.validateRule.price.min,
                                      step: _vm.validateRule.price.step,
                                      number: _vm.setPrice,
                                      errorMsg: _vm.errorMsg.price
                                    },
                                    on: { emit: _vm.savePrice }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "lesson-create__main__front__form__price__wrapper__bottom"
                                },
                                [
                                  _c("p", [
                                    _vm._v(
                                      "無料枠として公開するチャプター数を選択してください。(例: 2を選択するとチャプター1,2が無料公開されます)"
                                    )
                                  ]),
                                  _c("input-number", {
                                    staticClass:
                                      "lesson-create__main__front__form__price__wrapper__bottom__input",
                                    attrs: {
                                      maxNumber:
                                        _vm.validateRule.freeChapterNum.max,
                                      minNumber:
                                        _vm.validateRule.freeChapterNum.min,
                                      step:
                                        _vm.validateRule.freeChapterNum.step,
                                      number: _vm.setFreeChapterNum,
                                      errorMsg: _vm.errorMsg.freeChapterNum
                                    },
                                    on: { emit: _vm.saveFreeChapterNum }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "lesson-create__main__front__form__category" },
                [
                  _c("heading-normal", [_vm._v("サムネイル")]),
                  _c("input-file", {
                    staticClass:
                      "lesson-create__main__front__form__category__select",
                    attrs: {
                      accept: _vm.setThumbnailAccept,
                      description: _vm.inputFile.description,
                      errorMsg: _vm.errorMsg.thumbnail
                    },
                    on: { emit: _vm.saveThumbnaiImg }
                  })
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("heading-normal", [_vm._v("コンテンツアップロード")]),
                  _c(
                    "input-file-lesson",
                    {
                      attrs: { errorMsg: _vm.errorMsg.content },
                      on: { "emit-post-lesson": _vm.buildLessonFormat }
                    },
                    [_vm._v(" " + _vm._s(_vm.setInputText) + " ")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "lesson-create__button" },
                [
                  _c(
                    "button-card",
                    {
                      staticClass: "lesson-create__button__left",
                      attrs: { icon: "download" },
                      on: {
                        emit: function($event) {
                          return _vm.openNewTab(_vm.githubLink.lessonTemplate)
                        }
                      }
                    },
                    [_vm._v("レッスンのテンプレートをダウンロード")]
                  ),
                  _c(
                    "button-card",
                    {
                      staticClass: "lesson-create__button__right",
                      attrs: { icon: "sticky-note" },
                      on: {
                        emit: function($event) {
                          return _vm.openNewTab(_vm.setPostLink)
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$route.fullPath.includes("create")
                            ? "レッスン投稿手順"
                            : "レッスン更新手順"
                        )
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "lesson-create__main__front__form__nextbtn" },
                [
                  _c(
                    "button-normal",
                    {
                      attrs: { disabled: !_vm.buttonValidation, large: "" },
                      on: { emit: _vm.moveConfirmPage }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$route.fullPath.includes("create")
                            ? "投稿内容を確認"
                            : "更新内容を確認"
                        )
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ]
        )
      ]),
      _c("layout-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }