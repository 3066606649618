var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-login" }, [
    _c(
      "div",
      { staticClass: "form-login__main" },
      [
        _c(
          "heading-normal",
          { staticClass: "form-login__main__title", attrs: { large: "" } },
          [_vm._v(_vm._s(_vm.form))]
        ),
        !_vm.isLoading
          ? _c(
              "div",
              { staticClass: "form-login__main__input input__margin__top" },
              [
                _c("heading-normal", { attrs: { small: "" } }, [
                  _vm._v(_vm._s(_vm.userName))
                ]),
                _c("input-text", {
                  attrs: { text: _vm.loginInfo.userName },
                  on: { emit: _vm.saveUsername }
                })
              ],
              1
            )
          : _vm._e(),
        !_vm.isLoading
          ? _c(
              "div",
              { staticClass: "form-login__main__input" },
              [
                _c("heading-normal", { attrs: { small: "" } }, [
                  _vm._v(_vm._s(_vm.password))
                ]),
                _c("input-password", {
                  attrs: { text: _vm.loginInfo.password },
                  on: { emit: _vm.savePassword }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.isLoading
          ? _c("p", { staticClass: "form-login__main__loadtext" }, [
              _vm._v("ログインしています...")
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "form-login__main__button" },
          [
            _c(
              "button-normal",
              {
                attrs: {
                  disabled: !(_vm.loginInfo.userName && _vm.loginInfo.password),
                  loading: _vm.isLoading,
                  large: ""
                },
                on: {
                  emit: function($event) {
                    return _vm.$emit("emit-login", _vm.loginInfo)
                  }
                }
              },
              [_vm._v("ログイン")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }