var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "review-star-multi" }, [
    _c("div", { staticClass: "review-star-multi__star" }, [
      _c(
        "p",
        { staticClass: "review-star-multi__star__front", style: _vm.setWidth },
        [_vm._v("★★★")]
      ),
      _c("p", { staticClass: "review-star-multi__star__back" }, [_vm._v("★★★")])
    ]),
    _c(
      "p",
      { class: "review-star-multi__score " + (_vm.white ? "white" : "") },
      [_vm._v(_vm._s(_vm.setDisplayScore))]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }