<template>
  <div class='setting'>
    <layout-header />
    <div class='setting__main center'>
      <section-menu
        :userInfo='userInfo'
        :pageList='createTitleList'
        @emit-menu-index='movePageMenu'
        :defaultSelectId='0'
      />
      <div class='setting__main__right'>
        <canvas id='canvas' />
        <heading-normal class='setting__main__right__top' white large>設定_</heading-normal>
        <div class='setting__main__right__bottom'>
          <div class='setting__main__right__bottom__info'>
            <div class='setting__main__right__bottom__info__top'>
              <div class='setting__main__right__bottom__info__top__left'>
                <div class='setting__main__right__bottom__info__top__preview'>
                  <p class='setting__main__right__bottom__info__top__preview__text'>
                    プロフィール画像<br>プレビュー
                  </p>
                  <img
                    v-if='upload.avatar'
                    :src='upload.avatar'
                    class='setting__main__right__bottom__info__top__preview__img'
                    alt='プロフィール画像'
                  >
                </div>
              </div>
              <div class='setting__main__right__bottom__info__top__right'>
                  <input-file
                    name='avatar'
                    :title='inputFile.title'
                    :description='inputFile.description'
                    :accept='setAvatarAccept'
                    :errorMsg='errorMsg.avatar'
                    :resetCheck='upload.avatar === null ? true: false'
                    @emit='saveUploadImage'
                  />
                <input-text
                  class='setting__main__right__bottom__info__top__right__username'
                  name='userName'
                  :text='userInfo.profile.userName'
                  :maxLength='rule.userName.maxLength'
                  :errorMsg='errorMsg.userName'
                  @emit='saveUploadText'
                />
              </div>
            </div>
            <div class='setting__main__right__bottom__info__intro'>
              <heading-normal yellow>自己紹介_</heading-normal>
              <input-textarea
                class='setting__main__right__bottom__info__intro__form'
                name='introduction'
                :text='userInfo.profile.introduction'
                :maxLength='rule.introduction.maxLength'
                :rows='rule.introduction.rows'
                :errorMsg='errorMsg.introduction'
                @emit='saveUploadText'
              />
            </div>
            <div class='setting__main__right__bottom__info__botton'>
              <button-normal
                :disabled='!isValid'
                :loading='isLoading'
                @emit='execUpdateProfile'
                large
              >登録・更新する</button-normal>
            </div>
            <button class='setting__main__right__bottom__info__delete'
              @click='isActive = !isActive'
              >アカウントを削除される方はこちら</button>
            <modal-normal
              v-if='isActive'
              :title='modalText.title'
              :text='modalText.text'
              :acceptBtn='modalText.acceptBtn'
              :cancelBtn='modalText.cancelBtn'
              @emit='modalEvent'/>
          </div>
        </div>
      </div>
    </div>
    <layout-footer />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import helper from '@/mixins/general/methods/helper';
import pageList from '@/mixins/general/data/userInfoPageList';
import { LayoutHeader, LayoutFooter } from '@/components/layouts/general/';
import { ButtonNormal } from '@/components/atoms/button';
import { HeadingNormal } from '@/components/atoms/heading';
import { InputText, InputTextarea, InputFile } from '@/components/atoms/input';
import { ModalNormal } from '@/components/molecules/other';
import SectionMenu from '@/components/molecules/general/userInfo/SectionMenu';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    SectionMenu,
    ButtonNormal,
    HeadingNormal,
    InputText,
    InputTextarea,
    InputFile,
    ModalNormal,
  },
  data() {
    return {
      inputFile: {
        title: 'プロフィール画像',
        description: '最大5MB',
      },
      imgSize: {
        width: 0,
        height: 0,
      },
      isActive: false,
      isLoading: false,
      modalText: {
        title: 'アカウントの削除',
        text: 'アカウントを削除すると２度と復活できませんがよろしいですか？',
        acceptBtn: '削除する',
        cancelBtn: 'キャンセル',
      },
      rule: {
        avatar: {
          accept: ['image/png', 'image/jpeg'],
          maxSize: 5000000,
        },
        userName: {
          maxLength: 100,
        },
        introduction: {
          maxLength: 1000,
          rows: 10,
        },
      },
      upload: {
        avatar: null,
        userName: null,
        introduction: null,
      },
      errorMsg: {
        avatar: '',
        userName: '',
        introduction: '',
      },
    };
  },
  created() {
    // 学習日数取得
    this.loadCount().then((res) => {
      if (res === 401) {
        this.$store.commit('user/logout');
        this.movePage('Login', '', { url: this.$route.path });
      }
      if (!res) this.movePage('Error');
    });
  },
  mixins: [helper, pageList],
  computed: {
    ...mapState('user', ['userInfo']),
    createTitleList() {
      const titleList = this.pageList.map((page) => page.title);
      return titleList;
    },
    setAvatarAccept() {
      return this.rule.avatar.accept.join(',');
    },
    isValid() {
      // アップロード情報Nullチェック
      if (this.upload.avatar === null
          && this.upload.userName === null
          && this.upload.introduction === null) {
        return false;
      }
      // エラーメッセージ空チェック
      if (this.errorMsg.avatar === ''
          && this.errorMsg.userName === ''
          && this.errorMsg.introduction === '') {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions('user', ['loadCount', 'updateProfile']),
    movePageMenu(index) {
      const { name } = this.pageList[index];
      this.$router.push({ name }, () => {});
    },
    saveUploadText(content, target) {
      this.upload[target] = content;
    },
    saveUploadImage(content, target) {
      // errorMsg初期化
      this.errorMsg.avatar = '';

      // アップロードキャンセル
      if (!content) {
        this.upload[target] = null;
        return;
      }

      // バリデーション
      if (content.size > this.rule.avatar.maxSize) {
        // ファイルサイズ
        this.errorMsg.avatar = `${this.rule.avatar.maxSize / 1000000}MB以上のファイルはアップロードできません`;
        return;
      } if (!this.rule.avatar.accept.includes(content.type)) {
        // 拡張子チェック
        this.errorMsg.avatar = '拡張子がpng、jpg、jpeg以外のファイルはアップロードできません';
        return;
      }

      // 通常処理
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        const canvas = document.getElementById('canvas');
        const ctx = canvas.getContext('2d');
        const readerThis = this;
        const cut = {
          width: 0,
          height: 0,
        };
        img.onerror = function () {
          readerThis.errorMsg.avatar = 'ファイルが破損している可能性があります';
        };
        img.onload = function () {
          let canvasSize = 0;
          if (img.width > img.height) {
            cut.width = img.width - img.height;
            canvasSize = img.height;
          } else {
            cut.height = img.height - img.width;
            canvasSize = img.width;
          }
          canvas.width = canvasSize;
          canvas.height = canvasSize;
          ctx.fillStyle = '#fff';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, cut.width / 2, cut.height / 2, canvasSize, canvasSize,
            0, 0, canvasSize, canvasSize);
          let imgData = canvas.toDataURL('image/jpeg', 1);
          const blob = readerThis.base64ToBlob(imgData);
          const capacityRatio = 1000000 / blob.size;
          imgData = canvas.toDataURL('image/jpeg', capacityRatio);
          readerThis.upload[target] = imgData;
        };
      };
      reader.readAsDataURL(content);
    },
    base64ToBlob(base64) {
      const base64Data = base64.split(',')[1];
      const data = window.atob(base64Data);
      const buff = new ArrayBuffer(data.length);
      const arr = new Uint8Array(buff);
      let i;
      let dataLen;
      for (i = 0, dataLen = data.length; i < dataLen; i += 1) {
        arr[i] = data.charCodeAt(i);
      }
      const blob = new Blob([arr], { type: 'image/jpeg' });
      return blob;
    },
    async execUpdateProfile() {
      try {
        this.isLoading = !this.isLoading;
        if (!this.isValid) throw new Error();

        const res = await this.updateProfile(this.upload);
        if (res === 401) {
          this.$store.commit('user/logout');
          this.movePage('Login', '', { url: this.$route.path });
        } else if (await res) {
          this.showNoticeBar('success', '基本情報の更新を完了しました');
          this.upload.avatar = null;
          this.upload.userName = null;
          this.upload.introduction = null;
        } else {
          throw new Error();
        }
        this.isLoading = !this.isLoading;
      } catch (e) {
        this.isLoading = !this.isLoading;
        this.showNoticeBar('error', '基本情報の更新に失敗しました');
      }
    },
    async modalEvent(data) {
      this.isActive = !this.isActive;
      if (data) {
        try {
          this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
          await this.$http.delete('/api/auth/user');
          this.$store.commit('user/logout');
          this.movePage('Top');
        } catch (e) {
          this.isActive = !this.isActive;
          if (e.response !== undefined && e.response.status === 401) {
            this.unAuthenticate();
          } else {
            this.showNoticeBar('error', 'アカウントの削除に失敗しました');
          }
        }
      }
    },
  },
  watch: {
    // リアルタイムバリデーション
    'upload.userName': function (to) {
      if (to !== null) {
        if (to.length > this.rule.userName.maxLength) {
          this.errorMsg.userName = 'ユーザー名の最大文字数を超えています';
        } else if (to.length === 0) {
          this.errorMsg.userName = 'ユーザー名を入力してください';
        } else {
          this.errorMsg.userName = '';
        }
      }
    },
    'upload.introduction': function (to) {
      if (to !== null) {
        if (to.length > this.rule.introduction.maxLength) {
          this.errorMsg.introduction = '自己紹介の最大文字数を超えています';
        } else {
          this.errorMsg.introduction = '';
        }
      }
    },
  },
};
</script>

<style scoped>
.setting {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #4CAF50;
}

.setting__main {
  width: auto;
  display: flex;
  align-items: flex-start;
  margin-top: 40px;
  margin-bottom: 80px;
}

.setting__main__right {
  width: 700px;
  margin-left: 20px;
  margin-top: 50px;
}

.setting__main__right__top {
  padding-bottom: 10px;
}

.setting__main__right__bottom {
  background-color: #fff;
}

.setting__main__right__bottom__info__top {
  display: flex;
  padding: 40px;
  border-bottom: 1px solid #F2EEEE;
}

.setting__main__right__bottom__info__top__preview {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background-color: #F1EFFC;
  position: relative;
}

.setting__main__right__bottom__info__top__preview__text {
  text-align: center;
  padding-top: 100px;
  font-size: 12px;
}

.setting__main__right__bottom__info__top__preview__img {
  display: inline-block;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  position: absolute;
  top: 0;
}

.setting__main__right__bottom__info__top {
  display: flex;
  align-items: center;
}

.setting__main__right__bottom__info__top__right {
  margin-left: 20px;
}

.setting__main__right__bottom__info__top__right__username  {
  margin-top: 30px;
  width: 300px;
}

.setting__main__right__bottom__info__intro {
  padding: 40px;
  border-bottom: 1px solid #F2EEEE;
}

.setting__main__right__bottom__info__intro__form {
  margin-top: 10px;
}

.setting__main__right__bottom__info__botton {
  padding: 30px 40px;
  text-align: center;
  border-bottom: 1px solid #F2EEEE;
}
.setting__main__right__bottom__info__delete {
  font-size: 12px;
  padding: 15px 40px;
  color: #CBCBCB;
}
#canvas {
  display: none;
}
</style>
