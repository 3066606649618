<template>
  <div class='lesson-attend'>
    <lesson-header
      :lessonInfo='currentLesson'
      :userInfo='userInfo'
      :chapterNum='params.num'
      :isLoading='isLoading'
      :previewMode='previewMode'
      :adminPreviewMode='adminPreviewMode'
      @emit-chapter-list='active("modalChapterList")'
      @emit-reset='active("modalReset")'
      @emit-compare-source='active("modalCompareSource")'
      @emit-move-confirm='$router.push({ path: $route.query.path })'
      @emit-prev-page='movePageChapter(
        "LessonAttend", { id: currentLesson.id, num: params.num - 1})'
      @emit-next-page='movePageChapter("LessonAttend",
        { id: currentLesson.id, num: params.num + 1})'
      @emit-admin-code-load='active("adminCodeLoad")'
    />
    <main class='lesson-attend__main' v-if='!isLoading.loadChapter'>
      <multipane
        class='lesson-attend__main__vertical-panes'
        layout='vertical'
        @paneResizeStop='saveWidth'
        @paneResizeStart='active("resizer")'
      >
        <lesson-markdown
          id='markdown'
          :class='addClass("markdown")'
          :style='addMarkdownStyle'
          :markdown='attendInfo.chapterContent.markdown'
        />
        <multipane-resizer class='lesson-attend__main__vertical-panes__pane' />
        <lesson-editor
          :style='addEditorStyle'
          id='editor'
          :class='addClass("editor")'
          :sourceList='attendInfo.chapterContent.source'
          :resetSourceList='attendInfo.chapterContent.resetSource'
          :isActiveCodeReset='isActive.codeReset'
          :runButtonClient='lessonLang.js'
          :runButtonServer='lessonLang.php'
          :isActiveGeneratedServerEnv='isActive.generatedServerEnv'
          @emit-source='saveEditSource'
          @emit-reset='active("codeReset")'
          @emit-code-run='active("codeRun")'
        />
        <multipane-resizer class="lesson-attend__main__vertical-panes__pane" />
        <!-- JSのみレッスン専用処理 -->
        <div :class='addClass("output")'
          :style='{ flexGrow: 1 }'>
          <div
            class='lesson-attend__main__vertical-panes__iframe__overlay'
            v-if='isActive.resizer && (lessonLang.htmlcss || lessonLang.php)'/>
          <div v-if='showOutput'>
            <lesson-output-js
              v-if='lessonLang.js && !lessonLang.htmlcss'
              :editSource='attendInfo.editSource[0].source'
              :sampleSource='attendInfo.chapterContent.source[0].sample'
              :isActiveCodeRun='isActive.codeRun'
              @emit-code-run='active("codeRun")'
            />
            <!-- HTML/CSSレッスン専用処理 -->
            <lesson-output-html-css
              v-if='lessonLang.htmlcss && !lessonLang.js'
              :editSource='attendInfo.editSource'
              :sampleSource='attendInfo.chapterContent.source'
            />
            <!-- HTML/CSS/JSレッスン専用処理 -->
            <lesson-output-html-css-js
              v-if='lessonLang.htmlcss && lessonLang.js'
              :editSource='attendInfo.editSource'
              :sampleSource='attendInfo.chapterContent.source'
              :isActiveCodeRun='isActive.codeRun'
              @emit-code-run='active("codeRun")'
            />
            <!-- PHPのみレッスン専用処理 -->
            <lesson-output-php
              v-if='lessonLang.php'
              :editSource='attendInfo.editSource[0].source'
              :sampleSource='attendInfo.chapterContent.source[0].sample'
              :isActiveCodeRun='isActive.codeRun'
              :isActiveDatabaseReset='isActive.databaseReset'
              @emit-code-run='active("codeRun")'
              @emit-database-reset='active("databaseReset")'
              @emit-generated-server-env='active("generatedServerEnv")'
            />
          </div>
          <div
            class='lesson-attend__main__vertical-panes__output__codeload'
            v-if='adminPreviewMode && !isActive.adminCodeLoad'
          >
            <i class='fas fa-exclamation-triangle' />
            <p>悪意のあるコードが実行される可能性があるため十分に確認したのちCodeLoadボタンを押しコードを実行してください</p>
          </div>
        </div>
      </multipane>
      <div class='lesson-attend__main__modal'>
        <modal-compare-source
          v-if='isActive.modalCompareSource'
          :defaultSourceList='attendInfo.chapterContent.source'
          :editSourceList='attendInfo.editSource'
          @emit-hide='active("modalCompareSource")'
        />
        <modal-normal
          v-if='isActive.modalReset'
          :title='modalInfo.reset.title'
          :text='modalInfo.reset.text'
          :acceptBtn='modalInfo.reset.acceptBtn'
          :cancelBtn='modalInfo.reset.cancelBtn'
          @emit='codeReset'
        />
        <modal-normal
          v-if='isActive.modalPreviewComplete'
          :title='modalInfo.previewComplete.title'
          :text='modalInfo.previewComplete.text'
          :cancelBtn='modalInfo.previewComplete.cancelBtn'
          :acceptBtn='modalInfo.previewComplete.acceptBtn'
          @emit='closePreview'
          disabledOverlay
        />
        <modal-normal
          v-if='isActive.modalAdminPreviewComplete'
          :title='modalInfo.adminPreviewComplete.title'
          :text='modalInfo.adminPreviewComplete.text'
          :cancelBtn='modalInfo.adminPreviewComplete.cancelBtn'
          :acceptBtn='modalInfo.adminPreviewComplete.acceptBtn'
          @emit='closeAdminPreview'
          disabledOverlay
        />
        <modal-normal
          v-if='isActive.modalFreeRangeComplete'
          :title='modalInfo.freeRangeComplete.title'
          :text='modalInfo.freeRangeComplete.text'
          :cancelBtn='modalInfo.freeRangeComplete.cancelBtn'
          :acceptBtn='modalInfo.freeRangeComplete.acceptBtn'
          @emit='closeFreeRange'
          disabledOverlay
        />
        <modal-custom v-if='isActive.modalChapterList' @emit-hide='active("modalChapterList")'>
          <div class='lesson-attend__main__modal__chapter'>
            <div class='lesson-attend__main__modal__chapter__top'>
              <h3>{{ currentLesson.title }}</h3>
              <button-normal @emit='movePage("LessonDetail", { id: currentLesson.id })'>
                レッスン詳細
              </button-normal>
            </div>
            <ul class='lesson-attend__main__modal__chapter__bottom'>
              <li v-for='(title, index) in currentLesson.chapterTitle' :key='title.id'>
                <list-chapter-mini
                  v-if='!freeRangeMode'
                  :num='index'
                  :id='currentLesson.id'
                  :title='title'
                  :complete='attendInfo.completeChapter[index].complete'
                  :current='params.num - 1 === index? true : false'
                  @emit-move-page='movePage("LessonAttend",
                  { id: currentLesson.id, num: index + 1 })'
                />
                <list-chapter-mini
                  v-if='freeRangeMode'
                  :num='index'
                  :id='currentLesson.id'
                  :title='title'
                  :current='params.num - 1 === index? true : false'
                  hideCheck
                  @emit-move-page='movePage("LessonAttendFreeRange",
                  { id: currentLesson.id, num: index + 1 })'
                />
              </li>
            </ul>
          </div>
        </modal-custom>
        <modal-lesson-complete
          v-if='isActive.modalLessonComplete'
          @emit-evaluation='saveEvaluation'
          @emit-move-twitter='movePageTwitter'
          @emit-move-detail='submitEvaluation(currentLesson.id),
            movePage("LessonDetail", { id: currentLesson.id })'
        />
        <modal-custom
          v-if='isActive.modalNeedComplete'
          @emit-hide='active("modalNeedComplete")'>
          <div class='lesson-attend__main__modal__needcomplete'>
            <div class='lesson-attend__main__modal__needcomplete__top'>
              <h3>{{ modalInfo.needComplete.title }}</h3>
              <p>{{ modalInfo.needComplete.text }}</p>
            </div>
            <button-normal @emit='movePage("LessonDetail", { id: currentLesson.id })' large>
              {{ modalInfo.needComplete.acceptBtn }}
            </button-normal>
          </div>
        </modal-custom>
      </div>
      <img
        class='lesson-attend__main__background__first'
        src='@/assets/img/background-top-pink.svg'
        alt=''
      />
      <img
        class='lesson-attend__main__background__second'
        src='@/assets/img/background-top-pink.svg'
        alt=''
      />
      <img
        class='lesson-attend__main__background__third'
        src='@/assets/img/background-top-pink.svg'
        alt=''
      />
    </main>
    <div class='lesson-attend__load' v-if='this.isLoading.loadChapter' />
  </div>
</template>

<script>
import axios from 'axios';
import { mapState, mapActions, mapMutations } from 'vuex';
import helper from '@/mixins/general/methods/helper';
import { Multipane, MultipaneResizer } from 'vue-multipane';
import {
  LessonEditor, LessonHeader, LessonOutputJs, LessonOutputHtmlCss,
  LessonOutputHtmlCssJs, LessonOutputPhp, ModalCompareSource, ModalLessonComplete,
} from '@/components/molecules/general/lessonAttend';
import { ModalNormal, ModalCustom } from '@/components/molecules/other';
import { LessonMarkdown } from '@/components/atoms/lesson';
import { ButtonNormal } from '@/components/atoms/button';
import { ListChapterMini } from '@/components/atoms/list';

export default {
  components: {
    LessonEditor,
    LessonHeader,
    LessonOutputJs,
    LessonOutputHtmlCss,
    LessonOutputHtmlCssJs,
    LessonOutputPhp,
    ModalCompareSource,
    ModalLessonComplete,
    ModalNormal,
    ModalCustom,
    LessonMarkdown,
    ButtonNormal,
    ListChapterMini,
    Multipane,
    MultipaneResizer,
  },
  data() {
    return {
      previewMode: false,
      adminPreviewMode: false,
      freeRangeMode: false,
      lessonLang: {
        htmlcss: false,
        js: false,
        php: false,
      },
      isActive: {
        modalReset: false,
        modalCompareSource: false,
        modalChapterList: false,
        modalLessonComplete: false,
        modalNeedComplete: false,
        modalPreviewComplete: false,
        modalAdminPreviewComplete: false,
        modalFreeRangeComplete: false,
        codeRun: false,
        codeReset: false,
        databaseReset: false,
        generatedServerEnv: false,
        adminCodeLoad: false,
        resizer: false,
      },
      isLoading: {
        loadChapter: false,
      },
      modalInfo: {
        reset: {
          title: 'リセット',
          text: 'このチャプターで入力したソースコードをリセットします。よろしいですか？',
          acceptBtn: 'リセットする',
          cancelBtn: 'キャンセル',
        },
        lessonComplete: {
          title: 'Congrations!!',
          text: 'レッスン修了おめでとうございます!!',
          sns: 'SNSでシェアする',
          evaluation: '評価を選ぶ',
          acceptBtn: 'レッスン詳細に戻る',
        },
        needComplete: {
          title: '未クリアのチャプターがあります',
          text: 'レッスン詳細画面に戻り確認してみましょう',
          acceptBtn: 'レッスン詳細に戻る',
        },
        previewComplete: {
          title: '最後のチャプターです',
          text: '動作が問題なければ確認画面に戻りましょう',
          cancelBtn: 'キャンセル',
          acceptBtn: 'プレビューを閉じる',
        },
        adminPreviewComplete: {
          title: '最後のチャプターです',
          text: '動作が問題なければウィンドウを閉じましょう',
          cancelBtn: 'キャンセル',
          acceptBtn: 'プレビューを閉じる',
        },
        freeRangeComplete: {
          title: '無料枠最後のチャプターです',
          text: 'レッスン詳細画面に戻り、内容を確認しましょう',
          cancelBtn: 'キャンセル',
          acceptBtn: 'レッスン詳細に戻る',
        },
      },
      attendInfo: {
        completeChapter: [],
        editSource: [],
        evaluation: null,
        chapterContent: {
          markdown: '',
          source: [],
          resetSource: [],
        },
        autoSave: {
          saveCounter: 0,
          execCounter: 0,
          countLimit: 4,
          interval: '',
        },
      },
      params: {
        lessonId: Number(this.$route.params.id),
        num: Number(this.$route.params.num),
      },
    };
  },
  created() {
    if (this.$route.name === 'LessonPreview') {
      this.previewMode = true;
      this.loadPreviewChapter();
    } else if (this.$route.name === 'AdminLessonPreview') {
      this.adminPreviewMode = true;
      this.loadComplete(this.params.lessonId);
      this.loadAdminPreviewChapter();
    } else if (this.$route.name === 'LessonAttendFreeRange') {
      this.freeRangeMode = true;
      this.loadFreeRangeChapter();
    } else {
      this.loadComplete(this.params.lessonId);
      this.loadChapter();
    }
    this.lockScroll();
  },
  mixins: [helper],
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapState('lessonAttend', ['currentLesson']),
    ...mapState('lessonAttend', ['setting']),
    ...mapState('lessonCreate', ['postLessonInfo']),
    showOutput() {
      // adminPreview時、遷移直後にコード読み込みを行わないよう修正
      if (this.adminPreviewMode && !this.isActive.adminCodeLoad) return false;
      return true;
    },
    addMarkdownStyle() {
      return {
        width: `${this.setting.areaWidth.markdown}%`,
      };
    },
    addEditorStyle() {
      return {
        width: `${this.setting.areaWidth.editor}%`,
      };
    },
    setTweetText() {
      const lessonTitle = this.currentLesson.title.length > 40 ? `${this.currentLesson.title.substr(0, 40)}...` : this.currentLesson.title;
      const text = `${'🍑 manabibaでプログラミングを学習中！\n'
        + ' \n'
        + '「'}${lessonTitle}」をクリアしました！\n`
        + `https://donbler.com/lessons/${this.currentLesson.id}\n`
        + ' \n'
        + '#manabiba';
      return text;
    },
  },
  methods: {
    ...mapActions('user', ['loadPurchaseLesson', 'submitContinue']),
    ...mapActions('lessonCreate', ['execDeletePostLesson']),
    ...mapActions('lessonAttend', ['updateUsercode']),
    ...mapMutations('lessonAttend', ['saveAreaWidth']),
    saveWidth(pane, container, size) {
      const changeAreaName = pane.getAttribute('id');
      const parseSize = parseInt(size, 10);
      this.saveAreaWidth({
        changeAreaName,
        parseSize,
      });
      this.active('resizer');
    },
    active(target) {
      this.isActive[target] = !this.isActive[target];
    },
    loading(target) {
      this.isLoading[target] = !this.isLoading[target];
    },
    movePageTwitter() {
      const msg = encodeURIComponent(this.setTweetText);
      const url = `https://twitter.com/intent/tweet?text=${msg}`;
      window.open(url);
    },
    addClass(target) {
      // jsパターン
      if (this.lessonLang.js) return `lesson-attend__main__vertical-panes--js__${target}`;
      // htmlcssパターン
      return `lesson-attend__main__vertical-panes--htmlcss__${target}`;
    },
    closePreview(event) {
      if (event) {
        window.close();
      } else {
        this.active('modalPreviewComplete');
      }
    },
    closeAdminPreview(event) {
      if (event) {
        window.close();
      } else {
        this.active('modalAdminPreviewComplete');
      }
    },
    closeFreeRange(event) {
      if (event) {
        this.movePage('LessonDetail', { id: this.currentLesson.id });
      } else {
        this.active('modalFreeRangeComplete');
      }
    },
    async loadChapter() {
      this.loading('loadChapter');
      // チャプターコンテンツ(markdown, source)読み込み
      const chapterContent = await axios.get(this.currentLesson.content)
        .then((allChapterContent) => allChapterContent.data[this.params.num - 1])
        .catch(() => this.movePage('Error'));
      this.attendInfo.chapterContent.markdown = chapterContent.markdown;
      this.attendInfo.chapterContent.resetSource = chapterContent.source;

      // ユーザー入力コードの存在チェック
      if (this.currentLesson.usercode[this.params.num] === undefined) {
        this.attendInfo.chapterContent.source = chapterContent.source;
      } else {
        const date = new Date();
        const usercodeUrl = `${this.currentLesson.usercode[this.params.num]}?${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getSeconds()}`;
        await axios.get(usercodeUrl).then((usercode) => {
          this.attendInfo.chapterContent.source = chapterContent.source.map((source) => ({
            filename: source.filename,
            sample: source.sample,
            assignment: usercode.data.filter((codeInfo) => codeInfo.filename
            === source.filename)[0].source,
          }));
        }).catch(() => {
          this.attendInfo.chapterContent.source = chapterContent.source;
        });
      }

      // ソースコードをeditSourceにセット
      chapterContent.source.forEach((val) => {
        const lang = val.filename.split('.')[1];
        if (lang === 'html') {
          this.lessonLang.htmlcss = true;
        } else if (lang === 'js') {
          this.lessonLang.js = true;
        } else if (lang === 'php') {
          this.lessonLang.php = true;
        }
        this.attendInfo.editSource.push({ filename: val.filename, source: '' });
      });
      this.loading('loadChapter');
    },
    async loadFreeRangeChapter() {
      this.loading('loadChapter');
      // チャプターコンテンツ(markdown, source)読み込み
      const allChapterContent = await axios.get(this.currentLesson.freeContent);
      const chapterContent = allChapterContent.data[this.params.num - 1];
      this.attendInfo.chapterContent.markdown = chapterContent.markdown;
      this.attendInfo.chapterContent.resetSource = chapterContent.source;
      this.attendInfo.chapterContent.source = chapterContent.source;

      // ソースコードをeditSourceにセット
      chapterContent.source.forEach((val) => {
        const lang = val.filename.split('.')[1];
        if (lang === 'html') {
          this.lessonLang.htmlcss = true;
        } else if (lang === 'js') {
          this.lessonLang.js = true;
        } else if (lang === 'php') {
          this.lessonLang.php = true;
        }
        this.attendInfo.editSource.push({ filename: val.filename, source: '' });
      });
      this.loading('loadChapter');
    },
    loadPreviewChapter() {
      this.loading('loadChapter');
      // チャプターコンテンツ(markdown, source)読み込み
      const chapterContent = this.postLessonInfo.content[this.params.num - 1];
      this.attendInfo.chapterContent.markdown = chapterContent.markdown;
      this.attendInfo.chapterContent.source = chapterContent.source;
      this.attendInfo.chapterContent.resetSource = chapterContent.source;

      // ソースコードをeditSourceにセット
      chapterContent.source.forEach((val) => {
        const lang = val.filename.split('.')[1];
        if (lang === 'html') {
          this.lessonLang.htmlcss = true;
        } else if (lang === 'js') {
          this.lessonLang.js = true;
        } else if (lang === 'php') {
          this.lessonLang.php = true;
        }
        this.attendInfo.editSource.push({ filename: val.filename, source: '' });
      });
      this.loading('loadChapter');
    },
    async loadAdminPreviewChapter() {
      this.loading('loadChapter');
      // チャプターコンテンツ(markdown, source)読み込み
      const allChapterContent = await axios.get(this.currentLesson.content);
      const chapterContent = allChapterContent.data[this.params.num - 1];
      this.attendInfo.chapterContent.markdown = chapterContent.markdown;
      this.attendInfo.chapterContent.resetSource = chapterContent.source;
      this.attendInfo.chapterContent.source = chapterContent.source;

      // ソースコードをeditSourceにセット
      chapterContent.source.forEach((val) => {
        const lang = val.filename.split('.')[1];
        if (lang === 'html') {
          this.lessonLang.htmlcss = true;
        } else if (lang === 'js') {
          this.lessonLang.js = true;
        } else if (lang === 'php') {
          this.lessonLang.php = true;
        }
        this.attendInfo.editSource.push({ filename: val.filename, source: '' });
      });
      this.loading('loadChapter');
    },
    // LessonAttend->LessonAttendへの画面遷移用メソッド
    async movePageChapter(name, params) {
      if (this.previewMode) {
        if (params.num > this.currentLesson.chapterTitle.length) {
          this.active('modalPreviewComplete');
        } else {
          this.movePage('LessonPreview', { num: params.num });
        }
      } else if (this.adminPreviewMode) {
        if (params.num > this.currentLesson.chapterTitle.length) {
          this.active('modalAdminPreviewComplete');
        } else {
          this.movePage('AdminLessonPreview', { num: params.num });
        }
      } else if (this.freeRangeMode) {
        if (params.num > this.currentLesson.chapterTitle.length) {
          this.active('modalFreeRangeComplete');
        } else {
          this.movePage('LessonAttendFreeRange', { num: params.num });
        }
      } else {
        // 次のChapterに進む場合、chapterComplete情報を更新
        if (params.num > this.params.num) {
          const res = await this.updateChapterComplete({
            lessonId: this.params.lessonId,
            chapterId: this.params.num,
          });
          if (res === 401) {
            this.$store.commit('user/logout');
            this.movePage('Login', '', { url: this.$route.path });
          } else if (res) {
            await this.loadPurchaseLesson();
            await this.loadComplete(this.params.lessonId);
          }
        }

        // 画面遷移・モーダル系処理
        if (await params.num > this.attendInfo.completeChapter.length) {
          const notComplete = await this.attendInfo.completeChapter
            .filter((chapter) => chapter.complete === false);
          if (await notComplete.length === 0) {
            this.isActive.modalLessonComplete = await true;
          } else {
            this.isActive.modalNeedComplete = await true;
          }
        } else {
          await this.movePage('LessonAttend', params);
        }
      }
    },
    // 入力されたソースコードのリセット
    codeReset(event) {
      this.isActive.modalReset = false;
      if (event) {
        if (this.lessonLang.php) this.active('databaseReset');
        this.active('codeReset');
        this.active('codeRun');
      }
    },
    loadComplete(lessonId) {
      const target = this.userInfo.lesson.purchase
        .filter((lessonPurchase) => lessonPurchase.id === lessonId)[0];
      if (target !== undefined) {
        this.attendInfo.completeChapter = [];
        target.chapter.forEach((chapter, index) => {
          this.attendInfo.completeChapter.push({
            chapterId: index + 1,
            complete: Boolean(target.inProgress.completeChapter.indexOf(index + 1) + 1),
          });
        });
      }
    },
    lockScroll() {
      const html = document.querySelector('html');
      const css = html.style;
      if (css.overflow === '') {
        css.cssText = 'position: fixed; width: 100%; overflow: hidden;';
      } else {
        css.cssText = 'position: static; overflow: none;';
      }
    },
    // エディタに入力されたコードを格納
    saveEditSource(filename, source) {
      // ファイル名からindex割り出し
      const index = this.attendInfo.editSource.findIndex((edit) => edit.filename === filename);
      this.attendInfo.editSource[index].source = source;
    },
    // レッスン評価をdataに格納
    saveEvaluation(num) {
      this.attendInfo.evaluation = num;
    },
    // レッスンの評価を送信
    async submitEvaluation(lessonId) {
      if (this.attendInfo.evaluation !== null) {
        this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
        try {
          this.$http.put(`/api/auth/lessons/evaluation/${lessonId}`, { evaluation: this.attendInfo.evaluation });
        } catch (e) {
          if (e.response !== undefined && e.response.status === 401) {
            this.unAuthenticate();
          } else {
            this.movePage('Error');
          }
        }
      }
    },
    // ChapterのComplete情報を更新
    async updateChapterComplete(progress) {
      // Complete情報送信
      try {
        this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
        await this.$http.put(`/api/auth/lessons/progress/${progress.lessonId}`, { sequence: progress.chapterId });
        return true;
      } catch (e) {
        if (e.response.status === 401) return e.response.status;
        this.movePage('Error');
        return false;
      }
    },
    async saveUsercode() {
      const target = this.userInfo.lesson.purchase
        .filter((lessonPurchase) => lessonPurchase.id === this.params.lessonId)[0];
      await this.updateUsercode({
        token: this.userInfo.token,
        lessonId: this.params.lessonId,
        num: this.params.num,
        editSource: this.attendInfo.editSource,
        updatedDate: target.updatedDate,
      });
    },
  },
  watch: {
    async $route(to) {
      await this.$router.go({ name: to.name, params: { id: to.params.id, num: to.params.num } });
    },
    'attendInfo.editSource': {
      handler() {
        if (!this.previewMode && !this.adminPreviewMode && !this.freeRangeMode) {
          if (this.attendInfo.autoSave.execCounter > 1) {
            this.attendInfo.autoSave.saveCounter = 0;
            clearInterval(this.attendInfo.autoSave.interval);
            this.attendInfo.autoSave.interval = setInterval(() => {
              this.attendInfo.autoSave.saveCounter += 1;
            }, 1000);
          }
          this.attendInfo.autoSave.execCounter += 1;
        }
      },
      deep: true,
    },
    'attendInfo.autoSave.saveCounter': function () {
      if (this.attendInfo.autoSave.saveCounter === this.attendInfo.autoSave.countLimit) {
        clearInterval(this.attendInfo.autoSave.interval);
        this.saveUsercode();
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    // previewModeの場合、確認ページ以外に遷移する場合アップロードしたレッスンを破棄
    if (!this.previewMode) {
      next();
    } else if (to.name === 'LessonCreateConfirm' || to.name === 'LessonUpdateConfirm') {
      next();
    } else {
      this.execDeletePostLesson();
      next();
    }
  },
  destroyed() {
    this.lockScroll(); // スクロール固定解除
    // LessonAttendから離脱する際、最後に滞在していたChapterIdを送信する
    if (!this.previewMode && !this.freeRangeMode) {
      this.submitContinue({
        lessonId: this.params.lessonId,
        chapterId: this.params.num,
      }).then((res) => {
        if (res === 401) {
          this.$store.commit('user/logout');
          this.movePage('Login', '', { url: this.$route.path });
        }
      });
    }
  },
};
</script>

<style scoped>
.lesson-attend__main {
  width: 100%;
  padding: 0 10px 10px 10px;
  display: flex;
  height: calc(100vh - 60px);
}

.lesson-attend__load {
  height: calc(100vh - 60px);
}

.lesson-attend__main__vertical-panes {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 2;
}
.lesson-attend__main__vertical-panes__pane {
  width: 20px!important;
  z-index: 999;
}

.lesson-attend__main__vertical-panes__iframe__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

.lesson-attend__main__vertical-panes--js__markdown {
  width: 40%;
  min-width: 100px;
  max-width: 100%;
  z-index: 2;
}
.lesson-attend__main__vertical-panes--js__editor {
  width: 35%;
  min-width: 220px;
  max-width: 100%;
  /* padding: 0 10px; */
  z-index: 2;
}
.lesson-attend__main__vertical-panes--js__output {
  min-width: 10%;
  flex-grow: 1;
  z-index: 2;
}
.lesson-attend__main__vertical-panes--htmlcss__markdown {
  width: 30%;
  min-width: 100px;
  max-width: 100%;
  z-index: 2;
}
.lesson-attend__main__vertical-panes--htmlcss__editor {
  width: 30%;
  min-width: 200px;
  max-width: 100%;
  z-index: 2;
}
.lesson-attend__main__vertical-panes--htmlcss__output {
  min-width: 10%;
  flex-grow: 1;
  z-index: 2;
  position: relative;
}

.lesson-attend__main__vertical-panes__output__codeload {
  background-color: #E74860;
  color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

.lesson-attend__main__vertical-panes__output__codeload i {
  font-size: 140px;
  margin-bottom: 15px;
}

.lesson-attend__main__vertical-panes__output__codeload p {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.lesson-attend__main__modal__chapter__top {
  display: flex;
  justify-content: space-between;
}

.lesson-attend__main__modal__chapter__top h3 {
  max-width: 350px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-all;
}

.lesson-attend__main__modal__chapter__bottom li {
  list-style: none;
  margin-top: 15px;
}

.lesson-attend__main__modal__needcomplete__top {
  margin-bottom: 20px;
}

.lesson-attend__main__background__first {
  position: absolute;
  right: -100px;
  bottom: -50px;
  z-index: 1;
}

.lesson-attend__main__background__second {
  position: absolute;
  width: 800px;
  right: 400px;
  top: 100px;
  z-index: 1;
}

.lesson-attend__main__background__third {
  position: absolute;
  width: 600px;
  left: -400px;
  top: 60px;
  z-index: 1;
}
</style>
