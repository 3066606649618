var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "error" },
    [
      _c("layout-header"),
      _c(
        "modal-custom",
        { attrs: { hideButton: "", hideOverlay: "" } },
        [
          _c("div", { staticClass: "error__text" }, [
            _c("h3", [_vm._v(_vm._s(_vm.title))]),
            _c("p", [_vm._v(_vm._s(_vm.text))])
          ]),
          _c(
            "button-normal",
            {
              on: {
                emit: function($event) {
                  return _vm.movePage("AdminLessons")
                }
              }
            },
            [_vm._v(_vm._s(_vm.button))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }