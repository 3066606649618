<template>
  <div :class='addClass'>
    <div class='section-tool-bar__left'>
      <div class='section-tool-bar__left__info'>
        <icon-level
            class='section-tool-bar__left__info__level'
            :level='lessonInfo.level'/>
        <icon-post-date
          class='section-tool-bar__left__info__update'
          :date='lessonInfo.updatedDate'
        >
          UPDATE
        </icon-post-date>
        <p class='section-tool-bar__left__info__version'>
            Ver. {{ lessonInfo.version }}
        </p>
        <p
          class='section-tool-bar__left__info__price'
          v-if='pageType === "beforeBuy" || pageType === "creator"'
        >
          {{ setPrice }}
        </p>
      </div>
      <div class='section-tool-bar__left__bottom' v-if='pageType === "afterBuy"'>
        <bar-progress
          class='section-tool-bar__left__bar'
          :progress='progressRate'
          large
        />
        <p class='section-tool-bar__left__rate'>{{ progressRate }}%</p>
      </div>
    </div>
    <div class='section-tool-bar__right'>
      <button-normal
        v-if='pageType === "beforeBuy" && lessonInfo.price === 0'
        :loading='isLoading.resetProgress'
        @emit='$emit("emit-reset-progress")'
        large
        >無料で始める
      </button-normal>
      <button-normal
        v-if='pageType === "beforeBuy" && lessonInfo.price !== 0 && !checkCart'
        :loading='isLoading.addCart'
        @emit='$emit("emit-add-cart")'
        large
      >
        カートに入れる
      </button-normal>
      <router-link :to='{ name: "Cart" }'>
        <button-normal
          v-if='pageType === "beforeBuy" && lessonInfo.price !== 0 && checkCart'
          @emit='$emit("emit-move-page-cart")'
          large
        >
          カートを見る
        </button-normal>
      </router-link>
      <div v-if='pageType === "afterBuy"' class='section-tool-bar__right__afterbuy'>
        <button-normal
          :loading='isLoading.resetProgress'
          @emit='$emit("emit-reset-progress")'
          large
          outline
        >
          最初から始める
        </button-normal>
        <button-normal
          class='section-tool-bar__right__afterbuy__continue'
          v-if='progressRate !== 0'
          :loading='isLoading.loadContinue'
          @emit='$emit("emit-move-page", "LessonAttend",
          { id: lessonInfo.id, num: loadContinue })'
          large
        >
          途中から始める
        </button-normal>
      </div>
      <div v-if='pageType === "creator"' class='section-tool-bar__right__creator'>
        <button-normal
          v-if='status === 1'
          @emit='$emit("emit-change-status-private")'
          large
          outline
          >
          レッスンを非公開にする
        </button-normal>
        <button-normal
          v-if='status === 2'
          @emit='$emit("emit-change-status-public")'
          large
          outline
        >
          レッスンを公開する
        </button-normal>
        <button-normal
          class='section-tool-bar__right__creator__update'
          @emit='$emit("emit-move-page-update")'
          :disabled='lessonInfo.judge === 2'
          large
        >
          内容を更新する
        </button-normal>
      </div>
    </div>
  </div>
</template>

<script>
import { ButtonNormal } from '@/components/atoms/button';
import BarProgress from '@/components/atoms/other/BarProgress';
import { IconLevel, IconPostDate } from '@/components/atoms/icon';

export default {
  components: {
    ButtonNormal,
    BarProgress,
    IconLevel,
    IconPostDate,
  },
  props: {
    lessonInfo: Object,
    isLoading: Object,
    purchaseList: Array,
    postList: Array,
    pageType: String,
    status: Number,
    checkCart: Boolean,
  },
  data() {
    return {
      progressRate: 0,
    };
  },
  mounted() {
    this.loadRate(this.lessonInfo.id);
  },
  computed: {
    loadContinue() {
      const lesson = this.purchaseList
        .filter((purchaseLesson) => purchaseLesson.id === this.lessonInfo.id)[0];
      const chapterIdList = lesson.chapter.map((chapter, index) => index + 1);
      return chapterIdList.findIndex((chapterId) => chapterId === lesson.inProgress.continue) + 1;
    },
    addClass() {
      return {
        'section-tool-bar': true,
        'section-tool-bar--border': this.pageType !== 'afterBuy',
      };
    },
    setPrice() {
      if (this.lessonInfo.price === 0) return '無料';
      return `¥${this.lessonInfo.price}`;
    },
  },
  methods: {
    loadRate(id) {
      const lesson = this.purchaseList.filter((purchaseLesson) => purchaseLesson.id === id)[0];
      if (lesson === undefined) {
        this.progressRate = 0;
      } else {
        this.progressRate = lesson.inProgress.progressRate;
      }
    },
  },
};
</script>

<style scoped>
.section-tool-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px;
}

.section-tool-bar--border {
  border-bottom: 1px solid #F2EEEE;
}
.section-tool-bar__left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.section-tool-bar__left__info {
  display: flex;
  align-items: center;
}

.section-tool-bar__left__info__update {
  margin-left: 10px;
}

.section-tool-bar__left__info__version {
  margin-left: 10px;
}

.section-tool-bar__left__info__price {
  font-size: 24px;
  margin-left: 10px;
}

.section-tool-bar__left__bar{
  width: 500px;
}

.section-tool-bar__left__bottom {
  display: flex;
  margin-top: 15px;
  width: 450px;
}

.section-tool-bar__left__rate {
  margin-left: 10px;
  font-weight: 600;
}

.section-tool-bar__right {
  display: flex;
  align-items: center;
}
.section-tool-bar__right__afterbuy button-normal:first-child {
  margin: 100px;
}

.section-tool-bar__right__afterbuy {
  display: flex;
  align-items: center;
}

.section-tool-bar__right__afterbuy__continue {
  margin-left: 10px;
}

.section-tool-bar__right__creator {
  display: flex;
  align-items: center;
}

.section-tool-bar__right__creator__update {
  margin-left: 10px;
}
</style>
