<template>
  <div class='cart-checkout-complete'>
    <layout-header />
    <heading-title>
      <template v-slot:main>
        <p>Checkout Complete</p>
      </template>
      <template v-slot:sub>
        <p>決済完了_</p>
      </template>
    </heading-title>
    <section class='cart-checkout-complete__main center'>
      <div class='cart-checkout-complete__main__top'>
        <heading-normal large>レッスンの購入が完了しました！</heading-normal>
        <p>レッスンを選択し、早速学習をスタートしましょう</p>
      </div>
      <ul class='cart-checkout-complete__main__bottom'>
        <li v-for='lessonInfo in checkoutLesson' :key='lessonInfo.id'>
          <lesson-card-wide :lessonInfo='lessonInfo'/>
        </li>
      </ul>
    </section>
    <layout-footer />
  </div>
</template>

<script>
import helper from '@/mixins/general/methods/helper';
import { mapState } from 'vuex';
import { LayoutHeader, LayoutFooter } from '@/components/layouts/general/';
import { HeadingTitle, HeadingNormal } from '@/components/atoms/heading';
import { LessonCardWide } from '@/components/molecules/other';

export default {
  mixins: [helper],
  components: {
    LayoutHeader,
    LayoutFooter,
    HeadingTitle,
    HeadingNormal,
    LessonCardWide,
  },
  data() {
    return {
      checkoutLesson: [],
    };
  },
  computed: {
    ...mapState('user', ['userInfo']),
  },
  mounted() {
    // vuexのcheckoutLesson避難後削除
    this.checkoutLesson = this.userInfo.checkoutLesson;
    this.$store.commit('user/saveResetCheckoutLesson');
  },
};
</script>

<style scoped>
.cart-checkout-complete {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.cart-checkout-complete__main {
  margin: 80px auto;
    display: flex;
  flex-direction: column;
}

.cart-checkout-complete__main__bottom {
  list-style: none;
}

.cart-checkout-complete__main__bottom li {
  padding: 20px 0;
  border-bottom: 1px solid #F2EEEE;
}
</style>
