<template>
  <button class="button-username" @click='$emit("emit")'>
    <i class='button-username__icon fas fa-caret-up' v-if='isActive'></i>
    <i class='button-username__icon fas fa-caret-down' v-if='!isActive'></i>
    <slot class='button-username__text'/>
  </button>
</template>

<script>
export default {
  props: {
    isActive: Boolean,
  },
};
</script>

<style>
.button-username {
  color: #fff;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.button-username__icon {
  font-size: 24px;
  margin-right: 5px;
}
</style>
