<template>
  <div class='search' id='search'>
    <layout-header />
    <heading-title>
      <template v-slot:main>
        <p class='search__title'>{{ fixSearchWord }}</p>
      </template>
      <template v-slot:sub v-if='!isLoading.search'>
        <div class='search__header'>
          <p>検索結果 {{ searchInfo.count.totalLesson }}件</p>
          <p class='search__header__order'>順番</p>
          <ul
            id='lesson-search'
            class='search__header__label'
            v-for='( label, index) in headingInfo.label'
            :key='label.id'>
            <li>
              <button
                @click='setOrder(index)'
                :disabled='isLoading.search'
                :class='{
                  "search__header__label__text": true,
                  "search__header__label__text--abled": index !== searchInfo.order}'
              >
                {{ label }}
              </button>
            </li>
          </ul>
        </div>
      </template>
    </heading-title>
    <section class='search__main'>
      <div class='search__main__back' />
      <div class='search__main__front center'>
        <ul
          class='search__main__lessonlist'
          v-if='lessonInfo.length && !isLoading.search'>
          <li
            class='search__main__lessonlist__lesson'
            v-for='info in lessonInfo'
            :key='info.id'>
            <lesson-card-wide
              :lessonInfo='info'
              :levelLabel='levelLabel'
            />
          </li>
        </ul>
        <div class='search__main__load' v-if='isLoading.search'>
          <icon-loading />
        </div>
        <p class='search__main__notfound' v-if='!lessonInfo.length && !isLoading.search'>
          レッスンが存在しません
        </p>
        <pager-normal
          class='search__main__pager'
          v-show='showPager(0)'
          :totalCount='searchInfo.count.totalLesson'
          :display='searchInfo.limit'
          @emit='setPagerCurrent("new", $event)'
        />
        <pager-normal
          class='search__main__pager'
          v-show='showPager(1)'
          :totalCount='searchInfo.count.totalLesson'
          :display='searchInfo.limit'
          @emit='setPagerCurrent("relevance", $event)'
        />
      </div>
    </section>
    <layout-footer />
  </div>
</template>

<script>
import helper from '@/mixins/general/methods/helper';
import { mapState } from 'vuex';
import { LayoutHeader, LayoutFooter } from '@/components/layouts/general/';
import { LessonCardWide } from '@/components/molecules/other/';
import { HeadingTitle } from '@/components/atoms/heading/';
import { IconLoading } from '@/components/atoms/icon/';
import { PagerNormal } from '@/components/atoms/other/';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    HeadingTitle,
    LessonCardWide,
    IconLoading,
    PagerNormal,
  },
  data() {
    return {
      lessonInfo: [],
      levelLabel: ['初心者', '中級者', '上級者'],
      isLoading: {
        search: true,
      },
      searchInfo: {
        word: '',
        order: 1,
        maxLength: 20,
        limit: 10,
        count: {
          totalLesson: 0,
          maxPage: 0,
        },
        pagerCurrent: {
          new: 1,
          relevance: 1,
        },
      },
      headingInfo: {
        title: '検索結果一覧',
        label: ['関連度順', '新着順'],
      },
    };
  },
  created() {
    this.searchInfo.word = this.$route.query.text !== ''
      && this.$route.query.text !== undefined
      && this.$route.query.text !== null
      ? this.$route.query.text.trim() : '';
    this.searchInfo.word !== ''
      ? this.loadLesson() : this.isLoading.search = false;
  },
  mixins: [helper],
  computed: {
    ...mapState('user', ['userInfo']),
    fixSearchWord() {
      if (this.searchInfo.word === ''
        || this.searchInfo.word === undefined
        || this.searchInfo.word === null) return '';
      return this.searchInfo.word;
    },
  },
  methods: {
    loading(target) {
      this.isLoading[target] = !this.isLoading[target];
    },
    setOrder(index) {
      this.searchInfo.order = index === 1 ? 0 : 1;
    },
    setPagerCurrent(target, index) {
      this.searchInfo.pagerCurrent[target] = index;
    },
    showPager(target) {
      return !!(this.searchInfo.order === target
        && !this.isLoading.search
        && this.searchInfo.count.maxPage > 1);
    },
    async scrollTo(id) {
      await this.nextTick;
      this.$scrollTo(document.getElementById(id), 1);
    },
    loadLesson() {
      if (!this.lessonInfo.length && !this.isLoading.search) return;
      if (this.userInfo.login) this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
      this.$http.get('/api/lessons/search', {
        params: {
          search: this.searchInfo.word,
          limit: this.searchInfo.limit,
          order: this.searchInfo.order,
          count: this.searchInfo.pagerCurrent[
            this.searchInfo.order === 1 ? 'relevance' : 'new'],
        },
      }).then((searchLessons) => {
        this.saveLesson(searchLessons.data.lessons);
        this.searchInfo.count.totalLesson = searchLessons.data.totalCount;
        this.isLoading.search = false;
      }).catch(() => {
        this.movePage('Error');
      });
    },
    saveLesson(lessonInfo) {
      this.lessonInfo = lessonInfo.map((lesson) => ({
        id: lesson.id,
        author: lesson.author,
        title: lesson.title,
        level: lesson.level,
        description: lesson.description,
        price: lesson.price,
        evaluation: lesson.evaluation,
        image: lesson.thumbnail,
        avatar: lesson.author_avatar,
        updatedDate: lesson.updated_at,
      }));
    },
  },
  watch: {
    'searchInfo.count.totalLesson': function (to) {
      this.searchInfo.count.maxPage = Math.ceil(to / this.searchInfo.limit);
    },
    'searchInfo.order': function () {
      this.loadLesson();
    },
    'searchInfo.pagerCurrent': {
      handler() {
        this.scrollTo('search');
        this.loadLesson();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.search {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.search__title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-all;
}

.search__header {
  display: flex;
  font-size: 20px;
  align-items: center;
}

.search__header__order {
  margin-left: 40px;
  font-size: 20px;
}

.search__header__label {
  list-style: none;
}

.search__header__label__text {
  margin-left: 10px;
  cursor: pointer;
  font-size: 16px;
  padding: 5px 10px;
  border: 2px solid white;
  border-radius: 25px;
}

.search__header__label__text--abled {
  background-color: #D6B516;
}

.search__main {
  position: relative;
}

.search__main__back {
  background-color: #4CAF50;
  left: 0;
  width: 100%;
  height: 60px;
  position: absolute;
  top: 0;
  z-index: 1;
}

.search__main__front {
  z-index: 2;
  margin: 0 auto 40px;
  position: relative;
  width: 900px;
}

.search__main__lessonlist__lesson {
  list-style: none;
  padding: 15px 0;
  border-bottom: 1px solid #F2EEEE;
}

.search__main__lessonlist__lesson:first-child {
  padding-top: 0;
}

.search__main__notfound {
  padding: 40px;
  text-align: center;
  background-color: #fff;
}

.search__main__pager {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.search__main__load {
  width: 100%;
  padding: 40px 0;
  background-color: #fff;
}
</style>
