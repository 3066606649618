import Vue from 'vue';
import VueAxios from 'vue-axios';
import VueSocialauth from 'vue-social-auth';
import axios from 'axios';
import Notifications from 'vue-notification';
import VueScrollTo from 'vue-scrollto';
import VueSocialSharing from 'vue-social-sharing';
import VueGtm from 'vue-gtm';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

// axios設定
const axiosBase = axios.create({
  baseURL: process.env.VUE_APP_HOST,
  headers: {
    'Content-Type': 'application/json',
  },
  credentials: true,
  responseType: 'json',
});
Vue.use(VueAxios, axiosBase);

// 画面通知設定
Vue.use(Notifications);

// アンカーリンク設定
Vue.use(VueScrollTo);

// シェアボタン設定
Vue.use(VueSocialSharing);

// ソーシャルログイン設定
Vue.use(VueSocialauth, {
  providers: {
    github: {
      clientId: process.env.VUE_APP_GITHUB_CLIENT_ID,
      redirectUri: process.env.VUE_APP_GITHUB_URL,
    },
    twitter: {
      clientId: process.env.VUE_APP_TWITTER_CLIENT_ID,
      redirectUri: process.env.VUE_APP_TWITTER_URL,
    },
    google: {
      clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      redirectUri: process.env.VUE_APP_GOOGLE_URL,
    },
    facebook: {
      clientId: process.env.VUE_APP_FACEBOOK_CLIENT_ID,
      redirectUri: process.env.VUE_APP_FACEBOOK_URL,
    },
  },
});

// GA読み込み Stg&prod
if (process.env.VUE_APP_HOST !== 'https://dev.donbler.com') Vue.use(VueGtm, { id: process.env.VUE_APP_GTM_ID });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
