<template>
  <div class='categories'>
    <layout-header />
    <heading-title>
      <template v-slot:main>
        {{ categoryNameList[category] }}
      </template>
    </heading-title>
    <main class='categories__main center'>
      <!-- あなたにオススメ -->
      <section class='categories__main__recommend' v-if='showLessonSection("recommend")'>
        <heading-normal large>
          {{ headingInfo.recommend.title }}
        </heading-normal>
        <div class='categories__main__recommend__lessonlist'>
          <ul class='categories__main__recommend__lessonlist__info'>
            <li  v-for='info in lessonInfo.recommend' :key='info.id'>
              <lesson-card-simple
                :lessonInfo='info'
                :levelLabel='levelLabel'
              />
            </li>
          </ul>
          <icon-loading v-if='isLoading.recommend' />
        </div>
      </section>
      <!-- 人気のレッスン -->
      <section class='categories__main__popular' v-if='showLessonSection("popular")'>
        <heading-normal large>
          {{ headingInfo.popular.title }}
        </heading-normal>
        <div class='categories__main__popular__lessonlist'>
          <button-arrow
            class='categories__main__popular__lessonlist__prev'
            direction='left'
            v-if='!isLoading.popular && lessonInfo.popular.length > 4'
            @emit='moveCarousel("prev", "popular")'
          />
          <vue-slick-carousel
            class='categories__main__popular__lessonlist__carousel'
            v-bind="carousel.setting"
            v-if='!isLoading.popular && lessonInfo.popular.length !== 0'
            ref='popular'
          >
            <div v-for='info in lessonInfo.popular' :key='info.id'>
              <lesson-card-popular
                class='categories__main__popular__lessonlist__carousel__card'
                :lessonInfo='info'
                :levelLabel='levelLabel'
              />
            </div>
          </vue-slick-carousel>
          <button-arrow
            class='categories__main__popular__lessonlist__next'
            direction='right'
            v-if='!isLoading.popular && lessonInfo.popular.length > 4'
            @emit='moveCarousel("next", "popular")'
          />
          <icon-loading v-if='isLoading.popular' />
        </div>
      </section>
      <!-- 新着レッスン -->
      <section id='lesson-new' class='categories__main__new'>
        <div class='categories__main__new__heading'>
          <heading-normal large>
            {{ headingInfo.new.title }}
          </heading-normal>
          <button-list
            class='categories__main__new__heading__label'
            title='難易度選択:'
            :disabled='isLoading.new'
            :labelList='headingInfo.new.label'
            @emit='execLoadLesson'
          />
        </div>
        <ul class='categories__main__new__lessonlist'>
          <li class='categories__main__new__lessonlist__lesson center'
            v-for='info in lessonInfo.new'
            :key='info.id'>
            <lesson-card-wide
              :lessonInfo='info'
              :levelLabel='levelLabel'
            />
          </li>
        </ul>
        <icon-loading v-if='isLoading.new' />
        <p class='categories__main__new__notfound' v-if='!lessonInfo.new.length && !isLoading.new'>
          レッスンが存在しません
        </p>
        <pager-normal
          class='categories__main__new__pager'
          v-show='showPager(0)'
          :totalCount='pagerInfo.count.totalLesson'
          :display='pagerInfo.limit.new'
          @emit='setCount'
        />
        <pager-normal
          class='categories__main__new__pager'
          v-show='showPager(1)'
          :totalCount='pagerInfo.count.totalLesson'
          :display='pagerInfo.limit.new'
          @emit='setCount'
        />
        <pager-normal
          class='categories__main__new__pager'
          v-show='showPager(2)'
          :totalCount='pagerInfo.count.totalLesson'
          :display='pagerInfo.limit.new'
          @emit='setCount'
        />
        <pager-normal
          class='categories__main__new__pager'
          v-show='showPager(3)'
          :totalCount='pagerInfo.count.totalLesson'
          :display='pagerInfo.limit.new'
          @emit='setCount'
        />
      </section>
    </main>
    <layout-footer />
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import helper from '@/mixins/general/methods/helper';
import { mapState } from 'vuex';
import { LayoutHeader, LayoutFooter } from '@/components/layouts/general/';
import { LessonCardPopular, LessonCardWide, LessonCardSimple } from '@/components/molecules/other/';
import { HeadingNormal, HeadingTitle } from '@/components/atoms/heading/';
import { IconLoading } from '@/components/atoms/icon/';
import { ButtonArrow, ButtonList } from '@/components/atoms/button/';
import { PagerNormal } from '@/components/atoms/other/';

export default {
  mixins: [helper],
  components: {
    VueSlickCarousel,
    LayoutHeader,
    LayoutFooter,
    HeadingNormal,
    HeadingTitle,
    LessonCardPopular,
    LessonCardWide,
    LessonCardSimple,
    IconLoading,
    ButtonArrow,
    ButtonList,
    PagerNormal,
  },
  data() {
    return {
      levelLabel: ['初心者', '中級者', '上級者'],
      loadLessonOnce: false,
      selectLevelNum: 0, // 0:全て, 1:初心者, 2:中級者, 3:上級者
      category: null,
      categoryNameList: {
        htmlcss: 'HTML/CSS',
        javascript: 'Javascript',
        php: 'PHP',
      },
      isLoading: {
        new: true,
        popular: true,
        recommend: true,
      },
      lessonInfo: {
        new: [],
        popular: [],
        recommend: [],
      },
      headingInfo: {
        new: {
          title: '新着レッスン_',
          label: ['すべて', '初心者', '中級者', '上級者'],
        },
        recommend: {
          title: 'あなたにオススメ_',
        },
        popular: {
          title: '人気のレッスン_',
        },
      },
      pagerInfo: {
        limit: {
          new: 10,
          recommend: 3,
        },
        count: {
          totalLesson: 0,
          maxPage: 0,
        },
        currentPage: {
          new: {
            all: 1,
            beginner: 1,
            intermediate: 1,
            advanced: 1,
          },
        },
      },
      carousel: {
        index: 0,
        setting: {
          arrows: false,
          dots: false,
          infinite: true,
          focusOnSelect: false,
          draggable: false,
          speed: 300,
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
    };
  },
  created() {
    this.category = this.$route.params.category;
    this.categoryNameList[this.category] === undefined
      ? this.$router.push({ path: '/notfound' }) : this.loadLesson(this.category);
  },
  computed: { ...mapState('user', ['userInfo']) },
  methods: {
    moveCarousel(direction, target) {
      direction === 'prev' ? this.$refs[target].prev() : this.$refs[target].next();
    },
    loading(target) {
      this.isLoading[target] = !this.isLoading[target];
    },
    showLessonSection(target) {
      if (target === 'recommend' && !this.userInfo.login) return false;
      if (this.loadLessonOnce && this.lessonInfo[target].length === 0) return false;
      return true;
    },
    showPager(target) {
      return !!(this.selectLevelNum === target
        && !this.isLoading.new
        && this.pagerInfo.count.maxPage > 1);
    },
    setCount(index) {
      this.scrollTo('lesson-new');
      this.execLoadLesson(this.selectLevelNum, index);
    },
    async scrollTo(id) {
      await this.nextTick;
      this.$scrollTo(document.getElementById(id), 1);
    },
    execLoadLesson(levelNum, index) {
      const levelName = Object.keys(this.pagerInfo.currentPage.new)[levelNum];
      if (index) this.pagerInfo.currentPage.new[levelName] = index;
      if (levelNum === 0) {
        this.selectLevelNum = 0;
        this.loadLesson(this.category, this.pagerInfo.currentPage.new[levelName]);
      } else {
        this.selectLevelNum = levelNum;
        this.loadLesson(this.category, this.pagerInfo.currentPage.new[levelName], levelNum - 1);
      }
    },
    loadLesson(category, count, level) {
      // 新着レッスン一Push
      this.$http.get('/api/lessons', {
        params: {
          category,
          level,
          count,
          limit: this.pagerInfo.limit.new,
          type: 'new',
        },
      }).then((newLessons) => {
        this.saveLesson('new', newLessons.data.lessons);
        this.checkLoadLessonDone('new');
        this.pagerInfo.count.totalLesson = newLessons.data.totalCount;
      }).catch((e) => {
        this.judgeShouldLogout(e) ? this.unAuthenticate() : this.movePage('Error');
      });

      if (!this.loadLessonOnce) {
        // 人気のレッスンPush
        this.$http.get('/api/lessons', {
          params: {
            category,
            type: 'popular',
          },
        }).then((popularLessons) => {
          this.saveLesson('popular', popularLessons.data.lessons);
          this.checkLoadLessonDone('popular');
        }).catch((e) => {
          this.judgeShouldLogout(e) ? this.unAuthenticate() : this.movePage('Error');
        });

        // おすすめのレッスンPush
        if (!this.userInfo.login) {
          this.isLoading.recommend = false;
        } else {
          this.$http.defaults.headers.common.Authorization = `Bearer ${this.userInfo.token}`;
          this.$http.get('/api/auth/lessons', {
            params: {
              category,
              limit: this.pagerInfo.limit.recommend,
              type: 'recommend',
            },
          }).then((recommendLessons) => {
            this.saveLesson('recommend', recommendLessons.data.lessons);
            this.checkLoadLessonDone('recommend');
          }).catch((e) => {
            this.judgeShouldLogout(e) ? this.unAuthenticate() : this.movePage('Error');
          });
        }
      }
    },
    checkLoadLessonDone(target) {
      this.isLoading[target] = false;
      if (!Object.values(this.isLoading).includes(true)) this.loadLessonOnce = true;
    },
    saveLesson(target, lessonInfo) {
      this.lessonInfo[target] = [];
      lessonInfo.forEach((lesson) => {
        this.lessonInfo[target].push({
          id: lesson.id,
          author: lesson.author,
          title: lesson.title,
          level: lesson.level,
          description: lesson.description,
          price: lesson.price,
          evaluation: lesson.evaluation,
          image: lesson.thumbnail,
          avatar: lesson.author_avatar,
          updatedDate: lesson.updated_at,
        });
      });
    },
  },
  watch: {
    'pagerInfo.count.totalLesson': function (to) {
      this.pagerInfo.count.maxPage = Math.ceil(to / this.pagerInfo.limit.new);
    },
  },
};
</script>

<style scoped>
.categories {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.categories__main {
  margin: 60px auto 80px;
}

/* あなたにオススメ系 */
.categories__main__recommend {
  margin-bottom: 40px;
}

.categories__main__recommend__lessonlist {
  width: 100%;
}

.categories__main__recommend__lessonlist__info {
  display: flex;
  list-style: none;
  justify-content: center;
  margin-top: 10px;
}

.categories__main__recommend__lessonlist__info li {
  margin-left: 30px;
}

.categories__main__recommend__lessonlist__info li:first-child {
  margin-left: 0;
}

/* 人気のレッスン系 */
.categories__main__popular {
  margin-bottom: 80px;
}

.categories__main__popular__lessonlist {
  position: relative;
  margin-top: 10px;
}

.categories__main__popular__lessonlist__prev {
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translateY(-50%);
}

.categories__main__popular__lessonlist__next {
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translateY(-50%);
}

.categories__main__popular__lessonlist__carousel {
  width: 101%;
}

.categories__main__popular__lessonlist__carousel__card {
  margin-right: 10px;
}

/* 新着系 */
.categories__main__new__heading {
  display: flex;
  align-items: center;
}

.categories__main__new__heading__label {
  margin-left: 20px;
}

.categories__main__new__lessonlist {
  margin-top: 5px;
}

.categories__main__new__lessonlist__lesson {
  list-style: none;
  padding: 20px 0;
  border-bottom: 1px solid #F2EEEE;
}

.categories__main__new__lessonlist__lesson:first-child {
  padding-top: 0;
}

.categories__main__new__notfound {
  padding: 40px;
  text-align: center;
  background-color: #fff;
}

.categories__main__new__pager {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
</style>
