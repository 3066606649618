var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-header" },
    [
      _vm.showNoticeAnnounce
        ? _c("bar-announce", [
            _vm._v(
              " 対応ブラウザはGoogleChromeです。お使いのブラウザでは正常に動作しない可能性があります。 "
            )
          ])
        : _vm._e(),
      _c("notifications", { attrs: { group: "top-right" } }),
      _c("div", { staticClass: "layout-header__main" }, [
        _c(
          "div",
          { staticClass: "layout-header__main__left" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Top" } } },
              [_c("layout-logo")],
              1
            ),
            _vm.isActive.creatorMode
              ? _c("p", { staticClass: "layout-header__main__left__creator" }, [
                  _vm._v("Creator Mode")
                ])
              : _vm._e(),
            !_vm.isActive.creatorMode
              ? _c(
                  "button",
                  {
                    staticClass: "layout-header__main__left__category",
                    on: {
                      click: function($event) {
                        return _vm.active("dropdownCategory")
                      },
                      mouseover: function($event) {
                        _vm.isActive.hoverCategory = true
                      },
                      mouseleave: function($event) {
                        _vm.isActive.hoverCategory = false
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/category.svg"),
                        alt: "タグ tag"
                      }
                    }),
                    _c("p", [_vm._v("CATEGORY")]),
                    _c(
                      "transition",
                      {
                        staticClass:
                          "layout-header__main__left__category__hover"
                      },
                      [
                        _vm.isActive.hoverCategory
                          ? _c("div", {
                              staticClass:
                                "layout-header__main__left__category__hover__bar"
                            })
                          : _vm._e()
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isActive.creatorMode
              ? _c("input-search", {
                  staticClass: "layout-header__main__left__search",
                  attrs: { placeholder: "キーワードを入力" },
                  on: { "emit-search": _vm.movePage }
                })
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "layout-header__main__right" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Cart" } } },
              [
                _c("button-cart", {
                  staticClass: "layout-header__main__right__notice",
                  attrs: { countCart: _vm.userInfo.cart.length }
                })
              ],
              1
            ),
            _c(
              "router-link",
              { attrs: { to: { name: "NewsList" } } },
              [
                _c("button-notice", {
                  staticClass: "layout-header__main__right__notice"
                })
              ],
              1
            ),
            _vm.userInfo.login
              ? _c("button-user", {
                  attrs: { src: _vm.userInfo.profile.avatar },
                  on: {
                    emit: function($event) {
                      return _vm.active("dropdownUser")
                    }
                  }
                })
              : _vm._e(),
            _c(
              "router-link",
              { attrs: { to: { name: "Login" } } },
              [
                !_vm.userInfo.login
                  ? _c("button-normal", { attrs: { small: "", white: "" } }, [
                      _vm._v("ログイン")
                    ])
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "layout-header__dropdown" },
        [
          _vm.isActive.dropdownUser
            ? _c("dropdown-header-user", {
                staticClass: "layout-header__main__dropdown__user",
                attrs: {
                  userIcon: _vm.userInfo.profile.avatar,
                  userName: _vm.userInfo.profile.userName,
                  purchaseList: _vm.filterPurchaseList
                },
                on: { "emit-logout": _vm.logout }
              })
            : _vm._e(),
          _vm.isActive.dropdownCategory
            ? _c("dropdown-header-category", {
                staticClass: "layout-header__main__dropdown__category",
                attrs: { categoryNameList: _vm.categoryNameList }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.isActive.dropdownUser
        ? _c("div", {
            staticClass: "layout-header__overlay",
            on: {
              click: function($event) {
                return _vm.active("dropdownUser")
              }
            }
          })
        : _vm._e(),
      _vm.isActive.dropdownNotice
        ? _c("div", {
            staticClass: "layout-header__overlay",
            on: {
              click: function($event) {
                return _vm.active("dropdownNotice")
              }
            }
          })
        : _vm._e(),
      _vm.isActive.dropdownCategory
        ? _c("div", {
            staticClass: "layout-header__overlay",
            on: {
              click: function($event) {
                return _vm.active("dropdownCategory")
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }