var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "icon-user-lesson" }, [
    _c("img", {
      attrs: { src: _vm.setAvatar, alt: "ユーザーアイコン" },
      on: { error: _vm.setErrorAvatar }
    }),
    _c("p", [_vm._v("EDITOR: " + _vm._s(_vm.setUsername))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }