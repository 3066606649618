<template>
  <div class='section-author'>
    <div class='section-author__background' />
    <div class='section-author__info center'>
      <div class='section-author__info__person'>
        <router-link
          :class='pointer ? "section-author__info__person__img--pointer"
            : "section-author__info__person__img--disabled"'
          :to='{ name: "User", params: { id: authorInfo.id }}'
        >
          <icon-user :src='setAvatar' @error='setErrorAvatar' large />
        </router-link>
        <h3 class='section-author__info__person__name'>{{ authorInfo.name }}</h3>
      </div>
      <div class='section-author__info__description'>
        <heading-normal
          class='section-author__info__description__title'
          white
        >
          自己紹介_
        </heading-normal>
        <p class='section-author__info__description__text'>
          {{ setDescription }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { IconUser } from '@/components/atoms/icon';
import { HeadingNormal } from '@/components/atoms/heading';

export default {
  components: {
    IconUser,
    HeadingNormal,
  },
  props: {
    authorInfo: Object,
    pointer: Boolean,
  },
  computed: {
    setDescription() {
      if (!this.authorInfo.description) return '自己紹介文はありません';
      return this.authorInfo.description;
    },
    setAvatar() {
      if (this.authorInfo.img !== null) {
        const separateFilename = this.authorInfo.img.split('.');
        separateFilename[separateFilename.length - 2] = `${separateFilename[separateFilename.length - 2]}_original`;
        const combineFilename = separateFilename.join('.');
        return combineFilename;
      }
      return `${process.env.VUE_APP_S3_URL}/image/avatar/unknown.png`;
    },
  },
  methods: {
    setErrorAvatar() {
      this.lessonInfo.avatar = `${process.env.VUE_APP_S3_URL}/image/avatar/unknown.png`;
    },
  },
};
</script>

<style scoped>
.section-author {
  align-items: center;
  position: relative;
  margin: 30px 0;
}

.section-author__background {
  background-color: #4CAF50;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 30px;
}

.section-author__info {
  z-index: 2;
  position: relative;
  padding: 0 60px;
}

.section-author__info__person {
  position: relative;
  display: flex;
  align-items: center;
}

.section-author__info__person__img--pointer {
  cursor: pointer;
}

.section-author__info__person__img--disabled {
  pointer-events: none;
}
.section-author__info__person__name  {
  font-size: 24px;
  color: white;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-all;
  margin: 10px 0 0 40px;
}

.section-author__info__description {
  margin: 40px 0 10px 0;
}

.section-author__info__description__text {
  font-size: 16px;
  line-height: 30px;
  color: white;
  word-break: break-all;
  white-space: pre-wrap;
}
</style>
