<template>
  <div class='about'>
    <layout-header />
    <section class='about__top'>
      <div class='about__top__wrapper'>
        <div class='about__top__content'>
          <h1 class='about__top__content__title'>脱プログラミング初心者！<br>次の一歩に悩むあなたへ</h1>
          <p class='about__top__content__text'>
            manabibaは環境構築不要で、すぐ学べる<br>プログラミング学習レッスンプラットフォームです。
          </p>
        </div>
        <div class='about__top__image'>
          <img
            class='about__top__image__attend'
            src='@/assets/img/sphone/sphone_attendpage.png'
            alt=''>
        </div>
      </div>
    </section>

    <section class='about__feature'>
      <div class='about__feature__wrapper'>
        <h2 class='about__feature__title'>manabibaの特徴</h2>
        <ul class='about__feature__list__wrapper'>
          <li class='about__feature__list'>
            <img
              class='about__feature__list__img'
              src='@/assets/img/sphone/sphone_attendpage_zoom.png'
              alt='manabibaの受講ページ'>
            <div class='about__feature__list__contents'>
              <h3 class='about__feature__list__contents__title'>
              <div>
                <span class='about__feature__list__contents__title__num'>1</span>
                <span class='about__feature__list__contents__title__period'>.</span>
              </div>
                <p class='about__feature__list__contents__title__text'>
                  開発環境の準備不要！<br>ブラウザ上ですぐ学べる
                </p>
              </h3>
              <p class='about__feature__list__contents__text'>
                ブラウザ上でコードを実行できるため<br>難しい環境構築の必要はありません！
              </p>
            </div>
          </li>
          <li class='about__feature__list'>
            <img
              class='about__feature__list__img'
              src='@/assets/img/sphone/sphone_feature_variety.png'
              alt='プログラミング言語'>
            <div class='about__feature__list__contents'>
              <h3 class='about__feature__list__contents__title'>
              <div>
                <span class='about__feature__list__contents__title__num'>2</span>
                <span class='about__feature__list__contents__title__period'>.</span>
              </div>
                <p class='about__feature__list__contents__title__text'>
                  基礎から応用まで幅広い<br>レベルのレッスンを提供
                </p>
              </h3>
              <p class='about__feature__list__contents__text'>
                投稿型サービスなので多種多様なレッスンの中からあなたに合ったレッスンを受講できます。
              </p>
            </div>
          </li>
          <li class='about__feature__list'>
            <img
              class='about__feature__list__img'
              src='@/assets/img/sphone/sphone_feature_knowledge.png'
              alt='プログラミングの学習についての悩み'>
            <div class='about__feature__list__contents'>
              <h3 class='about__feature__list__contents__title'>
              <div>
                <span class='about__feature__list__contents__title__num'>3</span>
                <span class='about__feature__list__contents__title__period'>.</span>
              </div>
                <p class='about__feature__list__contents__title__text'>
                  独学で必要な予備知識を徹底解説(準備中)
                </p>
              </h3>
              <p class='about__feature__list__contents__text'>
                学習ロードマップやどこまで学べば 次へ進んでよいかなど、独学する上で悩みがちな箇所の解説を発信します。
              </p>
            </div>
          </li>
        </ul>
      </div>
    </section>

    <section class='about__procedure'>
      <div class='about__procedure__wrapper'>
        <h2 class='about__procedure__title'>レッスンの受講手順</h2>
        <ul class='about__procedure__list__wrapper'>
          <li class='about__procedure__list'>
            <div class='about__procedure__list__contents'>
              <p class='about__procedure__list__contents__num'>1</p>
              <img
                class='about__procedure__list__contents__img'
                src='@/assets/img/toppage.png'
                alt='manabibaのトップページ'>
              <h3 class='about__procedure__list__contents__title'>
                1. manabibaにログイン
              </h3>
              <p class='about__procedure__list__contents__text'>
                Github/Twitter/Google/Facebook<br>アカウントでログインできます。
              </p>
            </div>
            <div class='about__procedure__list__triangle'></div>
          </li>
          <li class='about__procedure__list'>
            <div class='about__procedure__list__contents'>
              <p class='about__procedure__list__contents__num'>2</p>
              <img
                class='about__procedure__list__contents__img'
                src='@/assets/img/detailpage.png'
                alt='manabibaの詳細ページ'>
              <h3 class='about__procedure__list__contents__title'>
                2. レッスンを選択
              </h3>
              <p class='about__procedure__list__contents__text'>
                自分に合ったレッスンを選択してください。
              </p>
            </div>
            <div class='about__procedure__list__triangle'></div>
          </li>
          <li class='about__procedure__list'>
            <div class='about__procedure__list__contents'>
              <p class='about__procedure__list__contents__num'>3</p>
              <img
                class='about__procedure__list__contents__img'
                src='@/assets/img/sphone/sphone_attendpage.png'
                alt='manabibaの受講ページ'>
              <h3 class='about__procedure__list__contents__title'>
                3. 受講スタート
              </h3>
              <p class='about__procedure__list__contents__text'>
                ブラウザ内で学習を開始できます。
              </p>
            </div>
          </li>
        </ul>
      </div>
      <img class='about__procedure__first-bar' src='@/assets/img/section-welcome-back.svg' alt=''>
    </section>

    <section class='about__lang'>
      <div class='about__lang__wrapper'>
        <h2 class='about__lang__title'>対応言語</h2>
        <ul class='about__lang__contents'>
          <li class='about__lang__contents__list'>
            <div class='about__lang__contents__htmlcss'>
              <i class="fab fa-html5 about__lang__contents__html"></i>
              <i class="fab fa-css3-alt about__lang__contents__css"></i>
            </div>
            <p class='about__lang__contents__list__text'>HTML/CSS</p>
          </li>
          <li class='about__lang__contents__list'>
            <i class="fab fa-js about__lang__contents__js"></i>
            <p class='about__lang__contents__list__text'>Javascript</p>
          </li>
          <li class='about__lang__contents__list'>
            <i class="fab fa-php about__lang__contents__php"></i>
            <p class='about__lang__contents__list__text'>PHP</p>
          </li>
          <li class='about__lang__contents__list'>
            <img
              src='@/assets/img/sphone/sphone_language.svg'
              class='about__lang__contents__other'
              alt='桃 peach'>
            <p class='about__lang__contents__list__text'>その他<br>順次対応中</p>
          </li>
        </ul>
      </div>
    </section>

    <section class='about__creator'>
      <div class='about__creator__wrapper'>
        <h2 class='about__creator__title'>レッスンを作ってみたい方も</h2>
        <p class='about__creator__text'>
          manabibaではレッスンを作成してくれる方を募集しています。<br>
          テキストとソースコードがあれば投稿できて、副業としても最適です。<br>
          manabibaを通じて次世代のエンジニア育成に貢献しませんか？
        </p>
      </div>
    </section>

    <section class='about__start'>
      <div class='about__start__wrapper' v-if='!userInfo.login'>
        <h2 class='about__start__title'>さっそく始めましょう</h2>
        <p class='about__start__lead'>
          Github/Twitter/Google/Facebookアカウントがあれば<br>すぐに学習を開始できます。
        </p>
        <router-link :to='{ name: "Login" }'>
          <button-normal
            class='about__start__button'
            large>
            ログイン
          </button-normal>
        </router-link>
        <router-link class='about__start__movetop' :to='{ name: "Top"}'>レッスンを見る</router-link>
      </div>
      <div class='about__start__wrapper' v-if='userInfo.login'>
        <h2 class='about__start__title'>さっそく始めましょう</h2>
        <p class='about__start__lead'>
          自分に合ったレッスンを見つけ勉強をスタートしましょう！
        </p>
        <router-link :to='{ name: "Top"}'>
          <button-normal
            class='about__start__button'
            large>
            レッスンを見る
          </button-normal>
        </router-link>
      </div>
    </section>

    <section class='about__sns about__sns__wrapper'>
    </section>

    <section class='about__official'>
      <div class='about__official__warpper'>
        <a
          href='https://twitter.com/manabiba_jp'
          class='about__official__img__wrap'
          target='_blank'
        >
          <i class='fab fa-twitter about__official__img'></i>
        </a>
        <p class='about__official__text'>
          manabibaの最新情報は公式Twitter<br>@manabiba_jp でチェックできます。
        </p>
        <div class='about__official__sns'>
          <share-network
            class='about__official__sns__btn'
            :network='shareInfo.twitter.network'
            :url='shareInfo.twitter.url'
            :title='shareInfo.twitter.title'
            :hashtags='shareInfo.twitter.hashtags'
          >
            <i class="fab fa-twitter about__official__sns__twitter"></i>
          </share-network>
          <share-network
            class='about__official__sns__btn'
            :network='shareInfo.facebook.network'
            :url='shareInfo.facebook.url'
            :title='shareInfo.facebook.title'
            :hashtags='shareInfo.facebook.hashtags'
          >
            <i class="fab fa-facebook-square about__official__sns__facebook"></i>
          </share-network>
          <share-network
            class='about__official__sns__btn'
            :network='shareInfo.line.network'
            :url='shareInfo.line.url'
            :title='shareInfo.line.title'
            :description='shareInfo.line.description'
          >
            <i class="fab fa-line about__official__sns__line"></i>
          </share-network>
          <share-network
            class='about__official__sns__btn'
            :network='shareInfo.pocket.network'
            :url='shareInfo.pocket.url'
            :title='shareInfo.pocket.title'
          >
            <i class="fab fa-get-pocket about__official__sns__pocket"></i>
          </share-network>
        </div>
      </div>
    </section>

    <layout-footer />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import helper from '@/mixins/general/methods/helper';
import { LayoutHeader, LayoutFooter } from '@/components/layouts/general/';
import { ButtonNormal } from '@/components/atoms/button/';

export default {
  mixins: [helper],
  components: {
    LayoutHeader,
    LayoutFooter,
    ButtonNormal,
  },
  data() {
    return {
      youtubeInfo: {
        creator: {
          src: 'https://www.youtube.com/embed/KEvoCfIefuU',
          width: '560',
          height: '322',
          frameborder: '0',
          allow: 'accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture',
        },
      },
      shareInfo: {
        twitter: {
          network: 'twitter',
          url: 'https://manabiba.app',
          title: 'manabibaは環境構築不要で、すぐ学べるプログラミング学習レッスンプラットフォームです。',
          hashtags: 'manabiba',
        },
        facebook: {
          network: 'facebook',
          url: 'https://manabiba.app',
          title: 'manabiba | 脱プログラミング初心者！次の一歩に悩むあなたへ',
          description: 'manabibaは環境構築不要で、すぐ学べるプログラミング学習レッスンプラットフォームです。',
          quote: '引用',
          hashtags: 'manabiba',
        },
        line: {
          network: 'line',
          url: 'https://manabiba.app',
          title: 'manabiba | 脱プログラミング初心者！次の一歩に悩むあなたへ',
          description: 'manabibaは環境構築不要で、すぐ学べるプログラミング学習レッスンプラットフォームです。',
        },
        pocket: {
          network: 'pocket',
          url: 'https://manabiba.app',
          title: 'manabibaは環境構築不要で、すぐ学べるプログラミング学習レッスンプラットフォームです。',
        },
      },
    };
  },
  computed: {
    ...mapState('user', ['userInfo']),
  },
};
</script>

<style scoped>
img {
  vertical-align:top;
}

.about__top {
  position: relative;
}

.about__top__wrapper {
  display: flex;
  justify-content: center;
  padding: 100px 0;
  background-color: #4CAF50;
}

.about__top__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  position: relative;
}
.about__top__content__title {
  font-size: 40px;
  color: white;
  font-weight: bold;
}

.about__top__content__text {
  margin-top: 8px;
  color: white;
  font-size: 20px;
  font-weight: 500;
}

.about__top__image {
  position: relative;
  z-index: 2;
}

.about__top__image__attend {
  width: 500px;
  height: 280px;
  margin-left: 100px;
}

.about__top__first-bar {
  position: absolute;
  top: 0;
  left: -120px;
  width: 650px;
}

.about__top__second-bar {
  position: absolute;
  bottom: -30px;
  right: 0;
  width: 700px;
  height: 200px;
  object-fit: cover;
}

.about__feature__wrapper {
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about__feature__title {
  font-size: 28px;
}

.about__feature__list__wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.about__feature__list {
  width: 330px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}

.about__feature__list:first-child {
  margin-left: 0;
}

.about__feature__list__img {
  width: 100%;
}

.about__feature__list__contents {
  padding:20px 23px 25px;
  border: 1px solid #F2EEEE;
  border-top: none;
  flex-grow: 1;
  border-radius: 0 0 8px 8px;
}
.about__feature__list__contents__title {
  display: flex;
  align-items: top;
  color: #E95456;
}

.about__feature__list__contents__title__num {
  font-size: 60px;
  font-family: 'tahoma','Helvetica Neue', 'Arial';
  font-weight: bold;
  line-height: 60px;
}

.about__feature__list__contents__title__period {
  font-size: 30px;
  font-family: 'tahoma','Helvetica Neue', 'Arial';
}

.about__feature__list__contents__title__text {
  margin-left: 7px;
  font-size: 20px;
  line-height: 30px;
}

.about__feature__list__contents__text {
  font-size: 16px;
  line-height: 26px;
  margin-top: 15px;
}

.about__procedure {
  padding: 60px 0;
  background-color: #4CAF50;
  position: relative;
}

.about__procedure__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about__procedure__title {
  font-size: 28px;
  z-index: 2;
}

.about__procedure__list__wrapper {
  display: flex;
  justify-content: flex-start;
  z-index: 2;
}
.about__procedure__list {
  position: relative;
  list-style: none;
  display: flex;
  border-radius: 8px;
  width: 100%;
}

.about__procedure__list__contents {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about__procedure__list__contents__num {
  width: 40px;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
  align-self: flex-start;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  transform: translate(-18px, 50%);
}

.about__procedure__list__contents__img {
  width: 280px;
  border: 5px solid #fff;
}

.about__procedure__list__contents__title {
  margin-top: 25px;
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
}

.about__procedure__list__contents__text {
  margin-top: 10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.about__procedure__list__triangle {
  position: relative;
  top: 55%;
  transform: translateX(30%) rotate(-90deg);
  height: 0;
  width: 0;
  border-top: 18px solid #E74860;
  border-right: 15px solid transparent;
  border-bottom: 18px solid transparent;
  border-left: 15px solid transparent;
  margin: 0 30px;
}

.about__procedure__first-bar {
  position: absolute;
  top: -25px;
  left: -100px;
  width: 700px;
  height: 200px;
  object-fit: cover;
}

.about__lang__wrapper {
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about__lang__title {
  font-size: 28px;

}

.about__lang__contents {
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  list-style: none;
  margin-top: 30px;
}

.about__lang__contents__list {
  background-color: white;
  padding: 0 30px 15px;
  border-radius: 11px;
  border: 1px solid #F2EEEE;
  display: flex;
  justify-content: flex-end;
  flex-direction:column;
  text-align: center;
  width: 136px;
  height: 136px;
  margin-left: 30px;
}

.about__lang__contents__list:first-child {
  margin-left: 0;
}

.about__lang__contents__list__text {
  font-size: 14px;
  line-height: 17px;
}

.about__lang__contents__htmlcss {
  display: flex;
  justify-content: space-between;
  margin-bottom:16px;
}

.about__lang__contents__html {
  color: #FF971D;
  font-size: 45px;
}

.about__lang__contents__css {
  color: #14A4FF;
  font-size: 45px;
}

.about__lang__contents__js {
  color: #FFD800;
  font-size: 45px;
  margin-bottom: 16px;
}

.about__lang__contents__php {
  color: #7948FF;
  font-size: 45px;
  margin-bottom: 16px;
}

.about__lang__contents__other {
  height: 45px;
  margin-bottom: 10px;
}

.about__creator {
  position: relative;
}

.about__creator__wrapper {
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #4CAF50;
}

.about__creator__title {
  font-size: 28px;
  z-index: 2;
}

.about__creator__movie {
  margin: 30px 0;
  border: 8px solid #fff;
}

.about__creator__text {
  font-size: 16px;
  text-align: center;
  z-index: 2;
}

.about__creator__first-bar {
  position: absolute;
  bottom: -30px;
  right: 0;
  width: 700px;
  height: 200px;
  object-fit: cover;
}

.about__start {
  display: flex;
  justify-content: center;
}

.about__start__wrapper {
  padding: 80px 0;
  width: 800px;
  border-bottom: 1px solid #F2EEEE;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about__start__title {
  font-size: 28px;
  font-weight: bold;
}

.about__start__lead {
  margin-top: 15px;
  font-size: 16px;
  text-align: center;
}

.about__start__button {
  margin-top: 30px;
}

.about__start__movetop {
  font-size: 13px;
  border-bottom: 1px solid #90CCE6;
  color: #90CCE6;
  margin-top: 15px;
}

.about__official__warpper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  background-color: white;
}

.about__official__img__wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  background-color: #74AFFF;
  border-radius: 11px;
  width: 63px;
  height: 63px;
}

.about__official__img {
  color: white;
  font-size: 30px;
}

.about__official__text {
  margin-left: 20px;
  font-size: 14px;
}

.about__official__sns {
  display: flex;
  justify-content: space-between;
  margin-left: 90px;
}

.about__official__sns__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  cursor: pointer;
}

.about__official__sns__twitter {
  color: #74AFFF;
  font-size: 27px;
}

.about__official__sns__facebook {
  color: #3E54FF;
  font-size: 24px;
}

.about__official__sns__line {
  color: #10CC00;
  font-size: 24px;
}

.about__official__sns__pocket {
  color: #FF4B4B;
  font-size: 24px;
}
</style>
