export default {
  methods: {
    movePage(name, params, query) {
      this.$router.push({ name, params, query }, () => {});
    },
    unAdminAuthenticate() {
      this.$store.commit('admin/logout');
      this.movePage('AdminLogin', '', { url: this.$route.path });
    },
    showNoticeBar(type, title, text) {
      this.$notify({
        title,
        text,
        type,
        duration: 3000,
        group: 'top-right',
      });
    },
  },
};
