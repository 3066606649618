var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setting" },
    [
      _c("layout-header"),
      _c(
        "div",
        { staticClass: "setting__main center" },
        [
          _c("section-menu", {
            attrs: {
              userInfo: _vm.userInfo,
              pageList: _vm.createTitleList,
              defaultSelectId: 0
            },
            on: { "emit-menu-index": _vm.movePageMenu }
          }),
          _c(
            "div",
            { staticClass: "setting__main__right" },
            [
              _c("canvas", { attrs: { id: "canvas" } }),
              _c(
                "heading-normal",
                {
                  staticClass: "setting__main__right__top",
                  attrs: { white: "", large: "" }
                },
                [_vm._v("設定_")]
              ),
              _c("div", { staticClass: "setting__main__right__bottom" }, [
                _c(
                  "div",
                  { staticClass: "setting__main__right__bottom__info" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "setting__main__right__bottom__info__top"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "setting__main__right__bottom__info__top__left"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "setting__main__right__bottom__info__top__preview"
                              },
                              [
                                _vm._m(0),
                                _vm.upload.avatar
                                  ? _c("img", {
                                      staticClass:
                                        "setting__main__right__bottom__info__top__preview__img",
                                      attrs: {
                                        src: _vm.upload.avatar,
                                        alt: "プロフィール画像"
                                      }
                                    })
                                  : _vm._e()
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "setting__main__right__bottom__info__top__right"
                          },
                          [
                            _c("input-file", {
                              attrs: {
                                name: "avatar",
                                title: _vm.inputFile.title,
                                description: _vm.inputFile.description,
                                accept: _vm.setAvatarAccept,
                                errorMsg: _vm.errorMsg.avatar,
                                resetCheck:
                                  _vm.upload.avatar === null ? true : false
                              },
                              on: { emit: _vm.saveUploadImage }
                            }),
                            _c("input-text", {
                              staticClass:
                                "setting__main__right__bottom__info__top__right__username",
                              attrs: {
                                name: "userName",
                                text: _vm.userInfo.profile.userName,
                                maxLength: _vm.rule.userName.maxLength,
                                errorMsg: _vm.errorMsg.userName
                              },
                              on: { emit: _vm.saveUploadText }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "setting__main__right__bottom__info__intro"
                      },
                      [
                        _c("heading-normal", { attrs: { yellow: "" } }, [
                          _vm._v("自己紹介_")
                        ]),
                        _c("input-textarea", {
                          staticClass:
                            "setting__main__right__bottom__info__intro__form",
                          attrs: {
                            name: "introduction",
                            text: _vm.userInfo.profile.introduction,
                            maxLength: _vm.rule.introduction.maxLength,
                            rows: _vm.rule.introduction.rows,
                            errorMsg: _vm.errorMsg.introduction
                          },
                          on: { emit: _vm.saveUploadText }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "setting__main__right__bottom__info__botton"
                      },
                      [
                        _c(
                          "button-normal",
                          {
                            attrs: {
                              disabled: !_vm.isValid,
                              loading: _vm.isLoading,
                              large: ""
                            },
                            on: { emit: _vm.execUpdateProfile }
                          },
                          [_vm._v("登録・更新する")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "button",
                      {
                        staticClass:
                          "setting__main__right__bottom__info__delete",
                        on: {
                          click: function($event) {
                            _vm.isActive = !_vm.isActive
                          }
                        }
                      },
                      [_vm._v("アカウントを削除される方はこちら")]
                    ),
                    _vm.isActive
                      ? _c("modal-normal", {
                          attrs: {
                            title: _vm.modalText.title,
                            text: _vm.modalText.text,
                            acceptBtn: _vm.modalText.acceptBtn,
                            cancelBtn: _vm.modalText.cancelBtn
                          },
                          on: { emit: _vm.modalEvent }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("layout-footer")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticClass: "setting__main__right__bottom__info__top__preview__text" },
      [_vm._v(" プロフィール画像"), _c("br"), _vm._v("プレビュー ")]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }