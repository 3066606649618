<template>
  <div :class='addClass'>
    <input
      type='password'
      v-model='emitText'
      :maxlength='maxLength'
    >
    <p class='input-password__error' v-if='errorMsg'>{{ errorMsg }}</p>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    maxLength: Number,
    errorMsg: String,
  },
  data() {
    return {
      emitText: String,
    };
  },
  computed: {
    addClass() {
      return {
        'input-password': true,
        'input-password--error': this.errorMsg,
      };
    },
  },
  created() {
    this.emitText = this.text;
  },
  updated() {
    this.$emit('emit', this.emitText);
  },
};
</script>

<style scoped>
.input-password input {
  background-color: #F9F9FA;
  padding: 20px;
  font-size: 14px;
  width: 100%;
}

.input-password--error input {
  background-color: rgb(255, 147, 169);
}

.input-password__error {
  color: rgb(255, 147, 169);
  margin-top: 5px;
  font-size: 12px;
}
</style>
