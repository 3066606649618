var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "news" },
    [
      _c("layout-header"),
      _c("heading-title", {
        scopedSlots: _vm._u([
          {
            key: "main",
            fn: function() {
              return [_c("p", [_vm._v("NEWS")])]
            },
            proxy: true
          },
          {
            key: "sub",
            fn: function() {
              return [_c("p", [_vm._v("お知らせ_")])]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "section",
        { staticClass: "news__main center" },
        [
          _c("article-normal", {
            staticClass: "news__main__article",
            attrs: { title: _vm.title, text: _vm.body, date: _vm.setDate }
          }),
          _c(
            "button",
            {
              staticClass: "news__main__back",
              on: {
                click: function($event) {
                  return _vm.movePage("NewsList")
                }
              }
            },
            [_vm._m(0)]
          )
        ],
        1
      ),
      _c("layout-footer")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "news__main__back__text" }, [
      _c("i", { staticClass: "fa fa-chevron-left" }),
      _vm._v(" お知らせ一覧 ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }