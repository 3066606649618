<template>
  <div class='lesson-card-cart'>
    <router-link
      class='lesson-card-cart__main'
      :to='{ name: "LessonDetail", params: { id: lessonInfo.id }}'>
      <img
        class='lesson-card-cart__main__left'
        :src='lessonInfo.thumbnail'
        @error='setErrorThumbnail'
      >
      <div class='lesson-card-cart__main__right'>
        <div class='lesson-card-cart__main__right__top'>
          <h3 class='lesson-card-cart__main__right__top__title'>
            {{ lessonInfo.title }}
          </h3>
          <p class='lesson-card-cart__main__right__top__author'>
            {{ lessonInfo.author }}
          </p>
        </div>
        <p class='lesson-card-cart__main__right__price'>
          ¥{{ lessonInfo.price }}
        </p>
      </div>
    </router-link>
    <button-hide
      class='lesson-card-cart__hide'
      @emit='$emit("emit-delete-cart", lessonInfo.id)'
    />
  </div>
</template>

<script>
import { ButtonHide } from '@/components/atoms/button';

export default {
  props: {
    lessonInfo: Object,
  },
  components: {
    ButtonHide,
  },
  methods: {
    setErrorThumbnail(e) {
      e.target.src = `${process.env.VUE_APP_S3_URL}/image/thumbnail/manabiba.png`;
    },
  },
};
</script>

<style scoped>
.lesson-card-cart {
  transition-duration: 0.2s;
  border: 8px solid #fff;
  padding: 10px;
  position: relative;
  display: flex;
}

.lesson-card-cart__main {
  display: flex;
}

.lesson-card-cart:hover {
  border: 8px solid #F1EFFC;
}

.lesson-card-cart__main__left {
  width: 180px;
  height: 101px;
}

.lesson-card-cart__main__right {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 650px;
}

.lesson-card-cart__main__right__top__title {
  font-size: 16px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
}

.lesson-card-cart__main__right__top__author {
  font-size: 14px;
  font-weight: 600;
  color: #CBCBCB;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-all;
}

.lesson-card-cart__main__right__price {
  font-weight: 600;
  font-size: 18px;
}

.lesson-card-cart__hide {
  position: absolute;
  top: 45px;
  right: 30px;
}
</style>
