<template>
  <div :class='addClass'>
    <div class='layout-footer__main center' v-if='!simpleFooter'>
      <div class='layout-footer__main__left'>
        <div class='layout-footer__main__left__top'>
          <layout-logo small />
          <p class='layout-footer__main__left__top__subtitle'>{{ subtitle }}</p>
        </div>
        <div class='layout-footer__main__left__bottom'>
          <p class='layout-footer__main__left__bottom__copyright'>{{ copyright }}</p>
        </div>
      </div>
      <div class='layout-footer__main__right'>
        <nav class='layout-footer__main__right__nav'>
          <h3 class='layout-footer__main__right__nav__title'>
            {{ menuInfo.service.title }}
          </h3>
          <ul class='layout-footer__main__right__nav__menu'>
            <li
              class='layout-footer__main__right__nav__menu__list'
              v-for='menu in menuInfo.service.menuList'
              :key='menu.id'
            >
              <a
                v-if='menu.link === "Youtube"'
                href='https://www.youtube.com/channel/UCnbZUetlXRKUZuRcXAK0dBg?view_as=subscriber'
                target='_blank'
                rel='noopener noreferrer'
              >
                {{ menu.title }}
              </a>
              <a
                v-else-if='menu.link === "CreatorWiki"'
                href='https://www.notion.so/manabiba-Creator-Wiki-d530dbc3058841e3a3078aacb41bbb8c'
                target='_blank'
                rel='noopener noreferrer'
              >
                {{ menu.title }}
              </a>
              <router-link
                v-else
                :to='{ name: menu.link}'
              >
                {{ menu.title }}
              </router-link>
            </li>
          </ul>
        </nav>
        <nav class='layout-footer__main__right__nav'>
          <h3 class='layout-footer__main__right__nav__title'>
            {{ menuInfo.support.title }}
          </h3>
          <ul class='layout-footer__main__right__nav__menu'>
            <li
              class='layout-footer__main__right__nav__menu__list'
              v-for='menu in menuInfo.support.menuList'
              :key='menu.id'
            >
              <a
                v-if='menu.link === "Contact"'
                :href='getGoogleFormUrl'
                target='_blank'
                rel='noopener noreferrer'
              >
                {{ menu.title }}
              </a>
              <router-link
                v-else
                :to='{ name: menu.link}'
              >
                {{ menu.title }}
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class='layout-footer--simple__main center' v-if='simpleFooter'>
      <p>{{ copyright }}</p>
      <a
        :href='getGoogleFormUrl'
        target='_blank'
        rel='noopener noreferrer'
      >
        お問い合わせ
      </a>
    </div>
  </div>
</template>

<script>
import LayoutLogo from '@/components/layouts/general/LayoutLogo';

export default {
  components: {
    LayoutLogo,
  },
  props: {
    hidePageUp: Boolean,
    simpleFooter: Boolean,
  },
  data() {
    return {
      copyright: '©︎ 2021 manabiba',
      subtitle: 'プログラミング学習レッスンプラットフォーム manabiba',
      menuInfo: {
        service: {
          title: 'サービス',
          menuList: [
            { link: 'About', title: 'manabibaについて' },
            { link: 'Help', title: 'ヘルプ' },
          ],
        },
        support: {
          title: 'サポート',
          menuList: [
            // { link: 'Company', title: '運営会社' },
            { link: 'Terms', title: '利用規約' },
            { link: 'PrivacyPolicy', title: 'プライバシーポリシー' },
          ],
        },
      },
      snsInfo: [
        {
          title: 'twitter',
          link: 'https://twitter.com/manabiba_jp',
        },
        {
          title: 'github',
          link: 'https://github.com/donbler',
        },
      ],
    };
  },
  computed: {
    addClass() {
      return {
        'layout-footer': true,
        'layout-footer--simple': this.simpleFooter,
      };
    },
    getGoogleFormUrl() {
      return process.env.VUE_APP_GOOGLE_FORM_URL;
    },
  },
};
</script>

<style scoped>
/* 通常Footer記述 */
a {
  color: #fff;
}

.layout-footer {
  background-color: #9c9c9c;
  color: #fff;
  margin-top: auto;
}

.layout-footer__main {
  display: flex;
  justify-content: space-between;
  padding: 60px 0;
}

.layout-footer__main__left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.layout-footer__main__left__top__subtitle {
  font-size: 11px;
  margin-top: 10px;
  width: 255px;
}

.layout-footer__main__left__top__sns {
  margin-top: 5px;
  font-size: 18px;
  list-style: none;
  display: flex;
}

.layout-footer__main__left__top__sns li {
  margin-right: 5px;
}

.layout-footer__main__left__top__sns li:last-child {
  margin-right: 0;
}

.layout-footer__main__left__top__sns li button a {
  color: #fff;
}

.layout-footer__main__left__top__sns li button a:visited {
  color: #fff;
}

.layout-footer__main__left__bottom {
  font-size: 12px;
  margin-top: 30px;
}

.layout-footer__main__right {
  display: flex;
}

.layout-footer__main__right__nav {
  width: 150px;
}

.layout-footer__main__right__nav__title {
  font-size: 14px;
  color: #4CAF50;
}

.layout-footer__main__right__nav__menu {
  list-style: none;
  font-size: 12px;
  margin-top: 5px;
}

.layout-footer__main__right__nav__menu__list a {
  margin-top: 10px;
  font-weight: 400;
}

.layout-footer__main__right__nav__menu__list a:hover {
  border-bottom: 1px solid #fff;
  margin-bottom: -1px;
}

/* simpleFooter記述 */
.layout-footer--simple {
  background-color: rgba(0, 0, 0, 0);
}

.layout-footer--simple__main {
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  font-size: 12px;
}
</style>
