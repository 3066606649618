var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lesson-editor" },
    [
      _c("lesson-editor-tab", {
        attrs: { tabInfo: _vm.tabInfo },
        on: { emit: _vm.switchEditor }
      }),
      _c(
        "div",
        { staticClass: "lesson-editor__bottom" },
        [
          _vm._l(_vm.sourceList, function(sourceInfo, index) {
            return _c("lesson-ace", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.editor == index,
                  expression: "editor == index"
                }
              ],
              key: sourceInfo.id,
              attrs: {
                id: index,
                filename: sourceInfo.filename,
                source: sourceInfo.assignment,
                resetSource: _vm.resetSourceList[index].assignment,
                resetFlg: _vm.resetFlg
              },
              on: { "emit-source": _vm.passEmit }
            })
          }),
          _vm.runButtonClient
            ? _c(
                "button",
                {
                  staticClass: "lesson-editor__bottom__runbtn",
                  on: {
                    click: function($event) {
                      return _vm.$emit("emit-code-run")
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fas fa-play" }),
                  _c("p", [_vm._v("コードを実行")])
                ]
              )
            : _vm._e(),
          _vm.runButtonServer
            ? _c(
                "button",
                {
                  staticClass: "lesson-editor__bottom__runbtn",
                  attrs: {
                    disabled:
                      _vm.isActive.codeRun || !_vm.isActiveGeneratedServerEnv
                  },
                  on: {
                    click: function($event) {
                      return _vm.emitCodeRun()
                    }
                  }
                },
                [
                  !_vm.isActive.codeRun
                    ? _c("div", [
                        _c("i", { staticClass: "fas fa-play" }),
                        _c("p", [_vm._v("コードを実行")])
                      ])
                    : _vm._e(),
                  _vm.isActive.codeRun
                    ? _c("div", [_c("p", [_vm._v("実行中...")])])
                    : _vm._e()
                ]
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }