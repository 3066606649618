var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-auth" }, [
    !_vm.load
      ? _c("div", { staticClass: "modal-auth__main" }, [
          _c("div", { staticClass: "modal-auth__main__content" }, [
            _c("h3", { staticClass: "modal-auth__main__title" }, [
              _vm._v("ログイン")
            ]),
            _c(
              "ul",
              { staticClass: "modal-auth__main__iconlist" },
              _vm._l(_vm.authList, function(auth) {
                return _c(
                  "li",
                  { key: auth.id },
                  [
                    _c("button-sns", {
                      staticClass: "modal-auth__main__iconlist__sns",
                      attrs: { auth: auth },
                      on: {
                        emit: function($event) {
                          return _vm.$emit("emit-login", auth)
                        }
                      }
                    })
                  ],
                  1
                )
              }),
              0
            ),
            _c("div", { staticClass: "modal-auth__main__description" }, [
              _c("p", [
                _vm._v("いずれかのサービスのアカウントでログインしてください。")
              ]),
              _c("p", [_vm._v("続行することで")]),
              _c(
                "a",
                {
                  staticClass: "modal-auth__main__description__link",
                  attrs: {
                    href: _vm.getRouteData("Terms"),
                    target: "_blank",
                    rel: "noopener noreferrer"
                  }
                },
                [_vm._v("利用規約")]
              ),
              _c("p", [_vm._v("、")]),
              _c(
                "a",
                {
                  staticClass: "modal-auth__main__description__link",
                  attrs: {
                    href: _vm.getRouteData("PrivacyPolicy"),
                    target: "_blank",
                    rel: "noopener noreferrer"
                  }
                },
                [_vm._v("プライバシーポリシー")]
              ),
              _c("p", [_vm._v("、")]),
              _c(
                "a",
                {
                  staticClass: "modal-auth__main__description__link",
                  attrs: {
                    href: _vm.getRouteData("CheckoutService"),
                    target: "_blank",
                    rel: "noopener noreferrer"
                  }
                },
                [_vm._v("決済について")]
              ),
              _c("p", [_vm._v("に同意したとみなします。")])
            ])
          ])
        ])
      : _vm._e(),
    _vm.load
      ? _c(
          "div",
          { staticClass: "modal-auth__main" },
          [
            _c("icon-loading", [
              _vm._v(" ログインしています... 少々お待ちください ")
            ])
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }