<template>
  <div class='error'>
    <layout-header />
    <modal-custom hideButton hideOverlay>
      <div  class='error__text'>
        <h3>{{ title }}</h3>
        <p>{{ text }}</p>
      </div>
      <button-normal @emit='movePage("AdminLessons")'>{{ button }}</button-normal>
    </modal-custom>
  </div>
</template>

<script>
import helper from '@/mixins/admin/methods/helper';
import LayoutHeader from '@/components/layouts/admin/LayoutHeader';
import { ModalCustom } from '@/components/molecules/other';
import { ButtonNormal } from '@/components/atoms/button/';

export default {
  components: {
    LayoutHeader,
    ModalCustom,
    ButtonNormal,
  },
  data() {
    return {
      title: 'ページの読み込み中にエラーが発生しました',
      text: 'しばらくしてから再度お試しください',
      button: 'レッスン一覧に戻る',
    };
  },
  mixins: [helper],
};
</script>

<style scoped>
.error {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f9fa;
}

.error__text {
  margin-bottom: 15px;
}

.error__text p {
  font-size: 14px;
  margin-top: 5px;
}
</style>
