<template>
  <div class='card-sale-info'>
    <div class='card-sale-info__right'>
      <p class='card-sale-info__title'>{{cardInfo.title}}</p>
      <p v-if='cardInfo.rate > 0' class='card-sale-info__rate card-sale-info__up'>
        <i class="fas fa-caret-up fa-lg"></i>
        {{Math.abs(cardInfo.rate)}}%
      </p>
      <p v-if='cardInfo.rate <= 0' class='card-sale-info__rate card-sale-info__down'>
        <i class="fas fa-caret-down fa-lg"></i>
        {{Math.abs(cardInfo.rate)}}%
      </p>
    </div>
    <div class='card-sale-info__left'>
      <p class='card-sale-info__score'>
        {{cardInfo.score}}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardInfo: Object,
  },
};
</script>

<style scope>
.card-sale-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #F9F9FA;
  padding: 5px 20px;
}

.card-sale-info__title {
  font-size: 14px;
}

.card-sale-info__rate {
  font-size: 10px;
  margin-top: 5px;
}

.card-sale-info__score {
  font-size: 48px;
  font-weight: bold;
  color: #46AAD5;
}

.card-sale-info__up {
  color: #FF2955;
}

.card-sale-info__down {
  color: #00CE5F;
}
</style>
