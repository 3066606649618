var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-input" }, [
    _c(
      "div",
      { staticClass: "modal-input__main" },
      [
        _c("h3", { staticClass: "modal-input__main__title" }, [
          _vm._v(_vm._s(_vm.title))
        ]),
        _c("p", { staticClass: "modal-input__main__text" }, [
          _vm._v(_vm._s(_vm.text))
        ]),
        _c("input-textarea", {
          staticClass: "modal-input__main__textarea",
          attrs: { placeholder: _vm.placeholder, rows: _vm.rows },
          on: {
            emit: function($event) {
              return _vm.$emit("emitTextarea", $event)
            }
          }
        }),
        _c(
          "div",
          { staticClass: "modal-input__main__btn" },
          [
            _vm.cancelBtn
              ? _c(
                  "button-normal",
                  {
                    staticClass: "modal-input__main__btn__cancel",
                    attrs: { outline: "" },
                    on: {
                      emit: function($event) {
                        _vm.$emit("emit", false), _vm.switchOverflow()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.cancelBtn))]
                )
              : _vm._e(),
            _vm.acceptBtn
              ? _c(
                  "button-normal",
                  {
                    attrs: { disabled: _vm.acceptBtnDisabled },
                    on: {
                      emit: function($event) {
                        _vm.$emit("emit", true), _vm.switchOverflow()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.acceptBtn))]
                )
              : _vm._e()
          ],
          1
        )
      ],
      1
    ),
    !_vm.disabledOverlay
      ? _c("div", {
          staticClass: "modal-input__overlay",
          on: {
            click: function($event) {
              _vm.$emit("emit", false), _vm.switchOverflow()
            }
          }
        })
      : _vm._e(),
    _vm.disabledOverlay
      ? _c("div", { staticClass: "modal-input__overlay" })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }