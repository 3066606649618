<template>
  <div class='terms'>
    <layout-header />
    <heading-title>
      <template v-slot:main>
        <p>TOS</p>
      </template>
      <template v-slot:sub>
        <p>利用規約_</p>
      </template>
    </heading-title>
    <terms-md class='terms__markdown center mdbase' />
    <layout-footer />
  </div>
</template>

<script>
import TermsMd from '@/assets/markdown/terms.md';
import { LayoutHeader, LayoutFooter } from '@/components/layouts/general/';
import { HeadingTitle } from '@/components/atoms/heading/';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    HeadingTitle,
    TermsMd,
  },
};
</script>

<style scoped>
.terms {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.terms__markdown {
  margin-top: 80px;
  margin-bottom: 80px;
  padding: 0 60px;
}
</style>
