<template>
  <section class='section-menu'>
    <div class='section-menu__back' />
    <div class='section-menu__contents'>
      <icon-user class='section-menu__contents__icon' :src='setAvatar' xlarge />
      <h3 class='section-menu__contents__username'>{{ userInfo.profile.userName }}</h3>
      <div class='section-menu__contents__count'>
        <p class='section-menu__contents__count__clear'>
          クリアレッスン数: {{ userInfo.profile.countClear }}
        </p>
        <p class='section-menu__contents__count__date'>
          学習日数: {{ userInfo.profile.countLearn }}
        </p>
      </div>
      <list-menu
        class='section-menu__contents__menu'
        :targetList='pageList'
        :defaultSelectId='defaultSelectId'
        @emit='passEmit'
      />
    </div>
  </section>
</template>

<script>
import { IconUser } from '@/components/atoms/icon';
import { ListMenu } from '@/components/atoms/list';

export default {
  components: {
    IconUser,
    ListMenu,
  },
  props: {
    userInfo: Object,
    pageList: Array,
    defaultSelectId: Number,
  },
  computed: {
    setAvatar() {
      if (this.userInfo.profile.avatar !== null) {
        const separateFilename = this.userInfo.profile.avatar.split('.');
        separateFilename[separateFilename.length - 2] = `${separateFilename[separateFilename.length - 2]}_original`;
        const combineFilename = separateFilename.join('.');
        return combineFilename;
      }
      return null;
    },
  },
  methods: {
    passEmit(index) {
      this.$emit('emit-menu-index', index);
    },
  },
};
</script>

<style scoped>
.section-menu {
  background-color: #fff;
  position: relative;
}

.section-menu__back {
  width: 100%;
  height: 30px;
  background-color: #4CAF50;
  position: absolute;
  top: 0;
  z-index: 1;
}
.section-menu__contents {
  z-index: 2;
  position: relative;
  width: 300px;
  padding: 0 30px 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.section-menu__contents__username {
  font-size: 24px;
  margin-top: 15px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  text-align: center;
}

.section-menu__contents__count {
  align-self: stretch;
  font-size: 12px;
  font-weight: normal;
  margin-top: 40px;
  line-height: 30px;
}

.section-menu__contents__count__clear {
  border-top: 1px solid #F1EFFC;
  border-bottom: 1px solid #F1EFFC;
}

.section-menu__contents__count__date {
  border-bottom: 1px solid #F1EFFC;
}
.section-menu__contents__menu {
  align-self: stretch;
  margin-top: 25px;
}
</style>
