<template>
  <div class='modal-input'>
    <div class='modal-input__main'>
      <h3 class='modal-input__main__title'>{{ title }}</h3>
      <p class='modal-input__main__text'>{{ text }}</p>
      <input-textarea
        class='modal-input__main__textarea'
        :placeholder='placeholder'
        :rows='rows'
        @emit='$emit("emitTextarea", $event)'>
      </input-textarea>
      <div class='modal-input__main__btn'>
        <button-normal
          class='modal-input__main__btn__cancel'
          v-if='cancelBtn'
          @emit='$emit("emit", false), switchOverflow()'
          outline
        >{{ cancelBtn }}</button-normal>
        <button-normal
          v-if='acceptBtn'
          :disabled='acceptBtnDisabled'
          @emit='$emit("emit", true),switchOverflow()'
        >{{ acceptBtn }}</button-normal>
      </div>
    </div>
    <div
      class='modal-input__overlay'
      v-if='!disabledOverlay'
      @click='$emit("emit", false),
      switchOverflow()'
    />
    <div
      class='modal-input__overlay'
      v-if='disabledOverlay'
    />
  </div>
</template>

<script>
import { ButtonNormal } from '@/components/atoms/button';
import { InputTextarea } from '@/components/atoms/input';

export default {
  components: {
    ButtonNormal,
    InputTextarea,
  },
  props: {
    title: String,
    text: String,
    acceptBtnDisabled: Boolean,
    acceptBtn: String,
    cancelBtn: String,
    disabledOverlay: Boolean,
    placeholder: String,
    rows: Number,
  },
  mounted() {
    this.switchOverflow();
  },
  methods: {
    // modalが立ち上がってる時はスクロールできないよう制御
    switchOverflow() {
      const body = document.querySelector('body');
      body.style.overflow = body.style.overflow === '' ? 'hidden' : '';
    },
  },
  destroyed() {
    const body = document.querySelector('body');
    body.style.overflow = '';
  },
};
</script>

<style scoped>
.modal-input__main {
  background-color: #fff;
  text-align: center;
  position: fixed;
  padding: 30px;
  width: 550px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 997;
}

.modal-input__main__title {
  font-size: 20px;
  margin-bottom: 10px;
}

.modal-input__main__text {
  font-size: 14px;
}

.modal-input__main__textarea {
  margin-top: 10px;
}
.modal-input__main__btn {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.modal-input__main__btn__cancel {
  margin-right: 40px;
}

.modal-input__overlay {
  position: fixed;
  z-index: 996;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
