var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "button-stripe" }, [
    _c(
      "button",
      {
        staticClass: "button-stripe__front",
        on: {
          click: function($event) {
            return _vm.$emit("emit")
          }
        }
      },
      [
        _vm._t("default"),
        _vm.arrow
          ? _c("i", {
              staticClass: "fas fa-chevron-right button-stripe__front__arrow"
            })
          : _vm._e()
      ],
      2
    ),
    _c("img", {
      staticClass: "button-stripe__back",
      attrs: {
        src: require("@/assets/img/lessoncard-button-background.svg"),
        alt: ""
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }