var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "button-hide",
      on: {
        click: function($event) {
          return _vm.$emit("emit")
        }
      }
    },
    [_c("i", { staticClass: "far fa-times-circle" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }