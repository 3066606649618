<template>
  <div class='layout-header'>
    <notifications group='top-right' />
    <div :class='addClass'>
      <div class='layout-header__main__left'>
        <router-link :to='{ name : "AdminLessons"}'>
          <layout-logo white small/>
        </router-link>
        <p class='layout-header__main__left__text'>Admin</p>
        <p class='layout-header__main__left__env'>{{ setEnvName }}</p>
      </div>
      <button-username
        class='layout-header__main__right'
        :isActive='isActive.dropdownUser'
        @emit='active("dropdownUser")'
      >{{ adminInfo.profile.userName }}</button-username>
    </div>
    <dropdown-header-user
      class="layout-header__dropdown"
      v-if='isActive.dropdownUser'
      @emit='logout'
    >ログアウト</dropdown-header-user>
    <div
      class='layout-header__overlay'
      v-if='isActive.dropdownUser'
      @click='active("dropdownUser")'
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import helper from '@/mixins/admin/methods/helper';
import LayoutLogo from '@/components/layouts/general/LayoutLogo';
import { ButtonUsername, DropdownHeaderUser } from '@/components/atoms/admin';

export default {
  components: {
    LayoutLogo,
    ButtonUsername,
    DropdownHeaderUser,
  },
  data() {
    return {
      isActive: {
        dropdownUser: false,
      },
    };
  },
  mixins: [helper],
  computed: {
    ...mapState('admin', ['adminInfo']),
    addClass() {
      return {
        'layout-header__main': true,
        'layout-header__main--local': this.judgeEnv === 1,
        'layout-header__main--stg': this.judgeEnv === 2,
        'layout-header__main--prd': this.judgeEnv === 3,
      };
    },
    judgeEnv() {
      if (window.location.hostname === 'localhost') {
        // 開発環境
        return 1;
      } if (window.location.hostname === 'stg.donbler.com') {
        // ステージング環境
        return 2;
      } if (window.location.hostname === 'donbler.com') {
        // 本番環境
        return 3;
      }
      return 0;
    },
    setEnvName() {
      if (this.judgeEnv === 1) {
        return '開発環境';
      } if (this.judgeEnv === 2) {
        return 'ステージング環境';
      } if (this.judgeEnv === 3) {
        return '本番環境';
      }
      return '環境の識別ができません、管理者に連絡して下さい';
    },
  },
  created() {
    // sphone判定
    const ua = window.navigator.userAgent.toLowerCase();
    if ((ua.indexOf('iphone') !== -1 || ua.indexOf('ipad') !== -1 || (ua.indexOf('macintosh') && 'ontouchend' in document))
      || ua.indexOf('android') !== -1) {
      this.movePage('Sphone');
    }
  },
  methods: {
    ...mapActions('admin', ['deleteAdminInfo']),
    active(target) {
      this.isActive[target] = !this.isActive[target];
    },
    async logout() {
      const result = await this.deleteAdminInfo(this.adminInfo.token);
      if (result === 401) {
        this.$store.commit('admin/logout');
        this.movePage('AdminLogin', '', { url: this.$route.path });
      }
      if (result) {
        this.movePage('AdminLogin');
      } else {
        throw new Error();
      }
    },
  },
};
</script>

<style scoped>
.layout-header__main {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 67px;
  padding: 0 20px;
  background-color: #000;
  box-shadow:  0 -5px 10px 10px rgba(0, 0, 0, 0.04);
}

.layout-header__main--local {
  background-color: #00d47c;
}

.layout-header__main--stg {
  background-color: #2E64D4;
}

.layout-header__main--prd {
  background-color: #E74860;
}

.layout-header__main__left {
  display: flex;
  align-items: center;
}

.layout-header__main__left__text{
  color: #fff;
  font-family: all-round-gothic, sans-serif;
  font-weight: 600;
  margin: 5px 0 0 10px;
}

.layout-header__main__left__env {
  font-weight: 600;
  background-color: #fff;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 14px;
  margin-left: 20px;
}

.layout-header__overlay {
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.layout-header__dropdown {
  position: absolute;
  top: 85px;
  right:20px;
  z-index: 999;
}
</style>
