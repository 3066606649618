<template>
  <router-link
    class='lesson-card-simple'
    :to='{ name: "LessonDetail", params: { id: lessonInfo.id }}'
  >
    <div class='lesson-card-simple__container'>
      <img
        class='lesson-card-simple__container__thumbnail'
        :src='lessonInfo.image'
        alt='レッスンのサムネイル'
        @error='setErrorThumbnail'>
      <div class='lesson-card-simple__container__info'>
        <div class='lesson-card-simple__container__info__top'>
          <div class='lesson-card-simple__container__info__top__author'>
            <p>{{ setUsername }}</p>
            <img :src='setAvatar' alt='avatar ユーザーアイコン' @error='setErrorAvatar'>
          </div>
          <h3 class='lesson-card-simple__container__info__top__title'>{{ lessonInfo.title }}</h3>
          <div class='lesson-card-simple__container__info__top__sub'>
            <icon-level
              class='lesson-card-simple__container__info__top__sub__level'
              :level='lessonInfo.level'
            />
            <review-star-multi :score='lessonInfo.evaluation' />
          </div>
          <p class='lesson-card-simple__container__info__top__description'>
            {{ lessonInfo.description }}
          </p>
        </div>
        <div class='lesson-card-simple__container__info__bottom'>
          <icon-post-date :date='lessonInfo.updatedDate'>UPDATE</icon-post-date>
          <p class='lesson-card-simple__container__info__bottom__price'>{{ setPrice }}</p>
        </div>
      </div>
    </div>
    <img class='lesson-card-simple__back' src='@/assets/img/lessoncard-simple-back.svg' alt=''>
  </router-link>
</template>

<script>
import { ReviewStarMulti } from '@/components/atoms/reviewStar';
import { IconLevel, IconPostDate } from '@/components/atoms/icon';

export default {
  components: {
    ReviewStarMulti,
    IconLevel,
    IconPostDate,
  },
  props: {
    lessonInfo: Object,
    levelLabel: Array,
  },
  computed: {
    setPrice() {
      if (this.lessonInfo.price === 0) {
        return '無料';
      }
      return `¥${this.lessonInfo.price}`;
    },
    setUsername() {
      if (this.lessonInfo.author === null) return '削除されたユーザー';
      return this.lessonInfo.author;
    },
    setAvatar() {
      if (this.lessonInfo.avatar === null) return `${process.env.VUE_APP_S3_URL}/image/avatar/unknown.png`;
      return this.lessonInfo.avatar;
    },
  },
  methods: {
    setErrorAvatar(e) {
      e.target.src = `${process.env.VUE_APP_S3_URL}/image/avatar/unknown.png`;
    },
    setErrorThumbnail(e) {
      e.target.src = `${process.env.VUE_APP_S3_URL}/image/thumbnail/manabiba.png`;
    },
  },
};
</script>

<style scoped>
.lesson-card-simple {
  background-color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 260px;
  position: relative;
}

.lesson-card-simple__container {
  transition: all 0.5s ease;
  z-index: 2;
}

.lesson-card-simple__container__thumbnail {
  width: 100%;
  height: 146px;
}

.lesson-card-simple__container__info {
  padding: 15px;
  text-align: left;
  width: 100%;
  background-color: #fff;
  margin-top: -5px;
}

.lesson-card-simple__container__info__top {
  height: 209px;
}

.lesson-card-simple__container__info__top__author {
  position: relative;
  margin-bottom: 15px;
}

.lesson-card-simple__container__info__top__author p {
  font-size: 14px;
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lesson-card-simple__container__info__top__author img {
  width: 60px;
  border: 2px solid #fff;
  border-radius: 60px;
  position: absolute;
  top: -25px;
  right: -5px;
}

.lesson-card-simple__container__info__top__title {
  font-size: 16px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  font-weight: 500;
}

.lesson-card-simple__container__info__top__sub {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.lesson-card-simple__container__info__top__sub__level {
  margin: 2px 5px 0 0;
}

.lesson-card-simple__container__info__top__description {
  font-size: 12px;
  font-weight: 400;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-all;
}

.lesson-card-simple__container__info__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lesson-card-simple__container__info__bottom__price {
  font-weight: 500;
  font-size: 20px;
}

.lesson-card-simple__back {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.lesson-card-simple:hover .lesson-card-simple__container {
  transform: translate(-3%, -2.3%);
  transition: all 0.5s ease;
  top: 5px;
  right: -50px;
}
</style>
