<template>
  <div class='review-star-multi'>
    <div class='review-star-multi__star'>
      <p class='review-star-multi__star__front' :style='setWidth'>★★★</p>
      <p class='review-star-multi__star__back'>★★★</p>
    </div>
    <p :class='`review-star-multi__score ${white? "white" : ""}`'>{{ setDisplayScore }}</p>
  </div>
</template>

<script>
export default {
  props: {
    score: Number,
    white: Boolean,
  },
  computed: {
    addClass() {
      return {
        white: this.white,
      };
    },
    setDisplayScore() {
      // if (this.score === 0) {
      //   return 'まだ評価がありません';
      // }
      return this.score.toFixed(1);
    },
    setWidth() {
      return `width: ${this.score.toFixed(1) * 33.3}%;`;
    },
  },
};
</script>

<style scoped>
.review-star-multi {
  display: flex;
  align-items: center;
}

.review-star-multi__star {
  position: relative;
}

.review-star-multi__star__front {
  position: absolute;
  top: 0;
  left: 0;
  color: #E74860;
  overflow: hidden;
  font-size: 20px;
}

.review-star-multi__star__back {
  color: rgba(174, 178, 194, 0.4);
  font-size: 20px;
}

.review-star-multi__score {
  margin-left: 5px;
  font-size: 16px;
}

.white {
  color: #fff;
}
</style>
