<template>
  <div class='modal-confirm'>
    <div class='modal-confirm__main'>
      <h3 class='modal-confirm__main__title'>{{ title }}</h3>
      <p class='modal-confirm__main__text'>{{ text }}</p>
      <img
        v-if="!isLoading"
        :src="qrCode"
        alt='QRコード'
      >
      <icon-loading v-if='isLoading'/>
      <heading-normal small>表示された6桁の数字を入力してください</heading-normal>
      <input-text :maxLength=maxLength @emit='saveToken' />
      <div class='modal-confirm__main__btn'>
        <button-normal
          class='modal-confirm__main__btn__cancel'
          @emit='$emit("emit", { answer: false }), switchOverflow()'
          outline
        >{{ cancelBtn }}</button-normal>
        <button-normal
          :disabled="isLoading || !token"
          @emit='$emit("emit", {
            answer: true,
            token: token,
            secret: secret
          }),switchOverflow()'
        >{{ acceptBtn }}</button-normal>
      </div>
    </div>
    <div
      class='modal-confirm__overlay'
      v-if='!disabledOverlay'
      @click='$emit("emit", false),
      switchOverflow()'
    />
    <div
      class='modal-confirm__overlay'
      v-if='disabledOverlay'
    />
  </div>
</template>

<script>
import speakeasy from 'speakeasy';
import QRCode from 'qrcode';
import { HeadingNormal } from '@/components/atoms/heading/';
import { IconLoading } from '@/components/atoms/icon';
import { InputText } from '@/components/atoms/input';
import { ButtonNormal } from '@/components/atoms/button';

export default {
  components: {
    HeadingNormal,
    IconLoading,
    InputText,
    ButtonNormal,
  },
  props: {
    title: String,
    text: String,
    acceptBtn: String,
    cancelBtn: String,
    disabledOverlay: Boolean,
    isLoading: Boolean,
  },
  data() {
    return {
      qrCode: '',
      secret: '',
      token: '',
      maxLength: 6,
    };
  },
  created() {
    this.secret = speakeasy.generateSecret({ length: 20 });
    QRCode.toDataURL(this.secret.otpauth_url, (err, dataUrl) => {
      this.qrCode = dataUrl;
    });
  },
  mounted() {
    this.switchOverflow();
  },
  methods: {
    // modalが立ち上がってる時はスクロールできないよう制御
    switchOverflow() {
      const body = document.querySelector('body');
      if (body.style.overflow === '') {
        body.style.overflow = 'hidden';
      } else {
        body.style.overflow = '';
      }
    },
    saveToken(token) {
      this.token = token;
    },
  },
  destroyed() {
    const body = document.querySelector('body');
    body.style.overflow = '';
  },
};
</script>

<style scoped>
.modal-confirm__main {
  background-color: #fff;
  text-align: center;
  position: fixed;
  padding: 30px;
  width: 550px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 998;
}

.modal-confirm__main__title {
  font-size: 20px;
  margin-bottom: 10px;
}

.modal-confirm__main__text {
  font-size: 14px;
}

.modal-confirm__main__btn {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.modal-confirm__main__btn__cancel {
  margin-right: 40px;
}

.modal-confirm__overlay {
  position: fixed;
  z-index: 997;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
