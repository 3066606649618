var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "section-menu" }, [
    _c("div", { staticClass: "section-menu__back" }),
    _c(
      "div",
      { staticClass: "section-menu__contents" },
      [
        _c("icon-user", {
          staticClass: "section-menu__contents__icon",
          attrs: { src: _vm.setAvatar, xlarge: "" }
        }),
        _c("h3", { staticClass: "section-menu__contents__username" }, [
          _vm._v(_vm._s(_vm.userInfo.profile.userName))
        ]),
        _c("div", { staticClass: "section-menu__contents__count" }, [
          _c("p", { staticClass: "section-menu__contents__count__clear" }, [
            _vm._v(
              " クリアレッスン数: " +
                _vm._s(_vm.userInfo.profile.countClear) +
                " "
            )
          ]),
          _c("p", { staticClass: "section-menu__contents__count__date" }, [
            _vm._v(
              " 学習日数: " + _vm._s(_vm.userInfo.profile.countLearn) + " "
            )
          ])
        ]),
        _c("list-menu", {
          staticClass: "section-menu__contents__menu",
          attrs: {
            targetList: _vm.pageList,
            defaultSelectId: _vm.defaultSelectId
          },
          on: { emit: _vm.passEmit }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }