<template>
  <button
    class='button-cart'
    @mouseover='isActive = true'
    @mouseleave='isActive = false'
  >
    <i class='fas fa-shopping-cart' />
    <p
      class='button-cart__count'
      v-if='countCart !== 0'
    >
      {{ countCart }}
    </p>
    <transition class='button-cart__hover'>
      <div
        class='button-cart__hover__bar'
        v-if='isActive'
      />
    </transition>
  </button>
</template>

<script>
export default {
  props: {
    countCart: Number,
  },
  data() {
    return {
      isActive: false,
    };
  },
};
</script>

<style scoped>
.button-cart {
  position: relative;
}

.button-cart i {
  font-size: 20px;
  color: #fff;
}

.button-cart__count {
  position: absolute;
  top: -5px;
  right: 2px;
  transform: translateX(50%);
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  background-color: #E74860;
  color: #fff;
  width: 15px;
  height: 15px;
  border-radius: 20px;
}

.button-cart__hover__bar {
  position: absolute;
  width: 17px;
  height: 4px;
  background-color: #d1ae00;
  border-radius: 20px;
  bottom: -18px;
  left: 5px;
}

.v-enter-active, .v-leave-active {
  transition: opacity 0.05s;
}

.v-enter, .v-leave-to {
  opacity: 0;
}
</style>
