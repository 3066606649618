var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-header" },
    [
      _c("notifications", { attrs: { group: "top-right" } }),
      _c(
        "div",
        { class: _vm.addClass },
        [
          _c(
            "div",
            { staticClass: "layout-header__main__left" },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "AdminLessons" } } },
                [_c("layout-logo", { attrs: { white: "", small: "" } })],
                1
              ),
              _c("p", { staticClass: "layout-header__main__left__text" }, [
                _vm._v("Admin")
              ]),
              _c("p", { staticClass: "layout-header__main__left__env" }, [
                _vm._v(_vm._s(_vm.setEnvName))
              ])
            ],
            1
          ),
          _c(
            "button-username",
            {
              staticClass: "layout-header__main__right",
              attrs: { isActive: _vm.isActive.dropdownUser },
              on: {
                emit: function($event) {
                  return _vm.active("dropdownUser")
                }
              }
            },
            [_vm._v(_vm._s(_vm.adminInfo.profile.userName))]
          )
        ],
        1
      ),
      _vm.isActive.dropdownUser
        ? _c(
            "dropdown-header-user",
            {
              staticClass: "layout-header__dropdown",
              on: { emit: _vm.logout }
            },
            [_vm._v("ログアウト")]
          )
        : _vm._e(),
      _vm.isActive.dropdownUser
        ? _c("div", {
            staticClass: "layout-header__overlay",
            on: {
              click: function($event) {
                return _vm.active("dropdownUser")
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }