<template>
  <ul class='list-menu'>
    <li class='list-menu__list' v-for='(target, index) in targetList' :key='target.id'>
      <button
        :class='{"focus" : selectId === index }'
        :disabled='selectId === index'
        @click="$emit('emit', index), changeFocus(index)"
      >
        <i class="fa fa-chevron-right list-menu__list__arrow"></i>
        {{ target }}
      </button>
    </li>
  </ul>
</template>
<script>

export default {
  props: {
    targetList: Array,
    defaultSelectId: Number,
  },
  data() {
    return {
      selectId: 0,
    };
  },
  mounted() {
    if (this.defaultSelectId) {
      this.selectId = this.defaultSelectId;
    }
  },
  methods: {
    changeFocus(index) {
      this.selectId = index;
    },
  },
};
</script>

<style scoped>
.list-menu__list {
  list-style: none;
}

.list-menu__list button {
  padding: 15px;
  font-size: 14px;
  width: 100%;
  text-align: left;
}

.list-menu__list__arrow {
  margin-right: 5px;
}

.focus {
  background-color: #F9F9FA;
  cursor: default;
}
</style>
