var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "section-user-info" },
    [
      !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "section-user-info__contents center" },
            [
              _c("icon-user", { attrs: { src: _vm.setAvatar, xlarge: "" } }),
              _c("p", { staticClass: "section-user-info__username" }, [
                _vm._v(_vm._s(_vm.userName))
              ]),
              _vm.ambassador
                ? _c("icon-role", {
                    staticClass: "section-user-info__ambassador",
                    attrs: { ambassador: "" }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.isLoading
        ? _c("icon-loading", { staticClass: "section-user-info__load" })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }