var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "button-notice",
      on: {
        mouseover: function($event) {
          _vm.isActive = true
        },
        mouseleave: function($event) {
          _vm.isActive = false
        }
      }
    },
    [
      _c("i", { staticClass: "fas fa-bell" }),
      _c("transition", { staticClass: "button-notice__hover" }, [
        _vm.isActive
          ? _c("div", { staticClass: "button-notice__hover__bar" })
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }