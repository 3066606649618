<template>
  <div class='create'>
    <LayoutHeader />
    <div class='create__contents'>
      <list-menu
        class='create__contents__left'
        :targetList='createTitleList'
        :defaultSelectId=3
        @emit='movePageMenu'
      />
      <div class='create__contents__right'>
        <title-normal
          class='create__contents__right__title'
          :title='titleBar.title'
          :button='titleBar.button'
          :disabled='!submitInfo.email'
          :loading='isLoading'
          @emit='sendSignupEmail'
        />
        <div class='create__contents__right__form'>
          <div class='create__contents__right__form__email'>
            <heading-normal small>{{ form.email }}</heading-normal>
            <input-text @emit='saveEmail' />
          </div>
          <div class='create__contents__right__form__auth'>
            <heading-normal small>{{ form.auth }}</heading-normal>
            <select v-model='submitInfo.auth'>
              <option
                v-for='(type, index) in types'
                :value='type.id'
                :key='index'
              >{{ type.name }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <modal-normal
      v-if='isActive.modalPost'
      title='メール送信完了'
      text='管理ユーザー招待メールを入力したメールアドレスに送信しました'
      acceptBtn='管理ユーザー一覧へ戻る'
      @emit='movePage("AdminAdminUserList")'
      disabledOverlay
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import helper from '@/mixins/admin/methods/helper';
import pageList from '@/mixins/admin/data/pageList';
import LayoutHeader from '@/components/layouts/admin/LayoutHeader';
import { TitleNormal, ModalNormal } from '@/components/molecules/other';
import { HeadingNormal } from '@/components/atoms/heading';
import { InputText } from '@/components/atoms/input';
import { ListMenu } from '@/components/atoms/list';

export default {
  components: {
    LayoutHeader,
    TitleNormal,
    ModalNormal,
    HeadingNormal,
    InputText,
    ListMenu,
  },
  data() {
    return {
      isActive: {
        modalPost: false,
      },
      types: [
        {
          id: 1,
          name: '閲覧者',
        },
        {
          id: 99,
          name: '管理者',
        },
      ],
      titleBar: {
        title: '管理ユーザー新規作成',
        button: '送信',
      },
      form: {
        email: 'メールアドレス',
        auth: '権限',
      },
      submitInfo: {
        email: '',
        auth: 1,
      },
      isLoading: false,
    };
  },
  mixins: [helper, pageList],
  computed: {
    ...mapState('admin', ['adminInfo']),
    createTitleList() {
      const titleList = this.pageList.map((page) => page.title);
      return titleList;
    },
  },
  methods: {
    ...mapActions('admin', ['createAdmin']),
    active(target) {
      this.isActive[target] = !this.isActive[target];
    },
    movePageMenu(index) {
      const { name } = this.pageList[index];
      this.$router.push({ name }, () => {});
    },
    saveEmail(email) {
      this.submitInfo.email = email;
    },
    async sendSignupEmail() {
      try {
        this.isLoading = !this.isLoading;
        const result = await this.createAdmin({
          email: this.submitInfo.email,
          status: this.submitInfo.auth,
          token: this.adminInfo.token,
        });
        if (result === 401) {
          this.$store.commit('admin/logout');
          this.movePage('AdminLogin', '', { url: this.$route.path });
        }
        if (result === false) throw new Error();
        this.isLoading = !this.isLoading;
        this.active('modalPost');
      } catch (e) {
        this.isLoading = !this.isLoading;
        this.showNoticeBar('error', '管理ユーザーの作成に失敗しました');
      }
    },
  },
};
</script>

<style scoped>
.create {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f9fa;
}

.create__contents {
  display: flex;
  margin: 20px;
}

.create__contents__left {
  width: 15%;
  background-color: #fff;
  padding: 20px;
}

.create__contents__right {
  width: 85%;
  margin-left: 20px;
}

.create__contents__right__title {
  margin-bottom: 10px;
}

.create__contents__right__form{
  padding: 20px;
  padding-right: 50%;
  width: 100%;
  text-align: left;
  background-color: #fff;
}

.create__contents__right__form__email {
  margin-bottom: 20px;
}

.create__contents__right__form__auth select {
  -webkit-appearance: menulist;
  border: 2px solid #d1d3d9;
  font-size: 14px;
  outline: none;
}
</style>
